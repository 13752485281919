import React, { useState, useContext, useEffect, useRef } from "react";
import {Link} from 'react-router-dom';


import classes from './TopNavigation.module.css';
import FavoritesContext from '../../store/favourites-context';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
import $ from 'jquery'; 

import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';


function TopNavigation() {

    const favcontxt=useContext(FavoritesContext);

    let content;
    let greetings;
    let dt1=new Date();
    if(dt1.getHours() > 12) {
        greetings='Good evening ';
    } else {
        greetings='Good morning ';
    }
    let name=greetings + favcontxt.name;

    if (favcontxt.colid==0) {
        content= <ul>
        <li>
            <Link to='/new'>Login</Link>
        </li>
    </ul>

    } else if (favcontxt.role=="Student") {
       content= <ul>
                    <li>
                        <Link to='/open'>Open Classes</Link>
                    </li>
                    <li>
                        <Link to='/exam'>Exam</Link>
                    </li>
                    <li>
                        <Link to='/new'>Log out</Link>
                    </li>
                </ul>

    } else if (favcontxt.role=="Faculty") {
        content=<ul>
                   
                    <li>
                    <DropdownButton id="dropdown-basic-button" title="Data">
                       
                         <div style={{marginBottom: 10}}>
                            <Link to='/viewnpaunewidetails' style={{marginLeft:23}}>Enter data</Link>
                        </div>
                  
                      



                      
                        
                    

                        {/* <DropdownButton id="dropdown-basic-button1" title="Yearly metric">
                            <div style={{marginBottom: 10}}>
                                <Link to='/viewexplearnproj' style={{marginLeft:23}}>Criteria 1</Link>
                            </div>
                            <div style={{marginBottom: 10}}>
                                <Link to='/viewteacherguide' style={{marginLeft:23}}> Criteria 2 </Link>
                            </div>
                            <div style={{marginBottom: 10}}>
                                <Link to='/viewmouact' style={{marginLeft:23}}> Criteria 3</Link>
                            </div>
                            <div style={{marginBottom: 10}}>
                                <Link to='/viewict' style={{marginLeft:23}}> Criteria 4</Link>
                            </div>
                            <div style={{marginBottom: 10}}>
                                <Link to='/viewsportsact' style={{marginLeft:23}}> Criteria 5</Link>
                            </div>
                            <div style={{marginBottom: 10}}>
                                <Link to='/viewegov' style={{marginLeft:23}}> Criteria 6</Link>
                            </div>
                        </DropdownButton> */}
                    
                     
                        
                        
                       
</DropdownButton>
                    </li>
                  
                    <li>
                    <DropdownButton id="dropdown-basic-button" title="Tickets">
                       
                       <div style={{marginBottom: 10}}>
                           <Link to='/viewntickets' style={{marginLeft:23}}>Add tickets</Link>
                       </div>
                       {/* <div style={{marginBottom: 10}}>
                           <Link to='/viewtaskticket' style={{marginLeft:23}}>View all tickets</Link>
                       </div> */}
                      
                      
</DropdownButton>
                       
                    </li>
                 
                 
                    <li>
                        <Link to='/new'>Log out</Link>
                    </li>
                </ul>

    } else if (favcontxt.role=="HoD") {
        content=<ul>
                   
                    <li>
                    <DropdownButton id="dropdown-basic-button" title="Data">
                        {/* <div style={{marginBottom: 10}}>
                            <Link to='/viewlibbooksfaculty' style={{marginLeft:23}}>OPAC</Link>
                        </div> */}
                        {/* <div style={{marginBottom: 10}}>
                            <Link to='/dashcriteria1' style={{marginLeft:23}}>Quick View</Link>
                        </div>
                        <div style={{marginBottom: 10}}>
                            <Link to='/viewalerts' style={{marginLeft:23}}>View Alerts</Link>
                        </div> */}
                        <div style={{marginBottom: 10}}>
                            <Link to='/selectperiodlms' style={{marginLeft:23}}>Select LMS Period</Link>
                        </div>
                        <div style={{marginBottom: 10}}>
                            <Link to='/selectperiod' style={{marginLeft:23}}>Select period</Link>
                        </div>
                        
                        {/* <div style={{marginBottom: 10}}>
                            <Link to='/viewkpi' style={{marginLeft:23}}>View KPI</Link>
                        </div> */}

                        <div style={{marginBottom: 10}}>
                            <Link to='/viewsdg3' style={{marginLeft:23}}>THE SDG</Link>
                        </div>
                        {/* <div style={{marginBottom: 10}}>
                            <Link to='/dsyllabusrevd' style={{marginLeft:23}}>All comments</Link>
                        </div> */}

                        <div style={{marginBottom: 10}}>
                            <Link to='/viewleavependinghod' style={{marginLeft:23}}>Approve Leave (HoD)</Link>
                        </div>

                        <DropdownButton id="dropdown-basic-button1" title="Metric wise data">
                            <div style={{marginBottom: 10}}>
                                <Link to='/viewbos' style={{marginLeft:23}}>Criteria 1</Link>
                            </div>
                            <div style={{marginBottom: 10}}>
                                <Link to='/viewadmission' style={{marginLeft:23}}> Criteria 2 </Link>
                            </div>
                            <div style={{marginBottom: 10}}>
                                <Link to='/viewextawards' style={{marginLeft:23}}> Criteria 3</Link>
                            </div>
                            <div style={{marginBottom: 10}}>
                                <Link to='/viewict' style={{marginLeft:23}}> Criteria 4</Link>
                            </div>
                            <div style={{marginBottom: 10}}>
                                <Link to='/viewskilldev' style={{marginLeft:23}}> Criteria 5</Link>
                            </div>
                            <div style={{marginBottom: 10}}>
                                <Link to='/viewegovern' style={{marginLeft:23}}> Criteria 6</Link>
                            </div>
                        </DropdownButton>

                       

                        <DropdownButton id="dropdown-basic-button1" title="Departmental data">
                            <div style={{marginBottom: 10}}>
                                <Link to='/viewdeppubmy' style={{marginLeft:23}}>Publications</Link>
                            </div>
                            <div style={{marginBottom: 10}}>
                                <Link to='/viewdepseminarmy' style={{marginLeft:23}}>Seminar</Link>
                            </div>
                            <div style={{marginBottom: 10}}>
                                <Link to='/viewdepprojectsmy' style={{marginLeft:23}}>Projects</Link>
                            </div>
                            <div style={{marginBottom: 10}}>
                                <Link to='/viewdepbooksmy' style={{marginLeft:23}}>Books</Link>
                            </div>
                           
                        </DropdownButton>

                      
                        
                    
                        {/* <DropdownButton id="dropdown-basic-button1" title="Metric wise data">
                            <div style={{marginBottom: 10}}>
                                <Link to='/viewbos' style={{marginLeft:23}}>Criteria 1</Link>
                            </div>
                            <div style={{marginBottom: 10}}>
                                <Link to='/viewadmission' style={{marginLeft:23}}> Criteria 2 </Link>
                            </div>
                            <div style={{marginBottom: 10}}>
                                <Link to='/viewextawards' style={{marginLeft:23}}> Criteria 3</Link>
                            </div>
                            <div style={{marginBottom: 10}}>
                                <Link to='/viewict' style={{marginLeft:23}}> Criteria 4</Link>
                            </div>
                            <div style={{marginBottom: 10}}>
                                <Link to='/viewskilldev' style={{marginLeft:23}}> Criteria 5</Link>
                            </div>
                            <div style={{marginBottom: 10}}>
                                <Link to='/viewegovern' style={{marginLeft:23}}> Criteria 6</Link>
                            </div>
                        </DropdownButton> */}

                        {/* <DropdownButton id="dropdown-basic-button1" title="Yearly metric">
                            <div style={{marginBottom: 10}}>
                                <Link to='/viewexplearnproj' style={{marginLeft:23}}>Criteria 1</Link>
                            </div>
                            <div style={{marginBottom: 10}}>
                                <Link to='/viewteacherguide' style={{marginLeft:23}}> Criteria 2 </Link>
                            </div>
                            <div style={{marginBottom: 10}}>
                                <Link to='/viewmouact' style={{marginLeft:23}}> Criteria 3</Link>
                            </div>
                            <div style={{marginBottom: 10}}>
                                <Link to='/viewict' style={{marginLeft:23}}> Criteria 4</Link>
                            </div>
                            <div style={{marginBottom: 10}}>
                                <Link to='/viewsportsact' style={{marginLeft:23}}> Criteria 5</Link>
                            </div>
                            <div style={{marginBottom: 10}}>
                                <Link to='/viewegov' style={{marginLeft:23}}> Criteria 6</Link>
                            </div>
                        </DropdownButton> */}
                    
                     
                        
                        
                       
</DropdownButton>
                    </li>
                  
                    <li>
                    <DropdownButton id="dropdown-basic-button" title="Tickets">
                       
                       <div style={{marginBottom: 10}}>
                           <Link to='/viewntickets' style={{marginLeft:23}}>Add tickets</Link>
                       </div>
                       {/* <div style={{marginBottom: 10}}>
                           <Link to='/viewtaskticket' style={{marginLeft:23}}>View all tickets</Link>
                       </div> */}
                      
                      
</DropdownButton>
                       
                    </li>
                 
                    <li>
                        <Link to='/dashcourse'>Courses
                        <span className={classes.badge}>
                            {favcontxt.course}
                        </span>
                        </Link>
                    </li>
                    <li>
                        <Link to='/new'>Log out</Link>
                    </li>
                </ul>

    } else if (favcontxt.role=="Admin") {
        content=<ul>
            <li>
                    <DropdownButton id="dropdown-basic-button" title="Data">
                       
                        <div style={{marginBottom: 10}}>
                            <Link to='/viewnpaunewidetailsadmin' style={{marginLeft:23}}>Quick View</Link>
                        </div>
                        <div style={{marginBottom: 10}}>
                            <Link to='/selectperiod' style={{marginLeft:23}}>Select period</Link>
                        </div>
                        
                       
</DropdownButton>
                    </li>
                
                    <li>
                    <DropdownButton id="dropdown-basic-button" title="Tools">
                       
                        {/* <div style={{marginBottom: 10}}>
                            <Link to='/libraryactions' style={{marginLeft:23}}>Library</Link>
                        </div> */}
                        <div style={{marginBottom: 10}}>
                            <Link to='/selectinstitution' style={{marginLeft:23}}>Select institution</Link>
                        </div>
                        <DropdownButton id="dropdown-basic-button" title="Tickets">
                       
                       <div style={{marginBottom: 10}}>
                           <Link to='/viewntickets' style={{marginLeft:23}}>Add tickets</Link>
                       </div>
                       {/* <div style={{marginBottom: 10}}>
                           <Link to='/viewtaskticket' style={{marginLeft:23}}>View all tickets</Link>
                       </div> */}
                      
                      
</DropdownButton>
                      
                       
</DropdownButton>
                    </li>
                   
                    
                    <li>
                    
                       
                    </li>
                   
                    <li>
                        <Link to='/new'>Log out</Link>
                    </li>
                </ul>

    } else if (favcontxt.role=="Super") {
        content=<ul>
            <li>
                    <DropdownButton id="dropdown-basic-button" title="NAAC">
                       
                        <div style={{marginBottom: 10}}>
                            <Link to='/dashcriteria1' style={{marginLeft:23}}>Quick View</Link>
                        </div>
                        <div style={{marginBottom: 10}}>
                            <Link to='/viewsuperfaculty' style={{marginLeft:23}}>Super faculties</Link>
                        </div>
                        <div style={{marginBottom: 10}}>
                            <Link to='/viewalerts' style={{marginLeft:23}}>View Alerts</Link>
                        </div>
                        <div style={{marginBottom: 10}}>
                            <Link to='/viewkpi' style={{marginLeft:23}}>View KPI</Link>
                        </div>
                        <div style={{marginBottom: 10}}>
                            <Link to='/viewnmeetingtaskspending' style={{marginLeft:23}}>All pending departmental tasks</Link>
                        </div>
                        <div style={{marginBottom: 10}}>
                            <Link to='/viewnmeetingtasksallclients' style={{marginLeft:23}}>All departmental tasks</Link>
                        </div>
                        <hr />
                        <div style={{marginBottom: 10}}>
                            <Link to='/viewaddoncourseadmin' style={{marginLeft:23}}>Metric data</Link>
                        </div>
                        <div style={{marginBottom: 10}}>
                            <Link to='/viewprojectyrdocs' style={{marginLeft:23}}>Yearly data</Link>
                        </div>
                        <div style={{marginBottom: 10}}>
                            <Link to='/selectperiod' style={{marginLeft:23}}>Select period</Link>
                        </div>
                       
</DropdownButton>
                    </li>
                    <li>
                    <DropdownButton id="dropdown-basic-button" title="Super">
                       
                        <div style={{marginBottom: 10}}>
                            <Link to='/viewsuperweekly' style={{marginLeft:23}}>Weekly report</Link>
                        </div>
                        {/* <div style={{marginBottom: 10}}>
                            <Link to='/viewtaskticketall' style={{marginLeft:23}}>View all tickets</Link>
                        </div> */}
                        <div style={{marginBottom: 10}}>
                            <Link to='/viewinstitutions' style={{marginLeft:23}}>View all institutions</Link>
                        </div>
                        <div style={{marginBottom: 10}}>
                            <Link to='/viewmetricrules' style={{marginLeft:23}}>View metric rules</Link>
                        </div>
                        <div style={{marginBottom: 10}}>
                            <Link to='/viewnsop' style={{marginLeft:23}}>SOP</Link>
                        </div>
                        <div style={{marginBottom: 10}}>
                            <Link to='/viewkpipending' style={{marginLeft:23}}>Pending KPI</Link>
                        </div>
                       
</DropdownButton>
                    </li>
                    <li>
                    <DropdownButton id="dropdown-basic-button" title="THE Impact">
                        <div style={{marginBottom: 10, paddingLeft: 10}}>
                            <Link to='/viewsdg'>Sustainable Development Goal</Link>
                        </div>
          
</DropdownButton>
                    </li>
                    <li>
                        <Link to='/libraryactions'>Library</Link>
                    </li>
                    <li>
                        <Link to='/dashcourse'>Courses
                        <span className={classes.badge}>
                            {favcontxt.course}
                        </span>
                        </Link>
                    </li>
                    <li>
                        <Link to='/new'>Log out</Link>
                    </li>
                </ul>

    }




    return (
        <div className={classes.header}>
            <div className={classes.logo}>{name}</div>
            <nav>
                {content}
            </nav>
        </div>
    );

}

export default TopNavigation;