import React, { useState, useContext, useEffect, useRef } from "react";
import {Link} from 'react-router-dom';


import classes from './MainNavigation1.module.css';
import classes1 from './Sideicon.module.css';
import FavoritesContext from '../../store/favourites-context';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faFileAlt, faIdCard, faDatabase, faVideo, faGlasses, faComment, faBoxes, faSignal, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus, faUser } from '@fortawesome/free-solid-svg-icons';



function Sideicon() {

    const favcontxt=useContext(FavoritesContext);

    let content;

    if (favcontxt.role=="Student") {
        content= <ul>
                     <li>
                         <Link to='/open'>Open Classes</Link>
                     </li>
                     <li>
                         <Link to='/exam'>Exam</Link>
                     </li>
                     <li>
                         <Link to='/new'>Log out</Link>
                     </li>
                 </ul>
 
     } else if (favcontxt.role=="Faculty") {
         content=
         <div>
         <Link to='/dashanna1'> <FontAwesomeIcon icon={faHome} style={{fontSize: 24, color: '#FA5638', marginTop: 10, marginBottom: 5, alignSelf: 'center' }} /></Link>
         <Link to='/viewnpaunewidetails'> <FontAwesomeIcon icon={faVideo} style={{fontSize: 24, color: '#1E78D6', marginTop: 5, marginBottom: 5, alignSelf: 'center' }} /></Link>
         <Link to='/dashmydetails'> <FontAwesomeIcon icon={faIdCard} style={{fontSize: 27, color: '#580C77', marginTop: 5, marginBottom: 5,marginLeft:2, alignSelf: 'center' }} /></Link>
        <Link to='/viewnpaungovcouncil'> <FontAwesomeIcon icon={faBook} style={{fontSize: 24, color: '#811C09', marginLeft:2, marginTop: 5, marginBottom: 5, alignSelf: 'center' }} title="Library" /></Link>
        <Link to='/viewnpauragcomt'> <FontAwesomeIcon icon={faBook} style={{fontSize: 24, color: '#811C09', marginLeft:2, marginTop: 5, marginBottom: 5, alignSelf: 'center' }} title="Library" /></Link>
        
        <Link to='/getmyfeedback'> <FontAwesomeIcon icon={faComment} style={{fontSize: 24, color: '#C96130', marginBottom: 5, marginTop: 5, alignSelf: 'center'}} /></Link>
        {/* <Link to='/dashcriteria1'> <FontAwesomeIcon icon={faGlasses} style={{fontSize: 24, color: '#FFC433', marginBottom: 15, marginTop: 5, alignSelf: 'center' }} /></Link> */}
        {/* <Link to='/assetlist'> <FontAwesomeIcon icon={faBoxes} style={{fontSize: 24, color: '#4DAE19', marginBottom: 15, marginTop: 5, alignSelf: 'center' }} /></Link> */}
        {/* <Link to='/viewuniversitymetric'> <FontAwesomeIcon icon={faDatabase} style={{fontSize: 24, color: '#4DAE19', marginBottom: 15, marginTop: 5, alignSelf: 'center' }} /></Link> */}


       
      
        </div>
     } else if (favcontxt.role=="HoD") {
        content=
        <div>
        <Link to='/dasherp1'> <FontAwesomeIcon icon={faHome} style={{fontSize: 24, color: '#FA5638', marginTop: 10, marginBottom: 5, alignSelf: 'center' }} /></Link>
        {/* <Link to='/workload'> <FontAwesomeIcon icon={faVideo} style={{fontSize: 24, color: '#1E78D6', marginTop: 5, marginBottom: 5, alignSelf: 'center' }} /></Link> */}
        <Link to='/dashmydetails'> <FontAwesomeIcon icon={faIdCard} style={{fontSize: 27, color: '#580C77', marginTop: 5, marginBottom: 5,marginLeft:2, alignSelf: 'center' }} /></Link>
       {/* <Link to='/viewlibbooksfaculty'> <FontAwesomeIcon icon={faBook} style={{fontSize: 24, color: '#811C09', marginLeft:2, marginTop: 5, marginBottom: 5, alignSelf: 'center' }} title="Library" /></Link> */}
       <Link to='/libraryactions'> <FontAwesomeIcon icon={faBook} style={{fontSize: 24, color: '#811C09', marginLeft:2, marginTop: 5, marginBottom: 5, alignSelf: 'center' }} title="Library" /></Link>
       
       {/* <Link to='/viewalerts'> <FontAwesomeIcon icon={faComment} style={{fontSize: 24, color: '#C96130', marginBottom: 5, marginTop: 5, alignSelf: 'center'}} /></Link> */}
       {/* <Link to='/dashcriteria1'> <FontAwesomeIcon icon={faGlasses} style={{fontSize: 24, color: '#FFC433', marginBottom: 15, marginTop: 5, alignSelf: 'center' }} /></Link> */}
       {/* <Link to='/assetlist'> <FontAwesomeIcon icon={faBoxes} style={{fontSize: 24, color: '#4DAE19', marginBottom: 15, marginTop: 5, alignSelf: 'center' }} /></Link> */}
       <Link to='/viewuniversitymetric'> <FontAwesomeIcon icon={faDatabase} style={{fontSize: 24, color: '#4DAE19', marginBottom: 15, marginTop: 5, alignSelf: 'center' }} /></Link>

      
     
       </div>
 
     } else if (favcontxt.role=="Admin") {
         content=
         <div>
           <Link to='/dashannaadmin1'> <FontAwesomeIcon icon={faHome} style={{fontSize: 24, color: '#FA5638', marginTop: 10, marginBottom: 5, alignSelf: 'center' }} /></Link>
         {/* <Link to='/dashcourse'> <FontAwesomeIcon icon={faVideo} style={{fontSize: 24, color: '#1E78D6', marginTop: 5, marginBottom: 5, alignSelf: 'center' }} /></Link>
         <Link to='/dashmydetails'> <FontAwesomeIcon icon={faIdCard} style={{fontSize: 27, color: '#580C77', marginTop: 5, marginBottom: 5,marginLeft:2, alignSelf: 'center' }} /></Link> */}
        {/* <Link to='/viewlibbooksfaculty'> <FontAwesomeIcon icon={faBook} style={{fontSize: 24, color: '#811C09', marginLeft:2, marginTop: 5, marginBottom: 5, alignSelf: 'center' }} title="Library" /></Link> */}
        {/* <Link to='/libraryactions'> <FontAwesomeIcon icon={faBook} style={{fontSize: 24, color: '#811C09', marginLeft:2, marginTop: 5, marginBottom: 5, alignSelf: 'center' }} title="Library" /></Link>
         */}
        {/* <Link to='/viewalerts'> <FontAwesomeIcon icon={faComment} style={{fontSize: 24, color: '#C96130', marginBottom: 5, marginTop: 5, alignSelf: 'center'}} /></Link> */}
        {/* <Link to='/dashcriteria1'> <FontAwesomeIcon icon={faGlasses} style={{fontSize: 24, color: '#FFC433', marginBottom: 15, marginTop: 5, alignSelf: 'center' }} /></Link> */}
        {/* <Link to='/assetlist'> <FontAwesomeIcon icon={faBoxes} style={{fontSize: 24, color: '#4DAE19', marginBottom: 15, marginTop: 5, alignSelf: 'center' }} /></Link> */}
        {/* <Link to='/dashfacultydetails'> <FontAwesomeIcon icon={faSignal} style={{fontSize: 24, color: '#4DAE19', marginBottom: 15, marginTop: 5, alignSelf: 'center' }} /></Link>
        <Link to='/adminaffiliatedmetric'> <FontAwesomeIcon icon={faDatabase} style={{fontSize: 24, color: '#4DAE19', marginBottom: 15, marginTop: 5, alignSelf: 'center' }} /></Link> */}

       
        </div>
 
     } else if (favcontxt.role=="Super") {
        content=
        <div>
          <Link to='/dasherp1'> <FontAwesomeIcon icon={faHome} style={{fontSize: 24, color: '#FA5638', marginTop: 10, marginBottom: 5, alignSelf: 'center' }} /></Link>
        {/* <Link to='/workload'> <FontAwesomeIcon icon={faVideo} style={{fontSize: 24, color: '#1E78D6', marginTop: 5, marginBottom: 5, alignSelf: 'center' }} /></Link> */}
        <Link to='/dashmydetails'> <FontAwesomeIcon icon={faIdCard} style={{fontSize: 27, color: '#580C77', marginTop: 5, marginBottom: 5,marginLeft:2, alignSelf: 'center' }} /></Link>
       <Link to='/viewlibbooksfaculty'> <FontAwesomeIcon icon={faBook} style={{fontSize: 24, color: '#811C09', marginLeft:2, marginTop: 5, marginBottom: 5, alignSelf: 'center' }} title="Library" /></Link>
       
       {/* <Link to='/viewalerts'> <FontAwesomeIcon icon={faComment} style={{fontSize: 24, color: '#C96130', marginBottom: 5, marginTop: 5, alignSelf: 'center'}} /></Link> */}
       <Link to='/dashcriteria1'> <FontAwesomeIcon icon={faGlasses} style={{fontSize: 24, color: '#FFC433', marginBottom: 15, marginTop: 5, alignSelf: 'center' }} /></Link>
       <Link to='/assetlist'> <FontAwesomeIcon icon={faBoxes} style={{fontSize: 24, color: '#4DAE19', marginBottom: 15, marginTop: 5, alignSelf: 'center' }} /></Link>
       <Link to='/dashfacultydetails'> <FontAwesomeIcon icon={faSignal} style={{fontSize: 24, color: '#4DAE19', marginBottom: 15, marginTop: 5, alignSelf: 'center' }} /></Link>

      
       </div>

    }

    



    return (
        <div className={classes1.sidebar}>
            
        
        {content}
       

</div>
    );

}

export default Sideicon;