import React, { useState, useContext, useEffect, useRef } from "react";
import classes from './form.module.css';
import { useHistory } from 'react-router-dom';
import global1 from './global1';
import { FileDrop } from 'react-file-drop';

import ep1 from '../api/ep1';
import {Link} from 'react-router-dom';
import FavoritesContext from '../store/favourites-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-date-picker';


import classes2 from './fileupload.module.css';
import classes1 from './newform2.module.css';
import './Demo.css';

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidefacNavigation from "../components/layout/SidefacNavigation";
import Sideicon from '../components/layout/Sideicon';
import SidedocNavigation from '../components/layout/An';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';



function Newclasses() {

    const favcontxt=useContext(FavoritesContext);



    const buildspaceref=useRef();
const spacereqref=useRef();
const availref=useRef();
const deficiencyref=useRef();

  

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [term2, setTerm2] = useState('');
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());

    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [isUploading, setIsuploading] = useState(false);
    const styles = { border: '1px solid black', width: '90%', height: 300,  color: 'black', padding: 20 };

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const name=global1.name;
    const user=global1.user;
    const courseid=global1.courseid;
    const coursecode=global1.coursecode;
    const coursename=global1.coursename;
    const token=global1.token;
    const accno=global1.accno;
    const book=global1.book;
    const department=global1.department;

    function logout() {
        favcontxt.addFavorite({
            studid: '',
            name: ''
        },'',0,'');
    }


    useEffect(() => {
       //logout();

    }, []);

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
    };

    const changeHandler1 = (files,event) => {
        setSelectedFile(files[0]);
        setIsFilePicked(true);
    };
    const getval1=(event)=> {
      
        //alert(event.target.value);
        const aff=buildspaceref.current.value;
        
        if (aff=='Gents Toilet / Ladies Toilet')
        {
            const noofprog=parseFloat(global1.noofprog);

            if (noofprog>1) {
                spacereqref.current.value=350;
            } else {
                spacereqref.current.value=150;            }

        }
       else if (aff=='Boys Common Room')
       {
        const noofprog=parseFloat(global1.noofprog);

            if (noofprog>1) {
                spacereqref.current.value=100;
            } else {
                spacereqref.current.value=75;            }

    }
    else if (aff=='Girls Common Room')
       {
        const noofprog=parseFloat(global1.noofprog);

            if (noofprog>1) {
                spacereqref.current.value=100;
            } else {
                spacereqref.current.value=75;            }

    }
    else if (aff=='Cafeteria')
       {
        
        const noofprog=parseFloat(global1.noofprog);

            if (noofprog>1) {
                spacereqref.current.value=150;
            } else {
                spacereqref.current.value=150;            }

    }
    else if (aff=='Stationery Store & Reprography')
       {
        const noofprog=parseFloat(global1.noofprog);

            if (noofprog>1) {
                spacereqref.current.value=10;
            } else {
                spacereqref.current.value=10;            }

    }
    else if (aff=='First Aid cum Sick room')
       {
        const noofprog=parseFloat(global1.noofprog);

        if (noofprog>1) {
            spacereqref.current.value=10;
        } else {
            spacereqref.current.value=10;            }

    }
    else if (aff=='Principals quarters')
       {
        const noofprog=parseFloat(global1.noofprog);

            if (noofprog>1) {
                spacereqref.current.value=150;
            } else {
                spacereqref.current.value=150;            }

    }
    else if (aff=='Guest House')
       {
        const noofprog=parseFloat(global1.noofprog);

        if (noofprog>1) {
            spacereqref.current.value=30;
        } else {
            spacereqref.current.value=30;            }

    }
    else if (aff=='Sports Club / Gymnasium')
       {
        const noofprog=parseFloat(global1.noofprog);

            if (noofprog>1) {
                spacereqref.current.value=200;
            } else {
                spacereqref.current.value=100;            }

    }
    else if (aff=='Auditorium or Amphi Theater')
       {
        const noofprog=parseFloat(global1.noofprog);

            if (noofprog>1) {
                spacereqref.current.value=400;
            } else {
                spacereqref.current.value=250;            }

    }
    else if (aff=='Boys Hostel')
       {
        const noofprog=parseFloat(global1.noofprog);

            if (noofprog>1) {
                spacereqref.current.value=0;
            } else {
                spacereqref.current.value=0;            }

    }
    
    else 
       {
        const noofprog=parseFloat(global1.noofprog);

            if (noofprog>1) {
                spacereqref.current.value=0;
            } else {
                spacereqref.current.value=0;            }

    }     
      
    }
    const searchapi = async () => {
       

        const buildspace=buildspaceref.current.value;
const spacereq=spacereqref.current.value;
const avail=availref.current.value;
//const deficiency=deficiencyref.current.value;
const deficiency=(spacereq-avail)*1.0/spacereq*100;

        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response = await ep1.get('/api/v1/createpaunamenbyfac', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: name,
                buildspace:buildspace,
spacereq:spacereq,
avail:avail,
deficiency:deficiency,
status1:'Submitted',
                comments:''

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        //alert(response.data.status);
        history.replace('/viewnpaunamen');
       
    };

   
           const width=window.innerWidth;
           const mdivwidth=width - 330;

           const openlink = async (link) => {
            
            var win = window.open(link, '_blank');
            win.focus();
          };


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width:60 }}>
                        <Sideicon />
                    </td>
                    <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <SidedocNavigation />
                    </td>
                    
                    <td> 
                        <TopNavigation />
                    <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
       >
                        
                        <p style={{ fontSize: 18 }}> Add data</p>
                        <hr />
                       
                            
                  <label htmlFor="buildspace">Building space for</label>

<select id="buildspace" className="form-control" onChange={getval1} ref={buildspaceref}>
<option value="Select">Select</option>
<option value="Gents Toilet / Ladies Toilet">Gents Toilet / Ladies Toilet</option>
<option value="Boys Common Room">Boys Common Room</option>
<option value="Girls Common Room">Girls Common Room</option>
<option value="Cafeteria">Cafeteria</option>
<option value="Stationery Store & Reprography">Stationery Store & Reprography</option>
<option value="First Aid cum Sick room">First Aid cum Sick room</option>
<option value="Principals quarters">Principals quarters</option>
<option value="Guest House">Guest House</option>
<option value="Sports Club / Gymnasium">Sports Club / Gymnasium</option>
<option value="Auditorium or Amphi Theater">Auditorium or Amphi Theater</option>
<option value="Boys Hostel">Boys Hostel</option>
<option value="Girls Hostel">Girls Hostel</option>
</select>
<br /><br />

Enter Required Space (sq.m)<br /><br />

<input type="number" readOnly required style={{width: "100%"}} placeholder="Enter Required Space (sq.m)" id="spacereq" ref={spacereqref} />
<br /><br />

Enter Available(sq.m)<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter Available(sq.m)" id="avail" ref={availref} />
<br /><br />

{/* Enter Deficiency%<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter Deficiency%" id="deficiency" ref={deficiencyref} />
<br /><br /> */}




                      
                        <button  onClick={searchapi}>Add data</button>

                      
      
            <div>
          
            <br />
            
                
            {isUploading ? (
				<div>
                    <p style={{fontSize: 18, fontWeight: 'bold'}}>Please wait</p>
					<img src="http://iqac.online/images/1480.gif" />
				</div>
			) : (
                <div></div>
			)}  
        
              
	
              
                <br />
                {term2}
            
          
           </div>
           </div>
           <FooterNav1 />
           </td></tr></table>
        </div>
    );

}

export default Newclasses;