import React, { useState, useContext, useEffect, useRef } from "react";
import {Link} from 'react-router-dom';


import classes from './MainNavigation1.module.css';
import classes1 from './SidecolorNavigation.module.css';
import FavoritesContext from '../../store/favourites-context';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus, faUser } from '@fortawesome/free-solid-svg-icons';



function SidefacNavigation() {

    const favcontxt=useContext(FavoritesContext);

    let content;

    



    return (
        <div className={classes1.sidebar}>
            <div className={classes1.sidebarinside}>

<div style={{alignItems: 'center', width:'100%'}}>
                <img src="logo.png" style={{width:180}} />
                <br /><i><span style={{ fontSize: 12}}>a portfolio company of</span></i><br />
                <img src="times-internet-logo.png" style={{height:20, marginTop: 5, alignSelf: 'center', marginLeft: 0}} />
            </div>
            <hr />
            
        
        {/* <div style={{marginBottom: 10}}><b>Insights</b></div> */}
        {/* <Link to='/viewnacademic'> Academic calendar</Link>
        <Link to='/viewnworkbook'> Personal workbook</Link>
        <Link to='/viewnenrollment'> Enrollment</Link>
        <Link to='/viewncourseplan'> Course plan</Link> */}
<div style={{marginBottom: 10}}><b>Information</b></div>

<Link to='/viewnamcpdetails3'> Chair person</Link>
<Link to='/viewnamcpobservations'> Observations</Link>
<Link to='/viewnammember1'> Member1</Link>
<Link to='/viewnammbr2comp'> Member 2</Link>
<Link to='/viewnammbr2det'> Other details</Link>

<div style={{marginBottom: 10}}><b>Information</b></div>

<Link to='/viewnamprograms'> Programs</Link>
<Link to='/viewnamclassroom'> Classroom</Link>
<Link to='/viewnamequipments'> Equipments</Link>
<Link to='/viewnamlibrary'> Library</Link>
<Link to='/viewnamlibdetails'> Library details</Link>
<Link to='/viewnamfaculties'> Faculties</Link>
<Link to='/viewnamadminstaff'> Admin staff</Link>
<Link to='/viewnaminfsuff'> Teaching staff</Link>
<Link to='/viewnamchemicals'> Chemicals</Link>
<Link to='/viewnamprogfaculties'> Program wise faculties</Link>
<Link to='/viewnamhostels'> Hostels</Link>
<Link to='/viewnamimprovements'> Improvements</Link>
<Link to='/viewnamrecommendation'> Recommendations</Link>








<br /><br /><br /><br />


      
       

        <hr />
        {/* <div style={{marginBottom: 10}}><b>Useful links</b></div> */}
     
     

        
       </div>

</div>
    );

}

export default SidefacNavigation;