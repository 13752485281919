import ep1 from '../api/ep1';
import React, { useState, useEffect, useContext, useRef } from "react";
import global1 from './global1';
import { Card } from 'primereact/card';
import { useHistory } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import FavoritesContext from '../store/favourites-context';
import classes from './form.module.css';
import DatePicker from 'react-date-picker';
import {Link} from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faCheckCircle, faUpload, faFlag, faEdit, faComment, faFileAlt, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus, faUser } from '@fortawesome/free-solid-svg-icons';
import { DataGrid } from '@material-ui/data-grid';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';

//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';

//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from 'jquery'; 

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidecolorNavigation from '../components/layout/SidecolorNavigation';
import Sidecr1Navigation from '../components/layout/An';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';
import Sideicon from '../components/layout/Sideicon';


// import classes from './Allclasses.module.css';

import classes1 from './newform2.module.css';

import Spinner from 'react-bootstrap/Spinner';

function Allclasses() {

    const favcontxt=useContext(FavoritesContext);

    const linkref=useRef();
    const moduleref=useRef();
    const topicref=useRef();

    const name=favcontxt.name;

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [coursecode, setCoursecode] = useState('');
    const [results, setResults] = useState([]);
    const [results1, setResults1] = useState([]);
    const [results2, setResults2] = useState([]);
    const [link, setLink] = useState([]);
    const [loading, setLoading] = useState();
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());
    const [format, setFormat] = useState(false);

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const user=global1.user;
    const token=global1.token;
    const role=global1.role;
    const coursecode1=global1.coursecode;
    const coursename=global1.coursename;
    //const name=global1.name;
    const aqaryear=global1.aqaryear;

    const [isUploading, setIsuploading] = useState(false);

    const columns = [
        // { field: '_id', headerName: 'ID', width: 70 },
        { field: 'student', headerName: 'Student', width: 130 },
        { field: 'regno', headerName: 'Reg no', width: 130 },
      ];


      const upload = (id) => {
        global1.field1=id;
        global1.criteria='1';
        global1.metric='1.3.2';
        global1.collection1='addonc';
        global1.type='BOT';
        global1.pagenav='viewnpaucourse';
        
        history.replace('/selectawsconfig');
    }

    const checklogin = () => {
        const studid=global1.studid;
        if (!studid) {
            //alert('login');
            history.replace('/new');
        }
    }
    // const edit = (id, coursetitle, coursecode, coursetype, year, offeredtimes, duration, studentsenrolled, studentscompleted, price, status, imagelink, category) => {
    //     global1.addonid=id;
    //     global1.coursetitle=coursetitle;
    //     global1.coursecode=coursecode;
    //     global1.coursetype=coursetype;
    //     global1.year=year;
    //     global1.offeredtimes=offeredtimes;
    //     global1.duration=duration;
    //     global1.studentsenrolled=studentsenrolled;
    //     global1.studentscompleted=studentscompleted;
    //     global1.price=price;
    //     global1.status=status;
    //     global1.imagelink=imagelink;
    //     global1.category=category;
    //     history.replace('/editaddoncourse');
    // }

    const edit = (id, department,degree,course,yoi,afflnature,fromdate,todate,letterno,letterdt,status1) => {
        if(status1=='Accepted') {
            alert('This document is accepted. Please contact IQAC coordinator to allow editing');
            return;
        }
        global1.addonid=id;
        global1.department=department;
global1.degree=degree;
global1.course=course;
global1.yoi=yoi;
global1.afflnature=afflnature;
global1.fromdate=fromdate;
global1.todate=todate;
global1.letterno=letterno;
global1.letterdt=letterdt;

        global1.pagenav='viewnpaucourse';
        history.replace('/editnpaucourse');
    }

    const list = (id, user) => {
        global1.field1=id;
        global1.criteria='1';
        global1.metric='1.3.2';
        global1.collection1='addonc';
        global1.type='Add on course';
        global1.buser=user;
        global1.pagenav='viewaddoncourseadmin';
        
        history.replace('/viewsupportingdocadmin');
    }

    
    const addperiod = () => {
        history.replace('/addperiod');
    }

    const addstudents = () => {
        // global1.courseid=courseid;
        // global1.coursecode=coursecode;
        // global1.coursename=coursename;
        //history.replace('/enrollstudents');
        history.replace('/addstudents');
    }

    function getaffiliated(affvalue) {
        if(affvalue=="1") {
            return "Yes";
        } else {
            return "No";
        }
    }

    

   

    let tutorials = [];
    const searchApi = async () => {
        //let tutorials = [];
        //setResults([]);
        setLoading(true);
        setIsuploading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/getpaucoursebyfac', {
            params: {
                user: user,
                token: token,
                colid: colid,
                year: aqaryear
            }
        });
        setLoading(false);
        setIsuploading(false);
        console.log(response.data.data);
        //localStorage.setItem("p_addoncoursea", JSON.stringify(response.data.data.classes));

       

        
        setResults(response.data.data.classes);
        //setLink(response.data.data.link);
        //alert('format ' + format);
        try{

            formattable();
        } catch(err) {

        }
        
       
        //alert(results.length);
       
    };

    function formattable() {
        if (!format) {
            $('#example').DataTable(
                {
                    pagingType: 'full_numbers',
                      pageLength: 5,
                      processing: true,
                      dom: 'Bfrtip',
                          buttons: ['copy', 'csv', 'print'
                          ]
                }
            );
            setFormat(true);

        }
    }

   

    // const deleteenrolment = async (enrolmentid) => {
    //     setLoading(true);
    //     //setTerm(studid);
    //     const response = await ep1.get('/api/v1/deleteaddoncoursebyfac', {
    //         params: {
    //             id: enrolmentid,
    //             token: token,
    //             user: user
    //         }

    //     });
    //     alert(response.data.status);
    //     setLoading(false);
    //     //history.replace('/viewlibbooks');
    //     searchApi();
    //     //console.log(response.data.data);
    //     //getperiod();
    //     //setResults1(response.data.data.classes);
    //     //alert(results.length);
       
    // };

    const deleteenrolment = async (enrolmentid, status1) => {
        if(status1=='Accepted') {
            alert('This document is accepted. Please contact IQAC coordinator to allow editing');
            return;
        }
        setLoading(true);
        //setTerm(studid);
        const response = await ep1.get('/api/v1/deletepaucoursebyfac', {
            params: {
                id: enrolmentid,
                token: token,
                user: user
            }

        });
        alert(response.data.status);
        setLoading(false);
        
        global1.pagenav='viewnpaucourse';
        history.replace('/thankyouafter');
       
    };

    const getlocal = () => {
        let collection=localStorage.getItem("p_addoncoursea");
        if(!collection) {

        } else {
            setResults(JSON.parse(collection));

        }
        
    }

    

    useEffect(() => {
        checklogin();
        //search1();
        //getexamlist();
        //getlocal();
        searchApi('');
        //$('#example').DataTable();
        //initialize datatable
    // $(document).ready(function () {
    //     setTimeout(function(){
    //     $('#example').DataTable();
    //     },
    //     1000
    //     );
    // });
    
    //initialize datatable
    // $(document).ready(function () {
    //     setTimeout(function(){
    //     $('#example').DataTable(
    //         {
    //             pagingType: 'full_numbers',
    //               pageLength: 5,
    //               processing: true,
    //               dom: 'Bfrtip',
    //                   buttons: ['copy', 'csv', 'print'
    //                   ]
    //         }
    //     );
    //     } ,
    //     1000
    //     );
    // });
        //getperiod();
        //getclass();
        //searchApi1('');

    }, []);


    const uploadlink = async (link1, id1, yop1) => {
        link1=link1 + '?criteria=3&Section=3.4.6&Subsection=Book&field1=' + id1 + '&Year=' + yop1; 
        var win = window.open(link1, '_blank');
        win.focus();
      };

      const list1 = async (id1) => {
        //var link1='http://localhost:3001/viewsupportingdocquery';
        var link1=window.location.origin + '/viewsupportingdocquery';
        link1=link1 + '?field1=' + id1 + '&colid=' + colid + '&user=' + user + '&name=' + name + '&token=' + token; 
        var win = window.open(link1, '_blank');
        win.focus();
      };

      const updatestatus = async (status1, id) => {
        //setLoading(true);
        const response = await ep1.get('/api/v1/updatecommentsapi', {
            params: {
                user: user,
                token: token,
                colid: colid,
                status1: status1,
                comments: "NA",
                id: id

            }

        });
        setLoading(false);
        alert(response.data.status + ' - ' + status1);
        //console.log('data');
        //console.log(response.data.data.classes);
        //setResults(response.data.data.classes);
    };

    

    const audit = (id, programcode, programname, year, offeredtimes, duration, studentsenrolled, studentscompleted) => {
        var comments='';  
        if(!programcode || !programname || !year || !offeredtimes || !duration || !studentsenrolled || !studentscompleted) {
            comments='All fields are required';
            return comments;
        } 

        if(isNaN(studentsenrolled)) {
            comments=comments + 'Invalid studentsenrolled entry. Must be a number.';
        }  

        if(isNaN(studentscompleted)) {
            comments=comments + 'Invalid studentscompleted entry. Must be a number.';
        } 

        if(isNaN(duration)) {
            try {
                var matches = duration.match(/(\d+)/);
                if(Number(matches[0])<30) {
                    comments=comments + 'Minimum 30 hours duration is required.';
                }
            } catch(err) {
                comments=comments + 'Minimum 30 hours duration is required. Duration must be specified in hours such as 30 or 30 hours.';      
            }
            
            if(duration.toLowerCase().indexOf('hour')<0 && duration.length > 4) {
                comments=comments + 'Duration must be specified in hours.';
            }
            
        }  else {
            if(Number(duration)<30) {
                comments=comments + 'Minimum 30 hours duration is required.';
            }

        }

        if(isNaN(offeredtimes)) {
            comments=comments + 'Invalid offered in times entry. Must be a number.';
        }  else {
            if(offeredtimes<1) {
                comments=comments + 'Course should be offered at least once';
            }

        }
        
        //comments=comments + ' year ' + year + ' ' + year.indexOf('-');

        if(year.indexOf('-') > -1) {
            const yeararray=year.split('-');
            //comments=comments + ' ' + yeararray[1] + ' ' + isNaN(yeararray[1]);
            if(isNaN(yeararray[1]) || isNaN(yeararray[0])) {
                comments=comments + ' Invalid introduction year. Must be in year such as 2017-18.'

            } else {

                if(Number(yeararray[0])<2017 || Number(yeararray[0])> 2022) {
                    comments=comments + ' Data outside assessment period.';
                }
                
                
                var firstyear=Number(yeararray[0].substring(2,4));
                var secondyear=Number(yeararray[1]);
                var diff=secondyear-firstyear;
                //comments=comments + ' firstyear ' + firstyear + ' secondyear ' + secondyear;
                if(diff>1) {
                    comments=comments + ' Invalid introduction year format.'
                } else {
                    comments=comments + 'Introduction year ok.';
                }
            }
        } else {
            comments = comments + ' Invalid introduction year. Must be in academic year format.'
        }
       
        var progstatus=' Course name appears ok.';
        if(programname.indexOf('MSc')>-1 || programname.indexOf('BSc') >-1 || programname.indexOf('Diploma') >-1 || programname.indexOf('BE') >-1 || programname.indexOf('BTech') > -1 || programname.indexOf('BA') > -1 || programname.indexOf('MA') > -1 || programname.indexOf('PhD') > -1 || programname.indexOf('MBA') > -1 || programname.indexOf('MCA') > -1 || programname.indexOf('LLB') > -1 || programname.indexOf('BCA') > -1 || programname.indexOf('BCom') > -1) {
            progstatus='Course name is invalid.'
        }
        if(programname.indexOf('M.Sc')>-1 || programname.indexOf('B.Sc') >-1 || programname.indexOf('Diploma') >-1 || programname.indexOf('B.E.') >-1 || programname.indexOf('B.Tech') > -1 || programname.indexOf('BA') > -1 || programname.indexOf('MA') > -1 || programname.indexOf('Ph.D') > -1 || programname.indexOf('MBA') > -1 || programname.indexOf('MCA') > -1 || programname.indexOf('LLB') > -1 || programname.indexOf('BCA') > -1 || programname.indexOf('B.Com') > -1 || programname.toLowerCase().indexOf('refresher') > -1) {
            progstatus='Course name is invalid.'
        }
        var proglength=programname.length;
        //comments=comments + programlength;
        if(proglength<6) {
            progstatus=progstatus + 'Check course name, appears too short.';
        } 
        comments=comments + progstatus;
        var programlength=programcode.length;
        //comments=comments + programlength;
        if(programlength<6) {
            comments=comments + 'Course code appears too short';
        } else {
            if(programcode.indexOf(' ')>-1) {
                comments=comments + 'Check course code, usually it does not contain spaces.';
            } else {
                comments=comments + 'Course code appears ok.';
            }
            
        }
        return comments;
    }

    const getweblink=(link) => {
        var newlink='';
        //newlink=link.substring(link.indexOf('amazonaws.com') + 13);
        //newlink=link.substring(link.indexOf('amazonaws.com') + 14, link.lastIndexOf('.'));
        newlink=link.substring(link.indexOf('amazonaws.com') + 14);
        var website=global1.website;
        if(!website) {
            website='https://<yourdomain.com>';
        }
        //newlink=website + '/Criteria1/valueaddedcourse' + newlink;
        newlink=website + '?link=' + newlink;
        return newlink;
    }
    
   
const formatdates = (date1) => {
          var dt1=new Date(date1);
          var month=dt1.getMonth() + 1;
          return dt1.getDate() + '/' + month + '/' + dt1.getFullYear();
      }
    

    const width=window.innerWidth;
    const mdivwidth=width - 330;

   
       
        //alert('loaded');
  

    


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width: 60 }}>
                        <Sideicon />
                    </td>
                    <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <Sidecr1Navigation />
                    </td>
                   
                    <td> 
                        <TopNavigation />

                    
        
        <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
        >
            <p style={{ fontSize: 18 }}>View data </p>
            <hr />

            <table>
                <tr>
                    <td>
                        <Link to='/addnpaucourse' className="btn btn-dark"> Add data</Link>
                    </td>
                    <td width="20px"></td>
                    <td>
                        <Link to='/addpaucoursebulkn' className="btn btn-dark"> Add bulk data</Link>
                    </td>
                 
                </tr>
            </table>
           
    
        <br />
       
        {isUploading ? (
				<div style={{ alignItems: 'center', marginTop: 30, marginBottom: 60}}>
                   <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                    </Spinner>
				</div>
			) : (
                <div></div>
			)} 
     
<Table striped bordered hover id="example">
{/* <table id="mytable"> */}
<thead>
<tr>
        <th>Document ID</th>
        <th>Added by</th>
     

     <th>Department</th>
<th>Degree</th>
<th>Courses</th>
<th>Year of Introduction</th>
<th>Status of accreditation</th>
<th>From Date</th>
<th>To Date</th>
<th>Letter No.</th>
<th>Letter Date</th>

        
     
       
       
        <th>Documents</th>
        <th>Virtual link</th>
        <th>Comments</th>
        <th>Status</th>
        <th>Actions</th>
    </tr>
    </thead>
    <tbody>
    

    {results.map((meetup3) => {
                return (
                    <tr>
                        <td>
                            {meetup3._id}
                        </td>
                        <td>
                            {meetup3.name}
                        </td>
                       
                    <td>{meetup3.department}</td>
<td>{meetup3.degree}</td>
<td>{meetup3.course}</td>
<td>{meetup3.yoi}</td>
<td>{meetup3.afflnature}</td>
<td>{formatdates(meetup3.fromdate)}</td>
<td>{formatdates(meetup3.todate)}</td>
<td>{meetup3.letterno}</td>
<td>{meetup3.letterdt}</td>

                        
                       
                        <td>
                        {meetup3.seminars.map((meetup31) => {
                return (
                    <div>
                        
                        {/* {meetup31.link} {meetup31.status1}  */}
                        <a href={meetup31.link} target="_blank">{meetup31.link}</a> - {meetup31.status1} 
                        <br />
                      
                        </div>

                    );
                })}
                        </td>
                        <td>
                        {meetup3.seminars.map((meetup31) => {
                return (
                    <div>
                        
                        {/* {meetup31.link} {meetup31.status1}  */}
                        <a href={getweblink(meetup31.link)} target="_blank">{getweblink(meetup31.link)}</a> 
                        <br />
                      
                        </div>

                    );
                })}
                        </td>
                        <td>
                        {meetup3.allcomments.map((meetup32) => {
                return (
                    <div>
                        
                        {meetup32.comments} 
                        <br />
                      
                        </div>

                    );
                })}
                        </td>
                        <td>
                            {meetup3.status1}
                        </td>
                      
                        <td>
                            
                

                    <Button variant="outline-danger" onClick={() => list1(meetup3._id)}>
                        <FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} />
                        List
                    </Button> 

                    <Button variant="outline-danger" onClick={() => deleteenrolment(meetup3._id,meetup3.status1)}>
                        <FontAwesomeIcon icon={faTrash} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} />
                            </Button>
                            <Button variant="outline-danger" onClick={() => edit(meetup3._id,meetup3.department,meetup3.degree,meetup3.course,meetup3.yoi,meetup3.afflnature,meetup3.fromdate,meetup3.todate,meetup3.letterno,meetup3.letterdt,meetup3.status1)}>
                        <FontAwesomeIcon icon={faEdit} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} />
                            </Button>
                            <Button variant="outline-danger" onClick={() => upload(meetup3._id)}>
                        <FontAwesomeIcon icon={faUpload} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} />
                        Upload
                            </Button>
                   
                           

                    {/* <Button variant="outline-danger" onClick={() => updatestatus('Accepted',meetup3._id)}>
                        <FontAwesomeIcon icon={faCheckCircle} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} />
                        Accept
                    </Button> 

                    <Button variant="outline-danger" onClick={() => updatestatus('Flagged',meetup3._id)}>
                        <FontAwesomeIcon icon={faFlag} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} />
                        Flag
                    </Button>  */}
                
                    
                      </td>
                       
                
                    
                
                
                   

                   
                                </tr>
              

                );
            })}
            </tbody>
            </Table>

            <br /><br />



            <h4>Document List</h4>
        <hr />
Supporting documents
        


            

       
        
        </div>
        <FooterNav1 />
        </td>
                </tr>
            </table>
        </div>
        
    );

}

export default Allclasses;