import React, { useState, useContext, useEffect, useRef } from "react";
import classes from './form.module.css';
import { useHistory } from 'react-router-dom';
import global1 from './global1';
import { FileDrop } from 'react-file-drop';

import ep1 from '../api/ep1';
import {Link} from 'react-router-dom';
import FavoritesContext from '../store/favourites-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-date-picker';


import classes2 from './fileupload.module.css';
import classes1 from './newform2.module.css';
import './Demo.css';

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidefacNavigation from "../components/layout/SidefacNavigation";
import Sideicon from '../components/layout/Sideicon';
import SidedocNavigation from '../components/layout/An';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';
import { duration } from "@material-ui/core";



function Newclasses() {

    const favcontxt=useContext(FavoritesContext);

    const bhostelref=useRef();
const hostelbuildref=useRef();
const lochostelref=useRef();
const distofcolref=useRef();
const totadmitstrnref=useRef();
const blnameref=useRef();
const roomref=useRef();
const carearef=useRef();
const noofreqroomref=useRef();
const availroomref=useRef();
const caproomref=useRef();
const availcaproomref=useRef();

  

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [term2, setTerm2] = useState('');
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());

    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [isUploading, setIsuploading] = useState(false);
    const styles = { border: '1px solid black', width: '90%', height: 300,  color: 'black', padding: 20 };

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const name=global1.name;
    const user=global1.user;
    const courseid=global1.courseid;
    const coursecode=global1.coursecode;
    const coursename=global1.coursename;
    const token=global1.token;
    const accno=global1.accno;
    const book=global1.book;
    const department=global1.department;
    const patentid=global1.addonid;


    function logout() {
        favcontxt.addFavorite({
            studid: '',
            name: ''
        },'',0,'');
    }


    useEffect(() => {
       //logout();
       

       bhostelref.current.value=global1.bhostel;
hostelbuildref.current.value=global1.hostelbuild;
lochostelref.current.value=global1.lochostel;
distofcolref.current.value=global1.distofcol;
totadmitstrnref.current.value=global1.totadmitstrn;
blnameref.current.value=global1.blname;
roomref.current.value=global1.room;
carearef.current.value=global1.carea;
noofreqroomref.current.value=global1.noofreqroom;
availroomref.current.value=global1.availroom;
caproomref.current.value=global1.caproom;
availcaproomref.current.value=global1.availcaproom;


    }, []);

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
    };

    const changeHandler1 = (files,event) => {
        setSelectedFile(files[0]);
        setIsFilePicked(true);
    };

    const searchapi = async () => {
        //const title=titleref.current.value;
        const bhostel=bhostelref.current.value;
const hostelbuild=hostelbuildref.current.value;
const lochostel=lochostelref.current.value;
const distofcol=distofcolref.current.value;
const totadmitstrn=totadmitstrnref.current.value;
const blname=blnameref.current.value;
const room=roomref.current.value;
const carea=carearef.current.value;
const noofreqroom=noofreqroomref.current.value;
const availroom=availroomref.current.value;
const caproom=caproomref.current.value;
const availcaproom=availcaproomref.current.value;

        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response = await ep1.get('/api/v1/updatepaubhostelbyfac', {
            params: {
                id: patentid,
                user: user,
                token: token,
                name: name,
                colid: colid,
                bhostel:bhostel,
hostelbuild:hostelbuild,
lochostel:lochostel,
distofcol:distofcol,
totadmitstrn:totadmitstrn,
blname:blname,
room:room,
carea:carea,
noofreqroom:noofreqroom,
availroom:availroom,
caproom:caproom,
availcaproom:availcaproom,
status1:'Submitted',
                comments:''

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        //alert(response.data.status);
        //history.replace('/viewnpaubhostel');
        const pagenav=global1.pagenav;
        //history.goBack();
        history.replace('/' + pagenav);
       
    };

   
           const width=window.innerWidth;
           const mdivwidth=width - 100;

           const openlink = async (link) => {
            
            var win = window.open(link, '_blank');
            win.focus();
          };


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width:60 }}>
                        <Sideicon />
                    </td>
                    {/* <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <SidedocNavigation />
                    </td> */}
                    
                    <td> 
                        <TopNavigation />
                    <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
       >
                        
                        <p style={{ fontSize: 18 }}> Edit data</p>
                        <hr />
                       

                   <label htmlFor="bhostel">Is Boys Hostel available</label>

<select id="bhostel" className="form-control"  ref={bhostelref}>
<option value="Yes">Yes</option>
<option value="No">No</option>
</select>
<br /><br />

<label htmlFor="hostelbuild">Hostel Buildings</label>

<select id="hostelbuild" className="form-control"  ref={hostelbuildref}>
<option value="Owned">Owned</option>
<option value="Rental">Rental</option>
<option value="Lease">Lease</option>
</select>
<br /><br />

<label htmlFor="lochostel">Location of the Hostel</label>

<select id="lochostel" className="form-control"  ref={lochostelref}>
<option value="Inside Campus">Inside Campus</option>
<option value="Outside Campus">Outside Campus</option>
</select>
<br /><br />

<label htmlFor="distofcol">Distance of Institution from the city</label>

<select id="distofcol" className="form-control"  ref={distofcolref}>
<option value="Within 20 km">Within 20 km</option>
<option value="Beyond 20 km">Beyond 20 km</option>
</select>
<br /><br />

Enter Total Admitted Strength<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter Total Admitted Strength" id="totadmitstrn" ref={totadmitstrnref} />
<br /><br />

Enter Block Name/Number<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Block Name/Number" id="blname" ref={blnameref} />
<br /><br />

<label htmlFor="room">Room Type</label>

<select id="room" className="form-control"  ref={roomref}>
<option value="Single Room">Single Room</option>
<option value="Triple Seated Room">Triple Seated Room</option>
<option value="Others">Others</option>
</select>
<br /><br />

Enter Carpet area of room (sq.m)<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter Carpet area of room (sq.m)" id="carea" ref={carearef} />
<br /><br />

Enter No of Rooms Required<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter No of Rooms Required" id="noofreqroom" ref={noofreqroomref} />
<br /><br />

Enter Number of Rooms Available<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter Number of Rooms Available" id="availroom" ref={availroomref} />
<br /><br />

Enter Room Capacity Required(sq.m)<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter Room Capacity Required(sq.m)" id="caproom" ref={caproomref} />
<br /><br />

Enter Room Capacity Available<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter Room Capacity Available" id="availcaproom" ref={availcaproomref} />
<br /><br />


                  


                      
                        <button  onClick={searchapi}>Update data</button>

                      
      
            <div>
          
            <br />
            
                
            {isUploading ? (
				<div>
                    <p style={{fontSize: 18, fontWeight: 'bold'}}>Please wait</p>
					<img src="http://iqac.online/images/1480.gif" />
				</div>
			) : (
                <div></div>
			)}  
        
              
	
              
                <br />
                {term2}
            
          
           </div>
           </div>
           <FooterNav1 />
           </td></tr></table>
        </div>
    );

}

export default Newclasses;