import { Route, Switch } from 'react-router-dom';

import Allclasses from './pages/Allclasses';
import Newclasses from './pages/Newclasses';
import Newclasses1 from './pages/Newclasses1';
import Newclassesf from './pages/Newclassesf';
import Newclassesf1 from './pages/Newclassesf1';
import Newclassesf2 from './pages/Newclassesf2';
import Openclasses from './pages/Openclasses';
import Workload from './pages/Workload';
import Workload1 from './pages/Workload1';
import Workload12 from './pages/Workload12';
import Addworkload from './pages/Addworkload';
import Addworkload1 from './pages/Addworkload1';
import Addworkloadf from './pages/Addworkloadf';
import Addperiod from './pages/Addperiod';
import Dashcourse from './pages/Dashcourse';
import Dashcourse1 from './pages/Dashcourse1';
import Dashcoursef from './pages/Dashcoursef';
import Enrollstudents from './pages/Enrollstudents';
import Enrollstudents1 from './pages/Enrollstudents1';
import CourseActions from './pages/CourseActions';
import Classstudents from './pages/Classstudents';
import Classattendance from './pages/Classattendance';
import ClassAttendance1 from './pages/ClassAttendance1';
import ClassAttendance2 from './pages/ClassAttendance2';
import Getmyfeedback from './pages/Getmyfeedback';
import Addassignments from './pages/Addassignments';
import Classassignments from './pages/Classassignments';
import Addcoursefiles from './pages/Addcoursefiles';
import Classfiles from './pages/Classfiles';
import Classstudentsm from './pages/Classstudentsm';
import Proctorlist from './pages/Proctorlist';
import Examenrlist from './pages/Examenrlist';
import Examenrlist1 from './pages/Examenrlist1';
import Lessonplan from './pages/Lessonplan';
import Addlessonplan from './pages/Addlessonplan';
import Addassignments1 from './pages/Addassignments1';
import Assetlist from './pages/Assetlist';
import Addassets from './pages/Addassets';
import Assetassignment from './pages/Assetassignment';
import Addassetassignment from './pages/Addassetassignment';
import Submittedassignments from './pages/Submittedassignments';
import Submissioncomments from './pages/Submissioncomments';
import Menteelist from './pages/Menteelist';
import Addmentee from './pages/Addmentee';
import Mentorlogbook from './pages/Mentorlogbook';
import Addmentorlogbook from './pages/Addmentorlogbook';
import Fees from './pages/Fees';
import Workdiary from './pages/Workdiary';
import Addlibbooks from './pages/Addlibbooks';
import LibraryActions from './pages/LibraryActions';
import Viewlibbooks from './pages/Viewlibbooks';
import Viewlibbooks1 from './pages/Viewlibbooks1';
import Viewlibbooksfaculty from './pages/Viewlibbooksfaculty';
import Searchlibbooks from './pages/Searchlibbooks';
import Searchlibbooks1 from './pages/Searchlibbooks1';
import Libissuebooks from './pages/Libissuebooks';
import Libreturnbooks from './pages/Libreturnbooks';
import Libissuelist from './pages/Libissuelist';
import Libissuelistall from './pages/Libissuelistall';
import Viewprojects from './pages/ViewProjects';
import Viewprojectsadmindoc from './pages/Viewprojectsadmindoc';
import Viewallprojects from './pages/Viewallprojects';
import Addprojects from './pages/Addprojects';
import Editprojects from './pages/Editprojects';
import Viewseminar from './pages/Viewseminar';
import Addseminar from './pages/Addseminar';
import Editseminar from './pages/Editseminar';
import Viewpatent from './pages/Viewpatent';
import Addpatent from './pages/Addpatent';
import Editpatent from './pages/Editpatent';
import Viewpub from './pages/Viewpub';
import Addpub from './pages/Addpub';
import Editpub from './pages/Editpub';
import Viewbooks from './pages/Viewbooks';
import Viewallbooks from './pages/Viewallbooks';
import Addbooks from './pages/Addbooks';
import Editbooks from './pages/Editbooks';
import Dashmydetails from './pages/Dashmydetails';
import Dashmydetails1 from './pages/Dashmydetails1';
import Dashmydetails2 from './pages/Dashmydetails2';
import Updatepassword from './pages/Updatepassword';
import Updatepassword1 from './pages/Updatepassword1';
import Dashcriteria1 from './pages/Dashcriteria1';
import Dashcriteria2 from './pages/Dashcriteria2';
import Dashcriteria3 from './pages/Dashcriteria3';
import Dashcriteria4 from './pages/Dashcriteria4';
import Dashcriteria5 from './pages/Dashcriteria5';
import Dashcriteria6 from './pages/Dashcriteria6';
import Webinarregister from './pages/Webinarregister';
import Parentfeedbackanalysis from './pages/Parentfeedbackanalysis';
import Parentfeedbackanalysis1 from './pages/Parentfeedbackanalysis1';
import Curriculumfeedbackopt1 from './pages/Curriculumfeedbackopt1';
import Employerfeedbackanalysis from './pages/Employerfeedbackanalysis';
import Viewalerts from './pages/Viewalerts';
import Viewkpi from './pages/Viewkpi';
import Viewdynamicworkdiary from './pages/Viewdynamicworkdiary';
import Viewdynamicmodules from './pages/Viewdynamicmodules';
import Selectcourse from './pages/Selectcourse';
import Viewvacstudlist from './pages/Viewvacstudlist';
import Dashfacultydetails from './pages/Dashfacultydetails';
import Viewallpubs from './pages/Viewallpubs';
import Viewallpatents from './pages/Viewallpatents';
import Viewallseminars from './pages/Viewallseminars';
import Viewaddoncourseadmin from './pages/Viewaddoncourseadmin';
import Viewadmissionadmin from './pages/Viewadmissionadmin';
import Viewbosadmin from './pages/Viewbosadmin';
import Viewcollaborationadmin from './pages/Viewcollaborationadmin';
import Vieweventadmin from './pages/Vieweventadmin';
import Viewexpenditureadmin from './pages/Viewexpenditureadmin';
import Viewextensionactadmin from './pages/Viewextensionactadmin';
import Viewictadmin from './pages/Viewictadmin';
import Viewinstawardsadmin from './pages/Viewinstawardsadmin';
import Viewlibraryexpadmin from './pages/Viewlibraryexpadmin';
import Viewmouadmin from './pages/Viewmouadmin';
import Viewpassexamadmin from './pages/Viewpassexamadmin';
import Viewreservecatadmin from './pages/Viewreservecatadmin';
import Deleteroleuser from './pages/Deleteroleuser';
import Vieweventipr from './pages/Vieweventipr';
import Vieweventextension from './pages/Vieweventextension';
import MainNavigation from './components/layout/MainNavigtion';
import FooterNavigation from './components/layout/FooterNav';

import Viewawardsadmin from './pages/Viewawardsadmin';
import Viewcareercounseladmin from './pages/Viewcareercounseladmin';
import Viewcbcsadmin from './pages/Viewcbcsadmin';
import Viewegovernadmin from './pages/Viewegovernadmin';
import Viewhighereduadmin from './pages/Viewhighereduadmin';
import Viewhigherexamadmin from './pages/Viewhigherexamadmin';
import Viewplacementadmin from './pages/Viewplacementadmin';
import Viewqualityadmin from './pages/Viewqualityadmin';
import Viewskilldevadmin from './pages/Viewskilldevadmin';
import Viewteacherdataadmin from './pages/Viewteacherdataadmin';
import Viewteacherfsadmin from './pages/Viewteacherfsadmin';


import Addaddoncourse from './pages/Addaddoncourse';
import Addadmission from './pages/Addadmission';
import Addbos from './pages/Addbos';
import Addcbcs from './pages/Addcbcs';
import Addcollaboration from './pages/Addcollaboration';
import Addevent from './pages/Addevent';
import Addexpenditure from './pages/Addexpenditure';
import Addextensionact from './pages/Addextensionact';
import Addict from './pages/Addict';
import Addinstawards from './pages/Addinstawards';
import Addlibraryexp from './pages/Addlibraryexp';
import Addmou from './pages/Addmou';
import Addpassexam from './pages/Addpassexam';
import Addreservecat from './pages/Addreservecat';
import Addteacherdata from './pages/Addteacherdata';

import Addawards from './pages/Addawards';
import Addcareercounsel from './pages/Addcareercounsel';
import Addegovern from './pages/Addegovern';
import Addhigheredu from './pages/Addhigheredu';
import Addhigherexam from './pages/Addhigherexam';
import Addplacement from './pages/Addplacement';
import Addquality from './pages/Addquality';
import Addskilldev from './pages/Addskilldev';
import Addteacherfs from './pages/Addteacherfs';


import Viewaddoncourse from './pages/Viewaddoncourse';
import Viewadmission from './pages/Viewadmission';
import Viewbos from './pages/Viewbos';
import Viewcbcs from './pages/Viewcbcs';
import Viewcollaboration from './pages/Viewcollaboration';
import Viewevent from './pages/Viewevent';
import Viewexpenditure from './pages/Viewexpenditure';
import Viewextensionact from './pages/Viewextensionact';
import Viewict from './pages/Viewict';
import Viewinstawards from './pages/Viewinstawards';
import Viewlibraryexp from './pages/Viewlibraryexp';
import Viewmou from './pages/Viewmou';
import Viewpassexam from './pages/Viewpassexam';
import Viewreservecat from './pages/Viewreservecat';
import Viewteacherdata from './pages/Viewteacherdata';

import Viewawards from './pages/Viewawards';
import Viewcareercounsel from './pages/Viewcareercounsel';
import Viewegovern from './pages/Viewegovern';
import Viewhigheredu from './pages/Viewhigheredu';
import Viewhigherexam from './pages/Viewhigherexam';
import Viewplacement from './pages/Viewplacement';
import Viewquality from './pages/Viewquality';
import Viewskilldev from './pages/Viewskilldev';
import Viewteacherfs from './pages/Viewteacherfs';

import Editaddoncourse from './pages/Editaddoncourse';
import Editadmission from './pages/Editadmission';
import Editbos from './pages/Editbos';
import Editcbcs from './pages/Editcbcs';
import Editcollaboration from './pages/Editcollaboration';
import Editevent from './pages/Editevent';
import Editexpenditure from './pages/Editexpenditure';
import Editextensionact from './pages/Editextensionact';
import Editict from './pages/Editict';
import Editinstawards from './pages/Editinstawards';
import Editlibraryexp from './pages/Editlibraryexp';
import Editmou from './pages/Editmou';
import Editpassexam from './pages/Editpassexam';
import Editreservecat from './pages/Editreservecat';
import Editteacherdata from './pages/Editteacherdata';

import Editawards from './pages/Editawards';
import Editcareercounsel from './pages/Editcareercounsel';
import Editegovern from './pages/Editegovern';
import Edithigheredu from './pages/Edithigheredu';
import Edithigherexam from './pages/Edithigherexam';
import Editplacement from './pages/Editplacement';
import Editquality from './pages/Editquality';
import Editskilldev from './pages/Editskilldev';
import Editteacherfs from './pages/Editteacherfs';

import Addsdg3 from './pages/Addsdg3';
import Viewsdg3 from './pages/Viewsdg3';
import Editsdg3 from './pages/Editsdg3';
import Addsdg4 from './pages/Addsdg4';
import Viewsdg4 from './pages/Viewsdg4';
import Editsdg4 from './pages/Editsdg4';
import Addsdg5 from './pages/Addsdg5';
import Viewsdg5 from './pages/Viewsdg5';
import Editsdg5 from './pages/Editsdg5';
import Addsdg8 from './pages/Addsdg8';
import Viewsdg8 from './pages/Viewsdg8';
import Editsdg8 from './pages/Editsdg8';
import Addsdg9 from './pages/Addsdg9';
import Viewsdg9 from './pages/Viewsdg9';
import Editsdg9 from './pages/Editsdg9';
import Addsdg10 from './pages/Addsdg10';
import Viewsdg10 from './pages/Viewsdg10';
import Editsdg10 from './pages/Editsdg10';
import Addsdg11 from './pages/Addsdg11';
import Viewsdg11 from './pages/Viewsdg11';
import Editsdg11 from './pages/Editsdg11';
import Addsdg12 from './pages/Addsdg12';
import Viewsdg12 from './pages/Viewsdg12';
import Editsdg12 from './pages/Editsdg12';
import Addsdg13 from './pages/Addsdg13';
import Viewsdg13 from './pages/Viewsdg13';
import Editsdg13 from './pages/Editsdg13';
import Addsdg16 from './pages/Addsdg16';
import Viewsdg16 from './pages/Viewsdg16';
import Editsdg16 from './pages/Editsdg16';
import Addsdg17 from './pages/Addsdg17';
import Viewsdg17 from './pages/Viewsdg17';
import Editsdg17 from './pages/Editsdg17';

import Addsdg1 from './pages/Addsdg1';
import Viewsdg1 from './pages/Viewsdg1';
import Editsdg1 from './pages/Editsdg1';
import Addsdg2 from './pages/Addsdg2';
import Viewsdg2 from './pages/Viewsdg2';
import Editsdg2 from './pages/Editsdg2';
import Addsdg6 from './pages/Addsdg6';
import Viewsdg6 from './pages/Viewsdg6';
import Editsdg6 from './pages/Editsdg6';
import Addsdg7 from './pages/Addsdg7';
import Viewsdg7 from './pages/Viewsdg7';
import Editsdg7 from './pages/Editsdg7';
import Addsdg14 from './pages/Addsdg14';
import Viewsdg14 from './pages/Viewsdg14';
import Editsdg14 from './pages/Editsdg14';
import Addsdg15 from './pages/Addsdg15';
import Viewsdg15 from './pages/Viewsdg15';
import Editsdg15 from './pages/Editsdg15';

import Viewsdg from './pages/Viewsdg';
import Viewallfaculties from './pages/Viewallfaculties';

import Viewmlink from './pages/Viewmlink';
import Addmlink from './pages/Addmlink';
import Editmlink from './pages/Editmlink';



import Vieweventfdp from './pages/Vieweventfdp';
import Viewinnovation from './pages/Viewinnovation';
import Viewinnovationadmin from './pages/Viewinnovationadmin';
import Addinnovation from './pages/Addinnovation';
import Editinnovation from './pages/Editinovation';

import Viewfacultyreport from './pages/Viewfacultyreport';

import Updatequestions from './pages/Updatequestions';

import Alumnifeedbackanalysis from './pages/Alumnifeedbackanalysis';

import Addprojectstatus from './pages/Addprojectstatus';
import Addprojectstatus1 from './pages/Addprojectstatus1';
import Viewprojectstatus from './pages/Viewprojectstatus';
import Viewprojectstatusv from './pages/Viewprojectstatusv';
import Viewprojectstatusbytaskdep from './pages/Viewprojectstatusbytaskdep';
import Editprojectstatus from './pages/Editprojectstatus';
import Viewprojectstatusbytask from './pages/Viewprojectstatusbytask';

import Viewweeklystatus from './pages/Viewweeklystatus';


import Addkpi from './pages/Addkpi';
import Viewkpiuser from './pages/Viewkpiuser';
import Editkpi from './pages/Editkpi';

import Addtasks from './pages/Addtasks';
import Viewtasks from './pages/Viewtasks';
import Edittasks from './pages/Edittasks';



import Viewscopus from './pages/Viewscopus';

import Viewkpigroup from './pages/Viewkpigroup';
import Viewkpicriteria from './pages/Viewkpicriteria';

import Addallkpi from './pages/Addallkpi';


import Addresult from './pages/Addresult';
import Addecontent from './pages/Addecontent';
import Addscholarship from './pages/Addscholarship';
import Addfunds from './pages/Addfunds';
import Addseedm from './pages/Addseedm';
import Addconsultancy from './pages/Addconsultancy';
import Addsyllabusrev from './pages/Addsyllabusrev';

import Editresult from './pages/Editresult';
import Editecontent from './pages/Editecontent';
import Editscholarship from './pages/Editscholarship';
import Editfunds from './pages/Editfunds';
import Editseedm from './pages/Editseedm';
import Editconsultancy from './pages/Editconsultancy';
import Editsyllabusrev from './pages/Editsyllabusrev';

import Viewresult from './pages/Viewresult';
import Viewecontent from './pages/Viewecontent';
import Viewscholarship from './pages/Viewscholarship';
import Viewfunds from './pages/Viewfunds';
import Viewseedm from './pages/Viewseedm';
import Viewconsultancy from './pages/Viewconsultancy';
import Viewsyllabusrev from './pages/Viewsyllabusrev';


import Addexplearning from './pages/Addexplearning';
import Viewexplearning from './pages/Viewexplearning';
import Editexplearning from './pages/Editexplearning';

import Viewsuperweekly from './pages/Viewsuperweekly';

import Addtaskassign from './pages/Addtaskassign';
import Viewtaskassign from './pages/Viewtaskassign';
import Edittaskassign from './pages/Edittaskassign';

import Addpubaqar from './pages/Addpubaqar';
import Editpubaqar from './pages/Editpubaqar';
import Viewpubaqar from './pages/Viewpubaqar';

import Adddepartment from './pages/Adddepartment';
import Editdepartment from './pages/Editdepartment';
import Viewdepartment from './pages/Viewdepartment';

import Addbosfac from './pages/Addbosfac';
import Editbosfac from './pages/Editbosfac';
import Viewbosfac from './pages/Viewbosfac';

import Addtaskticket from './pages/Addtasksticket';
import Viewtaskticket from './pages/Viewtaskticket';
import Viewtaskticketall from './pages/Viewtasksticketall';
import Edittaskticket from './pages/Edittaskticket';

import Thankyou from './pages/Thankyou';

import Viewtaskassignuser from './pages/Viewtaskassignuser';
import Edittaskassignuser from './pages/Edittaskassignuser';
import Viewtaskbyidticket from './pages/Viewtaskbyidticket';

import Viewawsconfig from './pages/Viewawsconfig';
import Editawsconfig from './pages/Editawsconfig';
import Addawsconfig from './pages/Addawsconfig';

import Addsupportingdoc from './pages/Addsupportingdoc1';
import Addsupportingdoc2 from './pages/Addsupportingdoc2';
import Selectawsconfig from './pages/Selectawsconfig';
import Viewsupportingdoc from './pages/Viewsupportingdoc';
import Viewsupportingdocall from './pages/Viewsupportingdocall';

import Addmetricrules from './pages/Addmetricrules';
import Editmetricrules from './pages/Editmetricrules';
import Viewmetricrules from './pages/Viewmetricrules';
import Selectmetricrule from './pages/Selectmetricrule';
import Selectmetricrulefiles from './pages/Selectmetricrulefiles';



import Viewcircularfac from './pages/Viewcircularfac';
import Editcircularfac from './pages/Editcircularfac';
import Addcircularfac from './pages/Addcircularfac';

import Viewinstitutions from './pages/Viewinstitutions';
import Editinstitutions from './pages/Editinstitutions';
import Addinstitution from './pages/Addinstitution';
import Selectinstitution from './pages/Selectinstitution';

import Selectdepartment from './pages/Selectdepartment';
import Viewdeppub from './pages/Viewdeppub';
import Viewdepprojects from './pages/Viewdepprojects';
import Viewdepseminar from './pages/Viewdepseminar';
import Viewdepbooks from './pages/Viewdepbooks';

import Viewdeppubmy from './pages/Viewdeppubmy';
import Viewdepseminarmy from './pages/Viewdepseminarmy';
import Viewdepprojectsmy from './pages/Viewdepprojectsmy';
import Viewdepbooksmy from './pages/Viewdepbooksmy';

import Getstudentcount from './pages/Getstudentcount';
import Getfacultycount from './pages/Getfacultycount';
import Getstudentbycategory from './pages/Getstudentbycategory';
import Getstudentbycatgender from './pages/Getstudentbycatgender';
import Selectattendance from './pages/Selectattendance';

import Addbulkaddonc from './pages/Addbulkaddonc';
import Editadminbooks from './pages/Editadminbooks';
import Viewsupportingdocadmin from './pages/Viewsupportingdocadmin';


import Workload13 from './pages/Workload13';
import Takeattendance from './pages/Takeattendance';
import Viewclassattendance from './pages/Viewclassattendance';

import Classattendance3 from './pages/ClassAttendance3';

import Addexplearnproj from './pages/Addexplearnproj';
import Viewexplearnproj from './pages/Viewexplearnproj';
import Editexplearnproj from './pages/Editexplearnproj';
import Addfieldproj from './pages/Addfieldproj';
import Viewfieldproj from './pages/Viewfieldproj';
import Editfieldproj from './pages/Editfieldproj';
import Addteacherguide from './pages/Addteacherguide';
import Viewteacherguide from './pages/Viewteacherguide';
import Editteacherguide from './pages/Editteacherguide';
import Addextawards from './pages/Addextawards';
import Viewextawards from './pages/Viewextawards';
import Editextawards from './pages/Editextawards';
import Addmouact from './pages/Addmouact';
import Viewmouact from './pages/Viewmouact';
import Editmouact from './pages/Editmouact';
import Addsportsact from './pages/Addsportsact';
import Viewsportsact from './pages/Viewsportsact';
import Editsportsact from './pages/Editsportsact';
import Addegov from './pages/Addegov';
import Viewegov from './pages/Viewegov';
import Editegov from './pages/Editegov'; 
import Addfdp from './pages/Addfdp';
import Viewfdp from './pages/Viewfdp';
import Editfdp from './pages/Editfdp'; 
import Addqualityinit from './pages/Addqualityinit';
import Viewqualityinit from './pages/Viewqualityinit';
import Editqualityinit from './pages/Editqualityinit';

import Addbulkpublications from './pages/Addbulkpublications';
import Addbulkpatents from './pages/Addbulkpatents';
import Addbulkbooks from './pages/Addbulkbooks';
import Addbulkseminar from './pages/Addbulkseminar';
import Addbulkprojects from './pages/Addbulkprojects';

import Viewallstudents from './pages/Viewallstudents';

import Addbosbulk from './pages/Addbosbulk';
import Addcbcsbulk from './pages/Addcbcsbulk';
import Addsyllabusrevbulk from './pages/Addsyllabusrevbulk';
import Addemployabilitybulk from './pages/Addemployabilitybulk';
import Addexplearningbulk from './pages/Addexplearningbulk';

import Addadmissionbulk from './pages/Addadmissionbulk';
import Addreservecatbulk from './pages/Addreservecatbulk';
import Addteacherdatabulk from './pages/Addteacherdatabulk';
import Addpassexambulk from './pages/Addpassexambulk';
import Addresultbulk from './pages/Addresultbulk';
import Addteacherawardbulk from './pages/Addteacherawardbulk';
import Addexamautomationbulk from './pages/Addexamautomationbulk';
import Addmenteesbulk from './pages/Addmenteesbulk';

import Addinstawardsbulk from './pages/Addinstawardsbulk';
import Addextactbulk from './pages/Addextactbulk';
import Addcollaborationbulk from './pages/Addcollaborationbulk';
import Addmoubulk from './pages/Addmoubulk';
import Addecontentbulk from './pages/Addecontentbulk';
import Addseedmbulk from './pages/Addseedmbulk';
import Addteacherfellowbulk from './pages/Addteacherfellowbulk';
import Addresearchfellowbulk from './pages/Addresearchfellowbulk';

import Addictbulk from './pages/Addictbulk';
import Addexpenditurebulk from './pages/Addexpenditurebulk';
import Addlibrarybulk from './pages/Addlibrarybulk';

import Addscholarshipbulk from './pages/Addscholarshipbulk';
import Addskilldevbulk from './pages/Addskilldevbulk';
import Addcareercounselbulk from './pages/Addcareercounselbulk';
import Addplacementbulk from './pages/Addplacementbulk';
import Addhigheredubulk from './pages/Addhigheredubulk';
import Addhigherexambulk from './pages/Addhigherexambulk';
import Addawardsbulk from './pages/Addawardsbulk';
import Addalumniconbulk from './pages/Addalumniconbulk';

import Addegovernbulk from './pages/Addegovernbulk';
import Addteacherfsbulk from './pages/Addteacherfsbulk';
import Addfundsbulk from './pages/Addfundsbulk';
import Addqualitybulk from './pages/Addqualitybulk';



import Addemployability from './pages/Addemployability';
import Viewemployability from './pages/Viewemployability';
import Editemployability from './pages/Editemployability';
import Addphdguide from './pages/Addphdguide';
import Viewphdguide from './pages/Viewphdguide';
import Editphdguide from './pages/Editphdguide';
import Addteacheraward from './pages/Addteacheraward';
import Viewteacheraward from './pages/Viewteacheraward';
import Editteacheraward from './pages/Editteacheraward';
import Addexamautomation from './pages/Addexamautomation';
import Viewexamautomation from './pages/Viewexamautomation';
import Editexamautomation from './pages/Editexamautomation';
import Addteacherfellow from './pages/Addteacherfellow';
import Viewteacherfellow from './pages/Viewteacherfellow';
import Editteacherfellow from './pages/Editteacherfellow';
import Addresearchfellow from './pages/Addresearchfellow';
import Viewresearchfellow from './pages/Viewresearchfellow';
import Editresearchfellow from './pages/Editresearchfellow';
import Addmentees from './pages/Addmentees';
import Viewmentees from './pages/Viewmentees';
import Editmentees from './pages/Editmentees';
import Addalumnicon from './pages/Addalumnicon';
import Viewalumnicon from './pages/Viewalumnicon';
import Editalumnicon from './pages/Editalumnicon';

import Viewlibbookstudents from './pages/Viewlibbookstudents';
import Enrollstudentsext from './pages/Enrollstudentsext';

import Addexplearnprojbulk from './pages/Addexplearnprojbulk';
import Addfieldprojbulk from './pages/Addfieldprojbulk';
import Addteacherguidebulk from './pages/Addteacherguidebulk';
import Addsportsactbulk from './pages/Addsportsactbulk';
import Addegovbulk from './pages/Addegovbulk';
import Addeventbulk from './pages/Addeventbulk';
import Addqualityinitbulk from './pages/Addqualityinitbulk';
import Addfdpbulk from './pages/Addfdpbulk';
import Addmouactbulk from './pages/Addmouactbulk';

import Viewallclasses from './pages/Viewallclasses';
import Viewallcoursefiles from './pages/Viewallcoursefiles';
import Viewallassignments from './pages/Viewallassignments';

import Viewincubation from './pages/Viewincubation';
import Addincubation from './pages/Addincubation';
import Editincubation from './pages/Editincubation';

import Viewstartup from './pages/Viewstartup';
import Addstartup from './pages/Addstartup';
import Editstartup from './pages/Editstartup';
import Viewcombined from './pages/Viewcombined';

import Addphdguidebulk from './pages/Addphdguidebulk';

import Addcbcsuao from './pages/Addcbcsuao';
import Editcbcsuao from './pages/Editcbcsuao';
import Viewcbcsuao from './pages/Viewcbcsuao';
import Addsyllabusrevaqao from './pages/Addsyllabusrevaqao';
import Editsyllabusrevaqao from './pages/Editsyllabusrevaqao';
import Viewsyllabusrevaqao from './pages/Viewsyllabusrevaqao';

import Viewemployabilityadmin from './pages/Viewemployabilityadmin';
import Viewsyllabusrevadmin from './pages/Viewsyllabusrevadmin';
import Viewexplearningadmin from './pages/Viewexplearningadmin';
import Viewphdguideadmin from './pages/Viewphdguideadmin';
import Viewresearchfellowadmin from './pages/Viewresearchfellowadmin';
import Viewteacherfellowadmin from './pages/Viewteacherfellowadmin';
import Classstudentsmsec from './pages/Classstudentsmsec';
import Viewextawardsadmin from './pages/Viewextawardsadmin';

import Viewecontentadmin from './pages/Viewecontentadmin';
import Viewalumniconadmin from './pages/Viewalumniconadmin';
import Viewresultadmin from './pages/Viewresultadmin';
import Viewexamautomationadmin from './pages/Viewexamautomationadmin';
import Viewmenteesadmin from './pages/Viewmenteesadmin';
import Viewteacherawardadmin from './pages/Viewteacherawardadmin';

import Viewseedmadmin from './pages/Viewseedmadmin';
import Viewconsultancyadmin from './pages/Viewconsultancyadmin';
import Viewscholarshipadmin from './pages/Viewscholarshipadmin';

import Qspeeradd from './pages/Qspeeradd';
import Qspeer1 from './pages/Qspeer1';
import Viewqspeer from './pages/Viewqspeer';
import Viewqsemployers from './pages/Viewqsemployers';
import Viewdeptreport from './pages/Viewdeptreport';

import Selectattendanceweek from './pages/Selectattendanceweek';
import ClassAttendanceweek from './pages/ClassAttendanceweek';
import Viewweeklyattendance from './pages/Viewweeklyattendance';
import Viewplacementall from './pages/Viewplacementall';
import Addkpibulk from './pages/Addkpibulk';
import Viewsupportingdocquery from './pages/Viewsupportingdocquery';
import Viewsupportingdocqueryuser from './pages/Viewsupportingdocqueryuser';

import Viewallhod from './pages/Viewallhod';
import Noaccess from './pages/Noaccess';


import Viewincubationadmin from './pages/Viewincubationadmin';
import Viewsportsactadmin from './pages/Viewsportsactadmin';
import Viewfdpadmin from './pages/Viewfdpadmin';
import Viewfieldprojadmin from './pages/Viewfieldprojadmin';
import Viewfundsadmin from './pages/Viewfundsadmin';
import Viewqualityinitadmin from './pages/Viewqualityinitadmin';
import Viewexplearnprojadmin from './pages/Viewexplearnprojadmin';

import Viewprojectyrdocs from './pages/Viewprojectyrdocs';
import Viewallseminardocs from './pages/Viewallseminardocs';
import Viewallsemreimbdocs from './pages/Viewallsemreimbdocs';
import Viewallpubdocs from './pages/Viewallpubdocs';
import Viewallpubdoccomments from './pages/Viewallpubdoccomments';
import Viewallpubdoccomcy from './pages/Viewallpubdoccomcy';
import Selectperiod from './pages/Selectperiod';
import Viewexplearningdocs from './pages/Viewexplearningdocs';
import Viewemployabilitydocs from './pages/Viewemployabilitydocs';
import Viewsyllabusrevdocs from './pages/Viewsyllabusrevdocs';
import Viewallpatentdocs from './pages/Viewallpatentdocs';
import Viewallbookdoccomments from './pages/Viewallbookdoccomments';
import Viewinnovationdoccomments from './pages/Viewinnovationdoccomments';
import Viewinnovationdoccommentsd from './pages/Viewinnovationdoccommentsd';
import Viewteacherfellowdoccomments from './pages/Viewteacherfellowdoccomments';
import Viewteacherawarddoccomments from './pages/Viewteacherawarddoccomments';
import Viewadmissiondoccomments from './pages/Viewadmissiondoccomments';
import Viewscholarshipdoccomments from './pages/Viewscholarshipdoccomments';
import Viewskilldevdoccomments from './pages/Viewskilldevdoccomments';
import Viewplacementdoccomments from './pages/Viewplacementdoccomments';
import Viewcareerdoccomments from './pages/Viewcareerdoccomments';
import Vieweventdoccomments from './pages/Vieweventdoccomments';
import Viewseedmdoscomments from './pages/Viewseedmdoscomments';
import Viewseedmdoccommentsd from './pages/Viewseedmdoccommentsd';
import Viewaddonccomments from './pages/Viewaddoncdoccomments';
import Viewictdoccomments from './pages/Viewictdoccomments';
import Viewbosdoccomments from './pages/Viewbosdoccomments';
import Viewcollabdoccomments from './pages/Viewcollabdoccomments';
import Viewmoudoccomments from './pages/Viewmoudoccomments';
import Viewscholarshipdocs from './pages/Viewscholarshipdoccommentsd';

import Viewcurgap from './pages/Viewcurgap';
import Viewcurgapadmin from './pages/Viewcurgapadmin';
import Addcurgap from './pages/Addcurgap';
import Editcurgap from './pages/Editcurgap';

import Viewprograms from './pages/Viewprograms';
import Addprograms from './pages/Addprograms';
import Editprograms from './pages/Editprograms';
import Addprogrambulk from './pages/Addprogrambulk';

import Viewdeppublications from './pages/Viewdeppublications';
import Adddeppub from './pages/Adddeppub';
import Editdeppub from './pages/Editdeppub';
import Viewdeppublicationsadmin from './pages/Viewdeppublicationsadmin';

import Viewstudpub from './pages/Viewstudpub';
import Addstudpub from './pages/Addstudpub';
import Editstudpub from './pages/Editstudpub';
import Viewstudpubadmin from './pages/Viewstudpubadmin';
import Viewuniversitymetric from './pages/Viewuniversitymetric';
import Viewuniversitymetric1 from './pages/Viewuniversitymetric1';
import Viewaffiliatedmetric from './pages/Viewaffiliatedmetric';
import Adminaffiliatedmetric from './pages/Adminaffiliatedmetric';
import Viewaffiliatedmetricnew from './pages/Viewaffiliatedmetricnew';
import Adminaffiliatedmetricnew from './pages/Adminaffiliatedmetricnew';
import Adminuniversitymetric from './pages/Adminuniversitymetric';
import Viewremedial from './pages/Viewremedial';
import Addremedial from './pages/Addremedial';
import Editremedial from './pages/Editremedial';
import Viewremedialadmin from './pages/Viewremedialadmin';
import Viewcurstructure from './pages/Viewcurstructure';
import Addcurstructure from './pages/Addcurstructure';
import Editcurstructure from './pages/Editcurstructure';
import Viewcurstructureadmin from './pages/Viewcurstructureadmin';
import Viewnbaug from './pages/Viewnbaug';

import Viewnbaug11 from './pages/Viewnbaug11';
import Addnbaug11 from './pages/Addnbaug11';
import Viewnbaug11admin from './pages/Viewnbaug11admin';

import Viewgoals from './pages/Viewgoals';
import Addgoals from './pages/Addgoals';

import Viewlessonnew from './pages/Viewlessonnew';
import Addlessonplannew from './pages/Addlessonplannew';
import Editlessonplannew from './pages/Editlessonplannew';
import Editworkloadnew from './pages/Editworkloadnew';
import Addclass from './pages/Addclass';

import Viewleavebalance from './pages/Viewleavebalance';
import Addleavebalance from './pages/Addleavebalance';
import Editleavebalance from './pages/Editleavebalance';

import Viewdeptfeedbackcount from './pages/Viewdeptfeedbackcount';
import Viewsssqscore from './pages/Viewsssqscore';
import Viewssscount from './pages/Viewssscount';
import Viewssstext from './pages/Viewssstext';

import Viewleaveapply from './pages/Viewleaveapply';
import Addleaveapply from './pages/Addleaveapply';
import Viewleavepending from './pages/Viewleavepending';
import Viewleavependinghod from './pages/Viewleavependinghod';
import Viewleavependingprincipal from './pages/Viewleavependingprincipal';
import Viewleavehistory from './pages/Viewleavehistory';

import Viewnbadiploma from './pages/Viewnbadiploma';
import Viewnbamca from './pages/Viewnbamca';

import Viewreservecatdoccomments from './pages/Viewreservecatdoccomments';
import Viewcbcsdoc from './pages/Viewcbcsdoc';
import Viewexplearningprojdoc from './pages/Viewexplearningprojdoc';
// import Viewexplearningprojdoc from './pages/Viewexplearningprojdoc';
import Viewteacherdatadoc from './pages/Viewteacherdatadoc';
import Viewphdguidedoccomments from './pages/Viewphdguidedoccomments';
import Viewpassexamdocs from './pages/Viewpassexamdocs';
import Viewresearchfellodocs from './pages/Viewresearchfellodocs';
import Viewresearchfellodocsd from './pages/Viewresearchfellodocsd';
import Selectproject from './pages/Selectproject';
import Selectmyproject from './pages/Selectmyproject';
import Viewresearchfellowadmfac from './pages/Viewresearchfellowadmfac';
import Selectmyprojectadm from './pages/Selectmyprojectadm';
import Viewprojectbalance from './pages/Viewprojectbalance';

import Addinvoice from './pages/Addinvoice';
import Viewinvoice from './pages/Viewinvoice';
import Editinvoice from './pages/Editinvoice';

import Addprojectbalance from './pages/Addprojectbalance';
import Editprojectbalance from './pages/Editprojectbalance';

import Viewkpiuser1 from './pages/Viewkpiuser1';
import Addallkpi1 from './pages/Addallkpi1';
import Viewsyllabuserror from './pages/Viewsyllabuserror';

import Viewadmissiondoccommentsd from './pages/Viewadmissiondoccommentsd';
import Viewallbookdoccommentsd from './pages/Viewallbookdoccommentsd';
import Viewallpatentdocsd from './pages/Viewallpatentdocsd';
import Viewallpubdoccomcyd from './pages/Viewallpubdoccomcyd';
import Viewallpubdoccommentsd from './pages/Viewallpubdoccommentsd';
import Viewallpubdocsd from './pages/Viewallpubdocsd';
import Viewallseminardocsd from './pages/Viewallseminardocsd';
import Viewallsemreimbdocsd from './pages/Viewallsemreimbdocsd';
import Viewbosdoccommentsd from './pages/Viewbosdoccommentsd';
import Viewcareerdoccommentsd from './pages/Viewcareerdoccommentsd';
import Viewcbcsdocd from './pages/Viewcbcsdocd';
import Viewcollabdoccommentsd from './pages/Viewcollabdoccommentsd';
import Viewemployabilitydocsd from './pages/Viewemployabilitydocsd';
import Vieweventdoccommentsd from './pages/Vieweventdoccommentsd';
import Viewexplearningdocsd from './pages/Viewexplearningdocsd';
import Viewexplearningprojdocd from './pages/Viewexplearningprojdocd';
import Viewictdoccommentsd from './pages/Viewictdoccommentsd';
import Viewmoudoccommentsd from './pages/Viewmoudoccommentsd';
import Viewpassexamdocsd from './pages/Viewpassexamdocsd';
import Viewphdguidedoccommentsd from './pages/Viewphdguidedoccommentsd';
import Viewplacementdoccommentsd from './pages/Viewplacementdoccommentsd';
import Viewprojectyrdocsd from './pages/Viewprojectyrdocsd';
import Viewreservecatdoccommentsd from './pages/Viewreservecatdoccommentsd';
import Viewscholarshipdoccommentsd from './pages/Viewskilldevdoccommentsd';
import Viewsyllabusrevdocsd from './pages/Viewsyllabusrevdocsd';
import Viewteacherawarddoccommentsd from './pages/Viewteacherawarddoccommentsd';
import Viewteacherdatadocd from './pages/Viewteacherdatadocd';
import Viewteacherfellowdoccommentsd from './pages/Viewteacherfellowdoccommentsd';
import Viewaddoncdoccommentsd from './pages/Viewaddoncdoccommentsd';

import Viewnprogcourseadminlinks from './pages/Viewnprogcourseadminlinks';


import Checkocr from './pages/Checkocr';

import Ocrtest1 from './pages/Ocrtest1';
import Ocrtest2 from './pages/Ocrtest2';
import Ocrtest3 from './pages/Ocrtest3';
import Ocrtest31 from './pages/Ocrtest31';
import Ocrtest32 from './pages/Ocrtest32';
import Ocrtest33 from './pages/Ocrtest33';
import Ocrtest34 from './pages/Ocrtest34';
import Selectawsconfignew from './pages/Selectawsconfignew';
import Viewleaveapplyadmin from './pages/Viewleaveapplyadmin';
import Offlinewelcome from './pages/Offlinewelcome';
import Geotagtest1 from './pages/Geotagtest1';
import Selectawsphoto from './pages/Selectawsphoto';

import Addpdfbulk from './pages/Addpdfbulk';
import Pdftest2 from './pages/Pdftest2';
import Pdftest3 from './pages/Pdftest3';
import Selectawspdf from './pages/Selectawspdf';

import Thankyouafter from './pages/Thankyouafter';

import Viewfieldprojdd from './pages/Viewfieldprojdd';
import Viewfdpcold from './pages/Viewfdpcold';



import Viewexamautomationd from './pages/Viewexamautomationd';
import Viewincubationd from './pages/Viewincubationd';
import Viewalumnicond from './pages/Viewalumnicond';
import Viewegovernd from './pages/Viewegovernd';
import Viewhigheredud from './pages/Viewhigheredud';
import Viewhigherexamd from './pages/Viewhigherexamd';
import Viewlibraryexpd from './pages/Viewlibraryexpd';
import Viewexpenditured from './pages/Viewexpenditured';
import Viewextensionactd from './pages/Viewextensionactd';
import Viewconsultancyd from './pages/Viewconsultancyd';
import Viewecontentd from './pages/Viewecontentd';
import Viewresultd from './pages/Viewresultd';
import Viewqualityd from './pages/Viewqualityd';
import Viewqualityinitd from './pages/Viewqualityinitd';
import Viewfundsd from './pages/Viewfundsd';
import Viewextawardsd from './pages/Viewextawardsd';
import Viewteacherfsd from './pages/Viewteacherfsd';


import Viewdeletebyuser from './pages/Viewdeletebyuser';

import Viewbosexcelyear from './pages/Viewbosexcelyear';
import Viewboslinksyear from './pages/Viewboslinksyear';
import Viewcbcsexcelyear from './pages/Viewcbcsexcelyear';
import Viewaddonexcelyear from './pages/Viewaddonexcelyear';
import Viewaddonlinksyear from './pages/Viewaddonlinksyear';
import Viewexplearnprojexcel from './pages/Viewexplearnprojexcel';
import Viewexplearnaffexcel from './pages/Viewexplearnaffexcel';
import Viewaddondeclaration from './pages/Viewaddondeclaration';
import Viewadmissionexcel from './pages/Viewadmissionexcel';
import Viewadmissionlinks from './pages/Viewadmissionlinks';
import Viewreservecatlinks from './pages/Viewreservecatlinks';
import Viewphdguideexcel from './pages/Viewphdguideexcel';
import Viewphdguidelinks from './pages/Viewphdguidelinks';
import Viewpassexcel from './pages/Viewpassexcel';
import Viewpasslinks from './pages/Viewpasslinks';
import Viewplacementexcel from './pages/Viewplacementexcel';
import Viewplacementlinks from './pages/Viewplacementlinks';
import Viewhighereduexcel from './pages/Viewhighereduexcel';
import Viewhigheredulinks from './pages/Viewhigheredulinks';
import Viewhigherexamexcel from './pages/Viewhigherexamexcel';
import Viewhigherexamlinks from './pages/Viewhigherexamlinks';
import Viewprojectexcel from './pages/Viewprojectexcel';
import Viewprojectlinks from './pages/Viewprojectlinks';
import Viewpubexcel from './pages/Viewpubexcel';
import Viewpublinks from './pages/Viewpublinks';
import Viewmouexcel from './pages/Viewmouexcel';
import Viewmoulinks from './pages/Viewmoulinks';
import Viewbooklinks from './pages/Viewbooklinks';
import Viewbooksexcel from './pages/Viewbooksexcel';
import Viewcollabexcel from './pages/Viewcollabexcel';
import Viewextensionexcel from './pages/Viewextensionexcel';
import Viewextensionlinks from './pages/Viewextensionlinks';
import Viewawardsdocsd from './pages/Viewawardsdocsd';
import Viewawardsexcel from './pages/Viewawardsexcel';
import Viewawardslink from './pages/Viewawardslink';
import Viewscholarshipexcel from './pages/Viewscholarshipexcel';
import Viewskilldevexcel from './pages/Viewskilldevexcel';
import Viewskilldevlinks from './pages/Viewskilldevlinks';
import Vieweventexcel from './pages/Vieweventexcel';
import Vieweventlinks from './pages/Vieweventlink';
import Viewfdpexcel from './pages/Viewfdpexcel';
import Viewfdplinks from './pages/Viewfdplinks';
import Viewhnewprog from './pages/Viewhnewprog';
import Addhnewprog from './pages/Addhnewprog';
import Edithnewprog from './pages/Edithnewprog';
import Viewhnewprogadmin from './pages/Viewhnewprogadmin';
import Viewntimesheetadmin from './pages/Viewntimesheetadmin';
import Viewntimesheet from './pages/Viewntimesheet';
import Addntimesheet from './pages/Addntimesheet';
import Editntimesheet from './pages/Editntimesheet';

import Editnsop from './pages/Editnsop';
import Viewnsop from './pages/Viewnsop';
import Addnsop from './pages/Addnsop';
import Viewnsopadmin from './pages/Viewnsopadmin';

import Addkpibulkin from './pages/Addkpibulkin';

import Editnacademic from './pages/Editnacademic';
import Viewnacademic from './pages/Viewnacademic';
import Addnacademic from './pages/Addnacademic';
import Addnacademicbulk from './pages/Addnacademicbulk';
import Viewnacademicadmin from './pages/Viewnacademicadmin';

import Viewsportsactdocs from './pages/Viewsportsactdocs';

import Editnworkbook from './pages/Editnworkbook';
import Viewnworkbook from './pages/Viewnworkbook';
import Addnworkbook from './pages/Addnworkbook';
import Addnworkbookbulk from './pages/Addnworkbookbulk';
import Viewnworkbookadmin from './pages/Viewnworkbookadmin';

import Editnenrollment from './pages/Editnenrollment';
import Viewnenrollment from './pages/Viewnenrollment';
import Addnenrollment from './pages/Addnenrollment';
import Addnenrollmentbulk from './pages/Addnenrollmentbulk';
import Viewnenrollmentadmin from './pages/Viewnenrollmentadmin';

import Editncourseplan from './pages/Editncourseplan';
import Viewncourseplan from './pages/Viewncourseplan';
import Addncourseplan from './pages/Addncourseplan';
import Addncourseplanbulk from './pages/Addncourseplanbulk';
import Viewncourseplanadmin from './pages/Viewncourseplanadmin';

import Editninter from './pages/Editninter';
import Viewninter from './pages/Viewninter';
import Addninter from './pages/Addninter';
import Addninterbulk from './pages/Addninterbulk';
import Viewninteradmin from './pages/Viewninteradmin';

import Editnclinicskills from './pages/Editnclinicskills';
import Viewnclinicskills from './pages/Viewnclinicskills';
import Addnclinicskills from './pages/Addnclinicskills';
import Addnclinicskillsbulk from './pages/Addnclinicskillsbulk';
import Viewnclinicskillsadmin from './pages/Viewnclinicskillsadmin';

import Editnhlearner from './pages/Editnhlearner';
import Viewnhlearner from './pages/Viewnhlearner';
import Addnhlearner from './pages/Addnhlearner';
import Addnhlearnerbulk from './pages/Addnhlearnerbulk';
import Viewnhlearneradmin from './pages/Viewnhlearneradmin';

import Editnotherstud from './pages/Editnotherstud';
import Viewnotherstud from './pages/Viewnotherstud';
import Addnotherstud from './pages/Addnotherstud';
import Addnotherstudbulk from './pages/Addnotherstudbulk';
import Viewnotherstudadmin from './pages/Viewnotherstudadmin';

import Editnreval from './pages/Editnreval';
import Viewnreval from './pages/Viewnreval';
import Addnreval from './pages/Addnreval';
import Addnrevalbulk from './pages/Addnrevalbulk';
import Viewnrevaladmin from './pages/Viewnrevaladmin';

import Editnfacilities from './pages/Editnfacilities';
import Viewnfacilities from './pages/Viewnfacilities';
import Addnfacilities from './pages/Addnfacilities';
import Addnfacilitiesbulk from './pages/Addnfacilitiesbulk';
import Viewnfacilitiesadmin from './pages/Viewnfacilitiesadmin';

import Editndepfunding from './pages/Editndepfunding';
import Viewndepfunding from './pages/Viewndepfunding';
import Addndepfunding from './pages/Addndepfunding';
import Addndepfundingbulk from './pages/Addndepfundingbulk';
import Viewndepfundingadmin from './pages/Viewndepfundingadmin';

import Editnprogcourse from './pages/Editnprogcourse';
import Viewnprogcourse from './pages/Viewnprogcourse';
import Addnprogcourse from './pages/Addnprogcourse';
import Addnprogcoursebulk from './pages/Addnprogcoursebulk';
import Viewnprogcourseadmin from './pages/Viewnprogcourseadmin';

import Editnnvacstud from './pages/Editnnvacstud';
import Viewnnvacstud from './pages/Viewnnvacstud';
import Addnnvacstud from './pages/Addnnvacstud';
import Addnnvacstudbulk from './pages/Addnnvacstudbulk';
import Viewnnvacstudadmin from './pages/Viewnnvacstudadmin';

import Editnnursinginter from './pages/Editnnursinginter';
import Viewnnursinginter from './pages/Viewnnursinginter';
import Addnnursinginter from './pages/Addnnursinginter';
import Addnnursinginterbulk from './pages/Addnnursinginterbulk';
import Viewnnursinginteradmin from './pages/Viewnnursinginteradmin';


import Editnnexp from './pages/Editnnexp';
import Viewnnexp from './pages/Viewnnexp';
import Addnnexp from './pages/Addnnexp';
import Addnnexpbulk from './pages/Addnnexpbulk';
import Viewnnexpadmin from './pages/Viewnnexpadmin';

import Editnnratio from './pages/Editnnratio';
import Viewnnratio from './pages/Viewnnratio';
import Addnnratio from './pages/Addnnratio';
import Addnnratiobulk from './pages/Addnnratiobulk';
import Viewnnratioadmin from './pages/Viewnnratioadmin';

import Editnnmentor from './pages/Editnnmentor';
import Viewnnmentor from './pages/Viewnnmentor';
import Addnnmentor from './pages/Addnnmentor';
import Addnnmentorbulk from './pages/Addnnmentorbulk';
import Viewnnmentoradmin from './pages/Viewnnmentoradmin';

import Editnnexam from './pages/Editnnexam';
import Viewnnexam from './pages/Viewnnexam';
import Addnnexam from './pages/Addnnexam';
import Addnnexambulk from './pages/Addnnexambulk';
import Viewnnexamadmin from './pages/Viewnnexamadmin';

import Editnecourse from './pages/Editnecourse';
import Viewnecourse from './pages/Viewnecourse';
import Addnecourse from './pages/Addnecourse';
import Addnecoursebulk from './pages/Addnecoursebulk';
import Viewnecourseadmin from './pages/Viewnecourseadmin';

import Editnnextension from './pages/Editnnextension';
import Viewnnextension from './pages/Viewnnextension';
import Addnnextension from './pages/Addnnextension';
import Addnnextensionbulk from './pages/Addnnextensionbulk';
import Viewnnextensionadmin from './pages/Viewnnextensionadmin';

import Editnncollab from './pages/Editnncollab';
import Viewnncollab from './pages/Viewnncollab';
import Addnncollab from './pages/Addnncollab';
import Addnncollabbulk from './pages/Addnncollabbulk';
import Viewnncollabadmin from './pages/Viewnncollabadmin';

import Editnnmou from './pages/Editnnmou';
import Viewnnmou from './pages/Viewnnmou';
import Addnnmou from './pages/Addnnmou';
import Addnnmoubulk from './pages/Addnnmoubulk';
import Viewnnmouadmin from './pages/Viewnnmouadmin';

import Editnnpatients from './pages/Editnnpatients';
import Viewnnpatients from './pages/Viewnnpatients';
import Addnnpatients from './pages/Addnnpatients';
import Addnnpatientsbulk from './pages/Addnnpatientsbulk';
import Viewnnpatientsadmin from './pages/Viewnnpatientsadmin';

import Editnnexposure from './pages/Editnnexposure';
import Viewnnexposure from './pages/Viewnnexposure';
import Addnnexposure from './pages/Addnnexposure';
import Addnnexposurebulk from './pages/Addnnexposurebulk';
import Viewnnexposureadmin from './pages/Viewnnexposureadmin';

import Dasherp1 from './pages/Dasherp1';
import Viewkpiuser2 from './pages/Viewkpiuser2';
import Viewkpipending from './pages/Viewkpipending';


import Editnclient from './pages/Editnclient';
import Viewnclient from './pages/Viewnclient';
import Addnclient from './pages/Addnclient';
import Addnclientbulk from './pages/Addnclientbulk';
import Viewnclientadmin from './pages/Viewnclientadmin';

import Editnmilestone from './pages/Editnmilestone';
import Viewnmilestone from './pages/Viewnmilestone';
import Addnmilestone from './pages/Addnmilestone';
import Addnmilestonebulk from './pages/Addnmilestonebulk';
import Viewnmilestoneadmin from './pages/Viewnmilestoneadmin';

import Editninvoice from './pages/Editninvoice';
import Viewninvoice from './pages/Viewninvoice';
import Addninvoice from './pages/Addninvoice';
import Addninvoicebulk from './pages/Addninvoicebulk';
import Viewninvoiceadmin from './pages/Viewninvoiceadmin';

import Editngstgain from './pages/Editngstgain';
import Viewngstgain from './pages/Viewngstgain';
import Addngstgain from './pages/Addngstgain';
import Addngstgainbulk from './pages/Addngstgainbulk';
import Viewngstgainadmin from './pages/Viewngstgainadmin';

import Editnvendor from './pages/Editnvendor';
import Viewnvendor from './pages/Viewnvendor';
import Addnvendor from './pages/Addnvendor';
import Addnvendorbulk from './pages/Addnvendorbulk';
import Viewnvendoradmin from './pages/Viewnvendoradmin';

import Editnvworkorder from './pages/Editnvworkorder';
import Viewnvworkorder from './pages/Viewnvworkorder';
import Addnvworkorder from './pages/Addnvworkorder';
import Addnvworkorderbulk from './pages/Addnvworkorderbulk';
import Viewnvworkorderadmin from './pages/Viewnvworkorderadmin';

import Editnvinvoice from './pages/Editnvinvoice';
import Viewnvinvoice from './pages/Viewnvinvoice';
import Addnvinvoice from './pages/Addnvinvoice';
import Addnvinvoicebulk from './pages/Addnvinvoicebulk';
import Viewnvinvoiceadmin from './pages/Viewnvinvoiceadmin';

import Editntrialbalance from './pages/Editntrialbalance';
import Viewntrialbalance from './pages/Viewntrialbalance';
import Addntrialbalance from './pages/Addntrialbalance';
import Addntrialbalancebulk from './pages/Addntrialbalancebulk';
import Viewntrialbalanceadmin from './pages/Viewntrialbalanceadmin';

import Viewemployabilitydocsdep from './pages/Viewemployabilitydocsdep';

import Viewsyllabusrevdocsdep from './pages/Viewsyllabusrevdocsdep';
import Viewexplearningdocsdep from './pages/Viewexplearningdocsdep';
import Viewecontentdep from './pages/Viewecontentdep';
import Viewhigheredudep from './pages/Viewhigheredudep';
import Viewhigherexamdep from './pages/Viewhigherexamdep';
import Viewplacementdep from './pages/Viewplacementdep';

import Dasherpadmin1 from './pages/Dasherpadmin1';
import Dashfeedbackreports from './pages/Dashfeedbackreports';


import Editnbudget from './pages/Editnbudget';
import Viewnbudget from './pages/Viewnbudget';
import Addnbudget from './pages/Addnbudget';
import Addnbudgetbulk from './pages/Addnbudgetbulk';
import Viewnbudgetadmin from './pages/Viewnbudgetadmin';

import Editnledger from './pages/Editnledger';
import Viewnledger from './pages/Viewnledger';
import Addnledger from './pages/Addnledger';
import Addnledgerbulk from './pages/Addnledgerbulk';
import Viewnledgeradmin from './pages/Viewnledgeradmin';

import Editnaccounts from './pages/Editnaccounts';
import Viewnaccounts from './pages/Viewnaccounts';
import Addnaccounts from './pages/Addnaccounts';
import Addnaccountsbulk from './pages/Addnaccountsbulk';
import Viewnaccountsadmin from './pages/Viewnaccountsadmin';


import Editnseedmoney from './pages/Editnseedmoney';
import Viewnseedmoney from './pages/Viewnseedmoney';
import Addnseedmoney from './pages/Addnseedmoney';
import Addnseedmoneybulk from './pages/Addnseedmoneybulk';
import Viewnseedmoneyadmin from './pages/Viewnseedmoneyadmin';

import Editnbudgetreq from './pages/Editnbudgetreq';
import Viewnbudgetreq from './pages/Viewnbudgetreq';
import Addnbudgetreq from './pages/Addnbudgetreq';
import Addnbudgetreqbulk from './pages/Addnbudgetreqbulk';
import Viewnbudgetreqadmin from './pages/Viewnbudgetreqadmin';

import Editnoodreq from './pages/Editnoodreq';
import Viewnoodreq from './pages/Viewnoodreq';
import Addnoodreq from './pages/Addnoodreq';
import Addnoodreqbulk from './pages/Addnoodreqbulk';
import Viewnoodreqadmin from './pages/Viewnoodreqadmin';

import Editneventreq from './pages/Editneventreq';
import Viewneventreq from './pages/Viewneventreq';
import Addneventreq from './pages/Addneventreq';
import Addneventreqbulk from './pages/Addneventreqbulk';
import Viewneventreqadmin from './pages/Viewneventreqadmin';

import Editnassistantreq from './pages/Editnassistantreq';
import Viewnassistantreq from './pages/Viewnassistantreq';
import Addnassistantreq from './pages/Addnassistantreq';
import Addnassistantreqbulk from './pages/Addnassistantreqbulk';
import Viewnassistantreqadmin from './pages/Viewnassistantreqadmin';

import Editnhousingreq from './pages/Editnhousingreq';
import Viewnhousingreq from './pages/Viewnhousingreq';
import Addnhousingreq from './pages/Addnhousingreq';
import Addnhousingreqbulk from './pages/Addnhousingreqbulk';
import Viewnhousingreqadmin from './pages/Viewnhousingreqadmin';

import Editntravelsupport from './pages/Editntravelsupport';
import Viewntravelsupport from './pages/Viewntravelsupport';
import Addntravelsupport from './pages/Addntravelsupport';
import Addntravelsupportbulk from './pages/Addntravelsupportbulk';
import Viewntravelsupportadmin from './pages/Viewntravelsupportadmin';


import Editncompanies from './pages/Editncompanies';
import Viewncompanies from './pages/Viewncompanies';
import Addncompanies from './pages/Addncompanies';
import Addncompaniesbulk from './pages/Addncompaniesbulk';
import Viewncompaniesadmin from './pages/Viewncompaniesadmin';

import Editnjobs from './pages/Editnjobs';
import Viewnjobs from './pages/Viewnjobs';
import Addnjobs from './pages/Addnjobs';
import Addnjobsbulk from './pages/Addnjobsbulk';
import Viewnjobsadmin from './pages/Viewnjobsadmin';

import Editnjobstudents from './pages/Editnjobstudents';
import Viewnjobstudents from './pages/Viewnjobstudents';
import Addnjobstudents from './pages/Addnjobstudents';
import Addnjobstudentsbulk from './pages/Addnjobstudentsbulk';
import Viewnjobstudentsadmin from './pages/Viewnjobstudentsadmin';

import Editnhighereducation from './pages/Editnhighereducation';
import Viewnhighereducation from './pages/Viewnhighereducation';
import Addnhighereducation from './pages/Addnhighereducation';
import Addnhighereducationbulk from './pages/Addnhighereducationbulk';
import Viewnhighereducationadmin from './pages/Viewnhighereducationadmin';



import Viewnoffcampusadmin from './pages/Viewnoffcampusadmin';
import Viewnjobsapplyadmin from './pages/Viewnjobsapplyadmin';
import Viewnhigheredurepadmin from './pages/Viewnhigheredurepadmin';
import Viewnoffawardsadmin from './pages/Viewnoffawardsadmin';
import Viewnoffscholarshipadmin from './pages/Viewnoffscholarshipadmin';
import Viewnoffextensionadmin from './pages/Viewnoffextensionadmin';
import Viewnoffcoursesadmin from './pages/Viewnoffcoursesadmin';

import Viewdepfacdocs from './pages/Viewdepfacdocs';

import Editncommittees from './pages/Editncommittees';
import Viewncommittees from './pages/Viewncommittees';
import Addncommittees from './pages/Addncommittees';
import Addncommitteesbulk from './pages/Addncommitteesbulk';
import Viewncommitteesadmin from './pages/Viewncommitteesadmin';

import Editncommembers from './pages/Editncommembers';
import Viewncommembers from './pages/Viewncommembers';
import Addncommembers from './pages/Addncommembers';
import Addncommembersbulk from './pages/Addncommembersbulk';
import Viewncommembersadmin from './pages/Viewncommembersadmin';

import Editncomminutes from './pages/Editncomminutes';
import Viewncomminutes from './pages/Viewncomminutes';
import Addncomminutes from './pages/Addncomminutes';
import Addncomminutesbulk from './pages/Addncomminutesbulk';
import Viewncomminutesadmin from './pages/Viewncomminutesadmin';

import Editncomtasks from './pages/Editncomtasks';
import Viewncomtasks from './pages/Viewncomtasks';
import Addncomtasks from './pages/Addncomtasks';
import Addncomtasksbulk from './pages/Addncomtasksbulk';
import Viewncomtasksadmin from './pages/Viewncomtasksadmin';


import Addworkloadbulk from './pages/Addworkloadbulk';
import Addlessonplanbulk from './pages/Addlessonplanbulk';
import Dashcourse2 from './pages/Dashcourse2';
import Enrollstudentsext1 from './pages/Enrollstudentsext1';

import Viewlessonplanadmin from './pages/Viewlessonplanadmin';
import Viewworkloadadmin from './pages/Viewworkloadadmin';

import Editcoursefiles from './pages/Editcoursefiles';
import Viewcoursefiles from './pages/Viewcoursefiles';
import Addcoursefilesnew from './pages/Addcoursefilesnew';
import Addcoursefilesbulk from './pages/Addcoursefilesbulk';
import Viewcoursefilesadmin from './pages/Viewcoursefilesadmin';

import Editclass from './pages/Editclass';
import Viewclass from './pages/Viewclass';
import Addclassnew from './pages/Addclassnew';
import Addclassbulk from './pages/Addclassbulk';
import Viewclassadmin from './pages/Viewclassadmin';
import Addclassnewweeks from './pages/Addclassnewweeks';

import Selectprogramstt from './pages/Selectprogramstt';
import Selectsemsecyr from './pages/Selectsemsecyr';
import Viewclassprogram from './pages/Viewclassprogram';
import Viewclassseven from './pages/Viewclassseven';
import Viewallfacultieslms from './pages/Viewallfacultieslms';
import Viewworkloadadminfac from './pages/Viewworkloadadminfac';


import Editco from './pages/Editco';
import Viewco from './pages/Viewco';
import Addco from './pages/Addco';
import Addcobulk from './pages/Addcobulk';
import Viewcoadmin from './pages/Viewcoadmin';

import Editlannouncement from './pages/Editlannouncement';
import Viewlannouncement from './pages/Viewlannouncement';
import Addlannouncement from './pages/Addlannouncement';
import Addlannouncementbulk from './pages/Addlannouncementbulk';
import Viewlannouncementadmin from './pages/Viewlannouncementadmin';

import Editlsyllabus from './pages/Editlsyllabus';
import Viewlsyllabus from './pages/Viewlsyllabus';
import Addlsyllabus from './pages/Addlsyllabus';
import Addlsyllabusbulk from './pages/Addlsyllabusbulk';
import Viewlsyllabusadmin from './pages/Viewlsyllabusadmin';

import Editlcalendar from './pages/Editlcalendar';
import Viewlcalendar from './pages/Viewlcalendar';
import Addlcalendar from './pages/Addlcalendar';
import Addlcalendarbulk from './pages/Addlcalendarbulk';
import Viewlcalendaradmin from './pages/Viewlcalendaradmin';

import Edituser from './pages/Edituser';
import Viewuserfaculty from './pages/Viewuserfaculty';
import Viewuserstudent from './pages/Viewuserstudent';
import Viewuserhod from './pages/Viewuserhod';
import Adduser from './pages/Adduser';
import Adduserstudent from './pages/Adduserstudent';
import Adduserfaculty from './pages/Adduserfaculty';
import Adduserhod from './pages/Adduserhod';
import Adduserbulk from './pages/Adduserbulk';
import Adduserbulkhod from './pages/Adduserbulkhod';
import Adduserbulkstudent from './pages/Adduserbulkstudent';
import Viewuseradmin from './pages/Viewuseradmin';

import Viewlsyllabusemp from './pages/Viewlsyllabusemp';
import Addphoto from './pages/Addphoto';
import Selectawsconfigphoto from './pages/Selectawsconfigphoto';

import Selectperiodlms from './pages/Selectperiodlms';
import Viewallfacultieslmsdep from './pages/Viewallfacultieslmsdep';
import Deleteworkload from './pages/Deleteworkload';

import Newclassesf3 from './pages/Newclassesf3';
import Dashstud1 from './pages/Dashstud1';
import Selectawsconfigphotostud from './pages/Selectawsconfigphotostud';
import Addphotostud from './pages/Addphotostud';
import Viewclassprogramstud from './pages/Viewclassprogramstud';
import Viewenrolcourseyr from './pages/Viewenrolcourseyr';
import Viewcoursefilesstud from './pages/Viewcoursefilesstud';


import Editcurrenyear from './pages/Editcurrenyear';
import Viewcurrenyear from './pages/Viewcurrenyear';
import Addcurrenyear from './pages/Addcurrenyear';
import Addcurrenyearbulk from './pages/Addcurrenyearbulk';
import Viewcurrenyearadmin from './pages/Viewcurrenyearadmin';
import Viewassignmentstud from './pages/Viewassignmentstud';

import Selectawsconfigstudassignment from './pages/Selectawsconfigstudassignment';
import Addphotostudassignment from './pages/Addphotostudassignment';
import Viewcircularstud from './pages/Viewcircularstud';


import Editstudparents from './pages/Editstudparents';
import Viewstudparents from './pages/Viewstudparents';
import Addstudparents from './pages/Addstudparents';
import Addstudparentsbulk from './pages/Addstudparentsbulk';
import Viewstudparentsadmin from './pages/Viewstudparentsadmin';

import Classattendance4 from './pages/Classattendance4';

import Addlessonplanbulknew from './pages/Addlessonplanbulknew';

import Dashstud2 from './pages/Dashstud2';

import Classattendancefac from './pages/Classattendancefac';
import Classattendancefaccl from './pages/Classattendancefaccl';
import Classattendancestud from './pages/Classattendancestud';


import Editprojectledger from './pages/Editprojectledger';
import Viewprojectledger from './pages/Viewprojectledger';
import Addprojectledger from './pages/Addprojectledger';
import Addprojectledgerbulk from './pages/Addprojectledgerbulk';
import Viewprojectledgeradmin from './pages/Viewprojectledgeradmin';

import Editprojectcentral from './pages/Editprojectcentral';
import Viewprojectcentral from './pages/Viewprojectcentral';
import Addprojectcentral from './pages/Addprojectcentral';
import Addprojectcentralbulk from './pages/Addprojectcentralbulk';
import Viewprojectcentraladmin from './pages/Viewprojectcentraladmin';

import Editmaintenance from './pages/Editmaintenance';
import Viewmaintenance from './pages/Viewmaintenance';
import Addmaintenance from './pages/Addmaintenance';
import Addmaintenancebulk from './pages/Addmaintenancebulk';
import Viewmaintenanceadmin from './pages/Viewmaintenanceadmin';

import Editotherincome from './pages/Editotherincome';
import Viewotherincome from './pages/Viewotherincome';
import Addotherincome from './pages/Addotherincome';
import Addotherincomebulk from './pages/Addotherincomebulk';
import Viewotherincomeadmin from './pages/Viewotherincomeadmin';

import Viewfeedbackdata from './pages/Viewfeedbackdata';
import Viewvactodaystud from './pages/Viewvactodaystud';
import Viewdepdata from './pages/Viewdepdata';

import Editsyllabusrevadmin from './pages/Editsyllabusrevadmin';
import Editaddoncourseadmin from './pages/Editaddoncourseadmin';


import Editnaccountheads from './pages/Editnaccountheads';
import Viewnaccountheads from './pages/Viewnaccountheads';
import Addnaccountheads from './pages/Addnaccountheads';
import Addnaccountheadsbulk from './pages/Addnaccountheadsbulk';
import Viewnaccountheadsadmin from './pages/Viewnaccountheadsadmin';

import Editntransactions from './pages/Editntransactions';
import Viewntransactions from './pages/Viewntransactions';
import Addntransactions from './pages/Addntransactions';
import Addntransactionsbulk from './pages/Addntransactionsbulk';
import Viewntransactionsadmin from './pages/Viewntransactionsadmin';

import Editntledger from './pages/Editntledger';
import Viewntledger from './pages/Viewntledger';
import Addntledger from './pages/Addntledger';
import Addntledgerbulk from './pages/Addntledgerbulk';
import Viewntledgeradmin from './pages/Viewntledgeradmin';
import Selectfin from './pages/Selectfin';
import Datewisesummaryledger from './pages/Datewisesummaryledger';

import Viewsuperfaculty from './pages/Viewsuperfaculty';

import Reportcriteria1 from './pages/Reportcriteria1';


import Editnnursing812 from './pages/Editnnursing812';
import Viewnnursing812 from './pages/Viewnnursing812';
import Addnnursing812 from './pages/Addnnursing812';
import Addnnursing812bulk from './pages/Addnnursing812bulk';
import Viewnnursing812admin from './pages/Viewnnursing812admin';

import Editnnursing814 from './pages/Editnnursing814';
import Viewnnursing814 from './pages/Viewnnursing814';
import Addnnursing814 from './pages/Addnnursing814';
import Addnnursing814bulk from './pages/Addnnursing814bulk';
import Viewnnursing814admin from './pages/Viewnnursing814admin';

import Editnnursing815 from './pages/Editnnursing815';
import Viewnnursing815 from './pages/Viewnnursing815';
import Addnnursing815 from './pages/Addnnursing815';
import Addnnursing815bulk from './pages/Addnnursing815bulk';
import Viewnnursing815admin from './pages/Viewnnursing815admin';

import Editnnursing818 from './pages/Editnnursing818';
import Viewnnursing818 from './pages/Viewnnursing818';
import Addnnursing818 from './pages/Addnnursing818';
import Addnnursing818bulk from './pages/Addnnursing818bulk';
import Viewnnursing818admin from './pages/Viewnnursing818admin';

import Editnnursing424 from './pages/Editnnursing424';
import Viewnnursing424 from './pages/Viewnnursing424';
import Addnnursing424 from './pages/Addnnursing424';
import Addnnursing424bulk from './pages/Addnnursing424bulk';
import Viewnnursing424admin from './pages/Viewnnursing424admin';

import Editncompetitive from './pages/Editncompetitive';
import Viewncompetitive from './pages/Viewncompetitive';
import Addncompetitive from './pages/Addncompetitive';
import Addncompetitivebulk from './pages/Addncompetitivebulk';
import Viewncompetitiveadmin from './pages/Viewncompetitiveadmin';

import Editnnursing515 from './pages/Editnnursing515';
import Viewnnursing515 from './pages/Viewnnursing515';
import Addnnursing515 from './pages/Addnnursing515';
import Addnnursing515bulk from './pages/Addnnursing515bulk';
import Viewnnursing515admin from './pages/Viewnnursing515admin';


import Viewnnursinginteradminlinks from './pages/Viewnnursinginteradminlinks';
import Viewnfacilitiesadminlinks from './pages/Viewnfacilitiesadminlinks';
import Viewaddonlinks from './pages/Viewaddonlinks';
import Viewboslinks from './pages/Viewboslinks';
import Viewadmissionlinksssr from './pages/Viewadmissionlinksssr';
import Viewcollablinks from './pages/Viewcollablinks';
import Viewallpublinksssr from './pages/Viewallpublinksssr';
import Viewictlinks from './pages/Viewictlinks';
import Vieweventlinksssr from './pages/Vieweventlinks';
import Viewskilldevlinksssr from './pages/Viewskilldevlinksssr';
import Viewscholarshiplinks from './pages/Viewscholarshiplinks';
import Viewcareerlinks from './pages/Viewcareerlinks';
import Viewnnursing515links from './pages/Viewnnursing515links';
import Viewplacementlinksssr from './pages/Viewplacementlinksssr';
import Viewhigherexamlinksssr from './pages/Viewhigherexamlinksssr';
import Viewteacherawardlinks from './pages/Viewteacherawardlinks';
import Viewphdguidelinksssr from './pages/Viewphdguidelinksssr';
import Viewallsemreimlinks from './pages/Viewallsemreimlinks';
import Viewteacherfslinks from './pages/Viewteacherfslinks';



import Editndepmeetings from './pages/Editndepmeetings';
import Viewndepmeetings from './pages/Viewndepmeetings';
import Addndepmeetings from './pages/Addndepmeetings';
import Addndepmeetingsbulk from './pages/Addndepmeetingsbulk';
import Viewndepmeetingsadmin from './pages/Viewndepmeetingsadmin';

import Editnmeetingtasks from './pages/Editnmeetingtasks';
import Viewnmeetingtasks from './pages/Viewnmeetingtasks';
import Addnmeetingtasks from './pages/Addnmeetingtasks';
import Addnmeetingtasksbulk from './pages/Addnmeetingtasksbulk';
import Viewnmeetingtasksadmin from './pages/Viewnmeetingtasksadmin';

import Viewmoulinksssr from './pages/Viewmoulinksssr';
import Viewhigheredulinksssr from './pages/Viewhigheredulinksssr';
import Viewsyllabusrevlinks from './pages/Viewsyllabusrevlinks';
import Addsyllabusrevupdate from './pages/Addsyllabusrevupdate';
import Modifysyllabusrev from './pages/Modifysyllabusrev';
import Viewkpiuserprint from './pages/Viewkpiuserprint';

import Viewnirfadmission from './pages/Viewnirfadmission';
import Viewnirfpass from './pages/Viewnirfpass';
import Viewnirfplacement from './pages/Viewnirfplacement';
import Viewnirflibraryexp from './pages/Viewnirflibraryexp';
import Viewnirfreimbursement from './pages/Viewnirfreimbursement';
import Viewnirfexpenditure from './pages/Viewnirfexpenditure';


import Editndepmeetingsall from './pages/Editndepmeetingsall';
import Viewndepmeetingsall from './pages/Viewndepmeetingsall';
import Addndepmeetingsall from './pages/Addndepmeetingsall';
import Addndepmeetingsallbulk from './pages/Addndepmeetingsallbulk';
import Viewndepmeetingsalladmin from './pages/Viewndepmeetingsalladmin';

import Editnmeetingtasksall from './pages/Editnmeetingtasksall';
import Viewnmeetingtasksall from './pages/Viewnmeetingtasksall';
import Addnmeetingtasksall from './pages/Addnmeetingtasksall';
import Addnmeetingtasksallbulk from './pages/Addnmeetingtasksallbulk';
import Viewnmeetingtasksalladmin from './pages/Viewnmeetingtasksalladmin';

import Editnallcourses from './pages/Editnallcourses';
import Viewnallcourses from './pages/Viewnallcourses';
import Addnallcourses from './pages/Addnallcourses';
import Addnallcoursesbulk from './pages/Addnallcoursesbulk';
import Viewnallcoursesadmin from './pages/Viewnallcoursesadmin';
import Viewnallcoursesprog from './pages/Viewnallcoursesprog';

import Viewnbaaug11adminnew from './pages/Viewnbaaug11adminnew';
import Viewnbaaug11adminnewlinks from './pages/Viewnbaaug11adminnewlinks';
import Viewaddonlinks1 from './pages/Viewaddonlinks1';

import Viewegovlinks from './pages/Viewegovlinks';
import Viewnnursing812links from './pages/Viewnnursing812links';
import Viewnnursing814links from './pages/Viewnnursing814links';
import Viewnnursing818adminlinks from './pages/Viewnursing818adminlinks';

import Viewnnratiolinks from './pages/Viewnnratiolinks';
import Viewnhlearneradminlinks from './pages/Viewnhlearneradminlinks';
import Viewnclinicskillsadminlinks from './pages/Viewnclinicskillsadminlinks';

import Viewexplearnuaqar from './pages/Viewexplearnuaqar';
import Viewexplearnuaqarlinks from './pages/Viewexplearnuaqarlinks';

import Vieweventlinksbyuser from './pages/Vieweventlinksbyuser';

import Viewhumetric from './pages/Viewhumetric';

import Viewnmeetingtaskspending from './pages/Viewnmeetingtaskspending';
import Viewnmeetingtasksallclients from './pages/Viewnmeetingtasksallclients';



import Editverifystatus from './pages/Editverifystatus';
import Viewverifystatus from './pages/Viewverifystatus';
import Addverifystatus from './pages/Addverifystatus';
import Addverifystatusbulk from './pages/Addverifystatusbulk';
import Viewverifystatusadmin from './pages/Viewverifystatusadmin';

import Viewverifystatusprog from './pages/Viewverifystatusprog';

import Editnissuesall from './pages/Editnissuesall';
import Viewnissuesall from './pages/Viewnissuesall';
import Addnissuesall from './pages/Addnissuesall';
import Addnissuesallbulk from './pages/Addnissuesallbulk';
import Viewnissuesalladmin from './pages/Viewnissuesalladmin';

import Viewnissuesallprog from './pages/Viewnissuesallprog';

import Editntickets from './pages/Editntickets';
import Viewntickets from './pages/Viewntickets';
import Addntickets from './pages/Addntickets';
import Addnticketsbulk from './pages/Addnticketsbulk';
import Viewnticketsadmin from './pages/Viewnticketsadmin';

import Viewnticketsprog from './pages/Viewnticketsprog';

import Adminhumetric from './pages/Adminhumetric';

import Viewsyllabusrevlinksssr from './pages/Viewsyllabusrevlinksssr';
import Viewemployabilitylinksssr from './pages/Viewemployabilitylinksssr';
import Viewexplearninglinksssr from './pages/Viewexplearninglinksssr';
import Viewinnovationlinks from './pages/Viewinnovationlinks';
import Viewawardsstudlinks from './pages/Viewawardsstudlinks';
import Viewallseminarlinksssr from './pages/Viewallseminarlinksssr';
import Viewresearchfellowlinks from './pages/Viewresearchfellowlinks';
import Viewteacherfellowlinks from './pages/Viewteacherfellowlinks';
import Viewseedmlinks from './pages/Viewseedmlinks';
import Viewpassexamlinks from './pages/Viewpassexamlinks';
import Viewteacherdatalinks from './pages/Viewteacherdatalinks';
import Viewexamautolinks from './pages/Viewexamautolinks';
import Viewecontentlinks from './pages/Viewecontentlinks';
import Viewresultlinks from './pages/Viewresultlinks';
import Viewsportsactlinks from './pages/Viewsportsactlinks';
import Viewallbooklinksssr from './pages/Viewallbooklinksssr';
import Viewallpatentlinksssr from './pages/Viewallpatentlinksssr';
import Viewextensionactlinksssr from './pages/Viewextensionactlinksssr';
import Viewexpenditurelinksssr from './pages/Viewexpenditurelinksssr';
import Viewlibraryexpenlinksssr from './pages/Viewlibraryexpenlinksssr';
import Viewfundslinkssr from './pages/Viewfundslinkssr';



import Editnbaug33result from './pages/Editnbaug33result';
import Viewnbaug33result from './pages/Viewnbaug33result';
import Addnbaug33result from './pages/Addnbaug33result';
import Addnbaug33resultbulk from './pages/Addnbaug33resultbulk';
import Viewnbaug33resultadmin from './pages/Viewnbaug33resultadmin';

import Viewnbaug33resultprog from './pages/Viewnbaug33resultprog';

import Editwriterdb from './pages/Editwriterdb';
import Viewwriterdb from './pages/Viewwriterdb';
import Addwriterdb from './pages/Addwriterdb';
import Addwriterdbbulk from './pages/Addwriterdbbulk';
import Viewwriterdbadmin from './pages/Viewwriterdbadmin';

import Viewwriterdbprog from './pages/Viewwriterdbprog';

import Viewadmissionprog from './pages/Viewadmissionprog';
import Viewpassexamprog from './pages/Viewpassexamprog';
import Viewplacementprog from './pages/Viewplacementprog';

import Selectreporttype from './pages/Selectreporttype';
import Generatereport from './pages/Generatereport';

import Editnadmission from './pages/Editnadmission';
import Viewnadmission from './pages/Viewnadmission';
import Addnadmission from './pages/Addnadmission';
import Addadmissionbulkn from './pages/Addadmissionbulkn';
import Viewnadmissionadmin from './pages/Viewnadmissionadmin';


import Editnplacement from './pages/Editnplacement';
import Viewnplacement from './pages/Viewnplacement';
import Addnplacement from './pages/Addnplacement';
import Addplacementbulkn from './pages/Addplacementbulkn';
import Viewnplacementadmin from './pages/Viewnplacementadmin';


import Editnpassexam from './pages/Editnpassexam';
import Viewnpassexam from './pages/Viewnpassexam';
import Addnpassexam from './pages/Addnpassexam';
import Addpassexambulkn from './pages/Addpassexambulkn';
import Viewnpassexamadmin from './pages/Viewnpassexamadmin';

import Editnmeetingtaskssuper from './pages/Editnmeetingtaskssuper';
import Bulkupdatecomments from './pages/Bulkupdatecomments';

import Editnhigheredu from './pages/Editnhigheredu';
import Viewnhigheredu from './pages/Viewnhigheredu';
import Addnhigheredu from './pages/Addnhigheredu';
import Addhigheredubulkn from './pages/Addhigheredubulkn';
import Viewnhighereduadmin from './pages/Viewnhighereduadmin';

import Viewhighereduprog from './pages/Viewhighereduprog';

import Editnhigherexam from './pages/Editnhigherexam';
import Viewnhigherexam from './pages/Viewnhigherexam';
import Addnhigherexam from './pages/Addnhigherexam';
import Addhigherexambulkn from './pages/Addhigherexambulkn';
import Viewnhigherexamadmin from './pages/Viewnhigherexamadmin';

import Viewhigherexamprog from './pages/Viewhigherexamprog';

import Editnreservecat from './pages/Editnreservecat';
import Viewnreservecat from './pages/Viewnreservecat';
import Addnreservecat from './pages/Addnreservecat';
import Addreservecatbulkn from './pages/Addreservecatbulkn';
import Viewnreservecatadmin from './pages/Viewnreservecatadmin';

import Viewreservecatprog from './pages/Viewreservecatprog';

import Editnphdfac from './pages/Editnphdfac';
import Viewnphdfac from './pages/Viewnphdfac';
import Addnphdfac from './pages/Addnphdfac';
import Addphdfacbulkn from './pages/Addphdfacbulkn';
import Viewnphdfacadmin from './pages/Viewnphdfacadmin';

import Viewphdfacprog from './pages/Viewphdfacprog';

import Viewemployabilitys from './pages/Viewemployabilitys';
import Editemployabilitys from './pages/Editemployabilitys';


import Editnteacherdata from './pages/Editnteacherdata';
import Viewnteacherdata from './pages/Viewnteacherdata';
import Addnteacherdata from './pages/Addnteacherdata';
import Addteacherdatabulkn from './pages/Addteacherdatabulkn';
import Viewnteacherdataadmin from './pages/Viewnteacherdataadmin';

import Viewteacherdataprog from './pages/Viewteacherdataprog';

import Editnresult from './pages/Editnresult';
import Viewnresult from './pages/Viewnresult';
import Addnresult from './pages/Addnresult';
import Addresultbulkn from './pages/Addresultbulkn';
import Viewnresultadmin from './pages/Viewnresultadmin';

import Viewresultprog from './pages/Viewresultprog';

import Editnphdface from './pages/Editnphdface';
import Viewnphdface from './pages/Viewnphdface';
import Addnphdface from './pages/Addnphdface';
import Addphdfacebulkn from './pages/Addphdfacebulkn';
import Viewnphdfaceadmin from './pages/Viewnphdfaceadmin';

import Viewphdfaceprog from './pages/Viewphdfaceprog';

import Editnnmetricissues from './pages/Editnnmetricissues';
import Viewnnmetricissues from './pages/Viewnnmetricissues';
import Addnnmetricissues from './pages/Addnnmetricissues';
import Addnmetricissuesbulkn from './pages/Addnmetricissuesbulkn';
import Viewnnmetricissuesadmin from './pages/Viewnnmetricissuesadmin';

import Viewnmetricissuesprog from './pages/Viewnmetricissuesprog';


import Editnempexpenses from './pages/Editnempexpenses';
import Viewnempexpenses from './pages/Viewnempexpenses';
import Addnempexpenses from './pages/Addnempexpenses';
import Addempexpensesbulkn from './pages/Addempexpensesbulkn';
import Viewnempexpensesadmin from './pages/Viewnempexpensesadmin';

import Viewempexpensesprog from './pages/Viewempexpensesprog';

import Editneventexpenses from './pages/Editneventexpenses';
import Viewneventexpenses from './pages/Viewneventexpenses';
import Addneventexpenses from './pages/Addneventexpenses';
import Addeventexpensesbulkn from './pages/Addeventexpensesbulkn';
import Viewneventexpensesadmin from './pages/Viewneventexpensesadmin';

import Vieweventexpensesprog from './pages/Vieweventexpensesprog';



import Editnmentees from './pages/Editnmentees';
import Viewnmentees from './pages/Viewnmentees';
import Addnmentees from './pages/Addnmentees';
import Addmenteesbulkn from './pages/Addmenteesbulkn';
import Viewnmenteesadmin from './pages/Viewnmenteesadmin';

import Viewmenteesprog from './pages/Viewmenteesprog';



import Editnalumnicon from './pages/Editnalumnicon';
import Viewnalumnicon from './pages/Viewnalumnicon';
import Addnalumnicon from './pages/Addnalumnicon';
import Addalumniconbulkn from './pages/Addalumniconbulkn';
import Viewnalumniconadmin from './pages/Viewnalumniconadmin';

import Viewalumniconprog from './pages/Viewalumniconprog';

import Editnevent from './pages/Editnevent';
import Viewnevent from './pages/Viewnevent';
import Addnevent from './pages/Addnevent';
import Addeventbulkn from './pages/Addeventbulkn';
import Viewneventadmin from './pages/Viewneventadmin';

import Vieweventprog from './pages/Vieweventprog';

import Editnteacherfs from './pages/Editnteacherfs';
import Viewnteacherfs from './pages/Viewnteacherfs';
import Addnteacherfs from './pages/Addnteacherfs';
import Addteacherfsbulkn from './pages/Addteacherfsbulkn';
import Viewnteacherfsadmin from './pages/Viewnteacherfsadmin';

import Viewteacherfsprog from './pages/Viewteacherfsprog';

import Viewneventlinks from './pages/Viewneventlinks';


import Editnlabsafety from './pages/Editnlabsafety';
import Viewnlabsafety from './pages/Viewnlabsafety';
import Addnlabsafety from './pages/Addnlabsafety';
import Addlabsafetybulkn from './pages/Addlabsafetybulkn';
import Viewnlabsafetyadmin from './pages/Viewnlabsafetyadmin';

import Viewlabsafetyprog from './pages/Viewlabsafetyprog';

import Editnlabequipments from './pages/Editnlabequipments';
import Viewnlabequipments from './pages/Viewnlabequipments';
import Addnlabequipments from './pages/Addnlabequipments';
import Addlabequipmentsbulkn from './pages/Addlabequipmentsbulkn';
import Viewnlabequipmentsadmin from './pages/Viewnlabequipmentsadmin';

import Viewlabequipmentsprog from './pages/Viewlabequipmentsprog';

import Editnprogrambudget from './pages/Editnprogrambudget';
import Viewnprogrambudget from './pages/Viewnprogrambudget';
import Addnprogrambudget from './pages/Addnprogrambudget';
import Addprogrambudgetbulkn from './pages/Addprogrambudgetbulkn';
import Viewnprogrambudgetadmin from './pages/Viewnprogrambudgetadmin';

import Viewprogrambudgetprog from './pages/Viewprogrambudgetprog';

import Editninternet from './pages/Editninternet';
import Viewninternet from './pages/Viewninternet';
import Addninternet from './pages/Addninternet';
import Addinternetbulkn from './pages/Addinternetbulkn';
import Viewninternetadmin from './pages/Viewninternetadmin';

import Viewinternetprog from './pages/Viewinternetprog';

import Editnemfaculty from './pages/Editnemfaculty';
import Viewnemfaculty from './pages/Viewnemfaculty';
import Addnemfaculty from './pages/Addnemfaculty';
import Addemfacultybulkn from './pages/Addemfacultybulkn';
import Viewnemfacultyadmin from './pages/Viewnemfacultyadmin';

import Viewemfacultyprog from './pages/Viewemfacultyprog';

import Editnstudentpubs from './pages/Editnstudentpubs';
import Viewnstudentpubs from './pages/Viewnstudentpubs';
import Addnstudentpubs from './pages/Addnstudentpubs';
import Addstudentpubsbulkn from './pages/Addstudentpubsbulkn';
import Viewnstudentpubsadmin from './pages/Viewnstudentpubsadmin';

import Viewstudentpubsprog from './pages/Viewstudentpubsprog';

import Editndeppublications from './pages/Editndeppublications';
import Viewndeppublications from './pages/Viewndeppublications';
import Addndeppublications from './pages/Addndeppublications';
import Adddeppublicationsbulkn from './pages/Adddeppublicationsbulkn';
import Viewndeppublicationsadmin from './pages/Viewndeppublicationsadmin';

import Viewdeppublicationsprog from './pages/Viewdeppublicationsprog';

import Editnstudmaster from './pages/Editnstudmaster';
import Viewnstudmaster from './pages/Viewnstudmaster';
import Addnstudmaster from './pages/Addnstudmaster';
import Addstudmasterbulkn from './pages/Addstudmasterbulkn';
import Viewnstudmasteradmin from './pages/Viewnstudmasteradmin';

import Viewstudmasterprog from './pages/Viewstudmasterprog';

import Editnseedm from './pages/Editnseedm';
import Viewnseedm from './pages/Viewnseedm';
import Addnseedm from './pages/Addnseedm';
import Addseedmbulkn from './pages/Addseedmbulkn';
import Viewnseedmadmin from './pages/Viewnseedmadmin';

import Viewseedmprog from './pages/Viewseedmprog';

import Editnfacyear from './pages/Editnfacyear';
import Viewnfacyear from './pages/Viewnfacyear';
import Addnfacyear from './pages/Addnfacyear';
import Addfacyearbulkn from './pages/Addfacyearbulkn';
import Viewnfacyearadmin from './pages/Viewnfacyearadmin';

import Viewfacyearprog from './pages/Viewfacyearprog';

// import Editnplacement from './pages/Editnplacement';
// import Viewnplacement from './pages/Viewnplacement';
// import Addnplacement from './pages/Addnplacement';
// import Addplacementbulkn from './pages/Addplacementbulkn';
// import Viewnplacementadmin from './pages/Viewnplacementadmin';

// import Viewplacementprog from './pages/Viewplacementprog';

import Viewnteacherfsadminlinks from './pages/Viewnteacherfsadminlinks';
import Viewnalumniconlinks from './pages/Viewnalumniconlinks';


import Editnstudmasternew from './pages/Editnstudmasternew';
import Viewnstudmasternew from './pages/Viewnstudmasternew';
import Addnstudmasternew from './pages/Addnstudmasternew';
import Addstudmasternewbulkn from './pages/Addstudmasternewbulkn';
import Viewnstudmasternewadmin from './pages/Viewnstudmasternewadmin';

import Viewstudmasternewprog from './pages/Viewstudmasternewprog';
import Viewnnursing424adminlinks from './pages/Viewnnursing424adminlinks';

import Viewsyllabusrevdocsdt from './pages/Viewsyllabusrevdocsdt';

import Viewnfacilitiesadminlinksnew from './pages/Viewnfacilitiesadminlinksnew';

import Editnstartup from './pages/Editnstartup';
import Viewnstartup from './pages/Viewnstartup';
import Addnstartup from './pages/Addnstartup';
import Addstartupbulkn from './pages/Addstartupbulkn';
import Viewnstartupadmin from './pages/Viewnstartupadmin';

import Viewstartupprog from './pages/Viewstartupprog';
import Viewplacementindex from './pages/Viewplacementindex';


import Editnacadperf from './pages/Editnacadperf';
import Viewnacadperf from './pages/Viewnacadperf';
import Addnacadperf from './pages/Addnacadperf';
import Addacadperfbulkn from './pages/Addacadperfbulkn';
import Viewnacadperfadmin from './pages/Viewnacadperfadmin';

import Viewacadperfprog from './pages/Viewacadperfprog';
import Viewacadapi from './pages/Viewacadapi';
import Viewplacementindex1 from './pages/Viewplacementindex1';
import Viewadmissionperfapi from './pages/Viewadmissionperfapi';
import Viewsfrapi from './pages/Viewsfrapi';
import Viewfacultycadre from './pages/Viewfacultycadre';

import Viewnrevaladminlinks from './pages/Viewnrevaladminlinks';
import Viewextawardsdlinks from './pages/Viewextawardsdlinks';
import Viewnnpatientsadminlinks from './pages/Viewnnpatientsadminlinks';
import Viewninteradminlinks from './pages/Viewninteradminlinks';
import Viewnotherstudadminlinks from './pages/Viewnotherstudadminlinks';
import Viewnmenteesadminlinks from './pages/Viewnmenteesadminlinks';
import Selecttype from './pages/Selecttype';

import Viewneventadmintype from './pages/Viewneventadmintype';
import Viewneventadmintypelinks from './pages/Viewneventadmintypelinks';

import Viewprojectyrdocsdlinks from './pages/Viewprojectyrdocsdlinks';
import Viewqualitydlinks from './pages/Viewqualitydlinks';
import Viewqualityinitdlinks from './pages/Viewqualityinitdlinks';
import Viewnseedmadminlinks from './pages/Viewnseedmadminlinks';
import Viewcollabdoccommentsdlinks from './pages/Viewcollabdoccommentsdlinks';
import Viewconsultancydlinks from './pages/Viewconsultancydlinks';

import Viewnphdfacadminlinks from './pages/Viewnphdfacadminlinks';
import Viewphdguidedoclinks from './pages/Viewphdguidedoclinks';

import Viewplacementdoclinks from './pages/Viewplacementdoclinks';

import Viewexplearningprojlinks from './pages/Viewexplearningprojlinks';


import Editnpayslips from './pages/Editnpayslips';
import Viewnpayslips from './pages/Viewnpayslips';
import Addnpayslips from './pages/Addnpayslips';
import Addpayslipsbulkn from './pages/Addpayslipsbulkn';
import Viewnpayslipsadmin from './pages/Viewnpayslipsadmin';

import Viewpayslipsprog from './pages/Viewpayslipsprog';

import Editnpayslipdetails from './pages/Editnpayslipdetails';
import Viewnpayslipdetails from './pages/Viewnpayslipdetails';
import Addnpayslipdetails from './pages/Addnpayslipdetails';
import Addpayslipdetailsbulkn from './pages/Addpayslipdetailsbulkn';
import Viewnpayslipdetailsadmin from './pages/Viewnpayslipdetailsadmin';

import Viewpayslipdetailsprog from './pages/Viewpayslipdetailsprog';

import Viewnpayslipdetailsadmiid from './pages/Viewnpayslipdetailsadmiid';
import Viewpayslipdetailsbyid from './pages/Viewpayslipdetailsbyid';

import Editnopencourse from './pages/Editnopencourse';
import Viewnopencourse from './pages/Viewnopencourse';
import Addnopencourse from './pages/Addnopencourse';
import Addopencoursebulkn from './pages/Addopencoursebulkn';
import Viewnopencourseadmin from './pages/Viewnopencourseadmin';

import Viewopencourseprog from './pages/Viewopencourseprog';

import Editnopenmodules from './pages/Editnopenmodules';
import Viewnopenmodules from './pages/Viewnopenmodules';
import Addnopenmodules from './pages/Addnopenmodules';
import Addopenmodulesbulkn from './pages/Addopenmodulesbulkn';
import Viewnopenmodulesadmin from './pages/Viewnopenmodulesadmin';

import Viewopenmodulesprog from './pages/Viewopenmodulesprog';


import Editnrapplication from './pages/Editnrapplication';
import Viewnrapplication from './pages/Viewnrapplication';
import Addnrapplication from './pages/Addnrapplication';
import Addrapplicationbulkn from './pages/Addrapplicationbulkn';
import Viewnrapplicationadmin from './pages/Viewnrapplicationadmin';

import Viewrapplicationprog from './pages/Viewrapplicationprog';

import Editnrtrust from './pages/Editnrtrust';
import Viewnrtrust from './pages/Viewnrtrust';
import Addnrtrust from './pages/Addnrtrust';
import Addrtrustbulkn from './pages/Addrtrustbulkn';
import Viewnrtrustadmin from './pages/Viewnrtrustadmin';

import Viewrtrustprog from './pages/Viewrtrustprog';


import Editnrinstitute from './pages/Editnrinstitute';
import Viewnrinstitute from './pages/Viewnrinstitute';
import Addnrinstitute from './pages/Addnrinstitute';
import Addrinstitutebulkn from './pages/Addrinstitutebulkn';
import Viewnrinstituteadmin from './pages/Viewnrinstituteadmin';

import Viewrinstituteprog from './pages/Viewrinstituteprog';


import Editnrnotherc from './pages/Editnrnotherc';
import Viewnrnotherc from './pages/Viewnrnotherc';
import Addnrnotherc from './pages/Addnrnotherc';
import Addrnothercbulkn from './pages/Addrnothercbulkn';
import Viewnrnothercadmin from './pages/Viewnrnothercadmin';

import Viewrnothercprog from './pages/Viewrnothercprog';

import Editnrnclassroom from './pages/Editnrnclassroom';
import Viewnrnclassroom from './pages/Viewnrnclassroom';
import Addnrnclassroom from './pages/Addnrnclassroom';
import Addrnclassroombulkn from './pages/Addrnclassroombulkn';
import Viewnrnclassroomadmin from './pages/Viewnrnclassroomadmin';

import Viewrnclassroomprog from './pages/Viewrnclassroomprog';

import Editnrnlabs from './pages/Editnrnlabs';
import Viewnrnlabs from './pages/Viewnrnlabs';
import Addnrnlabs from './pages/Addnrnlabs';
import Addrnlabsbulkn from './pages/Addrnlabsbulkn';
import Viewnrnlabsadmin from './pages/Viewnrnlabsadmin';

import Viewrnlabsprog from './pages/Viewrnlabsprog';

import Editnrnlibrarian from './pages/Editnrnlibrarian';
import Viewnrnlibrarian from './pages/Viewnrnlibrarian';
import Addnrnlibrarian from './pages/Addnrnlibrarian';
import Addrnlibrarianbulkn from './pages/Addrnlibrarianbulkn';
import Viewnrnlibrarianadmin from './pages/Viewnrnlibrarianadmin';

import Viewrnlibrarianprog from './pages/Viewrnlibrarianprog';

import Editnrnvehicle from './pages/Editnrnvehicle';
import Viewnrnvehicle from './pages/Viewnrnvehicle';
import Addnrnvehicle from './pages/Addnrnvehicle';
import Addrnvehiclebulkn from './pages/Addrnvehiclebulkn';
import Viewnrnvehicleadmin from './pages/Viewnrnvehicleadmin';

import Viewrnvehicleprog from './pages/Viewrnvehicleprog';

import Editnrnadministrative from './pages/Editnrnadministrative';
import Viewnrnadministrative from './pages/Viewnrnadministrative';
import Addnrnadministrative from './pages/Addnrnadministrative';
import Addrnadministrativebulkn from './pages/Addrnadministrativebulkn';
import Viewnrnadministrativeadmin from './pages/Viewnrnadministrativeadmin';

import Viewrnadministrativeprog from './pages/Viewrnadministrativeprog';

import Editnrnaffhospital from './pages/Editnrnaffhospital';
import Viewnrnaffhospital from './pages/Viewnrnaffhospital';
import Addnrnaffhospital from './pages/Addnrnaffhospital';
import Addrnaffhospitalbulkn from './pages/Addrnaffhospitalbulkn';
import Viewnrnaffhospitaladmin from './pages/Viewnrnaffhospitaladmin';

import Viewrnaffhospitalprog from './pages/Viewrnaffhospitalprog';

import Editnrndocuments from './pages/Editnrndocuments';
import Viewnrndocuments from './pages/Viewnrndocuments';
import Addnrndocuments from './pages/Addnrndocuments';
import Addrndocumentsbulkn from './pages/Addrndocumentsbulkn';
import Viewnrndocumentsadmin from './pages/Viewnrndocumentsadmin';

import Viewrndocumentsprog from './pages/Viewrndocumentsprog';

import Editnrnonteaching from './pages/Editnrnonteaching';
import Viewnrnonteaching from './pages/Viewnrnonteaching';
import Addnrnonteaching from './pages/Addnrnonteaching';
import Addrnonteachingbulkn from './pages/Addrnonteachingbulkn';
import Viewnrnonteachingadmin from './pages/Viewnrnonteachingadmin';

import Viewrnonteachingprog from './pages/Viewrnonteachingprog';



import Editnrnphospitals from './pages/Editnrnphospitals';
import Viewnrnphospitals from './pages/Viewnrnphospitals';
import Addnrnphospitals from './pages/Addnrnphospitals';
import Addrnphospitalsbulkn from './pages/Addrnphospitalsbulkn';
import Viewnrnphospitalsadmin from './pages/Viewnrnphospitalsadmin';

import Viewrnphospitalsprog from './pages/Viewrnphospitalsprog';

import Editnrnbuilding from './pages/Editnrnbuilding';
import Viewnrnbuilding from './pages/Viewnrnbuilding';
import Addnrnbuilding from './pages/Addnrnbuilding';
import Addrnbuildingbulkn from './pages/Addrnbuildingbulkn';
import Viewnrnbuildingadmin from './pages/Viewnrnbuildingadmin';

import Viewrnbuildingprog from './pages/Viewrnbuildingprog';



import Editnnqualification from './pages/Editnnqualification';
import Viewnnqualification from './pages/Viewnnqualification';
import Addnnqualification from './pages/Addnnqualification';
import Addnqualificationbulkn from './pages/Addnqualificationbulkn';
import Viewnnqualificationadmin from './pages/Viewnnqualificationadmin';

import Viewnqualificationprog from './pages/Viewnqualificationprog';

import Editnnexperience from './pages/Editnnexperience';
import Viewnnexperience from './pages/Viewnnexperience';
import Addnnexperience from './pages/Addnnexperience';
import Addnexperiencebulkn from './pages/Addnexperiencebulkn';
import Viewnnexperienceadmin from './pages/Viewnnexperienceadmin';

import Viewnexperienceprog from './pages/Viewnexperienceprog';

import Editnnmytasks from './pages/Editnnmytasks';
import Viewnnmytasks from './pages/Viewnnmytasks';
import Addnnmytasks from './pages/Addnnmytasks';
import Addnmytasksbulkn from './pages/Addnmytasksbulkn';
import Viewnnmytasksadmin from './pages/Viewnnmytasksadmin';

import Viewnmytasksprog from './pages/Viewnmytasksprog';

import Editnempapprec from './pages/Editnempapprec';
import Viewnempapprec from './pages/Viewnempapprec';
import Addnempapprec from './pages/Addnempapprec';
import Addempapprecbulkn from './pages/Addempapprecbulkn';
import Viewnempapprecadmin from './pages/Viewnempapprecadmin';

import Viewempapprecprog from './pages/Viewempapprecprog';

import Editnsalarydetails from './pages/Editnsalarydetails';
import Viewnsalarydetails from './pages/Viewnsalarydetails';
import Addnsalarydetails from './pages/Addnsalarydetails';
import Addsalarydetailsbulkn from './pages/Addsalarydetailsbulkn';
import Viewnsalarydetailsadmin from './pages/Viewnsalarydetailsadmin';

import Viewsalarydetailsprog from './pages/Viewsalarydetailsprog';
import Editnnmytasksuser from './pages/Editnnmytasksuser';
import Viewnnmytasksuser from './pages/Viewnnmytasksuser';

// import Editnnlibusage from './pages/Editnnlibusage';
// import Viewnnlibusage from './pages/Viewnnlibusage';
// import Addnnlibusage from './pages/Addnnlibusage';
// import Addnlibusagebulkn from './pages/Addnlibusagebulkn';
// import Viewnnlibusageadmin from './pages/Viewnnlibusageadmin';

// import Viewnlibusageprog from './pages/Viewnlibusageprog';

import Editnexplearning from './pages/Editnexplearning';
import Viewnexplearning from './pages/Viewnexplearning';
import Addnexplearning from './pages/Addnexplearning';
import Addexplearningbulkn from './pages/Addexplearningbulkn';
import Viewnexplearningadmin from './pages/Viewnexplearningadmin';

import Viewexplearningprog from './pages/Viewexplearningprog';

import Editnnufacultydata from './pages/Editnnufacultydata';
import Viewnnufacultydata from './pages/Viewnnufacultydata';
import Addnnufacultydata from './pages/Addnnufacultydata';
import Addnufacultydatabulkn from './pages/Addnufacultydatabulkn';
import Viewnnufacultydataadmin from './pages/Viewnnufacultydataadmin';

import Viewnufacultydataprog from './pages/Viewnufacultydataprog';

// import Editnphdface from './pages/Editnphdface';
// import Viewnphdface from './pages/Viewnphdface';
// import Addnphdface from './pages/Addnphdface';
// import Addphdfacebulkn from './pages/Addphdfacebulkn';
// import Viewnphdfaceadmin from './pages/Viewnphdfaceadmin';

// import Viewphdfaceprog from './pages/Viewphdfaceprog';

// import Editnteacherdata from './pages/Editnteacherdata';
// import Viewnteacherdata from './pages/Viewnteacherdata';
// import Addnteacherdata from './pages/Addnteacherdata';
// import Addteacherdatabulkn from './pages/Addteacherdatabulkn';
// import Viewnteacherdataadmin from './pages/Viewnteacherdataadmin';

// import Viewteacherdataprog from './pages/Viewteacherdataprog';

// import Editnresult from './pages/Editnresult';
// import Viewnresult from './pages/Viewnresult';
// import Addnresult from './pages/Addnresult';
// import Addresultbulkn from './pages/Addresultbulkn';
// import Viewnresultadmin from './pages/Viewnresultadmin';

// import Viewresultprog from './pages/Viewresultprog';

import Editnnugrievance from './pages/Editnnugrievance';
import Viewnnugrievance from './pages/Viewnnugrievance';
import Addnnugrievance from './pages/Addnnugrievance';
import Addnugrievancebulkn from './pages/Addnugrievancebulkn';
import Viewnnugrievanceadmin from './pages/Viewnnugrievanceadmin';

import Viewnugrievanceprog from './pages/Viewnugrievanceprog';

import Editnpasspercent from './pages/Editnpasspercent';
import Viewnpasspercent from './pages/Viewnpasspercent';
import Addnpasspercent from './pages/Addnpasspercent';
import Addpasspercentbulkn from './pages/Addpasspercentbulkn';
import Viewnpasspercentadmin from './pages/Viewnpasspercentadmin';

import Viewpasspercentprog from './pages/Viewpasspercentprog';

import Editnteacherfellow from './pages/Editnteacherfellow';
import Viewnteacherfellow from './pages/Viewnteacherfellow';
import Addnteacherfellow from './pages/Addnteacherfellow';
import Addteacherfellowbulkn from './pages/Addteacherfellowbulkn';
import Viewnteacherfellowadmin from './pages/Viewnteacherfellowadmin';

import Viewteacherfellowprog from './pages/Viewteacherfellowprog';

import Editnpatents from './pages/Editnpatents';
import Viewnpatents from './pages/Viewnpatents';
import Addnpatents from './pages/Addnpatents';
import Addpatentsbulkn from './pages/Addpatentsbulkn';
import Viewnpatentsadmin from './pages/Viewnpatentsadmin';

import Viewpatentsprog from './pages/Viewpatentsprog';

import Editnconsultancy from './pages/Editnconsultancy';
import Viewnconsultancy from './pages/Viewnconsultancy';
import Addnconsultancy from './pages/Addnconsultancy';
import Addconsultancybulkn from './pages/Addconsultancybulkn';
import Viewnconsultancyadmin from './pages/Viewnconsultancyadmin';

import Viewconsultancyprog from './pages/Viewconsultancyprog';

import Editnmou from './pages/Editnmou';
import Viewnmou from './pages/Viewnmou';
import Addnmou from './pages/Addnmou';
import Addmoubulkn from './pages/Addmoubulkn';
import Viewnmouadmin from './pages/Viewnmouadmin';

import Viewmouprog from './pages/Viewmouprog';

import Editnnuefacilities from './pages/Editnnuefacilities';
import Viewnnuefacilities from './pages/Viewnnuefacilities';
import Addnnuefacilities from './pages/Addnnuefacilities';
import Addnuefacilitiesbulkn from './pages/Addnuefacilitiesbulkn';
import Viewnnuefacilitiesadmin from './pages/Viewnnuefacilitiesadmin';

import Viewnuefacilitiesprog from './pages/Viewnuefacilitiesprog';

import Editnnustudcompratio from './pages/Editnnustudcompratio';
import Viewnnustudcompratio from './pages/Viewnnustudcompratio';
import Addnnustudcompratio from './pages/Addnnustudcompratio';
import Addnustudcompratiobulkn from './pages/Addnustudcompratiobulkn';
import Viewnnustudcompratioadmin from './pages/Viewnnustudcompratioadmin';

import Viewnustudcompratioprog from './pages/Viewnustudcompratioprog';

import Editnawards from './pages/Editnawards';
import Viewnawards from './pages/Viewnawards';
import Addnawards from './pages/Addnawards';
import Addawardsbulkn from './pages/Addawardsbulkn';
import Viewnawardsadmin from './pages/Viewnawardsadmin';

import Viewawardsprog from './pages/Viewawardsprog';

// import Editnhigherexam from './pages/Editnhigherexam';
// import Viewnhigherexam from './pages/Viewnhigherexam';
// import Addnhigherexam from './pages/Addnhigherexam';
// import Addhigherexambulkn from './pages/Addhigherexambulkn';
// import Viewnhigherexamadmin from './pages/Viewnhigherexamadmin';

// import Viewhigherexamprog from './pages/Viewhigherexamprog';

import Editnnlibusage from './pages/Editnnlibusage';
import Viewnnlibusage from './pages/Viewnnlibusage';
import Addnnlibusage from './pages/Addnnlibusage';
import Addnlibusagebulkn from './pages/Addnlibusagebulkn';
import Viewnnlibusageadmin from './pages/Viewnnlibusageadmin';

import Viewnlibusageprog from './pages/Viewnlibusageprog';

import Viewuniversitymetricnew from './pages/Viewuniversitymetricnew';

import Editnnluteacherdata from './pages/Editnnluteacherdata';
import Viewnnluteacherdata from './pages/Viewnnluteacherdata';
import Addnnluteacherdata from './pages/Addnnluteacherdata';
import Addnluteacherdatabulkn from './pages/Addnluteacherdatabulkn';
import Viewnnluteacherdataadmin from './pages/Viewnnluteacherdataadmin';

import Viewnluteacherdataprog from './pages/Viewnluteacherdataprog';

import Editnnlufacilities from './pages/Editnnlufacilities';
import Viewnnlufacilities from './pages/Viewnnlufacilities';
import Addnnlufacilities from './pages/Addnnlufacilities';
import Addnlufacilitiesbulkn from './pages/Addnlufacilitiesbulkn';
import Viewnnlufacilitiesadmin from './pages/Viewnnlufacilitiesadmin';

import Viewnlufacilitiesprog from './pages/Viewnlufacilitiesprog';

import Editnnlurecognitions from './pages/Editnnlurecognitions';
import Viewnnlurecognitions from './pages/Viewnnlurecognitions';
import Addnnlurecognitions from './pages/Addnnlurecognitions';
import Addnlurecognitionsbulkn from './pages/Addnlurecognitionsbulkn';
import Viewnnlurecognitionsadmin from './pages/Viewnnlurecognitionsadmin';

import Viewnlurecognitionsprog from './pages/Viewnlurecognitionsprog';

import Editnnlubills from './pages/Editnnlubills';
import Viewnnlubills from './pages/Viewnnlubills';
import Addnnlubills from './pages/Addnnlubills';
import Addnlubillsbulkn from './pages/Addnlubillsbulkn';
import Viewnnlubillsadmin from './pages/Viewnnlubillsadmin';

import Viewnlubillsprog from './pages/Viewnlubillsprog';

import Editnnluincentives from './pages/Editnnluincentives';
import Viewnnluincentives from './pages/Viewnnluincentives';
import Addnnluincentives from './pages/Addnnluincentives';
import Addnluincentivesbulkn from './pages/Addnluincentivesbulkn';
import Viewnnluincentivesadmin from './pages/Viewnnluincentivesadmin';

import Viewnluincentivesprog from './pages/Viewnluincentivesprog';

import Editnnluphdtheisis from './pages/Editnnluphdtheisis';
import Viewnnluphdtheisis from './pages/Viewnnluphdtheisis';
import Addnnluphdtheisis from './pages/Addnnluphdtheisis';
import Addnluphdtheisisbulkn from './pages/Addnluphdtheisisbulkn';
import Viewnnluphdtheisisadmin from './pages/Viewnnluphdtheisisadmin';

import Viewnluphdtheisisprog from './pages/Viewnluphdtheisisprog';

import Editnnlulegaldatabases from './pages/Editnnlulegaldatabases';
import Viewnnlulegaldatabases from './pages/Viewnnlulegaldatabases';
import Addnnlulegaldatabases from './pages/Addnnlulegaldatabases';
import Addnlulegaldatabasesbulkn from './pages/Addnlulegaldatabasesbulkn';
import Viewnnlulegaldatabasesadmin from './pages/Viewnnlulegaldatabasesadmin';

import Viewnlulegaldatabasesprog from './pages/Viewnlulegaldatabasesprog';

import Editnnlbar from './pages/Editnnlbar';
import Viewnnlbar from './pages/Viewnnlbar';
import Addnnlbar from './pages/Addnnlbar';
import Addnlbarbulkn from './pages/Addnlbarbulkn';
import Viewnnlbaradmin from './pages/Viewnnlbaradmin';

import Viewnlbarprog from './pages/Viewnlbarprog';

import Editnnlucitation from './pages/Editnnlucitation';
import Viewnnlucitation from './pages/Viewnnlucitation';
import Addnnlucitation from './pages/Addnnlucitation';
import Addnlucitationbulkn from './pages/Addnlucitationbulkn';
import Viewnnlucitationadmin from './pages/Viewnnlucitationadmin';

import Viewnlucitationprog from './pages/Viewnlucitationprog';


import Viewlegaluniversitymenu from './pages/Viewlegaluniversitymenu';

import Viewaffiliatedmenu from './pages/Viewaffiliatedmenu';

import Viewautonomousmenu from './pages/Viewautonomousmenu';

import Editnainst from './pages/Editnainst';
import Viewnainst from './pages/Viewnainst';
import Addnainst from './pages/Addnainst';
import Addainstbulkn from './pages/Addainstbulkn';
import Viewnainstadmin from './pages/Viewnainstadmin';

import Viewainstprog from './pages/Viewainstprog';


import Editnpaunewidetails from './pages/Editnpaunewidetails';
import Viewnpaunewidetails from './pages/Viewnpaunewidetails';
import Addnpaunewidetails from './pages/Addnpaunewidetails';
import Addpaunewidetailsbulkn from './pages/Addpaunewidetailsbulkn';
import Viewnpaunewidetailsadmin from './pages/Viewnpaunewidetailsadmin';

import Viewpaunewidetailsprog from './pages/Viewpaunewidetailsprog';

import Editnpauidetailsc from './pages/Editnpauidetailsc';
import Viewnpauidetailsc from './pages/Viewnpauidetailsc';
import Addnpauidetailsc from './pages/Addnpauidetailsc';
import Addpauidetailscbulkn from './pages/Addpauidetailscbulkn';
import Viewnpauidetailscadmin from './pages/Viewnpauidetailscadmin';

import Viewpauidetailscprog from './pages/Viewpauidetailscprog';

import Editnpautrust from './pages/Editnpautrust';
import Viewnpautrust from './pages/Viewnpautrust';
import Addnpautrust from './pages/Addnpautrust';
import Addpautrustbulkn from './pages/Addpautrustbulkn';
import Viewnpautrustadmin from './pages/Viewnpautrustadmin';

import Viewpautrustprog from './pages/Viewpautrustprog';

import Editnpaunewprincipal from './pages/Editnpaunewprincipal';
import Viewnpaunewprincipal from './pages/Viewnpaunewprincipal';
import Addnpaunewprincipal from './pages/Addnpaunewprincipal';
import Addpaunewprincipalbulkn from './pages/Addpaunewprincipalbulkn';
import Viewnpaunewprincipaladmin from './pages/Viewnpaunewprincipaladmin';

import Viewpaunewprincipalprog from './pages/Viewpaunewprincipalprog';

import Editnpaungovcouncil from './pages/Editnpaungovcouncil';
import Viewnpaungovcouncil from './pages/Viewnpaungovcouncil';
import Addnpaungovcouncil from './pages/Addnpaungovcouncil';
import Addpaungovcouncilbulkn from './pages/Addpaungovcouncilbulkn';
import Viewnpaungovcounciladmin from './pages/Viewnpaungovcounciladmin';

import Viewpaungovcouncilprog from './pages/Viewpaungovcouncilprog';

import Editnpaunboard from './pages/Editnpaunboard';
import Viewnpaunboard from './pages/Viewnpaunboard';
import Addnpaunboard from './pages/Addnpaunboard';
import Addpaunboardbulkn from './pages/Addpaunboardbulkn';
import Viewnpaunboardadmin from './pages/Viewnpaunboardadmin';

import Viewpaunboardprog from './pages/Viewpaunboardprog';

import Editnpauragcomt from './pages/Editnpauragcomt';
import Viewnpauragcomt from './pages/Viewnpauragcomt';
import Addnpauragcomt from './pages/Addnpauragcomt';
import Addpauragcomtbulkn from './pages/Addpauragcomtbulkn';
import Viewnpauragcomtadmin from './pages/Viewnpauragcomtadmin';

import Viewpauragcomtprog from './pages/Viewpauragcomtprog';

import Editnpauragsquad from './pages/Editnpauragsquad';
import Viewnpauragsquad from './pages/Viewnpauragsquad';
import Addnpauragsquad from './pages/Addnpauragsquad';
import Addpauragsquadbulkn from './pages/Addpauragsquadbulkn';
import Viewnpauragsquadadmin from './pages/Viewnpauragsquadadmin';

import Viewpauragsquadprog from './pages/Viewpauragsquadprog';

import Editnpauwelfarecomt from './pages/Editnpauwelfarecomt';
import Viewnpauwelfarecomt from './pages/Viewnpauwelfarecomt';
import Addnpauwelfarecomt from './pages/Addnpauwelfarecomt';
import Addpauwelfarecomtbulkn from './pages/Addpauwelfarecomtbulkn';
import Viewnpauwelfarecomtadmin from './pages/Viewnpauwelfarecomtadmin';

import Viewpauwelfarecomtprog from './pages/Viewpauwelfarecomtprog';

import Editnpauredressal from './pages/Editnpauredressal';
import Viewnpauredressal from './pages/Viewnpauredressal';
import Addnpauredressal from './pages/Addnpauredressal';
import Addpauredressalbulkn from './pages/Addpauredressalbulkn';
import Viewnpauredressaladmin from './pages/Viewnpauredressaladmin';

import Viewpauredressalprog from './pages/Viewpauredressalprog';

import Editnpaubankacnts from './pages/Editnpaubankacnts';
import Viewnpaubankacnts from './pages/Viewnpaubankacnts';
import Addnpaubankacnts from './pages/Addnpaubankacnts';
import Addpaubankacntsbulkn from './pages/Addpaubankacntsbulkn';
import Viewnpaubankacntsadmin from './pages/Viewnpaubankacntsadmin';

import Viewpaubankacntsprog from './pages/Viewpaubankacntsprog';

import Editnpaudeposit from './pages/Editnpaudeposit';
import Viewnpaudeposit from './pages/Viewnpaudeposit';
import Addnpaudeposit from './pages/Addnpaudeposit';
import Addpaudepositbulkn from './pages/Addpaudepositbulkn';
import Viewnpaudepositadmin from './pages/Viewnpaudepositadmin';

import Viewpaudepositprog from './pages/Viewpaudepositprog';

import Editnpauendowment from './pages/Editnpauendowment';
import Viewnpauendowment from './pages/Viewnpauendowment';
import Addnpauendowment from './pages/Addnpauendowment';
import Addpauendowmentbulkn from './pages/Addpauendowmentbulkn';
import Viewnpauendowmentadmin from './pages/Viewnpauendowmentadmin';

import Viewpauendowmentprog from './pages/Viewpauendowmentprog';

import Editnpaulanddetailsa from './pages/Editnpaulanddetailsa';
import Viewnpaulanddetailsa from './pages/Viewnpaulanddetailsa';
import Addnpaulanddetailsa from './pages/Addnpaulanddetailsa';
import Addpaulanddetailsabulkn from './pages/Addpaulanddetailsabulkn';
import Viewnpaulanddetailsaadmin from './pages/Viewnpaulanddetailsaadmin';

import Viewpaulanddetailsaprog from './pages/Viewpaulanddetailsaprog';

import Editnpaulanddetailsb from './pages/Editnpaulanddetailsb';
import Viewnpaulanddetailsb from './pages/Viewnpaulanddetailsb';
import Addnpaulanddetailsb from './pages/Addnpaulanddetailsb';
import Addpaulanddetailsbbulkn from './pages/Addpaulanddetailsbbulkn';
import Viewnpaulanddetailsbadmin from './pages/Viewnpaulanddetailsbadmin';

import Viewpaulanddetailsbprog from './pages/Viewpaulanddetailsbprog';

import Editnpaucourse from './pages/Editnpaucourse';
import Viewnpaucourse from './pages/Viewnpaucourse';
import Addnpaucourse from './pages/Addnpaucourse';
import Addpaucoursebulkn from './pages/Addpaucoursebulkn';
import Viewnpaucourseadmin from './pages/Viewnpaucourseadmin';

import Viewpaucourseprog from './pages/Viewpaucourseprog';

import Editnpaucrsedetails from './pages/Editnpaucrsedetails';
import Viewnpaucrsedetails from './pages/Viewnpaucrsedetails';
import Addnpaucrsedetails from './pages/Addnpaucrsedetails';
import Addpaucrsedetailsbulkn from './pages/Addpaucrsedetailsbulkn';
import Viewnpaucrsedetailsadmin from './pages/Viewnpaucrsedetailsadmin';

import Viewpaucrsedetailsprog from './pages/Viewpaucrsedetailsprog';

import Editnpauaddcrse from './pages/Editnpauaddcrse';
import Viewnpauaddcrse from './pages/Viewnpauaddcrse';
import Addnpauaddcrse from './pages/Addnpauaddcrse';
import Addpauaddcrsebulkn from './pages/Addpauaddcrsebulkn';
import Viewnpauaddcrseadmin from './pages/Viewnpauaddcrseadmin';

import Viewpauaddcrseprog from './pages/Viewpauaddcrseprog';

import Editnpaunstuddetails from './pages/Editnpaunstuddetails';
import Viewnpaunstuddetails from './pages/Viewnpaunstuddetails';
import Addnpaunstuddetails from './pages/Addnpaunstuddetails';
import Addpaunstuddetailsbulkn from './pages/Addpaunstuddetailsbulkn';
import Viewnpaunstuddetailsadmin from './pages/Viewnpaunstuddetailsadmin';

import Viewpaunstuddetailsprog from './pages/Viewpaunstuddetailsprog';

import Editnpaufaculty from './pages/Editnpaufaculty';
import Viewnpaufaculty from './pages/Viewnpaufaculty';
import Addnpaufaculty from './pages/Addnpaufaculty';
import Addpaufacultybulkn from './pages/Addpaufacultybulkn';
import Viewnpaufacultyadmin from './pages/Viewnpaufacultyadmin';

import Viewpaufacultyprog from './pages/Viewpaufacultyprog';

import Editnpaunfacultydetails from './pages/Editnpaunfacultydetails';
import Viewnpaunfacultydetails from './pages/Viewnpaunfacultydetails';
import Addnpaunfacultydetails from './pages/Addnpaunfacultydetails';
import Addpaunfacultydetailsbulkn from './pages/Addpaunfacultydetailsbulkn';
import Viewnpaunfacultydetailsadmin from './pages/Viewnpaunfacultydetailsadmin';

import Viewpaunfacultydetailsprog from './pages/Viewpaunfacultydetailsprog';

import Editnpaunfaddqualif from './pages/Editnpaunfaddqualif';
import Viewnpaunfaddqualif from './pages/Viewnpaunfaddqualif';
import Addnpaunfaddqualif from './pages/Addnpaunfaddqualif';
import Addpaunfaddqualifbulkn from './pages/Addpaunfaddqualifbulkn';
import Viewnpaunfaddqualifadmin from './pages/Viewnpaunfaddqualifadmin';

import Viewpaunfaddqualifprog from './pages/Viewpaunfaddqualifprog';

import Editnpaunfacdexp from './pages/Editnpaunfacdexp';
import Viewnpaunfacdexp from './pages/Viewnpaunfacdexp';
import Addnpaunfacdexp from './pages/Addnpaunfacdexp';
import Addpaunfacdexpbulkn from './pages/Addpaunfacdexpbulkn';
import Viewnpaunfacdexpadmin from './pages/Viewnpaunfacdexpadmin';

import Viewpaunfacdexpprog from './pages/Viewpaunfacdexpprog';

import Editnpaunfindexp from './pages/Editnpaunfindexp';
import Viewnpaunfindexp from './pages/Viewnpaunfindexp';
import Addnpaunfindexp from './pages/Addnpaunfindexp';
import Addpaunfindexpbulkn from './pages/Addpaunfindexpbulkn';
import Viewnpaunfindexpadmin from './pages/Viewnpaunfindexpadmin';

import Viewpaunfindexpprog from './pages/Viewpaunfindexpprog';

import Editnpaunnfeduqualif from './pages/Editnpaunnfeduqualif';
import Viewnpaunnfeduqualif from './pages/Viewnpaunnfeduqualif';
import Addnpaunnfeduqualif from './pages/Addnpaunnfeduqualif';
import Addpaunnfeduqualifbulkn from './pages/Addpaunnfeduqualifbulkn';
import Viewnpaunnfeduqualifadmin from './pages/Viewnpaunnfeduqualifadmin';

import Viewpaunnfeduqualifprog from './pages/Viewpaunnfeduqualifprog';

import Editnpaunnfcoeexp from './pages/Editnpaunnfcoeexp';
import Viewnpaunnfcoeexp from './pages/Viewnpaunnfcoeexp';
import Addnpaunnfcoeexp from './pages/Addnpaunnfcoeexp';
import Addpaunnfcoeexpbulkn from './pages/Addpaunnfcoeexpbulkn';
import Viewnpaunnfcoeexpadmin from './pages/Viewnpaunnfcoeexpadmin';

import Viewpaunnfcoeexpprog from './pages/Viewpaunnfcoeexpprog';

import Editnpauconfacshe from './pages/Editnpauconfacshe';
import Viewnpauconfacshe from './pages/Viewnpauconfacshe';
import Addnpauconfacshe from './pages/Addnpauconfacshe';
import Addpauconfacshebulkn from './pages/Addpauconfacshebulkn';
import Viewnpauconfacsheadmin from './pages/Viewnpauconfacsheadmin';

import Viewpauconfacsheprog from './pages/Viewpauconfacsheprog';

import Editnpauconfaccourse from './pages/Editnpauconfaccourse';
import Viewnpauconfaccourse from './pages/Viewnpauconfaccourse';
import Addnpauconfaccourse from './pages/Addnpauconfaccourse';
import Addpauconfaccoursebulkn from './pages/Addpauconfaccoursebulkn';
import Viewnpauconfaccourseadmin from './pages/Viewnpauconfaccourseadmin';

import Viewpauconfaccourseprog from './pages/Viewpauconfaccourseprog';

import Editnpauconfacmemtech from './pages/Editnpauconfacmemtech';
import Viewnpauconfacmemtech from './pages/Viewnpauconfacmemtech';
import Addnpauconfacmemtech from './pages/Addnpauconfacmemtech';
import Addpauconfacmemtechbulkn from './pages/Addpauconfacmemtechbulkn';
import Viewnpauconfacmemtechadmin from './pages/Viewnpauconfacmemtechadmin';

import Viewpauconfacmemtechprog from './pages/Viewpauconfacmemtechprog';

import Editnpaunontstaff from './pages/Editnpaunontstaff';
import Viewnpaunontstaff from './pages/Viewnpaunontstaff';
import Addnpaunontstaff from './pages/Addnpaunontstaff';
import Addpaunontstaffbulkn from './pages/Addpaunontstaffbulkn';
import Viewnpaunontstaffadmin from './pages/Viewnpaunontstaffadmin';

import Viewpaunontstaffprog from './pages/Viewpaunontstaffprog';

import Editnpauspacereq from './pages/Editnpauspacereq';
import Viewnpauspacereq from './pages/Viewnpauspacereq';
import Addnpauspacereq from './pages/Addnpauspacereq';
import Addpauspacereqbulkn from './pages/Addpauspacereqbulkn';
import Viewnpauspacereqadmin from './pages/Viewnpauspacereqadmin';

import Viewpauspacereqprog from './pages/Viewpauspacereqprog';

import Editnpaunequip from './pages/Editnpaunequip';
import Viewnpaunequip from './pages/Viewnpaunequip';
import Addnpaunequip from './pages/Addnpaunequip';
import Addpaunequipbulkn from './pages/Addpaunequipbulkn';
import Viewnpaunequipadmin from './pages/Viewnpaunequipadmin';

import Viewpaunequipprog from './pages/Viewpaunequipprog';

import Editnpauccomfarea from './pages/Editnpauccomfarea';
import Viewnpauccomfarea from './pages/Viewnpauccomfarea';
import Addnpauccomfarea from './pages/Addnpauccomfarea';
import Addpauccomfareabulkn from './pages/Addpauccomfareabulkn';
import Viewnpauccomfareaadmin from './pages/Viewnpauccomfareaadmin';

import Viewpauccomfareaprog from './pages/Viewpauccomfareaprog';

import Editnpauntermlanwan from './pages/Editnpauntermlanwan';
import Viewnpauntermlanwan from './pages/Viewnpauntermlanwan';
import Addnpauntermlanwan from './pages/Addnpauntermlanwan';
import Addpauntermlanwanbulkn from './pages/Addpauntermlanwanbulkn';
import Viewnpauntermlanwanadmin from './pages/Viewnpauntermlanwanadmin';

import Viewpauntermlanwanprog from './pages/Viewpauntermlanwanprog';

import Editnpausoftware from './pages/Editnpausoftware';
import Viewnpausoftware from './pages/Viewnpausoftware';
import Addnpausoftware from './pages/Addnpausoftware';
import Addpausoftwarebulkn from './pages/Addpausoftwarebulkn';
import Viewnpausoftwareadmin from './pages/Viewnpausoftwareadmin';

import Viewpausoftwareprog from './pages/Viewpausoftwareprog';

import Editnpaunetwork from './pages/Editnpaunetwork';
import Viewnpaunetwork from './pages/Viewnpaunetwork';
import Addnpaunetwork from './pages/Addnpaunetwork';
import Addpaunetworkbulkn from './pages/Addpaunetworkbulkn';
import Viewnpaunetworkadmin from './pages/Viewnpaunetworkadmin';

import Viewpaunetworkprog from './pages/Viewpaunetworkprog';

import Editnpaulibarea from './pages/Editnpaulibarea';
import Viewnpaulibarea from './pages/Viewnpaulibarea';
import Addnpaulibarea from './pages/Addnpaulibarea';
import Addpaulibareabulkn from './pages/Addpaulibareabulkn';
import Viewnpaulibareaadmin from './pages/Viewnpaulibareaadmin';

import Viewpaulibareaprog from './pages/Viewpaulibareaprog';

import Editnpaunlibbooks from './pages/Editnpaunlibbooks';
import Viewnpaunlibbooks from './pages/Viewnpaunlibbooks';
import Addnpaunlibbooks from './pages/Addnpaunlibbooks';
import Addpaunlibbooksbulkn from './pages/Addpaunlibbooksbulkn';
import Viewnpaunlibbooksadmin from './pages/Viewnpaunlibbooksadmin';

import Viewpaunlibbooksprog from './pages/Viewpaunlibbooksprog';

import Editnpaunlibjournal from './pages/Editnpaunlibjournal';
import Viewnpaunlibjournal from './pages/Viewnpaunlibjournal';
import Addnpaunlibjournal from './pages/Addnpaunlibjournal';
import Addpaunlibjournalbulkn from './pages/Addpaunlibjournalbulkn';
import Viewnpaunlibjournaladmin from './pages/Viewnpaunlibjournaladmin';

import Viewpaunlibjournalprog from './pages/Viewpaunlibjournalprog';

import Editnpauclassblock from './pages/Editnpauclassblock';
import Viewnpauclassblock from './pages/Viewnpauclassblock';
import Addnpauclassblock from './pages/Addnpauclassblock';
import Addpauclassblockbulkn from './pages/Addpauclassblockbulkn';
import Viewnpauclassblockadmin from './pages/Viewnpauclassblockadmin';

import Viewpauclassblockprog from './pages/Viewpauclassblockprog';

import Editnpauclassnew from './pages/Editnpauclassnew';
import Viewnpauclassnew from './pages/Viewnpauclassnew';
import Addnpauclassnew from './pages/Addnpauclassnew';
import Addpauclassnewbulkn from './pages/Addpauclassnewbulkn';
import Viewnpauclassnewadmin from './pages/Viewnpauclassnewadmin';

import Viewpauclassnewprog from './pages/Viewpauclassnewprog';

import Editnpaunnoofdrawhall from './pages/Editnpaunnoofdrawhall';
import Viewnpaunnoofdrawhall from './pages/Viewnpaunnoofdrawhall';
import Addnpaunnoofdrawhall from './pages/Addnpaunnoofdrawhall';
import Addpaunnoofdrawhallbulkn from './pages/Addpaunnoofdrawhallbulkn';
import Viewnpaunnoofdrawhalladmin from './pages/Viewnpaunnoofdrawhalladmin';

import Viewpaunnoofdrawhallprog from './pages/Viewpaunnoofdrawhallprog';

import Editnpauadminarea from './pages/Editnpauadminarea';
import Viewnpauadminarea from './pages/Viewnpauadminarea';
import Addnpauadminarea from './pages/Addnpauadminarea';
import Addpauadminareabulkn from './pages/Addpauadminareabulkn';
import Viewnpauadminareaadmin from './pages/Viewnpauadminareaadmin';

import Viewpauadminareaprog from './pages/Viewpauadminareaprog';

import Editnpaunamen from './pages/Editnpaunamen';
import Viewnpaunamen from './pages/Viewnpaunamen';
import Addnpaunamen from './pages/Addnpaunamen';
import Addpaunamenbulkn from './pages/Addpaunamenbulkn';
import Viewnpaunamenadmin from './pages/Viewnpaunamenadmin';

import Viewpaunamenprog from './pages/Viewpaunamenprog';

import Editnpaubhostel from './pages/Editnpaubhostel';
import Viewnpaubhostel from './pages/Viewnpaubhostel';
import Addnpaubhostel from './pages/Addnpaubhostel';
import Addpaubhostelbulkn from './pages/Addpaubhostelbulkn';
import Viewnpaubhosteladmin from './pages/Viewnpaubhosteladmin';

import Viewpaubhostelprog from './pages/Viewpaubhostelprog';

import Editnpaughostel from './pages/Editnpaughostel';
import Viewnpaughostel from './pages/Viewnpaughostel';
import Addnpaughostel from './pages/Addnpaughostel';
import Addpaughostelbulkn from './pages/Addpaughostelbulkn';
import Viewnpaughosteladmin from './pages/Viewnpaughosteladmin';

import Viewpaughostelprog from './pages/Viewpaughostelprog';

import Editnpaunotherbuildarea from './pages/Editnpaunotherbuildarea';
import Viewnpaunotherbuildarea from './pages/Viewnpaunotherbuildarea';
import Addnpaunotherbuildarea from './pages/Addnpaunotherbuildarea';
import Addpaunotherbuildareabulkn from './pages/Addpaunotherbuildareabulkn';
import Viewnpaunotherbuildareaadmin from './pages/Viewnpaunotherbuildareaadmin';

import Viewpaunotherbuildareaprog from './pages/Viewpaunotherbuildareaprog';

import Editnpauphyedu from './pages/Editnpauphyedu';
import Viewnpauphyedu from './pages/Viewnpauphyedu';
import Addnpauphyedu from './pages/Addnpauphyedu';
import Addpauphyedubulkn from './pages/Addpauphyedubulkn';
import Viewnpauphyeduadmin from './pages/Viewnpauphyeduadmin';

import Viewpauphyeduprog from './pages/Viewpauphyeduprog';

import Editnpaufacilityavail from './pages/Editnpaufacilityavail';
import Viewnpaufacilityavail from './pages/Viewnpaufacilityavail';
import Addnpaufacilityavail from './pages/Addnpaufacilityavail';
import Addpaufacilityavailbulkn from './pages/Addpaufacilityavailbulkn';
import Viewnpaufacilityavailadmin from './pages/Viewnpaufacilityavailadmin';

import Viewpaufacilityavailprog from './pages/Viewpaufacilityavailprog';

import Editnpaualumni from './pages/Editnpaualumni';
import Viewnpaualumni from './pages/Viewnpaualumni';
import Addnpaualumni from './pages/Addnpaualumni';
import Addpaualumnibulkn from './pages/Addpaualumnibulkn';
import Viewnpaualumniadmin from './pages/Viewnpaualumniadmin';

import Viewpaualumniprog from './pages/Viewpaualumniprog';

import Editnpautpc from './pages/Editnpautpc';
import Viewnpautpc from './pages/Viewnpautpc';
import Addnpautpc from './pages/Addnpautpc';
import Addpautpcbulkn from './pages/Addpautpcbulkn';
import Viewnpautpcadmin from './pages/Viewnpautpcadmin';

import Viewpautpcprog from './pages/Viewpautpcprog';

import Editnpaunhealth from './pages/Editnpaunhealth';
import Viewnpaunhealth from './pages/Viewnpaunhealth';
import Addnpaunhealth from './pages/Addnpaunhealth';
import Addpaunhealthbulkn from './pages/Addpaunhealthbulkn';
import Viewnpaunhealthadmin from './pages/Viewnpaunhealthadmin';

import Viewpaunhealthprog from './pages/Viewpaunhealthprog';

import Editnpauothamen from './pages/Editnpauothamen';
import Viewnpauothamen from './pages/Viewnpauothamen';
import Addnpauothamen from './pages/Addnpauothamen';
import Addpauothamenbulkn from './pages/Addpauothamenbulkn';
import Viewnpauothamenadmin from './pages/Viewnpauothamenadmin';

import Viewpauothamenprog from './pages/Viewpauothamenprog';

import Editnpauregrec from './pages/Editnpauregrec';
import Viewnpauregrec from './pages/Viewnpauregrec';
import Addnpauregrec from './pages/Addnpauregrec';
import Addpauregrecbulkn from './pages/Addpauregrecbulkn';
import Viewnpauregrecadmin from './pages/Viewnpauregrecadmin';

import Viewpauregrecprog from './pages/Viewpauregrecprog';

import Editnpaucertificates from './pages/Editnpaucertificates';
import Viewnpaucertificates from './pages/Viewnpaucertificates';
import Addnpaucertificates from './pages/Addnpaucertificates';
import Addpaucertificatesbulkn from './pages/Addpaucertificatesbulkn';
import Viewnpaucertificatesadmin from './pages/Viewnpaucertificatesadmin';

import Viewpaucertificatesprog from './pages/Viewpaucertificatesprog';

import Editnpaunprocfee from './pages/Editnpaunprocfee';
import Viewnpaunprocfee from './pages/Viewnpaunprocfee';
import Addnpaunprocfee from './pages/Addnpaunprocfee';
import Addpaunprocfeebulkn from './pages/Addpaunprocfeebulkn';
import Viewnpaunprocfeeadmin from './pages/Viewnpaunprocfeeadmin';

import Viewpaunprocfeeprog from './pages/Viewpaunprocfeeprog';

import Editnpauprcplendrsmnt from './pages/Editnpauprcplendrsmnt';
import Viewnpauprcplendrsmnt from './pages/Viewnpauprcplendrsmnt';
import Addnpauprcplendrsmnt from './pages/Addnpauprcplendrsmnt';
import Addpauprcplendrsmntbulkn from './pages/Addpauprcplendrsmntbulkn';
import Viewnpauprcplendrsmntadmin from './pages/Viewnpauprcplendrsmntadmin';

import Viewpauprcplendrsmntprog from './pages/Viewpauprcplendrsmntprog';

import Editnpaumngdeclaration from './pages/Editnpaumngdeclaration';
import Viewnpaumngdeclaration from './pages/Viewnpaumngdeclaration';
import Addnpaumngdeclaration from './pages/Addnpaumngdeclaration';
import Addpaumngdeclarationbulkn from './pages/Addpaumngdeclarationbulkn';
import Viewnpaumngdeclarationadmin from './pages/Viewnpaumngdeclarationadmin';

import Viewpaumngdeclarationprog from './pages/Viewpaumngdeclarationprog';



import Editnpaunewfacultydetails from './pages/Editnpaunewfacultydetails';
import Viewnpaunewfacultydetails from './pages/Viewnpaunewfacultydetails';
import Addnpaunewfacultydetails from './pages/Addnpaunewfacultydetails';
import Addpaunewfacultydetailsbulkn from './pages/Addpaunewfacultydetailsbulkn';
import Viewnpaunewfacultydetailsadmin from './pages/Viewnpaunewfacultydetailsadmin';

import Viewpaunewfacultydetailsprog from './pages/Viewpaunewfacultydetailsprog';

import Editnpaunewfeduqualif from './pages/Editnpaunewfeduqualif';
import Viewnpaunewfeduqualif from './pages/Viewnpaunewfeduqualif';
import Addnpaunewfeduqualif from './pages/Addnpaunewfeduqualif';
import Addpaunewfeduqualifbulkn from './pages/Addpaunewfeduqualifbulkn';
import Viewnpaunewfeduqualifadmin from './pages/Viewnpaunewfeduqualifadmin';

import Viewpaunewfeduqualifprog from './pages/Viewpaunewfeduqualifprog';

// import Editnpauconfaccourse from './pages/Editnpauconfaccourse';
// import Viewnpauconfaccourse from './pages/Viewnpauconfaccourse';
// import Addnpauconfaccourse from './pages/Addnpauconfaccourse';
// import Addpauconfaccoursebulkn from './pages/Addpauconfaccoursebulkn';
// import Viewnpauconfaccourseadmin from './pages/Viewnpauconfaccourseadmin';

// import Viewpauconfaccourseprog from './pages/Viewpauconfaccourseprog';

// import Editnpauconfacmemtech from './pages/Editnpauconfacmemtech';
// import Viewnpauconfacmemtech from './pages/Viewnpauconfacmemtech';
// import Addnpauconfacmemtech from './pages/Addnpauconfacmemtech';
// import Addpauconfacmemtechbulkn from './pages/Addpauconfacmemtechbulkn';
// import Viewnpauconfacmemtechadmin from './pages/Viewnpauconfacmemtechadmin';

// import Viewpauconfacmemtechprog from './pages/Viewpauconfacmemtechprog';

// import Editnpauspacereq from './pages/Editnpauspacereq';
// import Viewnpauspacereq from './pages/Viewnpauspacereq';
// import Addnpauspacereq from './pages/Addnpauspacereq';
// import Addpauspacereqbulkn from './pages/Addpauspacereqbulkn';
// import Viewnpauspacereqadmin from './pages/Viewnpauspacereqadmin';

// import Viewpauspacereqprog from './pages/Viewpauspacereqprog';

// import Editnpaunequip from './pages/Editnpaunequip';
// import Viewnpaunequip from './pages/Viewnpaunequip';
// import Addnpaunequip from './pages/Addnpaunequip';
// import Addpaunequipbulkn from './pages/Addpaunequipbulkn';
// import Viewnpaunequipadmin from './pages/Viewnpaunequipadmin';

// import Viewpaunequipprog from './pages/Viewpaunequipprog';

// import Editnpaunlibjournal from './pages/Editnpaunlibjournal';
// import Viewnpaunlibjournal from './pages/Viewnpaunlibjournal';
// import Addnpaunlibjournal from './pages/Addnpaunlibjournal';
// import Addpaunlibjournalbulkn from './pages/Addpaunlibjournalbulkn';
// import Viewnpaunlibjournaladmin from './pages/Viewnpaunlibjournaladmin';

// import Viewpaunlibjournalprog from './pages/Viewpaunlibjournalprog';

// import Editnpaunnoofdrawhall from './pages/Editnpaunnoofdrawhall';
// import Viewnpaunnoofdrawhall from './pages/Viewnpaunnoofdrawhall';
// import Addnpaunnoofdrawhall from './pages/Addnpaunnoofdrawhall';
// import Addpaunnoofdrawhallbulkn from './pages/Addpaunnoofdrawhallbulkn';
// import Viewnpaunnoofdrawhalladmin from './pages/Viewnpaunnoofdrawhalladmin';

// import Viewpaunnoofdrawhallprog from './pages/Viewpaunnoofdrawhallprog';

import Editnpaunewlanddetails from './pages/Editnpaunewlanddetails';
import Viewnpaunewlanddetails from './pages/Viewnpaunewlanddetails';
import Addnpaunewlanddetails from './pages/Addnpaunewlanddetails';
import Addpaunewlanddetailsbulkn from './pages/Addpaunewlanddetailsbulkn';
import Viewnpaunewlanddetailsadmin from './pages/Viewnpaunewlanddetailsadmin';

import Viewpaunewlanddetailsprog from './pages/Viewpaunewlanddetailsprog';

import Editnpaunewfaddqualif from './pages/Editnpaunewfaddqualif';
import Viewnpaunewfaddqualif from './pages/Viewnpaunewfaddqualif';
import Addnpaunewfaddqualif from './pages/Addnpaunewfaddqualif';
import Addpaunewfaddqualifbulkn from './pages/Addpaunewfaddqualifbulkn';
import Viewnpaunewfaddqualifadmin from './pages/Viewnpaunewfaddqualifadmin';

import Viewpaunewfaddqualifprog from './pages/Viewpaunewfaddqualifprog';

import Editnpaunewfacdexp from './pages/Editnpaunewfacdexp';
import Viewnpaunewfacdexp from './pages/Viewnpaunewfacdexp';
import Addnpaunewfacdexp from './pages/Addnpaunewfacdexp';
import Addpaunewfacdexpbulkn from './pages/Addpaunewfacdexpbulkn';
import Viewnpaunewfacdexpadmin from './pages/Viewnpaunewfacdexpadmin';

import Viewpaunewfacdexpprog from './pages/Viewpaunewfacdexpprog';

import Editnpaunewfindexp from './pages/Editnpaunewfindexp';
import Viewnpaunewfindexp from './pages/Viewnpaunewfindexp';
import Addnpaunewfindexp from './pages/Addnpaunewfindexp';
import Addpaunewfindexpbulkn from './pages/Addpaunewfindexpbulkn';
import Viewnpaunewfindexpadmin from './pages/Viewnpaunewfindexpadmin';

import Viewpaunewfindexpprog from './pages/Viewpaunewfindexpprog';

import Dashanna1 from './pages/Dashanna1';
import Dashannaadmin1 from './pages/Dashannaadmin1';


import Editnpnirf from './pages/Editnpnirf';
import Viewnpnirf from './pages/Viewnpnirf';
import Addnpnirf from './pages/Addnpnirf';
import Addpnirfbulkn from './pages/Addpnirfbulkn';
import Viewnpnirfadmin from './pages/Viewnpnirfadmin';

import Viewpnirfprog from './pages/Viewpnirfprog';

import Editnpadmnew from './pages/Editnpadmnew';
import Viewnpadmnew from './pages/Viewnpadmnew';
import Addnpadmnew from './pages/Addnpadmnew';
import Addpadmnewbulkn from './pages/Addpadmnewbulkn';
import Viewnpadmnewadmin from './pages/Viewnpadmnewadmin';

import Viewpadmnewprog from './pages/Viewpadmnewprog';

import Editnpnbanew from './pages/Editnpnbanew';
import Viewnpnbanew from './pages/Viewnpnbanew';
import Addnpnbanew from './pages/Addnpnbanew';
import Addpnbanewbulkn from './pages/Addpnbanewbulkn';
import Viewnpnbanewadmin from './pages/Viewnpnbanewadmin';

import Editnpaunewfcoeexp from './pages/Editnpaunewfcoeexp';
import Viewnpaunewfcoeexp from './pages/Viewnpaunewfcoeexp';
import Addnpaunewfcoeexp from './pages/Addnpaunewfcoeexp';
import Addpaunewfcoeexpbulkn from './pages/Addpaunewfcoeexpbulkn';
import Viewnpaunewfcoeexpadmin from './pages/Viewnpaunewfcoeexpadmin';

import Viewpaunewfcoeexpprog from './pages/Viewpaunewfcoeexpprog';


// mar 16 2023

import Editnainscom from './pages/Editnainscom';
import Viewnainscom from './pages/Viewnainscom';
import Addnainscom from './pages/Addnainscom';
import Addainscombulkn from './pages/Addainscombulkn';
import Viewnainscomadmin from './pages/Viewnainscomadmin';

import Viewainscomprog from './pages/Viewainscomprog';

import Editnafaccadre from './pages/Editnafaccadre';
import Viewnafaccadre from './pages/Viewnafaccadre';
import Addnafaccadre from './pages/Addnafaccadre';
import Addafaccadrebulkn from './pages/Addafaccadrebulkn';
import Viewnafaccadreadmin from './pages/Viewnafaccadreadmin';

import Viewafaccadreprog from './pages/Viewafaccadreprog';

import Editnaequipments from './pages/Editnaequipments';
import Viewnaequipments from './pages/Viewnaequipments';
import Addnaequipments from './pages/Addnaequipments';
import Addaequipmentsbulkn from './pages/Addaequipmentsbulkn';
import Viewnaequipmentsadmin from './pages/Viewnaequipmentsadmin';

import Viewaequipmentsprog from './pages/Viewaequipmentsprog';

import Editnpaunfacnew from './pages/Editnpaunfacnew';
import Viewnpaunfacnew from './pages/Viewnpaunfacnew';
import Addnpaunfacnew from './pages/Addnpaunfacnew';
import Addpaunfacnewbulkn from './pages/Addpaunfacnewbulkn';
import Viewnpaunfacnewadmin from './pages/Viewnpaunfacnewadmin';

import Viewpaunfacnewprog from './pages/Viewpaunfacnewprog';

import Editnpauprncplphd from './pages/Editnpauprncplphd';
import Viewnpauprncplphd from './pages/Viewnpauprncplphd';
import Addnpauprncplphd from './pages/Addnpauprncplphd';
import Addpauprncplphdbulkn from './pages/Addpauprncplphdbulkn';
import Viewnpauprncplphdadmin from './pages/Viewnpauprncplphdadmin';

import Viewpauprncplphdprog from './pages/Viewpauprncplphdprog';

import Editnpauprncplpub from './pages/Editnpauprncplpub';
import Viewnpauprncplpub from './pages/Viewnpauprncplpub';
import Addnpauprncplpub from './pages/Addnpauprncplpub';
import Addpauprncplpubbulkn from './pages/Addpauprncplpubbulkn';
import Viewnpauprncplpubadmin from './pages/Viewnpauprncplpubadmin';

import Viewpauprncplpubprog from './pages/Viewpauprncplpubprog';


import Editnanlibjournals from './pages/Editnanlibjournals';
import Viewnanlibjournals from './pages/Viewnanlibjournals';
import Addnanlibjournals from './pages/Addnanlibjournals';
import Addanlibjournalsbulkn from './pages/Addanlibjournalsbulkn';
import Viewnanlibjournalsadmin from './pages/Viewnanlibjournalsadmin';

import Viewanlibjournalsprog from './pages/Viewanlibjournalsprog';

import Viewafinspectorprog from './pages/Viewafinspectorprog';




import Editnamprograms from './pages/Editnamprograms';
import Viewnamprograms from './pages/Viewnamprograms';
import Addnamprograms from './pages/Addnamprograms';
import Addamprogramsbulkn from './pages/Addamprogramsbulkn';
import Viewnamprogramsadmin from './pages/Viewnamprogramsadmin';

import Viewamprogramsprog from './pages/Viewamprogramsprog';

import Editnamclassroom from './pages/Editnamclassroom';
import Viewnamclassroom from './pages/Viewnamclassroom';
import Addnamclassroom from './pages/Addnamclassroom';
import Addamclassroombulkn from './pages/Addamclassroombulkn';
import Viewnamclassroomadmin from './pages/Viewnamclassroomadmin';

import Viewamclassroomprog from './pages/Viewamclassroomprog';

import Editnamequipments from './pages/Editnamequipments';
import Viewnamequipments from './pages/Viewnamequipments';
import Addnamequipments from './pages/Addnamequipments';
import Addamequipmentsbulkn from './pages/Addamequipmentsbulkn';
import Viewnamequipmentsadmin from './pages/Viewnamequipmentsadmin';

import Viewamequipmentsprog from './pages/Viewamequipmentsprog';

import Editnamlibrary from './pages/Editnamlibrary';
import Viewnamlibrary from './pages/Viewnamlibrary';
import Addnamlibrary from './pages/Addnamlibrary';
import Addamlibrarybulkn from './pages/Addamlibrarybulkn';
import Viewnamlibraryadmin from './pages/Viewnamlibraryadmin';

import Viewamlibraryprog from './pages/Viewamlibraryprog';

import Editnamlibdetails from './pages/Editnamlibdetails';
import Viewnamlibdetails from './pages/Viewnamlibdetails';
import Addnamlibdetails from './pages/Addnamlibdetails';
import Addamlibdetailsbulkn from './pages/Addamlibdetailsbulkn';
import Viewnamlibdetailsadmin from './pages/Viewnamlibdetailsadmin';

import Viewamlibdetailsprog from './pages/Viewamlibdetailsprog';

import Editnamfaculties from './pages/Editnamfaculties';
import Viewnamfaculties from './pages/Viewnamfaculties';
import Addnamfaculties from './pages/Addnamfaculties';
import Addamfacultiesbulkn from './pages/Addamfacultiesbulkn';
import Viewnamfacultiesadmin from './pages/Viewnamfacultiesadmin';

import Viewamfacultiesprog from './pages/Viewamfacultiesprog';

import Editnamadminstaff from './pages/Editnamadminstaff';
import Viewnamadminstaff from './pages/Viewnamadminstaff';
import Addnamadminstaff from './pages/Addnamadminstaff';
import Addamadminstaffbulkn from './pages/Addamadminstaffbulkn';
import Viewnamadminstaffadmin from './pages/Viewnamadminstaffadmin';

import Viewamadminstaffprog from './pages/Viewamadminstaffprog';

import Editnaminfsuff from './pages/Editnaminfsuff';
import Viewnaminfsuff from './pages/Viewnaminfsuff';
import Addnaminfsuff from './pages/Addnaminfsuff';
import Addaminfsuffbulkn from './pages/Addaminfsuffbulkn';
import Viewnaminfsuffadmin from './pages/Viewnaminfsuffadmin';

import Viewaminfsuffprog from './pages/Viewaminfsuffprog';

import Editnamimprovements from './pages/Editnamimprovements';
import Viewnamimprovements from './pages/Viewnamimprovements';
import Addnamimprovements from './pages/Addnamimprovements';
import Addamimprovementsbulkn from './pages/Addamimprovementsbulkn';
import Viewnamimprovementsadmin from './pages/Viewnamimprovementsadmin';

import Viewamimprovementsprog from './pages/Viewamimprovementsprog';

import Editnamrecommendation from './pages/Editnamrecommendation';
import Viewnamrecommendation from './pages/Viewnamrecommendation';
import Addnamrecommendation from './pages/Addnamrecommendation';
import Addamrecommendationbulkn from './pages/Addamrecommendationbulkn';
import Viewnamrecommendationadmin from './pages/Viewnamrecommendationadmin';

import Viewamrecommendationprog from './pages/Viewamrecommendationprog';

import Editnamchemicals from './pages/Editnamchemicals';
import Viewnamchemicals from './pages/Viewnamchemicals';
import Addnamchemicals from './pages/Addnamchemicals';
import Addamchemicalsbulkn from './pages/Addamchemicalsbulkn';
import Viewnamchemicalsadmin from './pages/Viewnamchemicalsadmin';

import Viewamchemicalsprog from './pages/Viewamchemicalsprog';

import Editnamprogfaculties from './pages/Editnamprogfaculties';
import Viewnamprogfaculties from './pages/Viewnamprogfaculties';
import Addnamprogfaculties from './pages/Addnamprogfaculties';
import Addamprogfacultiesbulkn from './pages/Addamprogfacultiesbulkn';
import Viewnamprogfacultiesadmin from './pages/Viewnamprogfacultiesadmin';

import Viewamprogfacultiesprog from './pages/Viewamprogfacultiesprog';

import Editnamhostels from './pages/Editnamhostels';
import Viewnamhostels from './pages/Viewnamhostels';
import Addnamhostels from './pages/Addnamhostels';
import Addamhostelsbulkn from './pages/Addamhostelsbulkn';
import Viewnamhostelsadmin from './pages/Viewnamhostelsadmin';

import Viewamhostelsprog from './pages/Viewamhostelsprog';


import Editnamcpdetails3 from './pages/Editnamcpdetails3';
import Viewnamcpdetails3 from './pages/Viewnamcpdetails3';
import Addnamcpdetails3 from './pages/Addnamcpdetails3';
import Addamcpdetails3bulkn from './pages/Addamcpdetails3bulkn';
import Viewnamcpdetails3admin from './pages/Viewnamcpdetails3admin';

import Viewamcpdetails3prog from './pages/Viewamcpdetails3prog';

import Editnamcpobservations from './pages/Editnamcpobservations';
import Viewnamcpobservations from './pages/Viewnamcpobservations';
import Addnamcpobservations from './pages/Addnamcpobservations';
import Addamcpobservationsbulkn from './pages/Addamcpobservationsbulkn';
import Viewnamcpobservationsadmin from './pages/Viewnamcpobservationsadmin';

import Viewamcpobservationsprog from './pages/Viewamcpobservationsprog';

import Editnammember1 from './pages/Editnammember1';
import Viewnammember1 from './pages/Viewnammember1';
import Addnammember1 from './pages/Addnammember1';
import Addammember1bulkn from './pages/Addammember1bulkn';
import Viewnammember1admin from './pages/Viewnammember1admin';

import Viewammember1prog from './pages/Viewammember1prog';

import Editnammbr2comp from './pages/Editnammbr2comp';
import Viewnammbr2comp from './pages/Viewnammbr2comp';
import Addnammbr2comp from './pages/Addnammbr2comp';
import Addammbr2compbulkn from './pages/Addammbr2compbulkn';
import Viewnammbr2compadmin from './pages/Viewnammbr2compadmin';

import Viewammbr2compprog from './pages/Viewammbr2compprog';

import Editnammbr2det from './pages/Editnammbr2det';
import Viewnammbr2det from './pages/Viewnammbr2det';
import Addnammbr2det from './pages/Addnammbr2det';
import Addammbr2detbulkn from './pages/Addammbr2detbulkn';
import Viewnammbr2detadmin from './pages/Viewnammbr2detadmin';

import Viewammbr2detprog from './pages/Viewammbr2detprog';





function App() {
  return (
  <div>
    
    <Switch>
    <Route path='/exam' exact>
      <Allclasses />
    </Route>
    <Route path='/' exact>
      <Newclassesf3 />
    </Route>
    <Route path='/new' exact>
      <Newclassesf3 />
    </Route>

    <Route path='/editnamcpdetails3'>
<Editnamcpdetails3/>
</Route>
<Route path='/viewnamcpdetails3'>
<Viewnamcpdetails3/>
</Route>
<Route path='/viewnamcpdetails3admin'>
<Viewnamcpdetails3admin/>
</Route>
<Route path='/viewamcpdetails3prog'>
<Viewamcpdetails3prog/>
</Route>
<Route path='/addnamcpdetails3'>
<Addnamcpdetails3/>
</Route>
<Route path='/addamcpdetails3bulkn'>
<Addamcpdetails3bulkn />
</Route>
<Route path='/editnamcpobservations'>
<Editnamcpobservations/>
</Route>
<Route path='/viewnamcpobservations'>
<Viewnamcpobservations/>
</Route>
<Route path='/viewnamcpobservationsadmin'>
<Viewnamcpobservationsadmin/>
</Route>
<Route path='/viewamcpobservationsprog'>
<Viewamcpobservationsprog/>
</Route>
<Route path='/addnamcpobservations'>
<Addnamcpobservations/>
</Route>
<Route path='/addamcpobservationsbulkn'>
<Addamcpobservationsbulkn />
</Route>
<Route path='/editnammember1'>
<Editnammember1/>
</Route>
<Route path='/viewnammember1'>
<Viewnammember1/>
</Route>
<Route path='/viewnammember1admin'>
<Viewnammember1admin/>
</Route>
<Route path='/viewammember1prog'>
<Viewammember1prog/>
</Route>
<Route path='/addnammember1'>
<Addnammember1/>
</Route>
<Route path='/addammember1bulkn'>
<Addammember1bulkn />
</Route>
<Route path='/editnammbr2comp'>
<Editnammbr2comp/>
</Route>
<Route path='/viewnammbr2comp'>
<Viewnammbr2comp/>
</Route>
<Route path='/viewnammbr2compadmin'>
<Viewnammbr2compadmin/>
</Route>
<Route path='/viewammbr2compprog'>
<Viewammbr2compprog/>
</Route>
<Route path='/addnammbr2comp'>
<Addnammbr2comp/>
</Route>
<Route path='/addammbr2compbulkn'>
<Addammbr2compbulkn />
</Route>
<Route path='/editnammbr2det'>
<Editnammbr2det/>
</Route>
<Route path='/viewnammbr2det'>
<Viewnammbr2det/>
</Route>
<Route path='/viewnammbr2detadmin'>
<Viewnammbr2detadmin/>
</Route>
<Route path='/viewammbr2detprog'>
<Viewammbr2detprog/>
</Route>
<Route path='/addnammbr2det'>
<Addnammbr2det/>
</Route>
<Route path='/addammbr2detbulkn'>
<Addammbr2detbulkn />
</Route>

    <Route path='/editnamprograms'>
<Editnamprograms/>
</Route>
<Route path='/viewnamprograms'>
<Viewnamprograms/>
</Route>
<Route path='/viewnamprogramsadmin'>
<Viewnamprogramsadmin/>
</Route>
<Route path='/viewamprogramsprog'>
<Viewamprogramsprog/>
</Route>
<Route path='/addnamprograms'>
<Addnamprograms/>
</Route>
<Route path='/addamprogramsbulkn'>
<Addamprogramsbulkn />
</Route>
<Route path='/editnamclassroom'>
<Editnamclassroom/>
</Route>
<Route path='/viewnamclassroom'>
<Viewnamclassroom/>
</Route>
<Route path='/viewnamclassroomadmin'>
<Viewnamclassroomadmin/>
</Route>
<Route path='/viewamclassroomprog'>
<Viewamclassroomprog/>
</Route>
<Route path='/addnamclassroom'>
<Addnamclassroom/>
</Route>
<Route path='/addamclassroombulkn'>
<Addamclassroombulkn />
</Route>
<Route path='/editnamequipments'>
<Editnamequipments/>
</Route>
<Route path='/viewnamequipments'>
<Viewnamequipments/>
</Route>
<Route path='/viewnamequipmentsadmin'>
<Viewnamequipmentsadmin/>
</Route>
<Route path='/viewamequipmentsprog'>
<Viewamequipmentsprog/>
</Route>
<Route path='/addnamequipments'>
<Addnamequipments/>
</Route>
<Route path='/addamequipmentsbulkn'>
<Addamequipmentsbulkn />
</Route>
<Route path='/editnamlibrary'>
<Editnamlibrary/>
</Route>
<Route path='/viewnamlibrary'>
<Viewnamlibrary/>
</Route>
<Route path='/viewnamlibraryadmin'>
<Viewnamlibraryadmin/>
</Route>
<Route path='/viewamlibraryprog'>
<Viewamlibraryprog/>
</Route>
<Route path='/addnamlibrary'>
<Addnamlibrary/>
</Route>
<Route path='/addamlibrarybulkn'>
<Addamlibrarybulkn />
</Route>
<Route path='/editnamlibdetails'>
<Editnamlibdetails/>
</Route>
<Route path='/viewnamlibdetails'>
<Viewnamlibdetails/>
</Route>
<Route path='/viewnamlibdetailsadmin'>
<Viewnamlibdetailsadmin/>
</Route>
<Route path='/viewamlibdetailsprog'>
<Viewamlibdetailsprog/>
</Route>
<Route path='/addnamlibdetails'>
<Addnamlibdetails/>
</Route>
<Route path='/addamlibdetailsbulkn'>
<Addamlibdetailsbulkn />
</Route>
<Route path='/editnamfaculties'>
<Editnamfaculties/>
</Route>
<Route path='/viewnamfaculties'>
<Viewnamfaculties/>
</Route>
<Route path='/viewnamfacultiesadmin'>
<Viewnamfacultiesadmin/>
</Route>
<Route path='/viewamfacultiesprog'>
<Viewamfacultiesprog/>
</Route>
<Route path='/addnamfaculties'>
<Addnamfaculties/>
</Route>
<Route path='/addamfacultiesbulkn'>
<Addamfacultiesbulkn />
</Route>
<Route path='/editnamadminstaff'>
<Editnamadminstaff/>
</Route>
<Route path='/viewnamadminstaff'>
<Viewnamadminstaff/>
</Route>
<Route path='/viewnamadminstaffadmin'>
<Viewnamadminstaffadmin/>
</Route>
<Route path='/viewamadminstaffprog'>
<Viewamadminstaffprog/>
</Route>
<Route path='/addnamadminstaff'>
<Addnamadminstaff/>
</Route>
<Route path='/addamadminstaffbulkn'>
<Addamadminstaffbulkn />
</Route>
<Route path='/editnaminfsuff'>
<Editnaminfsuff/>
</Route>
<Route path='/viewnaminfsuff'>
<Viewnaminfsuff/>
</Route>
<Route path='/viewnaminfsuffadmin'>
<Viewnaminfsuffadmin/>
</Route>
<Route path='/viewaminfsuffprog'>
<Viewaminfsuffprog/>
</Route>
<Route path='/addnaminfsuff'>
<Addnaminfsuff/>
</Route>
<Route path='/addaminfsuffbulkn'>
<Addaminfsuffbulkn />
</Route>
<Route path='/editnamimprovements'>
<Editnamimprovements/>
</Route>
<Route path='/viewnamimprovements'>
<Viewnamimprovements/>
</Route>
<Route path='/viewnamimprovementsadmin'>
<Viewnamimprovementsadmin/>
</Route>
<Route path='/viewamimprovementsprog'>
<Viewamimprovementsprog/>
</Route>
<Route path='/addnamimprovements'>
<Addnamimprovements/>
</Route>
<Route path='/addamimprovementsbulkn'>
<Addamimprovementsbulkn />
</Route>
<Route path='/editnamrecommendation'>
<Editnamrecommendation/>
</Route>
<Route path='/viewnamrecommendation'>
<Viewnamrecommendation/>
</Route>
<Route path='/viewnamrecommendationadmin'>
<Viewnamrecommendationadmin/>
</Route>
<Route path='/viewamrecommendationprog'>
<Viewamrecommendationprog/>
</Route>
<Route path='/addnamrecommendation'>
<Addnamrecommendation/>
</Route>
<Route path='/addamrecommendationbulkn'>
<Addamrecommendationbulkn />
</Route>
<Route path='/editnamchemicals'>
<Editnamchemicals/>
</Route>
<Route path='/viewnamchemicals'>
<Viewnamchemicals/>
</Route>
<Route path='/viewnamchemicalsadmin'>
<Viewnamchemicalsadmin/>
</Route>
<Route path='/viewamchemicalsprog'>
<Viewamchemicalsprog/>
</Route>
<Route path='/addnamchemicals'>
<Addnamchemicals/>
</Route>
<Route path='/addamchemicalsbulkn'>
<Addamchemicalsbulkn />
</Route>
<Route path='/editnamprogfaculties'>
<Editnamprogfaculties/>
</Route>
<Route path='/viewnamprogfaculties'>
<Viewnamprogfaculties/>
</Route>
<Route path='/viewnamprogfacultiesadmin'>
<Viewnamprogfacultiesadmin/>
</Route>
<Route path='/viewamprogfacultiesprog'>
<Viewamprogfacultiesprog/>
</Route>
<Route path='/addnamprogfaculties'>
<Addnamprogfaculties/>
</Route>
<Route path='/addamprogfacultiesbulkn'>
<Addamprogfacultiesbulkn />
</Route>
<Route path='/editnamhostels'>
<Editnamhostels/>
</Route>
<Route path='/viewnamhostels'>
<Viewnamhostels/>
</Route>
<Route path='/viewnamhostelsadmin'>
<Viewnamhostelsadmin/>
</Route>
<Route path='/viewamhostelsprog'>
<Viewamhostelsprog/>
</Route>
<Route path='/addnamhostels'>
<Addnamhostels/>
</Route>
<Route path='/addamhostelsbulkn'>
<Addamhostelsbulkn />
</Route>

    <Route path='/viewafinspectorprog'>
<Viewafinspectorprog/>
</Route>
    <Route path='/editnanlibjournals'>
<Editnanlibjournals/>
</Route>
<Route path='/viewnanlibjournals'>
<Viewnanlibjournals/>
</Route>
<Route path='/viewnanlibjournalsadmin'>
<Viewnanlibjournalsadmin/>
</Route>
<Route path='/viewanlibjournalsprog'>
<Viewanlibjournalsprog/>
</Route>
<Route path='/addnanlibjournals'>
<Addnanlibjournals/>
</Route>
<Route path='/addanlibjournalsbulkn'>
<Addanlibjournalsbulkn />
</Route>


    <Route path='/editnainscom'>
<Editnainscom/>
</Route>
<Route path='/viewnainscom'>
<Viewnainscom/>
</Route>
<Route path='/viewnainscomadmin'>
<Viewnainscomadmin/>
</Route>
<Route path='/viewainscomprog'>
<Viewainscomprog/>
</Route>
<Route path='/addnainscom'>
<Addnainscom/>
</Route>
<Route path='/addainscombulkn'>
<Addainscombulkn />
</Route>
<Route path='/editnafaccadre'>
<Editnafaccadre/>
</Route>
<Route path='/viewnafaccadre'>
<Viewnafaccadre/>
</Route>
<Route path='/viewnafaccadreadmin'>
<Viewnafaccadreadmin/>
</Route>
<Route path='/viewafaccadreprog'>
<Viewafaccadreprog/>
</Route>
<Route path='/addnafaccadre'>
<Addnafaccadre/>
</Route>
<Route path='/addafaccadrebulkn'>
<Addafaccadrebulkn />
</Route>
<Route path='/editnaequipments'>
<Editnaequipments/>
</Route>
<Route path='/viewnaequipments'>
<Viewnaequipments/>
</Route>
<Route path='/viewnaequipmentsadmin'>
<Viewnaequipmentsadmin/>
</Route>
<Route path='/viewaequipmentsprog'>
<Viewaequipmentsprog/>
</Route>
<Route path='/addnaequipments'>
<Addnaequipments/>
</Route>
<Route path='/addaequipmentsbulkn'>
<Addaequipmentsbulkn />
</Route>
<Route path='/editnpaunfacnew'>
<Editnpaunfacnew/>
</Route>
<Route path='/viewnpaunfacnew'>
<Viewnpaunfacnew/>
</Route>
<Route path='/viewnpaunfacnewadmin'>
<Viewnpaunfacnewadmin/>
</Route>
<Route path='/viewpaunfacnewprog'>
<Viewpaunfacnewprog/>
</Route>
<Route path='/addnpaunfacnew'>
<Addnpaunfacnew/>
</Route>
<Route path='/addpaunfacnewbulkn'>
<Addpaunfacnewbulkn />
</Route>
<Route path='/editnpauprncplphd'>
<Editnpauprncplphd/>
</Route>
<Route path='/viewnpauprncplphd'>
<Viewnpauprncplphd/>
</Route>
<Route path='/viewnpauprncplphdadmin'>
<Viewnpauprncplphdadmin/>
</Route>
<Route path='/viewpauprncplphdprog'>
<Viewpauprncplphdprog/>
</Route>
<Route path='/addnpauprncplphd'>
<Addnpauprncplphd/>
</Route>
<Route path='/addpauprncplphdbulkn'>
<Addpauprncplphdbulkn />
</Route>
<Route path='/editnpauprncplpub'>
<Editnpauprncplpub/>
</Route>
<Route path='/viewnpauprncplpub'>
<Viewnpauprncplpub/>
</Route>
<Route path='/viewnpauprncplpubadmin'>
<Viewnpauprncplpubadmin/>
</Route>
<Route path='/viewpauprncplpubprog'>
<Viewpauprncplpubprog/>
</Route>
<Route path='/addnpauprncplpub'>
<Addnpauprncplpub/>
</Route>
<Route path='/addpauprncplpubbulkn'>
<Addpauprncplpubbulkn />
</Route>


    <Route path='/editnpaunewfcoeexp'>
<Editnpaunewfcoeexp/>
</Route>
<Route path='/viewnpaunewfcoeexp'>
<Viewnpaunewfcoeexp/>
</Route>
<Route path='/viewnpaunewfcoeexpadmin'>
<Viewnpaunewfcoeexpadmin/>
</Route>
<Route path='/viewpaunewfcoeexpprog'>
<Viewpaunewfcoeexpprog/>
</Route>
<Route path='/addnpaunewfcoeexp'>
<Addnpaunewfcoeexp/>
</Route>
<Route path='/addpaunewfcoeexpbulkn'>
<Addpaunewfcoeexpbulkn />
</Route>


    <Route path='/editnpnirf'>
  <Editnpnirf/>
  </Route>
  <Route path='/viewnpnirf'>
  <Viewnpnirf/>
  </Route>
  <Route path='/viewnpnirfadmin'>
  <Viewnpnirfadmin/>
  </Route>
  <Route path='/viewpnirfprog'>
  <Viewpnirfprog/>
  </Route>
  <Route path='/addnpnirf'>
  <Addnpnirf/>
  </Route>
  <Route path='/addpnirfbulkn'>
  <Addpnirfbulkn />
  </Route>
  <Route path='/editnpadmnew'>
  <Editnpadmnew/>
  </Route>
  <Route path='/viewnpadmnew'>
  <Viewnpadmnew/>
  </Route>
  <Route path='/viewnpadmnewadmin'>
  <Viewnpadmnewadmin/>
  </Route>
  <Route path='/viewpadmnewprog'>
  <Viewpadmnewprog/>
  </Route>
  <Route path='/addnpadmnew'>
  <Addnpadmnew/>
  </Route>
  <Route path='/addpadmnewbulkn'>
  <Addpadmnewbulkn />
  </Route>
  <Route path='/editnpnbanew'>
  <Editnpnbanew/>
  </Route>
  <Route path='/viewnpnbanew'>
  <Viewnpnbanew/>
  </Route>
  <Route path='/viewnpnbanewadmin'>
  <Viewnpnbanewadmin/>
  </Route>
  {/* <Route path='/viewpnbanewprog'>
  <Viewpnbanewprog/>
  </Route> */}
  <Route path='/addnpnbanew'>
  <Addnpnbanew/>
  </Route>
  <Route path='/addpnbanewbulkn'>
  <Addpnbanewbulkn />
  </Route>


    <Route path='/dashanna1'>
<Dashanna1/>
</Route>
<Route path='/dashannaadmin1'>
<Dashannaadmin1/>
</Route>

    <Route path='/editnpaunewlanddetails'>
<Editnpaunewlanddetails/>
</Route>
<Route path='/viewnpaunewlanddetails'>
<Viewnpaunewlanddetails/>
</Route>
<Route path='/viewnpaunewlanddetailsadmin'>
<Viewnpaunewlanddetailsadmin/>
</Route>
<Route path='/viewpaunewlanddetailsprog'>
<Viewpaunewlanddetailsprog/>
</Route>
<Route path='/addnpaunewlanddetails'>
<Addnpaunewlanddetails/>
</Route>
<Route path='/addpaunewlanddetailsbulkn'>
<Addpaunewlanddetailsbulkn />
</Route>
<Route path='/editnpaunewfaddqualif'>
<Editnpaunewfaddqualif/>
</Route>
<Route path='/viewnpaunewfaddqualif'>
<Viewnpaunewfaddqualif/>
</Route>
<Route path='/viewnpaunewfaddqualifadmin'>
<Viewnpaunewfaddqualifadmin/>
</Route>
<Route path='/viewpaunewfaddqualifprog'>
<Viewpaunewfaddqualifprog/>
</Route>
<Route path='/addnpaunewfaddqualif'>
<Addnpaunewfaddqualif/>
</Route>
<Route path='/addpaunewfaddqualifbulkn'>
<Addpaunewfaddqualifbulkn />
</Route>
<Route path='/editnpaunewfacdexp'>
<Editnpaunewfacdexp/>
</Route>
<Route path='/viewnpaunewfacdexp'>
<Viewnpaunewfacdexp/>
</Route>
<Route path='/viewnpaunewfacdexpadmin'>
<Viewnpaunewfacdexpadmin/>
</Route>
<Route path='/viewpaunewfacdexpprog'>
<Viewpaunewfacdexpprog/>
</Route>
<Route path='/addnpaunewfacdexp'>
<Addnpaunewfacdexp/>
</Route>
<Route path='/addpaunewfacdexpbulkn'>
<Addpaunewfacdexpbulkn />
</Route>
<Route path='/editnpaunewfindexp'>
<Editnpaunewfindexp/>
</Route>
<Route path='/viewnpaunewfindexp'>
<Viewnpaunewfindexp/>
</Route>
<Route path='/viewnpaunewfindexpadmin'>
<Viewnpaunewfindexpadmin/>
</Route>
<Route path='/viewpaunewfindexpprog'>
<Viewpaunewfindexpprog/>
</Route>
<Route path='/addnpaunewfindexp'>
<Addnpaunewfindexp/>
</Route>
<Route path='/addpaunewfindexpbulkn'>
<Addpaunewfindexpbulkn />
</Route>

    <Route path='/editnpaunewfacultydetails'>
<Editnpaunewfacultydetails/>
</Route>
<Route path='/viewnpaunewfacultydetails'>
<Viewnpaunewfacultydetails/>
</Route>
<Route path='/viewnpaunewfacultydetailsadmin'>
<Viewnpaunewfacultydetailsadmin/>
</Route>
<Route path='/viewpaunewfacultydetailsprog'>
<Viewpaunewfacultydetailsprog/>
</Route>
<Route path='/addnpaunewfacultydetails'>
<Addnpaunewfacultydetails/>
</Route>
<Route path='/addpaunewfacultydetailsbulkn'>
<Addpaunewfacultydetailsbulkn />
</Route>
<Route path='/editnpaunewfeduqualif'>
<Editnpaunewfeduqualif/>
</Route>
<Route path='/viewnpaunewfeduqualif'>
<Viewnpaunewfeduqualif/>
</Route>
<Route path='/viewnpaunewfeduqualifadmin'>
<Viewnpaunewfeduqualifadmin/>
</Route>
<Route path='/viewpaunewfeduqualifprog'>
<Viewpaunewfeduqualifprog/>
</Route>
<Route path='/addnpaunewfeduqualif'>
<Addnpaunewfeduqualif/>
</Route>
<Route path='/addpaunewfeduqualifbulkn'>
<Addpaunewfeduqualifbulkn />
</Route>



    <Route path='/editnpaunewidetails'>
<Editnpaunewidetails/>
</Route>
<Route path='/viewnpaunewidetails'>
<Viewnpaunewidetails/>
</Route>
<Route path='/viewnpaunewidetailsadmin'>
<Viewnpaunewidetailsadmin/>
</Route>
<Route path='/viewpaunewidetailsprog'>
<Viewpaunewidetailsprog/>
</Route>
<Route path='/addnpaunewidetails'>
<Addnpaunewidetails/>
</Route>
<Route path='/addpaunewidetailsbulkn'>
<Addpaunewidetailsbulkn />
</Route>
<Route path='/editnpauidetailsc'>
<Editnpauidetailsc/>
</Route>
<Route path='/viewnpauidetailsc'>
<Viewnpauidetailsc/>
</Route>
<Route path='/viewnpauidetailscadmin'>
<Viewnpauidetailscadmin/>
</Route>
<Route path='/viewpauidetailscprog'>
<Viewpauidetailscprog/>
</Route>
<Route path='/addnpauidetailsc'>
<Addnpauidetailsc/>
</Route>
<Route path='/addpauidetailscbulkn'>
<Addpauidetailscbulkn />
</Route>
<Route path='/editnpautrust'>
<Editnpautrust/>
</Route>
<Route path='/viewnpautrust'>
<Viewnpautrust/>
</Route>
<Route path='/viewnpautrustadmin'>
<Viewnpautrustadmin/>
</Route>
<Route path='/viewpautrustprog'>
<Viewpautrustprog/>
</Route>
<Route path='/addnpautrust'>
<Addnpautrust/>
</Route>
<Route path='/addpautrustbulkn'>
<Addpautrustbulkn />
</Route>
<Route path='/editnpaunewprincipal'>
<Editnpaunewprincipal/>
</Route>
<Route path='/viewnpaunewprincipal'>
<Viewnpaunewprincipal/>
</Route>
<Route path='/viewnpaunewprincipaladmin'>
<Viewnpaunewprincipaladmin/>
</Route>
<Route path='/viewpaunewprincipalprog'>
<Viewpaunewprincipalprog/>
</Route>
<Route path='/addnpaunewprincipal'>
<Addnpaunewprincipal/>
</Route>
<Route path='/addpaunewprincipalbulkn'>
<Addpaunewprincipalbulkn />
</Route>
<Route path='/editnpaungovcouncil'>
<Editnpaungovcouncil/>
</Route>
<Route path='/viewnpaungovcouncil'>
<Viewnpaungovcouncil/>
</Route>
<Route path='/viewnpaungovcounciladmin'>
<Viewnpaungovcounciladmin/>
</Route>
<Route path='/viewpaungovcouncilprog'>
<Viewpaungovcouncilprog/>
</Route>
<Route path='/addnpaungovcouncil'>
<Addnpaungovcouncil/>
</Route>
<Route path='/addpaungovcouncilbulkn'>
<Addpaungovcouncilbulkn />
</Route>
<Route path='/editnpaunboard'>
<Editnpaunboard/>
</Route>
<Route path='/viewnpaunboard'>
<Viewnpaunboard/>
</Route>
<Route path='/viewnpaunboardadmin'>
<Viewnpaunboardadmin/>
</Route>
<Route path='/viewpaunboardprog'>
<Viewpaunboardprog/>
</Route>
<Route path='/addnpaunboard'>
<Addnpaunboard/>
</Route>
<Route path='/addpaunboardbulkn'>
<Addpaunboardbulkn />
</Route>
<Route path='/editnpauragcomt'>
<Editnpauragcomt/>
</Route>
<Route path='/viewnpauragcomt'>
<Viewnpauragcomt/>
</Route>
<Route path='/viewnpauragcomtadmin'>
<Viewnpauragcomtadmin/>
</Route>
<Route path='/viewpauragcomtprog'>
<Viewpauragcomtprog/>
</Route>
<Route path='/addnpauragcomt'>
<Addnpauragcomt/>
</Route>
<Route path='/addpauragcomtbulkn'>
<Addpauragcomtbulkn />
</Route>
<Route path='/editnpauragsquad'>
<Editnpauragsquad/>
</Route>
<Route path='/viewnpauragsquad'>
<Viewnpauragsquad/>
</Route>
<Route path='/viewnpauragsquadadmin'>
<Viewnpauragsquadadmin/>
</Route>
<Route path='/viewpauragsquadprog'>
<Viewpauragsquadprog/>
</Route>
<Route path='/addnpauragsquad'>
<Addnpauragsquad/>
</Route>
<Route path='/addpauragsquadbulkn'>
<Addpauragsquadbulkn />
</Route>
<Route path='/editnpauwelfarecomt'>
<Editnpauwelfarecomt/>
</Route>
<Route path='/viewnpauwelfarecomt'>
<Viewnpauwelfarecomt/>
</Route>
<Route path='/viewnpauwelfarecomtadmin'>
<Viewnpauwelfarecomtadmin/>
</Route>
<Route path='/viewpauwelfarecomtprog'>
<Viewpauwelfarecomtprog/>
</Route>
<Route path='/addnpauwelfarecomt'>
<Addnpauwelfarecomt/>
</Route>
<Route path='/addpauwelfarecomtbulkn'>
<Addpauwelfarecomtbulkn />
</Route>
<Route path='/editnpauredressal'>
<Editnpauredressal/>
</Route>
<Route path='/viewnpauredressal'>
<Viewnpauredressal/>
</Route>
<Route path='/viewnpauredressaladmin'>
<Viewnpauredressaladmin/>
</Route>
<Route path='/viewpauredressalprog'>
<Viewpauredressalprog/>
</Route>
<Route path='/addnpauredressal'>
<Addnpauredressal/>
</Route>
<Route path='/addpauredressalbulkn'>
<Addpauredressalbulkn />
</Route>
<Route path='/editnpaubankacnts'>
<Editnpaubankacnts/>
</Route>
<Route path='/viewnpaubankacnts'>
<Viewnpaubankacnts/>
</Route>
<Route path='/viewnpaubankacntsadmin'>
<Viewnpaubankacntsadmin/>
</Route>
<Route path='/viewpaubankacntsprog'>
<Viewpaubankacntsprog/>
</Route>
<Route path='/addnpaubankacnts'>
<Addnpaubankacnts/>
</Route>
<Route path='/addpaubankacntsbulkn'>
<Addpaubankacntsbulkn />
</Route>
<Route path='/editnpaudeposit'>
<Editnpaudeposit/>
</Route>
<Route path='/viewnpaudeposit'>
<Viewnpaudeposit/>
</Route>
<Route path='/viewnpaudepositadmin'>
<Viewnpaudepositadmin/>
</Route>
<Route path='/viewpaudepositprog'>
<Viewpaudepositprog/>
</Route>
<Route path='/addnpaudeposit'>
<Addnpaudeposit/>
</Route>
<Route path='/addpaudepositbulkn'>
<Addpaudepositbulkn />
</Route>
<Route path='/editnpauendowment'>
<Editnpauendowment/>
</Route>
<Route path='/viewnpauendowment'>
<Viewnpauendowment/>
</Route>
<Route path='/viewnpauendowmentadmin'>
<Viewnpauendowmentadmin/>
</Route>
<Route path='/viewpauendowmentprog'>
<Viewpauendowmentprog/>
</Route>
<Route path='/addnpauendowment'>
<Addnpauendowment/>
</Route>
<Route path='/addpauendowmentbulkn'>
<Addpauendowmentbulkn />
</Route>
<Route path='/editnpaulanddetailsa'>
<Editnpaulanddetailsa/>
</Route>
<Route path='/viewnpaulanddetailsa'>
<Viewnpaulanddetailsa/>
</Route>
<Route path='/viewnpaulanddetailsaadmin'>
<Viewnpaulanddetailsaadmin/>
</Route>
<Route path='/viewpaulanddetailsaprog'>
<Viewpaulanddetailsaprog/>
</Route>
<Route path='/addnpaulanddetailsa'>
<Addnpaulanddetailsa/>
</Route>
<Route path='/addpaulanddetailsabulkn'>
<Addpaulanddetailsabulkn />
</Route>
<Route path='/editnpaulanddetailsb'>
<Editnpaulanddetailsb/>
</Route>
<Route path='/viewnpaulanddetailsb'>
<Viewnpaulanddetailsb/>
</Route>
<Route path='/viewnpaulanddetailsbadmin'>
<Viewnpaulanddetailsbadmin/>
</Route>
<Route path='/viewpaulanddetailsbprog'>
<Viewpaulanddetailsbprog/>
</Route>
<Route path='/addnpaulanddetailsb'>
<Addnpaulanddetailsb/>
</Route>
<Route path='/addpaulanddetailsbbulkn'>
<Addpaulanddetailsbbulkn />
</Route>
<Route path='/editnpaucourse'>
<Editnpaucourse/>
</Route>
<Route path='/viewnpaucourse'>
<Viewnpaucourse/>
</Route>
<Route path='/viewnpaucourseadmin'>
<Viewnpaucourseadmin/>
</Route>
<Route path='/viewpaucourseprog'>
<Viewpaucourseprog/>
</Route>
<Route path='/addnpaucourse'>
<Addnpaucourse/>
</Route>
<Route path='/addpaucoursebulkn'>
<Addpaucoursebulkn />
</Route>
<Route path='/editnpaucrsedetails'>
<Editnpaucrsedetails/>
</Route>
<Route path='/viewnpaucrsedetails'>
<Viewnpaucrsedetails/>
</Route>
<Route path='/viewnpaucrsedetailsadmin'>
<Viewnpaucrsedetailsadmin/>
</Route>
<Route path='/viewpaucrsedetailsprog'>
<Viewpaucrsedetailsprog/>
</Route>
<Route path='/addnpaucrsedetails'>
<Addnpaucrsedetails/>
</Route>
<Route path='/addpaucrsedetailsbulkn'>
<Addpaucrsedetailsbulkn />
</Route>
<Route path='/editnpauaddcrse'>
<Editnpauaddcrse/>
</Route>
<Route path='/viewnpauaddcrse'>
<Viewnpauaddcrse/>
</Route>
<Route path='/viewnpauaddcrseadmin'>
<Viewnpauaddcrseadmin/>
</Route>
<Route path='/viewpauaddcrseprog'>
<Viewpauaddcrseprog/>
</Route>
<Route path='/addnpauaddcrse'>
<Addnpauaddcrse/>
</Route>
<Route path='/addpauaddcrsebulkn'>
<Addpauaddcrsebulkn />
</Route>
<Route path='/editnpaunstuddetails'>
<Editnpaunstuddetails/>
</Route>
<Route path='/viewnpaunstuddetails'>
<Viewnpaunstuddetails/>
</Route>
<Route path='/viewnpaunstuddetailsadmin'>
<Viewnpaunstuddetailsadmin/>
</Route>
<Route path='/viewpaunstuddetailsprog'>
<Viewpaunstuddetailsprog/>
</Route>
<Route path='/addnpaunstuddetails'>
<Addnpaunstuddetails/>
</Route>
<Route path='/addpaunstuddetailsbulkn'>
<Addpaunstuddetailsbulkn />
</Route>
<Route path='/editnpaufaculty'>
<Editnpaufaculty/>
</Route>
<Route path='/viewnpaufaculty'>
<Viewnpaufaculty/>
</Route>
<Route path='/viewnpaufacultyadmin'>
<Viewnpaufacultyadmin/>
</Route>
<Route path='/viewpaufacultyprog'>
<Viewpaufacultyprog/>
</Route>
<Route path='/addnpaufaculty'>
<Addnpaufaculty/>
</Route>
<Route path='/addpaufacultybulkn'>
<Addpaufacultybulkn />
</Route>
<Route path='/editnpaunfacultydetails'>
<Editnpaunfacultydetails/>
</Route>
<Route path='/viewnpaunfacultydetails'>
<Viewnpaunfacultydetails/>
</Route>
<Route path='/viewnpaunfacultydetailsadmin'>
<Viewnpaunfacultydetailsadmin/>
</Route>
<Route path='/viewpaunfacultydetailsprog'>
<Viewpaunfacultydetailsprog/>
</Route>
<Route path='/addnpaunfacultydetails'>
<Addnpaunfacultydetails/>
</Route>
<Route path='/addpaunfacultydetailsbulkn'>
<Addpaunfacultydetailsbulkn />
</Route>
<Route path='/editnpaunfaddqualif'>
<Editnpaunfaddqualif/>
</Route>
<Route path='/viewnpaunfaddqualif'>
<Viewnpaunfaddqualif/>
</Route>
<Route path='/viewnpaunfaddqualifadmin'>
<Viewnpaunfaddqualifadmin/>
</Route>
<Route path='/viewpaunfaddqualifprog'>
<Viewpaunfaddqualifprog/>
</Route>
<Route path='/addnpaunfaddqualif'>
<Addnpaunfaddqualif/>
</Route>
<Route path='/addpaunfaddqualifbulkn'>
<Addpaunfaddqualifbulkn />
</Route>
<Route path='/editnpaunfacdexp'>
<Editnpaunfacdexp/>
</Route>
<Route path='/viewnpaunfacdexp'>
<Viewnpaunfacdexp/>
</Route>
<Route path='/viewnpaunfacdexpadmin'>
<Viewnpaunfacdexpadmin/>
</Route>
<Route path='/viewpaunfacdexpprog'>
<Viewpaunfacdexpprog/>
</Route>
<Route path='/addnpaunfacdexp'>
<Addnpaunfacdexp/>
</Route>
<Route path='/addpaunfacdexpbulkn'>
<Addpaunfacdexpbulkn />
</Route>
<Route path='/editnpaunfindexp'>
<Editnpaunfindexp/>
</Route>
<Route path='/viewnpaunfindexp'>
<Viewnpaunfindexp/>
</Route>
<Route path='/viewnpaunfindexpadmin'>
<Viewnpaunfindexpadmin/>
</Route>
<Route path='/viewpaunfindexpprog'>
<Viewpaunfindexpprog/>
</Route>
<Route path='/addnpaunfindexp'>
<Addnpaunfindexp/>
</Route>
<Route path='/addpaunfindexpbulkn'>
<Addpaunfindexpbulkn />
</Route>
<Route path='/editnpaunnfeduqualif'>
<Editnpaunnfeduqualif/>
</Route>
<Route path='/viewnpaunnfeduqualif'>
<Viewnpaunnfeduqualif/>
</Route>
<Route path='/viewnpaunnfeduqualifadmin'>
<Viewnpaunnfeduqualifadmin/>
</Route>
<Route path='/viewpaunnfeduqualifprog'>
<Viewpaunnfeduqualifprog/>
</Route>
<Route path='/addnpaunnfeduqualif'>
<Addnpaunnfeduqualif/>
</Route>
<Route path='/addpaunnfeduqualifbulkn'>
<Addpaunnfeduqualifbulkn />
</Route>
<Route path='/editnpaunnfcoeexp'>
<Editnpaunnfcoeexp/>
</Route>
<Route path='/viewnpaunnfcoeexp'>
<Viewnpaunnfcoeexp/>
</Route>
<Route path='/viewnpaunnfcoeexpadmin'>
<Viewnpaunnfcoeexpadmin/>
</Route>
<Route path='/viewpaunnfcoeexpprog'>
<Viewpaunnfcoeexpprog/>
</Route>
<Route path='/addnpaunnfcoeexp'>
<Addnpaunnfcoeexp/>
</Route>
<Route path='/addpaunnfcoeexpbulkn'>
<Addpaunnfcoeexpbulkn />
</Route>
<Route path='/editnpauconfacshe'>
<Editnpauconfacshe/>
</Route>
<Route path='/viewnpauconfacshe'>
<Viewnpauconfacshe/>
</Route>
<Route path='/viewnpauconfacsheadmin'>
<Viewnpauconfacsheadmin/>
</Route>
<Route path='/viewpauconfacsheprog'>
<Viewpauconfacsheprog/>
</Route>
<Route path='/addnpauconfacshe'>
<Addnpauconfacshe/>
</Route>
<Route path='/addpauconfacshebulkn'>
<Addpauconfacshebulkn />
</Route>
<Route path='/editnpauconfaccourse'>
<Editnpauconfaccourse/>
</Route>
<Route path='/viewnpauconfaccourse'>
<Viewnpauconfaccourse/>
</Route>
<Route path='/viewnpauconfaccourseadmin'>
<Viewnpauconfaccourseadmin/>
</Route>
<Route path='/viewpauconfaccourseprog'>
<Viewpauconfaccourseprog/>
</Route>
<Route path='/addnpauconfaccourse'>
<Addnpauconfaccourse/>
</Route>
<Route path='/addpauconfaccoursebulkn'>
<Addpauconfaccoursebulkn />
</Route>
<Route path='/editnpauconfacmemtech'>
<Editnpauconfacmemtech/>
</Route>
<Route path='/viewnpauconfacmemtech'>
<Viewnpauconfacmemtech/>
</Route>
<Route path='/viewnpauconfacmemtechadmin'>
<Viewnpauconfacmemtechadmin/>
</Route>
<Route path='/viewpauconfacmemtechprog'>
<Viewpauconfacmemtechprog/>
</Route>
<Route path='/addnpauconfacmemtech'>
<Addnpauconfacmemtech/>
</Route>
<Route path='/addpauconfacmemtechbulkn'>
<Addpauconfacmemtechbulkn />
</Route>
<Route path='/editnpaunontstaff'>
<Editnpaunontstaff/>
</Route>
<Route path='/viewnpaunontstaff'>
<Viewnpaunontstaff/>
</Route>
<Route path='/viewnpaunontstaffadmin'>
<Viewnpaunontstaffadmin/>
</Route>
<Route path='/viewpaunontstaffprog'>
<Viewpaunontstaffprog/>
</Route>
<Route path='/addnpaunontstaff'>
<Addnpaunontstaff/>
</Route>
<Route path='/addpaunontstaffbulkn'>
<Addpaunontstaffbulkn />
</Route>
<Route path='/editnpauspacereq'>
<Editnpauspacereq/>
</Route>
<Route path='/viewnpauspacereq'>
<Viewnpauspacereq/>
</Route>
<Route path='/viewnpauspacereqadmin'>
<Viewnpauspacereqadmin/>
</Route>
<Route path='/viewpauspacereqprog'>
<Viewpauspacereqprog/>
</Route>
<Route path='/addnpauspacereq'>
<Addnpauspacereq/>
</Route>
<Route path='/addpauspacereqbulkn'>
<Addpauspacereqbulkn />
</Route>
<Route path='/editnpaunequip'>
<Editnpaunequip/>
</Route>
<Route path='/viewnpaunequip'>
<Viewnpaunequip/>
</Route>
<Route path='/viewnpaunequipadmin'>
<Viewnpaunequipadmin/>
</Route>
<Route path='/viewpaunequipprog'>
<Viewpaunequipprog/>
</Route>
<Route path='/addnpaunequip'>
<Addnpaunequip/>
</Route>
<Route path='/addpaunequipbulkn'>
<Addpaunequipbulkn />
</Route>
<Route path='/editnpauccomfarea'>
<Editnpauccomfarea/>
</Route>
<Route path='/viewnpauccomfarea'>
<Viewnpauccomfarea/>
</Route>
<Route path='/viewnpauccomfareaadmin'>
<Viewnpauccomfareaadmin/>
</Route>
<Route path='/viewpauccomfareaprog'>
<Viewpauccomfareaprog/>
</Route>
<Route path='/addnpauccomfarea'>
<Addnpauccomfarea/>
</Route>
<Route path='/addpauccomfareabulkn'>
<Addpauccomfareabulkn />
</Route>
<Route path='/editnpauntermlanwan'>
<Editnpauntermlanwan/>
</Route>
<Route path='/viewnpauntermlanwan'>
<Viewnpauntermlanwan/>
</Route>
<Route path='/viewnpauntermlanwanadmin'>
<Viewnpauntermlanwanadmin/>
</Route>
<Route path='/viewpauntermlanwanprog'>
<Viewpauntermlanwanprog/>
</Route>
<Route path='/addnpauntermlanwan'>
<Addnpauntermlanwan/>
</Route>
<Route path='/addpauntermlanwanbulkn'>
<Addpauntermlanwanbulkn />
</Route>
<Route path='/editnpausoftware'>
<Editnpausoftware/>
</Route>
<Route path='/viewnpausoftware'>
<Viewnpausoftware/>
</Route>
<Route path='/viewnpausoftwareadmin'>
<Viewnpausoftwareadmin/>
</Route>
<Route path='/viewpausoftwareprog'>
<Viewpausoftwareprog/>
</Route>
<Route path='/addnpausoftware'>
<Addnpausoftware/>
</Route>
<Route path='/addpausoftwarebulkn'>
<Addpausoftwarebulkn />
</Route>
<Route path='/editnpaunetwork'>
<Editnpaunetwork/>
</Route>
<Route path='/viewnpaunetwork'>
<Viewnpaunetwork/>
</Route>
<Route path='/viewnpaunetworkadmin'>
<Viewnpaunetworkadmin/>
</Route>
<Route path='/viewpaunetworkprog'>
<Viewpaunetworkprog/>
</Route>
<Route path='/addnpaunetwork'>
<Addnpaunetwork/>
</Route>
<Route path='/addpaunetworkbulkn'>
<Addpaunetworkbulkn />
</Route>
<Route path='/editnpaulibarea'>
<Editnpaulibarea/>
</Route>
<Route path='/viewnpaulibarea'>
<Viewnpaulibarea/>
</Route>
<Route path='/viewnpaulibareaadmin'>
<Viewnpaulibareaadmin/>
</Route>
<Route path='/viewpaulibareaprog'>
<Viewpaulibareaprog/>
</Route>
<Route path='/addnpaulibarea'>
<Addnpaulibarea/>
</Route>
<Route path='/addpaulibareabulkn'>
<Addpaulibareabulkn />
</Route>
<Route path='/editnpaunlibbooks'>
<Editnpaunlibbooks/>
</Route>
<Route path='/viewnpaunlibbooks'>
<Viewnpaunlibbooks/>
</Route>
<Route path='/viewnpaunlibbooksadmin'>
<Viewnpaunlibbooksadmin/>
</Route>
<Route path='/viewpaunlibbooksprog'>
<Viewpaunlibbooksprog/>
</Route>
<Route path='/addnpaunlibbooks'>
<Addnpaunlibbooks/>
</Route>
<Route path='/addpaunlibbooksbulkn'>
<Addpaunlibbooksbulkn />
</Route>
<Route path='/editnpaunlibjournal'>
<Editnpaunlibjournal/>
</Route>
<Route path='/viewnpaunlibjournal'>
<Viewnpaunlibjournal/>
</Route>
<Route path='/viewnpaunlibjournaladmin'>
<Viewnpaunlibjournaladmin/>
</Route>
<Route path='/viewpaunlibjournalprog'>
<Viewpaunlibjournalprog/>
</Route>
<Route path='/addnpaunlibjournal'>
<Addnpaunlibjournal/>
</Route>
<Route path='/addpaunlibjournalbulkn'>
<Addpaunlibjournalbulkn />
</Route>
<Route path='/editnpauclassblock'>
<Editnpauclassblock/>
</Route>
<Route path='/viewnpauclassblock'>
<Viewnpauclassblock/>
</Route>
<Route path='/viewnpauclassblockadmin'>
<Viewnpauclassblockadmin/>
</Route>
<Route path='/viewpauclassblockprog'>
<Viewpauclassblockprog/>
</Route>
<Route path='/addnpauclassblock'>
<Addnpauclassblock/>
</Route>
<Route path='/addpauclassblockbulkn'>
<Addpauclassblockbulkn />
</Route>
<Route path='/editnpauclassnew'>
<Editnpauclassnew/>
</Route>
<Route path='/viewnpauclassnew'>
<Viewnpauclassnew/>
</Route>
<Route path='/viewnpauclassnewadmin'>
<Viewnpauclassnewadmin/>
</Route>
<Route path='/viewpauclassnewprog'>
<Viewpauclassnewprog/>
</Route>
<Route path='/addnpauclassnew'>
<Addnpauclassnew/>
</Route>
<Route path='/addpauclassnewbulkn'>
<Addpauclassnewbulkn />
</Route>
<Route path='/editnpaunnoofdrawhall'>
<Editnpaunnoofdrawhall/>
</Route>
<Route path='/viewnpaunnoofdrawhall'>
<Viewnpaunnoofdrawhall/>
</Route>
<Route path='/viewnpaunnoofdrawhalladmin'>
<Viewnpaunnoofdrawhalladmin/>
</Route>
<Route path='/viewpaunnoofdrawhallprog'>
<Viewpaunnoofdrawhallprog/>
</Route>
<Route path='/addnpaunnoofdrawhall'>
<Addnpaunnoofdrawhall/>
</Route>
<Route path='/addpaunnoofdrawhallbulkn'>
<Addpaunnoofdrawhallbulkn />
</Route>
<Route path='/editnpauadminarea'>
<Editnpauadminarea/>
</Route>
<Route path='/viewnpauadminarea'>
<Viewnpauadminarea/>
</Route>
<Route path='/viewnpauadminareaadmin'>
<Viewnpauadminareaadmin/>
</Route>
<Route path='/viewpauadminareaprog'>
<Viewpauadminareaprog/>
</Route>
<Route path='/addnpauadminarea'>
<Addnpauadminarea/>
</Route>
<Route path='/addpauadminareabulkn'>
<Addpauadminareabulkn />
</Route>
<Route path='/editnpaunamen'>
<Editnpaunamen/>
</Route>
<Route path='/viewnpaunamen'>
<Viewnpaunamen/>
</Route>
<Route path='/viewnpaunamenadmin'>
<Viewnpaunamenadmin/>
</Route>
<Route path='/viewpaunamenprog'>
<Viewpaunamenprog/>
</Route>
<Route path='/addnpaunamen'>
<Addnpaunamen/>
</Route>
<Route path='/addpaunamenbulkn'>
<Addpaunamenbulkn />
</Route>
<Route path='/editnpaubhostel'>
<Editnpaubhostel/>
</Route>
<Route path='/viewnpaubhostel'>
<Viewnpaubhostel/>
</Route>
<Route path='/viewnpaubhosteladmin'>
<Viewnpaubhosteladmin/>
</Route>
<Route path='/viewpaubhostelprog'>
<Viewpaubhostelprog/>
</Route>
<Route path='/addnpaubhostel'>
<Addnpaubhostel/>
</Route>
<Route path='/addpaubhostelbulkn'>
<Addpaubhostelbulkn />
</Route>
<Route path='/editnpaughostel'>
<Editnpaughostel/>
</Route>
<Route path='/viewnpaughostel'>
<Viewnpaughostel/>
</Route>
<Route path='/viewnpaughosteladmin'>
<Viewnpaughosteladmin/>
</Route>
<Route path='/viewpaughostelprog'>
<Viewpaughostelprog/>
</Route>
<Route path='/addnpaughostel'>
<Addnpaughostel/>
</Route>
<Route path='/addpaughostelbulkn'>
<Addpaughostelbulkn />
</Route>
<Route path='/editnpaunotherbuildarea'>
<Editnpaunotherbuildarea/>
</Route>
<Route path='/viewnpaunotherbuildarea'>
<Viewnpaunotherbuildarea/>
</Route>
<Route path='/viewnpaunotherbuildareaadmin'>
<Viewnpaunotherbuildareaadmin/>
</Route>
<Route path='/viewpaunotherbuildareaprog'>
<Viewpaunotherbuildareaprog/>
</Route>
<Route path='/addnpaunotherbuildarea'>
<Addnpaunotherbuildarea/>
</Route>
<Route path='/addpaunotherbuildareabulkn'>
<Addpaunotherbuildareabulkn />
</Route>
<Route path='/editnpauphyedu'>
<Editnpauphyedu/>
</Route>
<Route path='/viewnpauphyedu'>
<Viewnpauphyedu/>
</Route>
<Route path='/viewnpauphyeduadmin'>
<Viewnpauphyeduadmin/>
</Route>
<Route path='/viewpauphyeduprog'>
<Viewpauphyeduprog/>
</Route>
<Route path='/addnpauphyedu'>
<Addnpauphyedu/>
</Route>
<Route path='/addpauphyedubulkn'>
<Addpauphyedubulkn />
</Route>
<Route path='/editnpaufacilityavail'>
<Editnpaufacilityavail/>
</Route>
<Route path='/viewnpaufacilityavail'>
<Viewnpaufacilityavail/>
</Route>
<Route path='/viewnpaufacilityavailadmin'>
<Viewnpaufacilityavailadmin/>
</Route>
<Route path='/viewpaufacilityavailprog'>
<Viewpaufacilityavailprog/>
</Route>
<Route path='/addnpaufacilityavail'>
<Addnpaufacilityavail/>
</Route>
<Route path='/addpaufacilityavailbulkn'>
<Addpaufacilityavailbulkn />
</Route>
<Route path='/editnpaualumni'>
<Editnpaualumni/>
</Route>
<Route path='/viewnpaualumni'>
<Viewnpaualumni/>
</Route>
<Route path='/viewnpaualumniadmin'>
<Viewnpaualumniadmin/>
</Route>
<Route path='/viewpaualumniprog'>
<Viewpaualumniprog/>
</Route>
<Route path='/addnpaualumni'>
<Addnpaualumni/>
</Route>
<Route path='/addpaualumnibulkn'>
<Addpaualumnibulkn />
</Route>
<Route path='/editnpautpc'>
<Editnpautpc/>
</Route>
<Route path='/viewnpautpc'>
<Viewnpautpc/>
</Route>
<Route path='/viewnpautpcadmin'>
<Viewnpautpcadmin/>
</Route>
<Route path='/viewpautpcprog'>
<Viewpautpcprog/>
</Route>
<Route path='/addnpautpc'>
<Addnpautpc/>
</Route>
<Route path='/addpautpcbulkn'>
<Addpautpcbulkn />
</Route>
<Route path='/editnpaunhealth'>
<Editnpaunhealth/>
</Route>
<Route path='/viewnpaunhealth'>
<Viewnpaunhealth/>
</Route>
<Route path='/viewnpaunhealthadmin'>
<Viewnpaunhealthadmin/>
</Route>
<Route path='/viewpaunhealthprog'>
<Viewpaunhealthprog/>
</Route>
<Route path='/addnpaunhealth'>
<Addnpaunhealth/>
</Route>
<Route path='/addpaunhealthbulkn'>
<Addpaunhealthbulkn />
</Route>
<Route path='/editnpauothamen'>
<Editnpauothamen/>
</Route>
<Route path='/viewnpauothamen'>
<Viewnpauothamen/>
</Route>
<Route path='/viewnpauothamenadmin'>
<Viewnpauothamenadmin/>
</Route>
<Route path='/viewpauothamenprog'>
<Viewpauothamenprog/>
</Route>
<Route path='/addnpauothamen'>
<Addnpauothamen/>
</Route>
<Route path='/addpauothamenbulkn'>
<Addpauothamenbulkn />
</Route>
<Route path='/editnpauregrec'>
<Editnpauregrec/>
</Route>
<Route path='/viewnpauregrec'>
<Viewnpauregrec/>
</Route>
<Route path='/viewnpauregrecadmin'>
<Viewnpauregrecadmin/>
</Route>
<Route path='/viewpauregrecprog'>
<Viewpauregrecprog/>
</Route>
<Route path='/addnpauregrec'>
<Addnpauregrec/>
</Route>
<Route path='/addpauregrecbulkn'>
<Addpauregrecbulkn />
</Route>
<Route path='/editnpaucertificates'>
<Editnpaucertificates/>
</Route>
<Route path='/viewnpaucertificates'>
<Viewnpaucertificates/>
</Route>
<Route path='/viewnpaucertificatesadmin'>
<Viewnpaucertificatesadmin/>
</Route>
<Route path='/viewpaucertificatesprog'>
<Viewpaucertificatesprog/>
</Route>
<Route path='/addnpaucertificates'>
<Addnpaucertificates/>
</Route>
<Route path='/addpaucertificatesbulkn'>
<Addpaucertificatesbulkn />
</Route>
<Route path='/editnpaunprocfee'>
<Editnpaunprocfee/>
</Route>
<Route path='/viewnpaunprocfee'>
<Viewnpaunprocfee/>
</Route>
<Route path='/viewnpaunprocfeeadmin'>
<Viewnpaunprocfeeadmin/>
</Route>
<Route path='/viewpaunprocfeeprog'>
<Viewpaunprocfeeprog/>
</Route>
<Route path='/addnpaunprocfee'>
<Addnpaunprocfee/>
</Route>
<Route path='/addpaunprocfeebulkn'>
<Addpaunprocfeebulkn />
</Route>
<Route path='/editnpauprcplendrsmnt'>
<Editnpauprcplendrsmnt/>
</Route>
<Route path='/viewnpauprcplendrsmnt'>
<Viewnpauprcplendrsmnt/>
</Route>
<Route path='/viewnpauprcplendrsmntadmin'>
<Viewnpauprcplendrsmntadmin/>
</Route>
<Route path='/viewpauprcplendrsmntprog'>
<Viewpauprcplendrsmntprog/>
</Route>
<Route path='/addnpauprcplendrsmnt'>
<Addnpauprcplendrsmnt/>
</Route>
<Route path='/addpauprcplendrsmntbulkn'>
<Addpauprcplendrsmntbulkn />
</Route>
<Route path='/editnpaumngdeclaration'>
<Editnpaumngdeclaration/>
</Route>
<Route path='/viewnpaumngdeclaration'>
<Viewnpaumngdeclaration/>
</Route>
<Route path='/viewnpaumngdeclarationadmin'>
<Viewnpaumngdeclarationadmin/>
</Route>
<Route path='/viewpaumngdeclarationprog'>
<Viewpaumngdeclarationprog/>
</Route>
<Route path='/addnpaumngdeclaration'>
<Addnpaumngdeclaration/>
</Route>
<Route path='/addpaumngdeclarationbulkn'>
<Addpaumngdeclarationbulkn />
</Route>


    <Route path='/editnainst'>
<Editnainst/>
</Route>
<Route path='/viewnainst'>
<Viewnainst/>
</Route>
<Route path='/viewnainstadmin'>
<Viewnainstadmin/>
</Route>
<Route path='/viewainstprog'>
<Viewainstprog/>
</Route>
<Route path='/addnainst'>
<Addnainst/>
</Route>
<Route path='/addainstbulkn'>
<Addainstbulkn />
</Route>


    <Route path='/viewautonomousmenu'>
<Viewautonomousmenu/>
</Route>
    <Route path='/viewaffiliatedmenu'>
<Viewaffiliatedmenu/>
</Route>
    <Route path='/viewlegaluniversitymenu'>
<Viewlegaluniversitymenu/>
</Route>

    <Route path='/editnnluteacherdata'>
<Editnnluteacherdata/>
</Route>
<Route path='/viewnnluteacherdata'>
<Viewnnluteacherdata/>
</Route>
<Route path='/viewnnluteacherdataadmin'>
<Viewnnluteacherdataadmin/>
</Route>
<Route path='/viewnluteacherdataprog'>
<Viewnluteacherdataprog/>
</Route>
<Route path='/addnnluteacherdata'>
<Addnnluteacherdata/>
</Route>
<Route path='/addnluteacherdatabulkn'>
<Addnluteacherdatabulkn />
</Route>
<Route path='/editnnlufacilities'>
<Editnnlufacilities/>
</Route>
<Route path='/viewnnlufacilities'>
<Viewnnlufacilities/>
</Route>
<Route path='/viewnnlufacilitiesadmin'>
<Viewnnlufacilitiesadmin/>
</Route>
<Route path='/viewnlufacilitiesprog'>
<Viewnlufacilitiesprog/>
</Route>
<Route path='/addnnlufacilities'>
<Addnnlufacilities/>
</Route>
<Route path='/addnlufacilitiesbulkn'>
<Addnlufacilitiesbulkn />
</Route>
<Route path='/editnnlurecognitions'>
<Editnnlurecognitions/>
</Route>
<Route path='/viewnnlurecognitions'>
<Viewnnlurecognitions/>
</Route>
<Route path='/viewnnlurecognitionsadmin'>
<Viewnnlurecognitionsadmin/>
</Route>
<Route path='/viewnlurecognitionsprog'>
<Viewnlurecognitionsprog/>
</Route>
<Route path='/addnnlurecognitions'>
<Addnnlurecognitions/>
</Route>
<Route path='/addnlurecognitionsbulkn'>
<Addnlurecognitionsbulkn />
</Route>
<Route path='/editnnlubills'>
<Editnnlubills/>
</Route>
<Route path='/viewnnlubills'>
<Viewnnlubills/>
</Route>
<Route path='/viewnnlubillsadmin'>
<Viewnnlubillsadmin/>
</Route>
<Route path='/viewnlubillsprog'>
<Viewnlubillsprog/>
</Route>
<Route path='/addnnlubills'>
<Addnnlubills/>
</Route>
<Route path='/addnlubillsbulkn'>
<Addnlubillsbulkn />
</Route>
<Route path='/editnnluincentives'>
<Editnnluincentives/>
</Route>
<Route path='/viewnnluincentives'>
<Viewnnluincentives/>
</Route>
<Route path='/viewnnluincentivesadmin'>
<Viewnnluincentivesadmin/>
</Route>
<Route path='/viewnluincentivesprog'>
<Viewnluincentivesprog/>
</Route>
<Route path='/addnnluincentives'>
<Addnnluincentives/>
</Route>
<Route path='/addnluincentivesbulkn'>
<Addnluincentivesbulkn />
</Route>
<Route path='/editnnluphdtheisis'>
<Editnnluphdtheisis/>
</Route>
<Route path='/viewnnluphdtheisis'>
<Viewnnluphdtheisis/>
</Route>
<Route path='/viewnnluphdtheisisadmin'>
<Viewnnluphdtheisisadmin/>
</Route>
<Route path='/viewnluphdtheisisprog'>
<Viewnluphdtheisisprog/>
</Route>
<Route path='/addnnluphdtheisis'>
<Addnnluphdtheisis/>
</Route>
<Route path='/addnluphdtheisisbulkn'>
<Addnluphdtheisisbulkn />
</Route>
<Route path='/editnnlucitation'>
<Editnnlucitation/>
</Route>
<Route path='/viewnnlucitation'>
<Viewnnlucitation/>
</Route>
<Route path='/viewnnlucitationadmin'>
<Viewnnlucitationadmin/>
</Route>
<Route path='/viewnlucitationprog'>
<Viewnlucitationprog/>
</Route>
<Route path='/addnnlucitation'>
<Addnnlucitation/>
</Route>
<Route path='/addnlucitationbulkn'>
<Addnlucitationbulkn />
</Route>
<Route path='/editnnlulegaldatabases'>
<Editnnlulegaldatabases/>
</Route>
<Route path='/viewnnlulegaldatabases'>
<Viewnnlulegaldatabases/>
</Route>
<Route path='/viewnnlulegaldatabasesadmin'>
<Viewnnlulegaldatabasesadmin/>
</Route>
<Route path='/viewnlulegaldatabasesprog'>
<Viewnlulegaldatabasesprog/>
</Route>
<Route path='/addnnlulegaldatabases'>
<Addnnlulegaldatabases/>
</Route>
<Route path='/addnlulegaldatabasesbulkn'>
<Addnlulegaldatabasesbulkn />
</Route>
<Route path='/editnnlbar'>
<Editnnlbar/>
</Route>
<Route path='/viewnnlbar'>
<Viewnnlbar/>
</Route>
<Route path='/viewnnlbaradmin'>
<Viewnnlbaradmin/>
</Route>
<Route path='/viewnlbarprog'>
<Viewnlbarprog/>
</Route>
<Route path='/addnnlbar'>
<Addnnlbar/>
</Route>
<Route path='/addnlbarbulkn'>
<Addnlbarbulkn />
</Route>


    <Route path='/viewuniversitymetricnew'>
<Viewuniversitymetricnew/>
</Route>
    <Route path='/editnexplearning'>
<Editnexplearning/>
</Route>
<Route path='/viewnexplearning'>
<Viewnexplearning/>
</Route>
<Route path='/viewnexplearningadmin'>
<Viewnexplearningadmin/>
</Route>
<Route path='/viewexplearningprog'>
<Viewexplearningprog/>
</Route>
<Route path='/addnexplearning'>
<Addnexplearning/>
</Route>
<Route path='/addexplearningbulkn'>
<Addexplearningbulkn />
</Route>
<Route path='/editnnufacultydata'>
<Editnnufacultydata/>
</Route>
<Route path='/viewnnufacultydata'>
<Viewnnufacultydata/>
</Route>
<Route path='/viewnnufacultydataadmin'>
<Viewnnufacultydataadmin/>
</Route>
<Route path='/viewnufacultydataprog'>
<Viewnufacultydataprog/>
</Route>
<Route path='/addnnufacultydata'>
<Addnnufacultydata/>
</Route>
<Route path='/addnufacultydatabulkn'>
<Addnufacultydatabulkn />
</Route>
<Route path='/editnphdface'>
<Editnphdface/>
</Route>
<Route path='/viewnphdface'>
<Viewnphdface/>
</Route>
<Route path='/viewnphdfaceadmin'>
<Viewnphdfaceadmin/>
</Route>
<Route path='/viewphdfaceprog'>
<Viewphdfaceprog/>
</Route>
<Route path='/addnphdface'>
<Addnphdface/>
</Route>
<Route path='/addphdfacebulkn'>
<Addphdfacebulkn />
</Route>
<Route path='/editnteacherdata'>
<Editnteacherdata/>
</Route>
<Route path='/viewnteacherdata'>
<Viewnteacherdata/>
</Route>
<Route path='/viewnteacherdataadmin'>
<Viewnteacherdataadmin/>
</Route>
<Route path='/viewteacherdataprog'>
<Viewteacherdataprog/>
</Route>
<Route path='/addnteacherdata'>
<Addnteacherdata/>
</Route>
<Route path='/addteacherdatabulkn'>
<Addteacherdatabulkn />
</Route>
<Route path='/editnresult'>
<Editnresult/>
</Route>
<Route path='/viewnresult'>
<Viewnresult/>
</Route>
<Route path='/viewnresultadmin'>
<Viewnresultadmin/>
</Route>
<Route path='/viewresultprog'>
<Viewresultprog/>
</Route>
<Route path='/addnresult'>
<Addnresult/>
</Route>
<Route path='/addresultbulkn'>
<Addresultbulkn />
</Route>
<Route path='/editnnugrievance'>
<Editnnugrievance/>
</Route>
<Route path='/viewnnugrievance'>
<Viewnnugrievance/>
</Route>
<Route path='/viewnnugrievanceadmin'>
<Viewnnugrievanceadmin/>
</Route>
<Route path='/viewnugrievanceprog'>
<Viewnugrievanceprog/>
</Route>
<Route path='/addnnugrievance'>
<Addnnugrievance/>
</Route>
<Route path='/addnugrievancebulkn'>
<Addnugrievancebulkn />
</Route>
<Route path='/editnpasspercent'>
<Editnpasspercent/>
</Route>
<Route path='/viewnpasspercent'>
<Viewnpasspercent/>
</Route>
<Route path='/viewnpasspercentadmin'>
<Viewnpasspercentadmin/>
</Route>
<Route path='/viewpasspercentprog'>
<Viewpasspercentprog/>
</Route>
<Route path='/addnpasspercent'>
<Addnpasspercent/>
</Route>
<Route path='/addpasspercentbulkn'>
<Addpasspercentbulkn />
</Route>
<Route path='/editnteacherfellow'>
<Editnteacherfellow/>
</Route>
<Route path='/viewnteacherfellow'>
<Viewnteacherfellow/>
</Route>
<Route path='/viewnteacherfellowadmin'>
<Viewnteacherfellowadmin/>
</Route>
<Route path='/viewteacherfellowprog'>
<Viewteacherfellowprog/>
</Route>
<Route path='/addnteacherfellow'>
<Addnteacherfellow/>
</Route>
<Route path='/addteacherfellowbulkn'>
<Addteacherfellowbulkn />
</Route>
<Route path='/editnpatents'>
<Editnpatents/>
</Route>
<Route path='/viewnpatents'>
<Viewnpatents/>
</Route>
<Route path='/viewnpatentsadmin'>
<Viewnpatentsadmin/>
</Route>
<Route path='/viewpatentsprog'>
<Viewpatentsprog/>
</Route>
<Route path='/addnpatents'>
<Addnpatents/>
</Route>
<Route path='/addpatentsbulkn'>
<Addpatentsbulkn />
</Route>
<Route path='/editnconsultancy'>
<Editnconsultancy/>
</Route>
<Route path='/viewnconsultancy'>
<Viewnconsultancy/>
</Route>
<Route path='/viewnconsultancyadmin'>
<Viewnconsultancyadmin/>
</Route>
<Route path='/viewconsultancyprog'>
<Viewconsultancyprog/>
</Route>
<Route path='/addnconsultancy'>
<Addnconsultancy/>
</Route>
<Route path='/addconsultancybulkn'>
<Addconsultancybulkn />
</Route>
<Route path='/editnmou'>
<Editnmou/>
</Route>
<Route path='/viewnmou'>
<Viewnmou/>
</Route>
<Route path='/viewnmouadmin'>
<Viewnmouadmin/>
</Route>
<Route path='/viewmouprog'>
<Viewmouprog/>
</Route>
<Route path='/addnmou'>
<Addnmou/>
</Route>
<Route path='/addmoubulkn'>
<Addmoubulkn />
</Route>
<Route path='/editnnuefacilities'>
<Editnnuefacilities/>
</Route>
<Route path='/viewnnuefacilities'>
<Viewnnuefacilities/>
</Route>
<Route path='/viewnnuefacilitiesadmin'>
<Viewnnuefacilitiesadmin/>
</Route>
<Route path='/viewnuefacilitiesprog'>
<Viewnuefacilitiesprog/>
</Route>
<Route path='/addnnuefacilities'>
<Addnnuefacilities/>
</Route>
<Route path='/addnuefacilitiesbulkn'>
<Addnuefacilitiesbulkn />
</Route>
<Route path='/editnnustudcompratio'>
<Editnnustudcompratio/>
</Route>
<Route path='/viewnnustudcompratio'>
<Viewnnustudcompratio/>
</Route>
<Route path='/viewnnustudcompratioadmin'>
<Viewnnustudcompratioadmin/>
</Route>
<Route path='/viewnustudcompratioprog'>
<Viewnustudcompratioprog/>
</Route>
<Route path='/addnnustudcompratio'>
<Addnnustudcompratio/>
</Route>
<Route path='/addnustudcompratiobulkn'>
<Addnustudcompratiobulkn />
</Route>
<Route path='/editnawards'>
<Editnawards/>
</Route>
<Route path='/viewnawards'>
<Viewnawards/>
</Route>
<Route path='/viewnawardsadmin'>
<Viewnawardsadmin/>
</Route>
<Route path='/viewawardsprog'>
<Viewawardsprog/>
</Route>
<Route path='/addnawards'>
<Addnawards/>
</Route>
<Route path='/addawardsbulkn'>
<Addawardsbulkn />
</Route>
<Route path='/editnnlibusage'>
<Editnnlibusage/>
</Route>
<Route path='/viewnnlibusage'>
<Viewnnlibusage/>
</Route>
<Route path='/viewnnlibusageadmin'>
<Viewnnlibusageadmin/>
</Route>
<Route path='/viewnlibusageprog'>
<Viewnlibusageprog/>
</Route>
<Route path='/addnnlibusage'>
<Addnnlibusage/>
</Route>
<Route path='/addnlibusagebulkn'>
<Addnlibusagebulkn />
</Route>
<Route path='/editnhigherexam'>
<Editnhigherexam/>
</Route>
<Route path='/viewnhigherexam'>
<Viewnhigherexam/>
</Route>
<Route path='/viewnhigherexamadmin'>
<Viewnhigherexamadmin/>
</Route>
<Route path='/viewhigherexamprog'>
<Viewhigherexamprog/>
</Route>
<Route path='/addnhigherexam'>
<Addnhigherexam/>
</Route>
<Route path='/addhigherexambulkn'>
<Addhigherexambulkn />
</Route>



    {/* <Route path='/editnnlibusage'>
<Editnnlibusage/>
</Route>
<Route path='/viewnnlibusage'>
<Viewnnlibusage/>
</Route>
<Route path='/viewnnlibusageadmin'>
<Viewnnlibusageadmin/>
</Route>
<Route path='/viewnlibusageprog'>
<Viewnlibusageprog/>
</Route>
<Route path='/addnnlibusage'>
<Addnnlibusage/>
</Route>
<Route path='/addnlibusagebulkn'>
<Addnlibusagebulkn />
</Route> */}

    <Route path='/viewnnmytasksuser'>
<Viewnnmytasksuser/>
</Route>
    <Route path='/editnnmytasksuser'>
<Editnnmytasksuser/>
</Route>
    <Route path='/editnnqualification'>
<Editnnqualification/>
</Route>
<Route path='/viewnnqualification'>
<Viewnnqualification/>
</Route>
<Route path='/viewnnqualificationadmin'>
<Viewnnqualificationadmin/>
</Route>
<Route path='/viewnqualificationprog'>
<Viewnqualificationprog/>
</Route>
<Route path='/addnnqualification'>
<Addnnqualification/>
</Route>
<Route path='/addnqualificationbulkn'>
<Addnqualificationbulkn />
</Route>
<Route path='/editnnexperience'>
<Editnnexperience/>
</Route>
<Route path='/viewnnexperience'>
<Viewnnexperience/>
</Route>
<Route path='/viewnnexperienceadmin'>
<Viewnnexperienceadmin/>
</Route>
<Route path='/viewnexperienceprog'>
<Viewnexperienceprog/>
</Route>
<Route path='/addnnexperience'>
<Addnnexperience/>
</Route>
<Route path='/addnexperiencebulkn'>
<Addnexperiencebulkn />
</Route>
<Route path='/editnnmytasks'>
<Editnnmytasks/>
</Route>
<Route path='/viewnnmytasks'>
<Viewnnmytasks/>
</Route>
<Route path='/viewnnmytasksadmin'>
<Viewnnmytasksadmin/>
</Route>
<Route path='/viewnmytasksprog'>
<Viewnmytasksprog/>
</Route>
<Route path='/addnnmytasks'>
<Addnnmytasks/>
</Route>
<Route path='/addnmytasksbulkn'>
<Addnmytasksbulkn />
</Route>
<Route path='/editnsalarydetails'>
<Editnsalarydetails/>
</Route>
<Route path='/viewnsalarydetails'>
<Viewnsalarydetails/>
</Route>
<Route path='/viewnsalarydetailsadmin'>
<Viewnsalarydetailsadmin/>
</Route>
<Route path='/viewsalarydetailsprog'>
<Viewsalarydetailsprog/>
</Route>
<Route path='/addnsalarydetails'>
<Addnsalarydetails/>
</Route>
<Route path='/addsalarydetailsbulkn'>
<Addsalarydetailsbulkn />
</Route>
<Route path='/editnempapprec'>
<Editnempapprec/>
</Route>
<Route path='/viewnempapprec'>
<Viewnempapprec/>
</Route>
<Route path='/viewnempapprecadmin'>
<Viewnempapprecadmin/>
</Route>
<Route path='/viewempapprecprog'>
<Viewempapprecprog/>
</Route>
<Route path='/addnempapprec'>
<Addnempapprec/>
</Route>
<Route path='/addempapprecbulkn'>
<Addempapprecbulkn />
</Route>


    <Route path='/editnrnphospitals'>
<Editnrnphospitals/>
</Route>
<Route path='/viewnrnphospitals'>
<Viewnrnphospitals/>
</Route>
<Route path='/viewnrnphospitalsadmin'>
<Viewnrnphospitalsadmin/>
</Route>
<Route path='/viewrnphospitalsprog'>
<Viewrnphospitalsprog/>
</Route>
<Route path='/addnrnphospitals'>
<Addnrnphospitals/>
</Route>
<Route path='/addrnphospitalsbulkn'>
<Addrnphospitalsbulkn />
</Route>
<Route path='/editnrnbuilding'>
<Editnrnbuilding/>
</Route>
<Route path='/viewnrnbuilding'>
<Viewnrnbuilding/>
</Route>
<Route path='/viewnrnbuildingadmin'>
<Viewnrnbuildingadmin/>
</Route>
<Route path='/viewrnbuildingprog'>
<Viewrnbuildingprog/>
</Route>
<Route path='/addnrnbuilding'>
<Addnrnbuilding/>
</Route>
<Route path='/addrnbuildingbulkn'>
<Addrnbuildingbulkn />
</Route>



    <Route path='/editnrnotherc'>
<Editnrnotherc/>
</Route>
<Route path='/viewnrnotherc'>
<Viewnrnotherc/>
</Route>
<Route path='/viewnrnothercadmin'>
<Viewnrnothercadmin/>
</Route>
<Route path='/viewrnothercprog'>
<Viewrnothercprog/>
</Route>
<Route path='/addnrnotherc'>
<Addnrnotherc/>
</Route>
<Route path='/addrnothercbulkn'>
<Addrnothercbulkn />
</Route>
<Route path='/editnrnclassroom'>
<Editnrnclassroom/>
</Route>
<Route path='/viewnrnclassroom'>
<Viewnrnclassroom/>
</Route>
<Route path='/viewnrnclassroomadmin'>
<Viewnrnclassroomadmin/>
</Route>
<Route path='/viewrnclassroomprog'>
<Viewrnclassroomprog/>
</Route>
<Route path='/addnrnclassroom'>
<Addnrnclassroom/>
</Route>
<Route path='/addrnclassroombulkn'>
<Addrnclassroombulkn />
</Route>
<Route path='/editnrnlabs'>
<Editnrnlabs/>
</Route>
<Route path='/viewnrnlabs'>
<Viewnrnlabs/>
</Route>
<Route path='/viewnrnlabsadmin'>
<Viewnrnlabsadmin/>
</Route>
<Route path='/viewrnlabsprog'>
<Viewrnlabsprog/>
</Route>
<Route path='/addnrnlabs'>
<Addnrnlabs/>
</Route>
<Route path='/addrnlabsbulkn'>
<Addrnlabsbulkn />
</Route>
<Route path='/editnrnvehicle'>
<Editnrnvehicle/>
</Route>
<Route path='/viewnrnvehicle'>
<Viewnrnvehicle/>
</Route>
<Route path='/viewnrnvehicleadmin'>
<Viewnrnvehicleadmin/>
</Route>
<Route path='/viewrnvehicleprog'>
<Viewrnvehicleprog/>
</Route>
<Route path='/addnrnvehicle'>
<Addnrnvehicle/>
</Route>
<Route path='/addrnvehiclebulkn'>
<Addrnvehiclebulkn />
</Route>
<Route path='/editnrnlibrarian'>
<Editnrnlibrarian/>
</Route>
<Route path='/viewnrnlibrarian'>
<Viewnrnlibrarian/>
</Route>
<Route path='/viewnrnlibrarianadmin'>
<Viewnrnlibrarianadmin/>
</Route>
<Route path='/viewrnlibrarianprog'>
<Viewrnlibrarianprog/>
</Route>
<Route path='/addnrnlibrarian'>
<Addnrnlibrarian/>
</Route>
<Route path='/addrnlibrarianbulkn'>
<Addrnlibrarianbulkn />
</Route>
<Route path='/editnrnadministrative'>
<Editnrnadministrative/>
</Route>
<Route path='/viewnrnadministrative'>
<Viewnrnadministrative/>
</Route>
<Route path='/viewnrnadministrativeadmin'>
<Viewnrnadministrativeadmin/>
</Route>
<Route path='/viewrnadministrativeprog'>
<Viewrnadministrativeprog/>
</Route>
<Route path='/addnrnadministrative'>
<Addnrnadministrative/>
</Route>
<Route path='/addrnadministrativebulkn'>
<Addrnadministrativebulkn />
</Route>
<Route path='/editnrnaffhospital'>
<Editnrnaffhospital/>
</Route>
<Route path='/viewnrnaffhospital'>
<Viewnrnaffhospital/>
</Route>
<Route path='/viewnrnaffhospitaladmin'>
<Viewnrnaffhospitaladmin/>
</Route>
<Route path='/viewrnaffhospitalprog'>
<Viewrnaffhospitalprog/>
</Route>
<Route path='/addnrnaffhospital'>
<Addnrnaffhospital/>
</Route>
<Route path='/addrnaffhospitalbulkn'>
<Addrnaffhospitalbulkn />
</Route>
<Route path='/editnrndocuments'>
<Editnrndocuments/>
</Route>
<Route path='/viewnrndocuments'>
<Viewnrndocuments/>
</Route>
<Route path='/viewnrndocumentsadmin'>
<Viewnrndocumentsadmin/>
</Route>
<Route path='/viewrndocumentsprog'>
<Viewrndocumentsprog/>
</Route>
<Route path='/addnrndocuments'>
<Addnrndocuments/>
</Route>
<Route path='/addrndocumentsbulkn'>
<Addrndocumentsbulkn />
</Route>
<Route path='/editnrnonteaching'>
<Editnrnonteaching/>
</Route>
<Route path='/viewnrnonteaching'>
<Viewnrnonteaching/>
</Route>
<Route path='/viewnrnonteachingadmin'>
<Viewnrnonteachingadmin/>
</Route>
<Route path='/viewrnonteachingprog'>
<Viewrnonteachingprog/>
</Route>
<Route path='/addnrnonteaching'>
<Addnrnonteaching/>
</Route>
<Route path='/addrnonteachingbulkn'>
<Addrnonteachingbulkn />
</Route>



    <Route path='/editnrinstitute'>
<Editnrinstitute/>
</Route>
<Route path='/viewnrinstitute'>
<Viewnrinstitute/>
</Route>
<Route path='/viewnrinstituteadmin'>
<Viewnrinstituteadmin/>
</Route>
<Route path='/viewrinstituteprog'>
<Viewrinstituteprog/>
</Route>
<Route path='/addnrinstitute'>
<Addnrinstitute/>
</Route>
<Route path='/addrinstitutebulkn'>
<Addrinstitutebulkn />
</Route>



    <Route path='/editnrapplication'>
<Editnrapplication/>
</Route>
<Route path='/viewnrapplication'>
<Viewnrapplication/>
</Route>
<Route path='/viewnrapplicationadmin'>
<Viewnrapplicationadmin/>
</Route>
<Route path='/viewrapplicationprog'>
<Viewrapplicationprog/>
</Route>
<Route path='/addnrapplication'>
<Addnrapplication/>
</Route>
<Route path='/addrapplicationbulkn'>
<Addrapplicationbulkn />
</Route>
<Route path='/editnrtrust'>
<Editnrtrust/>
</Route>
<Route path='/viewnrtrust'>
<Viewnrtrust/>
</Route>
<Route path='/viewnrtrustadmin'>
<Viewnrtrustadmin/>
</Route>
<Route path='/viewrtrustprog'>
<Viewrtrustprog/>
</Route>
<Route path='/addnrtrust'>
<Addnrtrust/>
</Route>
<Route path='/addrtrustbulkn'>
<Addrtrustbulkn />
</Route>


    <Route path='/editnopencourse'>
<Editnopencourse/>
</Route>
<Route path='/viewnopencourse'>
<Viewnopencourse/>
</Route>
<Route path='/viewnopencourseadmin'>
<Viewnopencourseadmin/>
</Route>
<Route path='/viewopencourseprog'>
<Viewopencourseprog/>
</Route>
<Route path='/addnopencourse'>
<Addnopencourse/>
</Route>
<Route path='/addopencoursebulkn'>
<Addopencoursebulkn />
</Route>
<Route path='/editnopenmodules'>
<Editnopenmodules/>
</Route>
<Route path='/viewnopenmodules'>
<Viewnopenmodules/>
</Route>
<Route path='/viewnopenmodulesadmin'>
<Viewnopenmodulesadmin/>
</Route>
<Route path='/viewopenmodulesprog'>
<Viewopenmodulesprog/>
</Route>
<Route path='/addnopenmodules'>
<Addnopenmodules/>
</Route>
<Route path='/addopenmodulesbulkn'>
<Addopenmodulesbulkn />
</Route>


    <Route path='/viewpayslipdetailsbyid'>
<Viewpayslipdetailsbyid/>
</Route>
    <Route path='/viewnpayslipdetailsadmiid'>
<Viewnpayslipdetailsadmiid/>
</Route>
    <Route path='/editnpayslips'>
<Editnpayslips/>
</Route>
<Route path='/viewnpayslips'>
<Viewnpayslips/>
</Route>
<Route path='/viewnpayslipsadmin'>
<Viewnpayslipsadmin/>
</Route>
<Route path='/viewpayslipsprog'>
<Viewpayslipsprog/>
</Route>
<Route path='/addnpayslips'>
<Addnpayslips/>
</Route>
<Route path='/addpayslipsbulkn'>
<Addpayslipsbulkn />
</Route>
<Route path='/editnpayslipdetails'>
<Editnpayslipdetails/>
</Route>
<Route path='/viewnpayslipdetails'>
<Viewnpayslipdetails/>
</Route>
<Route path='/viewnpayslipdetailsadmin'>
<Viewnpayslipdetailsadmin/>
</Route>
<Route path='/viewpayslipdetailsprog'>
<Viewpayslipdetailsprog/>
</Route>
<Route path='/addnpayslipdetails'>
<Addnpayslipdetails/>
</Route>
<Route path='/addpayslipdetailsbulkn'>
<Addpayslipdetailsbulkn />
</Route>


    <Route path='/viewexplearningprojlinks'>
<Viewexplearningprojlinks/>
</Route>
    <Route path='/viewplacementdoclinks'>
<Viewplacementdoclinks/>
</Route>
    <Route path='/viewphdguidedoclinks'>
<Viewphdguidedoclinks/>
</Route>
    <Route path='/viewnphdfacadminlinks'>
<Viewnphdfacadminlinks/>
</Route>
    <Route path='/viewconsultancydlinks'>
<Viewconsultancydlinks/>
</Route>
    <Route path='/viewcollabdoccommentsdlinks'>
<Viewcollabdoccommentsdlinks/>
</Route>
    <Route path='/viewnseedmadminlinks'>
<Viewnseedmadminlinks/>
</Route>
    <Route path='/viewqualityinitdlinks'>
<Viewqualityinitdlinks/>
</Route>
    <Route path='/viewqualitydlinks'>
<Viewqualitydlinks/>
</Route>
    <Route path='/viewprojectyrdocsdlinks'>
<Viewprojectyrdocsdlinks/>
</Route>
    <Route path='/viewneventadmintype'>
<Viewneventadmintype/>
</Route>
<Route path='/viewneventadmintypelinks'>
<Viewneventadmintypelinks/>
</Route>
    <Route path='/selecttype'>
<Selecttype/>
</Route>
    <Route path='/viewnmenteesadminlinks'>
<Viewnmenteesadminlinks/>
</Route>
    <Route path='/viewnotherstudadminlinks'>
<Viewnotherstudadminlinks/>
</Route>
    <Route path='/viewninteradminlinks'>
<Viewninteradminlinks/>
</Route>
    <Route path='/viewnnpatientsadminlinks'>
<Viewnnpatientsadminlinks/>
</Route>
    <Route path='/viewextawardsdlinks'>
<Viewextawardsdlinks/>
</Route>
    <Route path='/viewnrevaladminlinks'>
<Viewnrevaladminlinks/>
</Route>
    <Route path='/viewfacultycadre'>
<Viewfacultycadre/>
</Route>
    <Route path='/viewsfrapi'>
<Viewsfrapi/>
</Route>
    <Route path='/viewadmissionperfapi'>
<Viewadmissionperfapi/>
</Route>
    <Route path='/viewacadapi'>
<Viewacadapi/>
</Route>
    <Route path='/editnacadperf'>
<Editnacadperf/>
</Route>
<Route path='/viewnacadperf'>
<Viewnacadperf/>
</Route>
<Route path='/viewnacadperfadmin'>
<Viewnacadperfadmin/>
</Route>
<Route path='/viewacadperfprog'>
<Viewacadperfprog/>
</Route>
<Route path='/addnacadperf'>
<Addnacadperf/>
</Route>
<Route path='/addacadperfbulkn'>
<Addacadperfbulkn />
</Route>

<Route path='/viewplacementindex1'>
<Viewplacementindex1/>
</Route>
    <Route path='/viewplacementindex'>
<Viewplacementindex/>
</Route>
    <Route path='/editnstartup'>
<Editnstartup/>
</Route>
<Route path='/viewnstartup'>
<Viewnstartup/>
</Route>
<Route path='/viewnstartupadmin'>
<Viewnstartupadmin/>
</Route>
<Route path='/viewstartupprog'>
<Viewstartupprog/>
</Route>
<Route path='/addnstartup'>
<Addnstartup/>
</Route>
<Route path='/addstartupbulkn'>
<Addstartupbulkn />
</Route>


    <Route path='/viewnfacilitiesadminlinksnew'>
<Viewnfacilitiesadminlinksnew/>
</Route>
    <Route path='/viewsyllabusrevdocsdt'>
<Viewsyllabusrevdocsdt/>
</Route>
    <Route path='/viewnnursing424adminlinks'>
<Viewnnursing424adminlinks/>
</Route>
    <Route path='/editnstudmasternew'>
<Editnstudmasternew/>
</Route>
<Route path='/viewnstudmasternew'>
<Viewnstudmasternew/>
</Route>
<Route path='/viewnstudmasternewadmin'>
<Viewnstudmasternewadmin/>
</Route>
<Route path='/viewstudmasternewprog'>
<Viewstudmasternewprog/>
</Route>
<Route path='/addnstudmasternew'>
<Addnstudmasternew/>
</Route>
<Route path='/addstudmasternewbulkn'>
<Addstudmasternewbulkn />
</Route>

    <Route path='/viewnalumniconlinks'>
<Viewnalumniconlinks/>
</Route>
    <Route path='/viewnteacherfsadminlinks'>
<Viewnteacherfsadminlinks/>
</Route>
    <Route path='/editnlabsafety'>
<Editnlabsafety/>
</Route>
<Route path='/viewnlabsafety'>
<Viewnlabsafety/>
</Route>
<Route path='/viewnlabsafetyadmin'>
<Viewnlabsafetyadmin/>
</Route>
<Route path='/viewlabsafetyprog'>
<Viewlabsafetyprog/>
</Route>
<Route path='/addnlabsafety'>
<Addnlabsafety/>
</Route>
<Route path='/addlabsafetybulkn'>
<Addlabsafetybulkn />
</Route>
<Route path='/editnlabequipments'>
<Editnlabequipments/>
</Route>
<Route path='/viewnlabequipments'>
<Viewnlabequipments/>
</Route>
<Route path='/viewnlabequipmentsadmin'>
<Viewnlabequipmentsadmin/>
</Route>
<Route path='/viewlabequipmentsprog'>
<Viewlabequipmentsprog/>
</Route>
<Route path='/addnlabequipments'>
<Addnlabequipments/>
</Route>
<Route path='/addlabequipmentsbulkn'>
<Addlabequipmentsbulkn />
</Route>
<Route path='/editnprogrambudget'>
<Editnprogrambudget/>
</Route>
<Route path='/viewnprogrambudget'>
<Viewnprogrambudget/>
</Route>
<Route path='/viewnprogrambudgetadmin'>
<Viewnprogrambudgetadmin/>
</Route>
<Route path='/viewprogrambudgetprog'>
<Viewprogrambudgetprog/>
</Route>
<Route path='/addnprogrambudget'>
<Addnprogrambudget/>
</Route>
<Route path='/addprogrambudgetbulkn'>
<Addprogrambudgetbulkn />
</Route>
<Route path='/editninternet'>
<Editninternet/>
</Route>
<Route path='/viewninternet'>
<Viewninternet/>
</Route>
<Route path='/viewninternetadmin'>
<Viewninternetadmin/>
</Route>
<Route path='/viewinternetprog'>
<Viewinternetprog/>
</Route>
<Route path='/addninternet'>
<Addninternet/>
</Route>
<Route path='/addinternetbulkn'>
<Addinternetbulkn />
</Route>
<Route path='/editnemfaculty'>
<Editnemfaculty/>
</Route>
<Route path='/viewnemfaculty'>
<Viewnemfaculty/>
</Route>
<Route path='/viewnemfacultyadmin'>
<Viewnemfacultyadmin/>
</Route>
<Route path='/viewemfacultyprog'>
<Viewemfacultyprog/>
</Route>
<Route path='/addnemfaculty'>
<Addnemfaculty/>
</Route>
<Route path='/addemfacultybulkn'>
<Addemfacultybulkn />
</Route>
<Route path='/editnstudentpubs'>
<Editnstudentpubs/>
</Route>
<Route path='/viewnstudentpubs'>
<Viewnstudentpubs/>
</Route>
<Route path='/viewnstudentpubsadmin'>
<Viewnstudentpubsadmin/>
</Route>
<Route path='/viewstudentpubsprog'>
<Viewstudentpubsprog/>
</Route>
<Route path='/addnstudentpubs'>
<Addnstudentpubs/>
</Route>
<Route path='/addstudentpubsbulkn'>
<Addstudentpubsbulkn />
</Route>
<Route path='/editndeppublications'>
<Editndeppublications/>
</Route>
<Route path='/viewndeppublications'>
<Viewndeppublications/>
</Route>
<Route path='/viewndeppublicationsadmin'>
<Viewndeppublicationsadmin/>
</Route>
<Route path='/viewdeppublicationsprog'>
<Viewdeppublicationsprog/>
</Route>
<Route path='/addndeppublications'>
<Addndeppublications/>
</Route>
<Route path='/adddeppublicationsbulkn'>
<Adddeppublicationsbulkn />
</Route>
<Route path='/editnfacyear'>
<Editnfacyear/>
</Route>
<Route path='/viewnfacyear'>
<Viewnfacyear/>
</Route>
<Route path='/viewnfacyearadmin'>
<Viewnfacyearadmin/>
</Route>
<Route path='/viewfacyearprog'>
<Viewfacyearprog/>
</Route>
<Route path='/addnfacyear'>
<Addnfacyear/>
</Route>
<Route path='/addfacyearbulkn'>
<Addfacyearbulkn />
</Route>
<Route path='/editnstudmaster'>
<Editnstudmaster/>
</Route>
<Route path='/viewnstudmaster'>
<Viewnstudmaster/>
</Route>
<Route path='/viewnstudmasteradmin'>
<Viewnstudmasteradmin/>
</Route>
<Route path='/viewstudmasterprog'>
<Viewstudmasterprog/>
</Route>
<Route path='/addnstudmaster'>
<Addnstudmaster/>
</Route>
<Route path='/addstudmasterbulkn'>
<Addstudmasterbulkn />
</Route>
<Route path='/editnseedm'>
<Editnseedm/>
</Route>
<Route path='/viewnseedm'>
<Viewnseedm/>
</Route>
<Route path='/viewnseedmadmin'>
<Viewnseedmadmin/>
</Route>
<Route path='/viewseedmprog'>
<Viewseedmprog/>
</Route>
<Route path='/addnseedm'>
<Addnseedm/>
</Route>
<Route path='/addseedmbulkn'>
<Addseedmbulkn />
</Route>
{/* <Route path='/editnplacement'>
<Editnplacement/>
</Route>
<Route path='/viewnplacement'>
<Viewnplacement/>
</Route>
<Route path='/viewnplacementadmin'>
<Viewnplacementadmin/>
</Route>
<Route path='/viewplacementprog'>
<Viewplacementprog/>
</Route>
<Route path='/addnplacement'>
<Addnplacement/>
</Route>
<Route path='/addplacementbulkn'>
<Addplacementbulkn />
</Route> */}



    <Route path='/viewneventlinks'>
<Viewneventlinks/>
</Route>
    <Route path='/editnteacherfs'>
<Editnteacherfs/>
</Route>
<Route path='/viewnteacherfs'>
<Viewnteacherfs/>
</Route>
<Route path='/viewnteacherfsadmin'>
<Viewnteacherfsadmin/>
</Route>
<Route path='/viewteacherfsprog'>
<Viewteacherfsprog/>
</Route>
<Route path='/addnteacherfs'>
<Addnteacherfs/>
</Route>
<Route path='/addteacherfsbulkn'>
<Addteacherfsbulkn />
</Route>




    <Route path='/editnalumnicon'>
<Editnalumnicon/>
</Route>
<Route path='/viewnalumnicon'>
<Viewnalumnicon/>
</Route>
<Route path='/viewnalumniconadmin'>
<Viewnalumniconadmin/>
</Route>
<Route path='/viewalumniconprog'>
<Viewalumniconprog/>
</Route>
<Route path='/addnalumnicon'>
<Addnalumnicon/>
</Route>
<Route path='/addalumniconbulkn'>
<Addalumniconbulkn />
</Route>
<Route path='/editnevent'>
<Editnevent/>
</Route>
<Route path='/viewnevent'>
<Viewnevent/>
</Route>
<Route path='/viewneventadmin'>
<Viewneventadmin/>
</Route>
<Route path='/vieweventprog'>
<Vieweventprog/>
</Route>
<Route path='/addnevent'>
<Addnevent/>
</Route>
<Route path='/addeventbulkn'>
<Addeventbulkn />
</Route>



    <Route path='/editnmentees'>
<Editnmentees/>
</Route>
<Route path='/viewnmentees'>
<Viewnmentees/>
</Route>
<Route path='/viewnmenteesadmin'>
<Viewnmenteesadmin/>
</Route>
<Route path='/viewmenteesprog'>
<Viewmenteesprog/>
</Route>
<Route path='/addnmentees'>
<Addnmentees/>
</Route>
<Route path='/addmenteesbulkn'>
<Addmenteesbulkn />
</Route>


    <Route path='/editnempexpenses'>
<Editnempexpenses/>
</Route>
<Route path='/viewnempexpenses'>
<Viewnempexpenses/>
</Route>
<Route path='/viewnempexpensesadmin'>
<Viewnempexpensesadmin/>
</Route>
<Route path='/viewempexpensesprog'>
<Viewempexpensesprog/>
</Route>
<Route path='/addnempexpenses'>
<Addnempexpenses/>
</Route>
<Route path='/addempexpensesbulkn'>
<Addempexpensesbulkn />
</Route>
<Route path='/editneventexpenses'>
<Editneventexpenses/>
</Route>
<Route path='/viewneventexpenses'>
<Viewneventexpenses/>
</Route>
<Route path='/viewneventexpensesadmin'>
<Viewneventexpensesadmin/>
</Route>
<Route path='/vieweventexpensesprog'>
<Vieweventexpensesprog/>
</Route>
<Route path='/addneventexpenses'>
<Addneventexpenses/>
</Route>
<Route path='/addeventexpensesbulkn'>
<Addeventexpensesbulkn />
</Route>


    <Route path='/editnnmetricissues'>
<Editnnmetricissues/>
</Route>
<Route path='/viewnnmetricissues'>
<Viewnnmetricissues/>
</Route>
<Route path='/viewnnmetricissuesadmin'>
<Viewnnmetricissuesadmin/>
</Route>
<Route path='/viewnmetricissuesprog'>
<Viewnmetricissuesprog/>
</Route>
<Route path='/addnnmetricissues'>
<Addnnmetricissues/>
</Route>
<Route path='/addnmetricissuesbulkn'>
<Addnmetricissuesbulkn />
</Route>


    <Route path='/editnteacherdata'>
<Editnteacherdata/>
</Route>
<Route path='/viewnteacherdata'>
<Viewnteacherdata/>
</Route>
<Route path='/viewnteacherdataadmin'>
<Viewnteacherdataadmin/>
</Route>
<Route path='/viewteacherdataprog'>
<Viewteacherdataprog/>
</Route>
<Route path='/addnteacherdata'>
<Addnteacherdata/>
</Route>
<Route path='/addteacherdatabulkn'>
<Addteacherdatabulkn />
</Route>
<Route path='/editnresult'>
<Editnresult/>
</Route>
<Route path='/viewnresult'>
<Viewnresult/>
</Route>
<Route path='/viewnresultadmin'>
<Viewnresultadmin/>
</Route>
<Route path='/viewresultprog'>
<Viewresultprog/>
</Route>
<Route path='/addnresult'>
<Addnresult/>
</Route>
<Route path='/addresultbulkn'>
<Addresultbulkn />
</Route>
<Route path='/editnphdface'>
<Editnphdface/>
</Route>
<Route path='/viewnphdface'>
<Viewnphdface/>
</Route>
<Route path='/viewnphdfaceadmin'>
<Viewnphdfaceadmin/>
</Route>
<Route path='/viewphdfaceprog'>
<Viewphdfaceprog/>
</Route>
<Route path='/addnphdface'>
<Addnphdface/>
</Route>
<Route path='/addphdfacebulkn'>
<Addphdfacebulkn />
</Route>



    <Route path='/viewemployabilitys'>
<Viewemployabilitys/>
</Route>
<Route path='/editemployabilitys'>
<Editemployabilitys/>
</Route>

    <Route path='/editnphdfac'>
<Editnphdfac/>
</Route>
<Route path='/viewnphdfac'>
<Viewnphdfac/>
</Route>
<Route path='/viewnphdfacadmin'>
<Viewnphdfacadmin/>
</Route>
<Route path='/viewphdfacprog'>
<Viewphdfacprog/>
</Route>
<Route path='/addnphdfac'>
<Addnphdfac/>
</Route>
<Route path='/addphdfacbulkn'>
<Addphdfacbulkn />
</Route>



    <Route path='/editnreservecat'>
<Editnreservecat/>
</Route>
<Route path='/viewnreservecat'>
<Viewnreservecat/>
</Route>
<Route path='/viewnreservecatadmin'>
<Viewnreservecatadmin/>
</Route>
<Route path='/viewreservecatprog'>
<Viewreservecatprog/>
</Route>
<Route path='/addnreservecat'>
<Addnreservecat/>
</Route>
<Route path='/addreservecatbulkn'>
<Addreservecatbulkn />
</Route>

    <Route path='/editnhigheredu'>
<Editnhigheredu/>
</Route>
<Route path='/viewnhigheredu'>
<Viewnhigheredu/>
</Route>
<Route path='/viewnhighereduadmin'>
<Viewnhighereduadmin/>
</Route>
<Route path='/viewhighereduprog'>
<Viewhighereduprog/>
</Route>
<Route path='/addnhigheredu'>
<Addnhigheredu/>
</Route>
<Route path='/addhigheredubulkn'>
<Addhigheredubulkn />
</Route>
<Route path='/editnhigherexam'>
<Editnhigherexam/>
</Route>
<Route path='/viewnhigherexam'>
<Viewnhigherexam/>
</Route>
<Route path='/viewnhigherexamadmin'>
<Viewnhigherexamadmin/>
</Route>
<Route path='/viewhigherexamprog'>
<Viewhigherexamprog/>
</Route>
<Route path='/addnhigherexam'>
<Addnhigherexam/>
</Route>
<Route path='/addhigherexambulkn'>
<Addhigherexambulkn />
</Route>


    <Route path='/bulkupdatecomments'>
<Bulkupdatecomments/>
</Route>
    <Route path='/editnmeetingtaskssuper'>
<Editnmeetingtaskssuper/>
</Route>
    <Route path='/editnadmission'>
<Editnadmission/>
</Route>
<Route path='/viewnadmission'>
<Viewnadmission/>
</Route>
<Route path='/viewnadmissionadmin'>
<Viewnadmissionadmin/>
</Route>
<Route path='/addnadmission'>
<Addnadmission/>
</Route>
<Route path='/addadmissionbulkn'>
<Addadmissionbulkn />
</Route>
<Route path='/editnpassexam'>
<Editnpassexam/>
</Route>
<Route path='/viewnpassexam'>
<Viewnpassexam/>
</Route>
<Route path='/viewnpassexamadmin'>
<Viewnpassexamadmin/>
</Route>
<Route path='/addnpassexam'>
<Addnpassexam/>
</Route>
<Route path='/addpassexambulkn'>
<Addpassexambulkn />
</Route>
<Route path='/editnplacement'>
<Editnplacement/>
</Route>
<Route path='/viewnplacement'>
<Viewnplacement/>
</Route>
<Route path='/viewnplacementadmin'>
<Viewnplacementadmin/>
</Route>
<Route path='/addnplacement'>
<Addnplacement/>
</Route>
<Route path='/addplacementbulkn'>
<Addplacementbulkn />
</Route>





    <Route path='/generatereport'>
<Generatereport/>
</Route>
    <Route path='/selectreporttype'>
<Selectreporttype/>
</Route>
    <Route path='/viewadmissionprog'>
<Viewadmissionprog/>
</Route>
<Route path='/viewpassexamprog'>
<Viewpassexamprog/>
</Route>
<Route path='/viewplacementprog'>
<Viewplacementprog/>
</Route>

    <Route path='/addpassexambulk'>
<Addpassexambulk />
</Route>
<Route path='/editnbaug33result'>
<Editnbaug33result/>
</Route>
<Route path='/viewnbaug33result'>
<Viewnbaug33result/>
</Route>
<Route path='/viewnbaug33resultadmin'>
<Viewnbaug33resultadmin/>
</Route>
<Route path='/viewnbaug33resultprog'>
<Viewnbaug33resultprog/>
</Route>
<Route path='/addnbaug33result'>
<Addnbaug33result/>
</Route>
<Route path='/addnbaug33resultbulk'>
<Addnbaug33resultbulk />
</Route>

<Route path='/editwriterdb'>
<Editwriterdb/>
</Route>
<Route path='/viewwriterdb'>
<Viewwriterdb/>
</Route>
<Route path='/viewwriterdbadmin'>
<Viewwriterdbadmin/>
</Route>
<Route path='/viewwriterdbprog'>
<Viewwriterdbprog/>
</Route>
<Route path='/addwriterdb'>
<Addwriterdb/>
</Route>
<Route path='/addwriterdbbulk'>
<Addwriterdbbulk />
</Route>

    <Route path='/viewfundslinkssr'>
<Viewfundslinkssr/>
</Route>
    <Route path='/viewlibraryexpenlinksssr'>
<Viewlibraryexpenlinksssr/>
</Route>
    <Route path='/viewexpenditurelinksssr'>
<Viewexpenditurelinksssr/>
</Route>
    <Route path='/viewextensionactlinksssr'>
<Viewextensionactlinksssr/>
</Route>
    <Route path='/viewallpatentlinksssr'>
<Viewallpatentlinksssr/>
</Route>
    <Route path='/viewallbooklinksssr'>
<Viewallbooklinksssr/>
</Route>
    <Route path='/viewsportsactlinks'>
<Viewsportsactlinks/>
</Route>
    <Route path='/viewresultlinks'>
<Viewresultlinks/>
</Route>
    <Route path='/viewecontentlinks'>
<Viewecontentlinks/>
</Route>
    <Route path='/viewexamautolinks'>
<Viewexamautolinks/>
</Route>
    <Route path='/viewteacherdatalinks'>
<Viewteacherdatalinks/>
</Route>
    <Route path='/viewpassexamlinks'>
<Viewpassexamlinks/>
</Route>
    <Route path='/viewseedmlinks'>
<Viewseedmlinks/>
</Route>
    <Route path='/viewteacherfellowlinks'>
<Viewteacherfellowlinks/>
</Route>
    <Route path='/viewresearchfellowlinks'>
<Viewresearchfellowlinks/>
</Route>
    <Route path='/viewallseminarlinksssr'>
<Viewallseminarlinksssr/>
</Route>
    <Route path='/viewawardsstudlinks'>
<Viewawardsstudlinks/>
</Route>
    <Route path='/viewinnovationlinks'>
<Viewinnovationlinks/>
</Route>
    <Route path='/viewexplearninglinksssr'>
<Viewexplearninglinksssr/>
</Route>
    <Route path='/viewemployabilitylinksssr'>
<Viewemployabilitylinksssr/>
</Route>
    <Route path='/viewsyllabusrevlinksssr'>
<Viewsyllabusrevlinksssr/>
</Route>
    <Route path='/adminhumetric'>
<Adminhumetric/>
</Route>
    <Route path='/editverifystatus'>
<Editverifystatus/>
</Route>
<Route path='/viewverifystatus'>
<Viewverifystatus/>
</Route>
<Route path='/viewverifystatusadmin'>
<Viewverifystatusadmin/>
</Route>
<Route path='/viewverifystatusprog'>
<Viewverifystatusprog/>
</Route>
<Route path='/addverifystatus'>
<Addverifystatus/>
</Route>
<Route path='/addverifystatusbulk'>
<Addverifystatusbulk />
</Route>
<Route path='/editnissuesall'>
<Editnissuesall/>
</Route>
<Route path='/viewnissuesall'>
<Viewnissuesall/>
</Route>
<Route path='/viewnissuesalladmin'>
<Viewnissuesalladmin/>
</Route>
<Route path='/viewnissuesallprog'>
<Viewnissuesallprog/>
</Route>
<Route path='/addnissuesall'>
<Addnissuesall/>
</Route>
<Route path='/addnissuesallbulk'>
<Addnissuesallbulk />
</Route>
<Route path='/editntickets'>
<Editntickets/>
</Route>
<Route path='/viewntickets'>
<Viewntickets/>
</Route>
<Route path='/viewnticketsadmin'>
<Viewnticketsadmin/>
</Route>
<Route path='/viewnticketsprog'>
<Viewnticketsprog/>
</Route>
<Route path='/addntickets'>
<Addntickets/>
</Route>
<Route path='/addnticketsbulk'>
<Addnticketsbulk />
</Route>




    <Route path='/viewnmeetingtasksallclients'>
<Viewnmeetingtasksallclients/>
</Route>
    <Route path='/viewnmeetingtaskspending'>
<Viewnmeetingtaskspending/>
</Route>
    <Route path='/viewhumetric'>
<Viewhumetric/>
</Route>
    <Route path='/vieweventlinksbyuser'>
<Vieweventlinksbyuser/>
</Route>
    <Route path='/viewexplearnuaqarlinks'>
<Viewexplearnuaqarlinks/>
</Route>
<Route path='/viewexplearnuaqar'>
<Viewexplearnuaqar/>
</Route>
    <Route path='/viewnclinicskillsadminlinks'>
<Viewnclinicskillsadminlinks/>
</Route>
    <Route path='/viewnhlearneradminlinks'>
<Viewnhlearneradminlinks/>
</Route>
    <Route path='/viewnnratiolinks'>
<Viewnnratiolinks/>
</Route>
    <Route path='/viewnnursing818adminlinks'>
<Viewnnursing818adminlinks/>
</Route>
    <Route path='/viewnnursing814links'>
<Viewnnursing814links/>
</Route>
    <Route path='/viewnnursing812links'>
<Viewnnursing812links/>
</Route>
    <Route path='/viewegovlinks'>
<Viewegovlinks/>
</Route>
    <Route path='/viewaddonlinks1'>
<Viewaddonlinks1/>
</Route>
    <Route path='/viewnbaaug11adminnewlinks'>
<Viewnbaaug11adminnewlinks/>
</Route>
    <Route path='/viewnbaaug11adminnew'>
<Viewnbaaug11adminnew/>
</Route>
    <Route path='/editnallcourses'>
<Editnallcourses/>
</Route>
<Route path='/viewnallcourses'>
<Viewnallcourses/>
</Route>
<Route path='/viewnallcoursesadmin'>
<Viewnallcoursesadmin/>
</Route>
<Route path='/viewnallcoursesprog'>
<Viewnallcoursesprog/>
</Route>
<Route path='/addnallcourses'>
<Addnallcourses/>
</Route>
<Route path='/addnallcoursesbulk'>
<Addnallcoursesbulk />
</Route>

    <Route path='/editndepmeetingsall'>
<Editndepmeetingsall/>
</Route>
<Route path='/viewndepmeetingsall'>
<Viewndepmeetingsall/>
</Route>
<Route path='/viewndepmeetingsalladmin'>
<Viewndepmeetingsalladmin/>
</Route>
<Route path='/addndepmeetingsall'>
<Addndepmeetingsall/>
</Route>
<Route path='/addndepmeetingsallbulk'>
<Addndepmeetingsallbulk />
</Route>
<Route path='/editnmeetingtasksall'>
<Editnmeetingtasksall/>
</Route>
<Route path='/viewnmeetingtasksall'>
<Viewnmeetingtasksall/>
</Route>
<Route path='/viewnmeetingtasksalladmin'>
<Viewnmeetingtasksalladmin/>
</Route>
<Route path='/addnmeetingtasksall'>
<Addnmeetingtasksall/>
</Route>
<Route path='/addnmeetingtasksallbulk'>
<Addnmeetingtasksallbulk />
</Route>


    <Route path='/viewnirfexpenditure'>
<Viewnirfexpenditure/>
</Route>
    <Route path='/viewnirfreimbursement'>
<Viewnirfreimbursement/>
</Route>
    <Route path='/viewnirflibraryexp'>
<Viewnirflibraryexp/>
</Route>
    <Route path='/viewnirfplacement'>
<Viewnirfplacement/>
</Route>
    <Route path='/viewnirfpass'>
<Viewnirfpass/>
</Route>
    <Route path='/viewnirfadmission'>
<Viewnirfadmission/>
</Route>
    <Route path='/viewkpiuserprint'>
<Viewkpiuserprint/>
</Route>
    <Route path='/modifysyllabusrev'>
<Modifysyllabusrev/>
</Route>
    <Route path='/addsyllabusrevupdate'>
<Addsyllabusrevupdate/>
</Route>
    <Route path='/viewsyllabusrevlinks'>
<Viewsyllabusrevlinks/>
</Route>
    <Route path='/viewhigheredulinksssr'>
<Viewhigheredulinksssr/>
</Route>
    <Route path='/viewmoulinksssr'>
<Viewmoulinksssr/>
</Route>
    <Route path='/editndepmeetings'>
<Editndepmeetings/>
</Route>
<Route path='/viewndepmeetings'>
<Viewndepmeetings/>
</Route>
<Route path='/viewndepmeetingsadmin'>
<Viewndepmeetingsadmin/>
</Route>
<Route path='/addndepmeetings'>
<Addndepmeetings/>
</Route>
<Route path='/addndepmeetingsbulk'>
<Addndepmeetingsbulk />
</Route>
<Route path='/editnmeetingtasks'>
<Editnmeetingtasks/>
</Route>
<Route path='/viewnmeetingtasks'>
<Viewnmeetingtasks/>
</Route>
<Route path='/viewnmeetingtasksadmin'>
<Viewnmeetingtasksadmin/>
</Route>
<Route path='/addnmeetingtasks'>
<Addnmeetingtasks/>
</Route>
<Route path='/addnmeetingtasksbulk'>
<Addnmeetingtasksbulk />
</Route>




    <Route path='/viewteacherfslinks'>
<Viewteacherfslinks/>
</Route>
    <Route path='/viewallsemreimlins'>
<Viewallsemreimlinks/>
</Route>
    <Route path='/viewphdguidelinksssr'>
<Viewphdguidelinksssr/>
</Route>
    <Route path='/viewteacherawardlinks'>
<Viewteacherawardlinks/>
</Route>
    <Route path='/viewhigherexamlinksssr'>
<Viewhigherexamlinksssr/>
</Route>
    <Route path='/viewplacementlinksssr'>
<Viewplacementlinksssr/>
</Route>
    <Route path='/viewnnursing515links'>
<Viewnnursing515links/>
</Route>
    <Route path='/viewcareerlinks'>
<Viewcareerlinks/>
</Route>
    <Route path='/viewscholarshiplinks'>
<Viewscholarshiplinks/>
</Route>
    <Route path='/viewskilldevlinksssr'>
<Viewskilldevlinksssr/>
</Route>
    <Route path='/vieweventlinksssr'>
<Vieweventlinksssr/>
</Route>
    <Route path='/viewictlinks'>
<Viewictlinks/>
</Route>
    <Route path='/viewallpublinksssr'>
<Viewallpublinksssr/>
</Route>
    <Route path='/viewcollablinks'>
<Viewcollablinks/>
</Route>
    <Route path='/viewadmissionlinksssr'>
<Viewadmissionlinksssr/>
</Route>
    <Route path='/viewboslinks'>
<Viewboslinks/>
</Route>
    <Route path='/viewaddonlinks'>
<Viewaddonlinks/>
</Route>
    <Route path='/viewnfacilitiesadminlinks'>
<Viewnfacilitiesadminlinks/>
</Route>
    <Route path='/viewnnursinginteradminlinks'>
<Viewnnursinginteradminlinks/>
</Route>
    <Route path='/viewnprogcourseadminlinks'>
<Viewnprogcourseadminlinks/>
</Route>
    <Route path='/editnnursing812'>
<Editnnursing812/>
</Route>
<Route path='/viewnnursing812'>
<Viewnnursing812/>
</Route>
<Route path='/viewnnursing812admin'>
<Viewnnursing812admin/>
</Route>
<Route path='/addnnursing812'>
<Addnnursing812/>
</Route>
<Route path='/addnnursing812bulk'>
<Addnnursing812bulk />
</Route>
<Route path='/editnnursing814'>
<Editnnursing814/>
</Route>
<Route path='/viewnnursing814'>
<Viewnnursing814/>
</Route>
<Route path='/viewnnursing814admin'>
<Viewnnursing814admin/>
</Route>
<Route path='/addnnursing814'>
<Addnnursing814/>
</Route>
<Route path='/addnnursing814bulk'>
<Addnnursing814bulk />
</Route>
<Route path='/editnnursing815'>
<Editnnursing815/>
</Route>
<Route path='/viewnnursing815'>
<Viewnnursing815/>
</Route>
<Route path='/viewnnursing815admin'>
<Viewnnursing815admin/>
</Route>
<Route path='/addnnursing815'>
<Addnnursing815/>
</Route>
<Route path='/addnnursing815bulk'>
<Addnnursing815bulk />
</Route>
<Route path='/editnnursing818'>
<Editnnursing818/>
</Route>
<Route path='/viewnnursing818'>
<Viewnnursing818/>
</Route>
<Route path='/viewnnursing818admin'>
<Viewnnursing818admin/>
</Route>
<Route path='/addnnursing818'>
<Addnnursing818/>
</Route>
<Route path='/addnnursing818bulk'>
<Addnnursing818bulk />
</Route>
<Route path='/editnnursing424'>
<Editnnursing424/>
</Route>
<Route path='/viewnnursing424'>
<Viewnnursing424/>
</Route>
<Route path='/viewnnursing424admin'>
<Viewnnursing424admin/>
</Route>
<Route path='/addnnursing424'>
<Addnnursing424/>
</Route>
<Route path='/addnnursing424bulk'>
<Addnnursing424bulk />
</Route>
<Route path='/editncompetitive'>
<Editncompetitive/>
</Route>
<Route path='/viewncompetitive'>
<Viewncompetitive/>
</Route>
<Route path='/viewncompetitiveadmin'>
<Viewncompetitiveadmin/>
</Route>
<Route path='/addncompetitive'>
<Addncompetitive/>
</Route>
<Route path='/addncompetitivebulk'>
<Addncompetitivebulk />
</Route>
<Route path='/editnnursing515'>
<Editnnursing515/>
</Route>
<Route path='/viewnnursing515'>
<Viewnnursing515/>
</Route>
<Route path='/viewnnursing515admin'>
<Viewnnursing515admin/>
</Route>
<Route path='/addnnursing515'>
<Addnnursing515/>
</Route>
<Route path='/addnnursing515bulk'>
<Addnnursing515bulk />
</Route>

    <Route path='/reportcriteria1'>
<Reportcriteria1/>
</Route>
    <Route path='/viewsuperfaculty'>
<Viewsuperfaculty/>
</Route>
    <Route path='/datewisesummaryledger'>
<Datewisesummaryledger/>
</Route>
<Route path='/selectfin'>
<Selectfin/>
</Route>
    <Route path='/editnaccountheads'>
<Editnaccountheads/>
</Route>
<Route path='/viewnaccountheads'>
<Viewnaccountheads/>
</Route>
<Route path='/viewnaccountheadsadmin'>
<Viewnaccountheadsadmin/>
</Route>
<Route path='/addnaccountheads'>
<Addnaccountheads/>
</Route>
<Route path='/addnaccountheadsbulk'>
<Addnaccountheadsbulk />
</Route>
<Route path='/editntransactions'>
<Editntransactions/>
</Route>
<Route path='/viewntransactions'>
<Viewntransactions/>
</Route>
<Route path='/viewntransactionsadmin'>
<Viewntransactionsadmin/>
</Route>
<Route path='/addntransactions'>
<Addntransactions/>
</Route>
<Route path='/addntransactionsbulk'>
<Addntransactionsbulk />
</Route>
<Route path='/editntledger'>
<Editntledger/>
</Route>
<Route path='/viewntledger'>
<Viewntledger/>
</Route>
<Route path='/viewntledgeradmin'>
<Viewntledgeradmin/>
</Route>
<Route path='/addntledger'>
<Addntledger/>
</Route>
<Route path='/addntledgerbulk'>
<Addntledgerbulk />
</Route>




    <Route path='/editaddoncourseadmin'>
<Editaddoncourseadmin/>
</Route>
    <Route path='/editsyllabusrevadmin'>
<Editsyllabusrevadmin/>
</Route>
    <Route path='/viewdepdata'>
<Viewdepdata/>
</Route>
    <Route path='/viewvactodaystud'>
<Viewvactodaystud/>
</Route>
    <Route path='/viewfeedbackdata'>
<Viewfeedbackdata/>
</Route>
    <Route path='/editprojectledger'>
<Editprojectledger/>
</Route>
<Route path='/viewprojectledger'>
<Viewprojectledger/>
</Route>
<Route path='/viewprojectledgeradmin'>
<Viewprojectledgeradmin/>
</Route>
<Route path='/addprojectledger'>
<Addprojectledger/>
</Route>
<Route path='/addprojectledgerbulk'>
<Addprojectledgerbulk />
</Route>
<Route path='/editprojectcentral'>
<Editprojectcentral/>
</Route>
<Route path='/viewprojectcentral'>
<Viewprojectcentral/>
</Route>
<Route path='/viewprojectcentraladmin'>
<Viewprojectcentraladmin/>
</Route>
<Route path='/addprojectcentral'>
<Addprojectcentral/>
</Route>
<Route path='/addprojectcentralbulk'>
<Addprojectcentralbulk />
</Route>
<Route path='/editmaintenance'>
<Editmaintenance/>
</Route>
<Route path='/viewmaintenance'>
<Viewmaintenance/>
</Route>
<Route path='/viewmaintenanceadmin'>
<Viewmaintenanceadmin/>
</Route>
<Route path='/addmaintenance'>
<Addmaintenance/>
</Route>
<Route path='/addmaintenancebulk'>
<Addmaintenancebulk />
</Route>
<Route path='/editotherincome'>
<Editotherincome/>
</Route>
<Route path='/viewotherincome'>
<Viewotherincome/>
</Route>
<Route path='/viewotherincomeadmin'>
<Viewotherincomeadmin/>
</Route>
<Route path='/addotherincome'>
<Addotherincome/>
</Route>
<Route path='/addotherincomebulk'>
<Addotherincomebulk />
</Route>


    <Route path='/classattendancefaccl'>
<Classattendancefaccl/>
</Route>
<Route path='/classattendancestud'>
<Classattendancestud/>
</Route>
    <Route path='/classattendancefac'>
<Classattendancefac/>
</Route>
    <Route path='/addlessonplanbulknew'>
<Addlessonplanbulknew/>
</Route>
    <Route path='/editstudparents'>
<Editstudparents/>
</Route>
<Route path='/viewstudparents'>
<Viewstudparents/>
</Route>
<Route path='/viewstudparentsadmin'>
<Viewstudparentsadmin/>
</Route>
<Route path='/addstudparents'>
<Addstudparents/>
</Route>
<Route path='/addstudparentsbulk'>
<Addstudparentsbulk />
</Route>

    <Route path='/viewcircularstud'>
<Viewcircularstud/>
</Route>
    <Route path='/selectawsconfigstudassignment'>
<Selectawsconfigstudassignment/>
</Route>
<Route path='/addphotostudassignment'>
<Addphotostudassignment/>
</Route>
    <Route path='/viewassignmentstud'>
<Viewassignmentstud/>
</Route>
    <Route path='/editcurrenyear'>
<Editcurrenyear/>
</Route>
<Route path='/viewcurrenyear'>
<Viewcurrenyear/>
</Route>
<Route path='/viewcurrenyearadmin'>
<Viewcurrenyearadmin/>
</Route>
<Route path='/addcurrenyear'>
<Addcurrenyear/>
</Route>
<Route path='/addcurrenyearbulk'>
<Addcurrenyearbulk />
</Route>

    <Route path='/viewcursefilesstud'>
<Viewcoursefilesstud/>
</Route>
    <Route path='/viewenrolcourseyr'>
<Viewenrolcourseyr/>
</Route>
    <Route path='/viewclassprogramstud'>
<Viewclassprogramstud/>
</Route>
    <Route path='/addphotostud'>
<Addphotostud/>
</Route>
    <Route path='/selectawsconfigphotostud'>
<Selectawsconfigphotostud/>
</Route>
    <Route path='/dashstud1'>
<Dashstud2/>
</Route>
    <Route path='/deleteworkload'>
<Deleteworkload/>
</Route>
    <Route path='/viewallfacultieslmsdep'>
<Viewallfacultieslmsdep/>
</Route>
    <Route path='/selectperiodlms'>
<Selectperiodlms/>
</Route>
    <Route path='/selectawsconfigphoto'>
<Selectawsconfigphoto/>
</Route>
    <Route path='/addphoto'>
<Addphoto/>
</Route>
    <Route path='/viewlsyllabusemp'>
<Viewlsyllabusemp/>
</Route>
    <Route path='/edituser'>
<Edituser/>
</Route>
<Route path='/viewuserstudent'>
<Viewuserstudent/>
</Route>
<Route path='/viewuserfaculty'>
<Viewuserfaculty/>
</Route>
<Route path='/viewuserhod'>
<Viewuserhod/>
</Route>
<Route path='/viewuseradmin'>
<Viewuseradmin/>
</Route>
<Route path='/adduser'>
<Adduser/>
</Route>
<Route path='/adduserhod'>
<Adduserhod/>
</Route>
<Route path='/adduserfaculty'>
<Adduserfaculty/>
</Route>
<Route path='/adduserstudent'>
<Adduserstudent/>
</Route>
<Route path='/adduserbulk'>
<Adduserbulk />
</Route>
<Route path='/adduserbulkhod'>
<Adduserbulkhod />
</Route>
<Route path='/adduserbulkstudent'>
<Adduserbulkstudent />
</Route>

    <Route path='/editco'>
<Editco/>
</Route>
<Route path='/viewco'>
<Viewco/>
</Route>
<Route path='/viewcoadmin'>
<Viewcoadmin/>
</Route>
<Route path='/addco'>
<Addco/>
</Route>
<Route path='/addcobulk'>
<Addcobulk />
</Route>
<Route path='/editlannouncement'>
<Editlannouncement/>
</Route>
<Route path='/viewlannouncement'>
<Viewlannouncement/>
</Route>
<Route path='/viewlannouncementadmin'>
<Viewlannouncementadmin/>
</Route>
<Route path='/addlannouncement'>
<Addlannouncement/>
</Route>
<Route path='/addlannouncementbulk'>
<Addlannouncementbulk />
</Route>
<Route path='/editlsyllabus'>
<Editlsyllabus/>
</Route>
<Route path='/viewlsyllabus'>
<Viewlsyllabus/>
</Route>
<Route path='/viewlsyllabusadmin'>
<Viewlsyllabusadmin/>
</Route>
<Route path='/addlsyllabus'>
<Addlsyllabus/>
</Route>
<Route path='/addlsyllabusbulk'>
<Addlsyllabusbulk />
</Route>
<Route path='/editlcalendar'>
<Editlcalendar/>
</Route>
<Route path='/viewlcalendar'>
<Viewlcalendar/>
</Route>
<Route path='/viewlcalendaradmin'>
<Viewlcalendaradmin/>
</Route>
<Route path='/addlcalendar'>
<Addlcalendar/>
</Route>
<Route path='/addlcalendarbulk'>
<Addlcalendarbulk />
</Route>




    <Route path='/viewworkloadadminfac'>
<Viewworkloadadminfac/>
</Route>
<Route path='/viewallfacultieslms'>
<Viewallfacultieslms/>
</Route>
    <Route path='/viewclassseven'>
<Viewclassseven/>
</Route>
    <Route path='/viewclassprogram'>
<Viewclassprogram/>
</Route>
<Route path='/selectprogramstt'>
<Selectprogramstt/>
</Route>
<Route path='/selectsemsecyr'>
<Selectsemsecyr/>
</Route>
    <Route path='/addclassnewweeks'>
<Addclassnewweeks/>
</Route>
    <Route path='/editcoursefiles'>
<Editcoursefiles/>
</Route>
<Route path='/viewcoursefiles'>
<Viewcoursefiles/>
</Route>
<Route path='/viewcoursefilesadmin'>
<Viewcoursefilesadmin/>
</Route>
<Route path='/addcoursefilesnew'>
<Addcoursefilesnew/>
</Route>
<Route path='/addcoursefilesbulk'>
<Addcoursefilesbulk />
</Route>
<Route path='/editclass'>
<Editclass/>
</Route>
<Route path='/viewclass'>
<Viewclass/>
</Route>
<Route path='/viewclassadmin'>
<Viewclassadmin/>
</Route>
<Route path='/addclassnew'>
<Addclassnew/>
</Route>
<Route path='/addclassbulk'>
<Addclassbulk />
</Route>



    <Route path='/viewlessonplanadmin'>
<Viewlessonplanadmin/>
</Route>
<Route path='/viewworkloadadmin'>
<Viewworkloadadmin/>
</Route>

    <Route path='/addworkloadbulk'>
<Addworkloadbulk />
</Route>
<Route path='/addlessonplanbulk'>
<Addlessonplanbulk />
</Route>

    <Route path='/editncommittees'>
<Editncommittees/>
</Route>
<Route path='/viewncommittees'>
<Viewncommittees/>
</Route>
<Route path='/viewncommitteesadmin'>
<Viewncommitteesadmin/>
</Route>
<Route path='/addncommittees'>
<Addncommittees/>
</Route>
<Route path='/addncommitteesbulk'>
<Addncommitteesbulk />
</Route>
<Route path='/editncommembers'>
<Editncommembers/>
</Route>
<Route path='/viewncommembers'>
<Viewncommembers/>
</Route>
<Route path='/viewncommembersadmin'>
<Viewncommembersadmin/>
</Route>
<Route path='/addncommembers'>
<Addncommembers/>
</Route>
<Route path='/addncommembersbulk'>
<Addncommembersbulk />
</Route>
<Route path='/editncomtasks'>
<Editncomtasks/>
</Route>
<Route path='/viewncomtasks'>
<Viewncomtasks/>
</Route>
<Route path='/viewncomtasksadmin'>
<Viewncomtasksadmin/>
</Route>
<Route path='/addncomtasks'>
<Addncomtasks/>
</Route>
<Route path='/addncomtasksbulk'>
<Addncomtasksbulk />
</Route>
<Route path='/editncomminutes'>
<Editncomminutes/>
</Route>
<Route path='/viewncomminutes'>
<Viewncomminutes/>
</Route>
<Route path='/viewncomminutesadmin'>
<Viewncomminutesadmin/>
</Route>
<Route path='/addncomminutes'>
<Addncomminutes/>
</Route>
<Route path='/addncomminutesbulk'>
<Addncomminutesbulk />
</Route>



    <Route path='/viewdepfacdocs'>
<Viewdepfacdocs/>
</Route>
    <Route path='/viewnoffcampusadmin'>
<Viewnoffcampusadmin/>
</Route>
<Route path='/viewnjobsapplyadmin'>
<Viewnjobsapplyadmin/>
</Route>
<Route path='/viewnhigheredurepadmin'>
<Viewnhigheredurepadmin/>
</Route>
<Route path='/viewnoffawardsadmin'>
<Viewnoffawardsadmin/>
</Route>
<Route path='/viewnoffscholarshipadmin'>
<Viewnoffscholarshipadmin/>
</Route>
<Route path='/viewnoffextensionadmin'>
<Viewnoffextensionadmin/>
</Route>
<Route path='/viewnoffcoursesadmin'>
<Viewnoffcoursesadmin/>
</Route>


    <Route path='/editnjobs'>
<Editnjobs/>
</Route>
<Route path='/viewnjobs'>
<Viewnjobs/>
</Route>
<Route path='/viewnjobsadmin'>
<Viewnjobsadmin/>
</Route>
<Route path='/addnjobs'>
<Addnjobs/>
</Route>
<Route path='/addnjobsbulk'>
<Addnjobsbulk />
</Route>
<Route path='/editnjobstudents'>
<Editnjobstudents/>
</Route>
<Route path='/viewnjobstudents'>
<Viewnjobstudents/>
</Route>
<Route path='/viewnjobstudentsadmin'>
<Viewnjobstudentsadmin/>
</Route>
<Route path='/addnjobstudents'>
<Addnjobstudents/>
</Route>
<Route path='/addnjobstudentsbulk'>
<Addnjobstudentsbulk />
</Route>
<Route path='/editnhighereducation'>
<Editnhighereducation/>
</Route>
<Route path='/viewnhighereducation'>
<Viewnhighereducation/>
</Route>
<Route path='/viewnhighereducationadmin'>
<Viewnhighereducationadmin/>
</Route>
<Route path='/addnhighereducation'>
<Addnhighereducation/>
</Route>
<Route path='/addnhighereducationbulk'>
<Addnhighereducationbulk />
</Route>
<Route path='/editncompanies'>
<Editncompanies/>
</Route>
<Route path='/viewncompanies'>
<Viewncompanies/>
</Route>
<Route path='/viewncompaniesadmin'>
<Viewncompaniesadmin/>
</Route>
<Route path='/addncompanies'>
<Addncompanies/>
</Route>
<Route path='/addncompaniesbulk'>
<Addncompaniesbulk />
</Route>


    <Route path='/editnseedmoney'>
<Editnseedmoney/>
</Route>
<Route path='/viewnseedmoney'>
<Viewnseedmoney/>
</Route>
<Route path='/viewnseedmoneyadmin'>
<Viewnseedmoneyadmin/>
</Route>
<Route path='/addnseedmoney'>
<Addnseedmoney/>
</Route>
<Route path='/addnseedmoneybulk'>
<Addnseedmoneybulk />
</Route>
<Route path='/editnbudgetreq'>
<Editnbudgetreq/>
</Route>
<Route path='/viewnbudgetreq'>
<Viewnbudgetreq/>
</Route>
<Route path='/viewnbudgetreqadmin'>
<Viewnbudgetreqadmin/>
</Route>
<Route path='/addnbudgetreq'>
<Addnbudgetreq/>
</Route>
<Route path='/addnbudgetreqbulk'>
<Addnbudgetreqbulk />
</Route>
<Route path='/editnoodreq'>
<Editnoodreq/>
</Route>
<Route path='/viewnoodreq'>
<Viewnoodreq/>
</Route>
<Route path='/viewnoodreqadmin'>
<Viewnoodreqadmin/>
</Route>
<Route path='/addnoodreq'>
<Addnoodreq/>
</Route>
<Route path='/addnoodreqbulk'>
<Addnoodreqbulk />
</Route>
<Route path='/editneventreq'>
<Editneventreq/>
</Route>
<Route path='/viewneventreq'>
<Viewneventreq/>
</Route>
<Route path='/viewneventreqadmin'>
<Viewneventreqadmin/>
</Route>
<Route path='/addneventreq'>
<Addneventreq/>
</Route>
<Route path='/addneventreqbulk'>
<Addneventreqbulk />
</Route>
<Route path='/editnassistantreq'>
<Editnassistantreq/>
</Route>
<Route path='/viewnassistantreq'>
<Viewnassistantreq/>
</Route>
<Route path='/viewnassistantreqadmin'>
<Viewnassistantreqadmin/>
</Route>
<Route path='/addnassistantreq'>
<Addnassistantreq/>
</Route>
<Route path='/addnassistantreqbulk'>
<Addnassistantreqbulk />
</Route>
<Route path='/editnhousingreq'>
<Editnhousingreq/>
</Route>
<Route path='/viewnhousingreq'>
<Viewnhousingreq/>
</Route>
<Route path='/viewnhousingreqadmin'>
<Viewnhousingreqadmin/>
</Route>
<Route path='/addnhousingreq'>
<Addnhousingreq/>
</Route>
<Route path='/addnhousingreqbulk'>
<Addnhousingreqbulk />
</Route>
<Route path='/editntravelsupport'>
<Editntravelsupport/>
</Route>
<Route path='/viewntravelsupport'>
<Viewntravelsupport/>
</Route>
<Route path='/viewntravelsupportadmin'>
<Viewntravelsupportadmin/>
</Route>
<Route path='/addntravelsupport'>
<Addntravelsupport/>
</Route>
<Route path='/addntravelsupportbulk'>
<Addntravelsupportbulk />
</Route>




    <Route path='/editnbudget'>
<Editnbudget/>
</Route>
<Route path='/viewnbudget'>
<Viewnbudget/>
</Route>
<Route path='/viewnbudgetadmin'>
<Viewnbudgetadmin/>
</Route>
<Route path='/addnbudget'>
<Addnbudget/>
</Route>
<Route path='/addnbudgetbulk'>
<Addnbudgetbulk />
</Route>
<Route path='/editnledger'>
<Editnledger/>
</Route>
<Route path='/viewnledger'>
<Viewnledger/>
</Route>
<Route path='/viewnledgeradmin'>
<Viewnledgeradmin/>
</Route>
<Route path='/addnledger'>
<Addnledger/>
</Route>
<Route path='/addnledgerbulk'>
<Addnledgerbulk />
</Route>
<Route path='/editnaccounts'>
<Editnaccounts/>
</Route>
<Route path='/viewnaccounts'>
<Viewnaccounts/>
</Route>
<Route path='/viewnaccountsadmin'>
<Viewnaccountsadmin/>
</Route>
<Route path='/addnaccounts'>
<Addnaccounts/>
</Route>
<Route path='/addnaccountsbulk'>
<Addnaccountsbulk />
</Route>




    <Route path='/dasherpadmin1'>
<Dasherpadmin1/>
</Route>
<Route path='/dashfeedbackreports'>
<Dashfeedbackreports/>
</Route>

    <Route path='/viewplacementdep'>
<Viewplacementdep/>
</Route>
    <Route path='/viewhigherexamdep'>
<Viewhigherexamdep/>
</Route>
    <Route path='/viewhigheredudep'>
<Viewhigheredudep/>
</Route>
    <Route path='/viewecontentdep'>
<Viewecontentdep/>
</Route>
    <Route path='/viewexplearningdocsdep'>
<Viewexplearningdocsdep/>
</Route>
    <Route path='/viewsyllabusrevdocsdep'>
<Viewsyllabusrevdocsdep/>
</Route>
    <Route path='/viewemployabilitydocsdep'>
<Viewemployabilitydocsdep/>
</Route>

    <Route path='/editnmilestone'>
<Editnmilestone/>
</Route>
<Route path='/viewnmilestone'>
<Viewnmilestone/>
</Route>
<Route path='/viewnmilestoneadmin'>
<Viewnmilestoneadmin/>
</Route>
<Route path='/addnmilestone'>
<Addnmilestone/>
</Route>
<Route path='/addnmilestonebulk'>
<Addnmilestonebulk />
</Route>
<Route path='/editninvoice'>
<Editninvoice/>
</Route>
<Route path='/viewninvoice'>
<Viewninvoice/>
</Route>
<Route path='/viewninvoiceadmin'>
<Viewninvoiceadmin/>
</Route>
<Route path='/addninvoice'>
<Addninvoice/>
</Route>
<Route path='/addninvoicebulk'>
<Addninvoicebulk />
</Route>
<Route path='/editngstgain'>
<Editngstgain/>
</Route>
<Route path='/viewngstgain'>
<Viewngstgain/>
</Route>
<Route path='/viewngstgainadmin'>
<Viewngstgainadmin/>
</Route>
<Route path='/addngstgain'>
<Addngstgain/>
</Route>
<Route path='/addngstgainbulk'>
<Addngstgainbulk />
</Route>
<Route path='/editnvendor'>
<Editnvendor/>
</Route>
<Route path='/viewnvendor'>
<Viewnvendor/>
</Route>
<Route path='/viewnvendoradmin'>
<Viewnvendoradmin/>
</Route>
<Route path='/addnvendor'>
<Addnvendor/>
</Route>
<Route path='/addnvendorbulk'>
<Addnvendorbulk />
</Route>
<Route path='/editnvworkorder'>
<Editnvworkorder/>
</Route>
<Route path='/viewnvworkorder'>
<Viewnvworkorder/>
</Route>
<Route path='/viewnvworkorderadmin'>
<Viewnvworkorderadmin/>
</Route>
<Route path='/addnvworkorder'>
<Addnvworkorder/>
</Route>
<Route path='/addnvworkorderbulk'>
<Addnvworkorderbulk />
</Route>
<Route path='/editnvinvoice'>
<Editnvinvoice/>
</Route>
<Route path='/viewnvinvoice'>
<Viewnvinvoice/>
</Route>
<Route path='/viewnvinvoiceadmin'>
<Viewnvinvoiceadmin/>
</Route>
<Route path='/addnvinvoice'>
<Addnvinvoice/>
</Route>
<Route path='/addnvinvoicebulk'>
<Addnvinvoicebulk />
</Route>
<Route path='/editntrialbalance'>
<Editntrialbalance/>
</Route>
<Route path='/viewntrialbalance'>
<Viewntrialbalance/>
</Route>
<Route path='/viewntrialbalanceadmin'>
<Viewntrialbalanceadmin/>
</Route>
<Route path='/addntrialbalance'>
<Addntrialbalance/>
</Route>
<Route path='/addntrialbalancebulk'>
<Addntrialbalancebulk />
</Route>
<Route path='/editnclient'>
<Editnclient/>
</Route>
<Route path='/viewnclient'>
<Viewnclient/>
</Route>
<Route path='/viewnclientadmin'>
<Viewnclientadmin/>
</Route>
<Route path='/addnclient'>
<Addnclient/>
</Route>
<Route path='/addnclientbulk'>
<Addnclientbulk />
</Route>





    <Route path='/viewkpipending'>
<Viewkpipending/>
</Route>
    <Route path='/dasherp1'>
<Dasherp1/>
</Route>

    <Route path='/editnecourse'>
<Editnecourse/>
</Route>
<Route path='/viewnecourse'>
<Viewnecourse/>
</Route>
<Route path='/viewnecourseadmin'>
<Viewnecourseadmin/>
</Route>
<Route path='/addnecourse'>
<Addnecourse/>
</Route>
<Route path='/addnecoursebulk'>
<Addnecoursebulk />
</Route>
<Route path='/editnnextension'>
<Editnnextension/>
</Route>
<Route path='/viewnnextension'>
<Viewnnextension/>
</Route>
<Route path='/viewnnextensionadmin'>
<Viewnnextensionadmin/>
</Route>
<Route path='/addnnextension'>
<Addnnextension/>
</Route>
<Route path='/addnnextensionbulk'>
<Addnnextensionbulk />
</Route>
<Route path='/editnncollab'>
<Editnncollab/>
</Route>
<Route path='/viewnncollab'>
<Viewnncollab/>
</Route>
<Route path='/viewnncollabadmin'>
<Viewnncollabadmin/>
</Route>
<Route path='/addnncollab'>
<Addnncollab/>
</Route>
<Route path='/addnncollabbulk'>
<Addnncollabbulk />
</Route>
<Route path='/editnnmou'>
<Editnnmou/>
</Route>
<Route path='/viewnnmou'>
<Viewnnmou/>
</Route>
<Route path='/viewnnmouadmin'>
<Viewnnmouadmin/>
</Route>
<Route path='/addnnmou'>
<Addnnmou/>
</Route>
<Route path='/addnnmoubulk'>
<Addnnmoubulk />
</Route>
<Route path='/editnnpatients'>
<Editnnpatients/>
</Route>
<Route path='/viewnnpatients'>
<Viewnnpatients/>
</Route>
<Route path='/viewnnpatientsadmin'>
<Viewnnpatientsadmin/>
</Route>
<Route path='/addnnpatients'>
<Addnnpatients/>
</Route>
<Route path='/addnnpatientsbulk'>
<Addnnpatientsbulk />
</Route>
<Route path='/editnnexposure'>
<Editnnexposure/>
</Route>
<Route path='/viewnnexposure'>
<Viewnnexposure/>
</Route>
<Route path='/viewnnexposureadmin'>
<Viewnnexposureadmin/>
</Route>
<Route path='/addnnexposure'>
<Addnnexposure/>
</Route>
<Route path='/addnnexposurebulk'>
<Addnnexposurebulk />
</Route>





    <Route path='/editnnexp'>
<Editnnexp/>
</Route>
<Route path='/viewnnexp'>
<Viewnnexp/>
</Route>
<Route path='/viewnnexpadmin'>
<Viewnnexpadmin/>
</Route>
<Route path='/addnnexp'>
<Addnnexp/>
</Route>
<Route path='/addnnexpbulk'>
<Addnnexpbulk />
</Route>
<Route path='/editnnratio'>
<Editnnratio/>
</Route>
<Route path='/viewnnratio'>
<Viewnnratio/>
</Route>
<Route path='/viewnnratioadmin'>
<Viewnnratioadmin/>
</Route>
<Route path='/addnnratio'>
<Addnnratio/>
</Route>
<Route path='/addnnratiobulk'>
<Addnnratiobulk />
</Route>
<Route path='/editnnmentor'>
<Editnnmentor/>
</Route>
<Route path='/viewnnmentor'>
<Viewnnmentor/>
</Route>
<Route path='/viewnnmentoradmin'>
<Viewnnmentoradmin/>
</Route>
<Route path='/addnnmentor'>
<Addnnmentor/>
</Route>
<Route path='/addnnmentorbulk'>
<Addnnmentorbulk />
</Route>
<Route path='/editnnexam'>
<Editnnexam/>
</Route>
<Route path='/viewnnexam'>
<Viewnnexam/>
</Route>
<Route path='/viewnnexamadmin'>
<Viewnnexamadmin/>
</Route>
<Route path='/addnnexam'>
<Addnnexam/>
</Route>
<Route path='/addnnexambulk'>
<Addnnexambulk />
</Route>


    <Route path='/editnnursinginter'>
<Editnnursinginter/>
</Route>
<Route path='/viewnnursinginter'>
<Viewnnursinginter/>
</Route>
<Route path='/viewnnursinginteradmin'>
<Viewnnursinginteradmin/>
</Route>
<Route path='/addnnursinginter'>
<Addnnursinginter/>
</Route>
<Route path='/addnnursinginterbulk'>
<Addnnursinginterbulk />
</Route>

    <Route path='/editnnvacstud'>
<Editnnvacstud/>
</Route>
<Route path='/viewnnvacstud'>
<Viewnnvacstud/>
</Route>
<Route path='/viewnnvacstudadmin'>
<Viewnnvacstudadmin/>
</Route>
<Route path='/addnnvacstud'>
<Addnnvacstud/>
</Route>
<Route path='/addnnvacstudbulk'>
<Addnnvacstudbulk />
</Route>

    <Route path='/editnprogcourse'>
<Editnprogcourse/>
</Route>
<Route path='/viewnprogcourse'>
<Viewnprogcourse/>
</Route>
<Route path='/viewnprogcourseadmin'>
<Viewnprogcourseadmin/>
</Route>
<Route path='/addnprogcourse'>
<Addnprogcourse/>
</Route>
<Route path='/addnprogcoursebulk'>
<Addnprogcoursebulk />
</Route>

    <Route path='/editndepfunding'>
<Editndepfunding/>
</Route>
<Route path='/viewndepfunding'>
<Viewndepfunding/>
</Route>
<Route path='/viewndepfundingadmin'>
<Viewndepfundingadmin/>
</Route>
<Route path='/addndepfunding'>
<Addndepfunding/>
</Route>
<Route path='/addndepfundingbulk'>
<Addndepfundingbulk />
</Route>


    <Route path='/editnfacilities'>
<Editnfacilities/>
</Route>
<Route path='/viewnfacilities'>
<Viewnfacilities/>
</Route>
<Route path='/viewnfacilitiesadmin'>
<Viewnfacilitiesadmin/>
</Route>
<Route path='/addnfacilities'>
<Addnfacilities/>
</Route>
<Route path='/addnfacilitiesbulk'>
<Addnfacilitiesbulk />
</Route>

    <Route path='/editnreval'>
<Editnreval/>
</Route>
<Route path='/viewnreval'>
<Viewnreval/>
</Route>
<Route path='/viewnrevaladmin'>
<Viewnrevaladmin/>
</Route>
<Route path='/addnreval'>
<Addnreval/>
</Route>
<Route path='/addnrevalbulk'>
<Addnrevalbulk />
</Route>


    <Route path='/editnotherstud'>
<Editnotherstud/>
</Route>
<Route path='/viewnotherstud'>
<Viewnotherstud/>
</Route>
<Route path='/viewnotherstudadmin'>
<Viewnotherstudadmin/>
</Route>
<Route path='/addnotherstud'>
<Addnotherstud/>
</Route>
<Route path='/addnotherstudbulk'>
<Addnotherstudbulk />
</Route>

    <Route path='/editnhlearner'>
<Editnhlearner/>
</Route>
<Route path='/viewnhlearner'>
<Viewnhlearner/>
</Route>
<Route path='/viewnhlearneradmin'>
<Viewnhlearneradmin/>
</Route>
<Route path='/addnhlearner'>
<Addnhlearner/>
</Route>
<Route path='/addnhlearnerbulk'>
<Addnhlearnerbulk />
</Route>

    <Route path='/editnclinicskills'>
<Editnclinicskills/>
</Route>
<Route path='/viewnclinicskills'>
<Viewnclinicskills/>
</Route>
<Route path='/viewnclinicskillsadmin'>
<Viewnclinicskillsadmin/>
</Route>
<Route path='/addnclinicskills'>
<Addnclinicskills/>
</Route>
<Route path='/addnclinicskillsbulk'>
<Addnclinicskillsbulk />
</Route>



    <Route path='/editninter'>
<Editninter/>
</Route>
<Route path='/viewninter'>
<Viewninter/>
</Route>
<Route path='/viewninteradmin'>
<Viewninteradmin/>
</Route>
<Route path='/addninter'>
<Addninter/>
</Route>
<Route path='/addninterbulk'>
<Addninterbulk />
</Route>


    <Route path='/editncourseplan'>
<Editncourseplan/>
</Route>
<Route path='/viewncourseplan'>
<Viewncourseplan/>
</Route>
<Route path='/viewncourseplanadmin'>
<Viewncourseplanadmin/>
</Route>
<Route path='/addncourseplan'>
<Addncourseplan/>
</Route>
<Route path='/addncourseplanbulk'>
<Addncourseplanbulk />
</Route>


    <Route path='/editnenrollment'>
<Editnenrollment/>
</Route>
<Route path='/viewnenrollment'>
<Viewnenrollment/>
</Route>
<Route path='/viewnenrollmentadmin'>
<Viewnenrollmentadmin/>
</Route>
<Route path='/addnenrollment'>
<Addnenrollment/>
</Route>
<Route path='/addnenrollmentbulk'>
<Addnenrollmentbulk />
</Route>


    <Route path='/editnworkbook'>
<Editnworkbook/>
</Route>
<Route path='/viewnworkbook'>
<Viewnworkbook/>
</Route>
<Route path='/viewnworkbookadmin'>
<Viewnworkbookadmin/>
</Route>
<Route path='/addnworkbook'>
<Addnworkbook/>
</Route>
<Route path='/addnworkbookbulk'>
<Addnworkbookbulk />
</Route>

    <Route path='/editnacademic'>
<Editnacademic/>
</Route>
<Route path='/viewnacademic'>
<Viewnacademic/>
</Route>
<Route path='/viewnacademicadmin'>
<Viewnacademicadmin/>
</Route>
<Route path='/addnacademic'>
<Addnacademic/>
</Route>
<Route path='/addnacademicbulk'>
<Addnacademicbulk />
</Route>


    <Route path='/editnsop'>
<Editnsop/></Route>
<Route path='/viewnsop'>
<Viewnsop/></Route>
<Route path='/viewnsopadmin'>
<Viewnsopadmin/></Route>
<Route path='/addnsop'>
<Addnsop/></Route>

<Route path='/viewsportsactdocs'>
      <Viewsportsactdocs/>
    </Route>

    <Route path='/addkpibulkin'>
      <Addkpibulkin/>
    </Route>
    <Route path='/editntimesheet'>
      <Editntimesheet/>
    </Route>
    <Route path='/addntimesheet'>
      <Addntimesheet/>
    </Route>
    <Route path='/viewntimesheet'>
      <Viewntimesheet/>
    </Route>
    <Route path='/viewntimesheetadmin'>
      <Viewntimesheetadmin/>
    </Route>
    <Route path='/viewhnewprogadmin'>
      <Viewhnewprogadmin/>
    </Route>
    <Route path='/edithnewprog'>
      <Edithnewprog/>
    </Route>
    <Route path='/addhnewprog'>
      <Addhnewprog/>
    </Route>
    <Route path='/viewhnewprog'>
      <Viewhnewprog/>
    </Route>
    <Route path='/viewfdplinks'>
      <Viewfdplinks/>
    </Route>
    <Route path='/viewfdpexcel'>
      <Viewfdpexcel/>
    </Route>
    <Route path='/vieweventexcel'>
      <Vieweventexcel/>
    </Route>
    <Route path='/vieweventlinks'>
      <Vieweventlinks/>
    </Route>
    <Route path='/viewskilldevexcel'>
      <Viewskilldevexcel/>
    </Route>
    <Route path='/viewskilldevlinks'>
      <Viewskilldevlinks/>
    </Route>
    <Route path='/viewscholarshipexcel'>
      <Viewscholarshipexcel/>
    </Route>
    <Route path='/viewawardsexcel'>
      <Viewawardsexcel/>
    </Route>
    <Route path='/viewawardslink'>
      <Viewawardslink/>
    </Route>
    <Route path='/viewawardsdocsd'>
      <Viewawardsdocsd/>
    </Route>
    <Route path='/viewextensionexcel'>
      <Viewextensionexcel/>
    </Route>
    <Route path='/viewextensionlinks'>
      <Viewextensionlinks/>
    </Route>
    <Route path='/viewcollabexcel'>
      <Viewcollabexcel/>
    </Route>
    <Route path='/viewbooksexcel'>
      <Viewbooksexcel/>
    </Route>
    <Route path='/viewbooklinks'>
      <Viewbooklinks/>
    </Route>
    <Route path='/viewmoulinks'>
      <Viewmoulinks/>
    </Route>
    <Route path='/viewmouexcel'>
      <Viewmouexcel/>
    </Route>
    <Route path='/viewpubexcel'>
      <Viewpubexcel/>
    </Route>
    <Route path='/viewpublinks'>
      <Viewpublinks/>
    </Route>
    <Route path='/viewprojectlinks'>
      <Viewprojectlinks/>
    </Route>
    <Route path='/viewprojectexcel'>
      <Viewprojectexcel/>
    </Route>
    <Route path='/viewhigherexamlinks'>
      <Viewhigherexamlinks/>
    </Route>
    <Route path='/viewhigherexamexcel'>
      <Viewhigherexamexcel/>
    </Route>
    <Route path='/viewhigheredulinks'>
      <Viewhigheredulinks/>
    </Route>
    <Route path='/viewhighereduexcel'>
      <Viewhighereduexcel/>
    </Route>
    <Route path='/viewplacementlinks'>
      <Viewplacementlinks/>
    </Route>
    <Route path='/viewplacementexcel'>
      <Viewplacementexcel/>
    </Route>
    <Route path='/viewpasslinks'>
      <Viewpasslinks/>
    </Route>
    <Route path='/viewpassexcel'>
      <Viewpassexcel/>
    </Route>
    <Route path='/viewphdguidelink'>
      <Viewphdguidelinks/>
    </Route>
    <Route path='/viewphdguideexcel'>
      <Viewphdguideexcel/>
    </Route>
    <Route path='/viewreservecatlinks'>
      <Viewreservecatlinks/>
    </Route>
    <Route path='/viewadmissionlinks'>
      <Viewadmissionlinks/>
    </Route>
    <Route path='/viewadmissionexcel'>
      <Viewadmissionexcel/>
    </Route>
    <Route path='/viewaddondeclaration'>
      <Viewaddondeclaration/>
    </Route>
    <Route path='/viewexplearnaffexcel'>
      <Viewexplearnaffexcel/>
    </Route>
    <Route path='/viewexplearnprojexcel'>
      <Viewexplearnprojexcel/>
    </Route>
    <Route path='/viewaddonlinksyear'>
      <Viewaddonlinksyear/>
    </Route>
    <Route path='/viewaddonexcelyear'>
      <Viewaddonexcelyear/>
    </Route>
    <Route path='/viewcbcsexcelyear'>
      <Viewcbcsexcelyear/>
    </Route>
    <Route path='/viewboslinksyear'>
      <Viewboslinksyear/>
    </Route>
    <Route path='/viewbosexcelyear'>
      <Viewbosexcelyear/>
    </Route>
    <Route path='/viewfdpcold'>
      <Viewfdpcold/>
    </Route>
    <Route path='/viewfieldprojdd'>
      <Viewfieldprojdd/>
    </Route>
    <Route path='/viewdeletebyuser'>
      <Viewdeletebyuser/>
    </Route>



    
  <Route path='/viewexamautomationdd'>
  <Viewexamautomationd />
  </Route>
  <Route path='/viewincubationdd'>
  <Viewincubationd />
  </Route>
  <Route path='/viewalumnicondd'>
  <Viewalumnicond />
  </Route>
  <Route path='/viewegoverndd'>
  <Viewegovernd />
  </Route>
  <Route path='/viewhigheredudd'>
  <Viewhigheredud />
  </Route>
  <Route path='/viewhigherexamdd'>
  <Viewhigherexamd />
  </Route>
  <Route path='/viewlibraryexpdd'>
  <Viewlibraryexpd />
  </Route>
  <Route path='/viewexpendituredd'>
  <Viewexpenditured />
  </Route>
  <Route path='/viewextensionactdd'>
  <Viewextensionactd />
  </Route>
  <Route path='/viewconsultancydd'>
  <Viewconsultancyd />
  </Route>
  <Route path='/viewecontentdd'>
  <Viewecontentd />
  </Route>
  <Route path='/viewresultdd'>
  <Viewresultd />
  </Route>
  <Route path='/viewqualitydd'>
  <Viewqualityd />
  </Route>
  <Route path='/Viewqualityinitdd'>
  <Viewqualityinitd />
  </Route>
  <Route path='/viewfundsdd'>
  <Viewfundsd />
  </Route>
  <Route path='/viewextawardsd'>
  <Viewextawardsd />
  </Route>
  <Route path='/viewteacherfsdd'>
  <Viewteacherfsd />
  </Route>



    
    <Route path='/viewscholarshipdocs'>
      <Viewscholarshipdocs/>
    </Route>
    <Route path='/thankyouafter'>
      <Thankyouafter/>
    </Route>
    <Route path='/selectawspdf'>
      <Selectawspdf/>
    </Route>
    <Route path='/pdftest2'>
      <Pdftest3/>
    </Route>
    <Route path='/addpdfbulk'>
      <Addpdfbulk/>
    </Route>
    <Route path='/selectawsphoto'>
      <Selectawsphoto/>
    </Route>
    <Route path='/geotagtest1'>
      <Geotagtest1/>
    </Route>
    <Route path='/offlinewelcome'>
      <Offlinewelcome/>
    </Route>
    <Route path='/viewleaveapplybyadmin'>
      <Viewleaveapplyadmin/>
    </Route>
    <Route path='/selectawsconfignew'>
      <Selectawsconfignew/>
    </Route>
    <Route path='/ocrtest2'>
      <Ocrtest34/>
    </Route>
    <Route path='/ocrtest1'>
      <Ocrtest1/>
    </Route>
    <Route path='/checkocr'>
      <Checkocr/>
    </Route>
    <Route path='/viewsyllabuserror'>
      <Viewsyllabuserror/>
    </Route>
    <Route path='/addallkpi1'>
      <Addallkpi1/>
    </Route>
    <Route path='/viewkpiuser1'>
      <Viewkpiuser2/>
    </Route>
    <Route path='/addprojectbalance'>
      <Addprojectbalance/>
    </Route>
    <Route path='/editprojectbalance'>
      <Editprojectbalance/>
    </Route>
    <Route path='/addinvoice'>
      <Addinvoice/>
    </Route>
    <Route path='/editinvoice'>
      <Editinvoice/>
    </Route>
    <Route path='/viewinvoice'>
      <Viewinvoice/>
    </Route>
    <Route path='/viewprojectbalance'>
      <Viewprojectbalance />
    </Route>
    <Route path='/selectmyprojectadm'>
      <Selectmyprojectadm />
    </Route>
    <Route path='/viewresearchfellowadmfac'>
      <Viewresearchfellowadmfac />
    </Route>
    <Route path='/selectmyproject'>
      <Selectmyproject />
    </Route>
    <Route path='/selectproject'>
      <Selectproject />
    </Route>
    <Route path='/viewresearchfellowdocs'>
      <Viewresearchfellodocs />
    </Route>
    <Route path='/viewresearchfellowdocsd'>
      <Viewresearchfellodocsd />
    </Route>
    <Route path='/viewpassexamdocs'>
      <Viewpassexamdocs />
    </Route>
    <Route path='/viewphdguidedoccomments'>
      <Viewphdguidedoccomments />
    </Route>
    <Route path='/viewteacherdatadoc'>
      <Viewteacherdatadoc />
    </Route>
    <Route path='/viewexplearningprojdoc'>
      <Viewexplearningprojdoc />
    </Route>
    <Route path='/viewcbcsdoc'>
      <Viewcbcsdoc />
    </Route>
    <Route path='/viewreservecatdoccomments'>
      <Viewreservecatdoccomments />
    </Route>
    <Route path='/viewnbamca'>
      <Viewnbamca />
    </Route>
    <Route path='/viewnbadiploma'>
      <Viewnbadiploma />
    </Route>
    <Route path='/viewleavehistory'>
      <Viewleavehistory />
    </Route>
    <Route path='/viewleavependingprincipal'>
      <Viewleavependingprincipal />
    </Route>
    <Route path='/viewleavependinghod'>
      <Viewleavependinghod />
    </Route>
    <Route path='/viewleavepending'>
      <Viewleavepending />
    </Route>
    <Route path='/addleaveapply'>
      <Addleaveapply />
    </Route>
    <Route path='/viewleaveapply'>
      <Viewleaveapply />
    </Route>
    <Route path='/viewssstext'>
      <Viewssstext />
    </Route>
    <Route path='/viewssscount'>
      <Viewssscount />
    </Route>
    <Route path='/viewsssqscore'>
      <Viewsssqscore />
    </Route>
    <Route path='/viewdeptfeedbackcount'>
      <Viewdeptfeedbackcount />
    </Route>
    <Route path='/addleavebalance'>
      <Addleavebalance />
    </Route>
    <Route path='/editleavebalance'>
      <Editleavebalance />
    </Route>
    <Route path='/viewleavebalance'>
      <Viewleavebalance />
    </Route>
    <Route path='/addclass'>
      <Addclass />
    </Route>
    <Route path='/editworkloadnew'>
      <Editworkloadnew />
    </Route>
    <Route path='/editlessonplannew'>
      <Editlessonplannew />
    </Route>
    <Route path='/addlessonplannew'>
      <Addlessonplannew />
    </Route>
    <Route path='/viewlessonnew'>
      <Viewlessonnew />
    </Route>
    <Route path='/viewgoals'>
      <Viewgoals />
    </Route>
    <Route path='/addgoals'>
      <Addgoals />
    </Route>
    <Route path='/viewnbaug11admin'>
      <Viewnbaug11admin />
    </Route>
    <Route path='/addnbaug11'>
      <Addnbaug11 />
    </Route>
    <Route path='/viewnbaug11'>
      <Viewnbaug11 />
    </Route>
    <Route path='/viewnbaug'>
      <Viewnbaug />
    </Route>
    <Route path='/viewcurstructureadmin'>
      <Viewcurstructureadmin />
    </Route>
    <Route path='/editcurstructure'>
      <Editcurstructure />
    </Route>
    <Route path='/addcurstructure'>
      <Addcurstructure />
    </Route>
    <Route path='/viewcurstructure'>
      <Viewcurstructure />
    </Route>
    <Route path='/viewremedialadmin'>
      <Viewremedialadmin />
    </Route>
    <Route path='/editremedial'>
      <Editremedial />
    </Route>
    <Route path='/addremedial'>
      <Addremedial />
    </Route>
    <Route path='/viewremedial'>
      <Viewremedial />
    </Route>
    <Route path='/adminuniversitymetric'>
      <Adminuniversitymetric />
    </Route>
    <Route path='/adminaffiliatedmetricnew'>
      <Adminaffiliatedmetricnew />
    </Route>
    <Route path='/viewaffiliatedmetricnew'>
      <Viewaffiliatedmetricnew />
    </Route>
    <Route path='/adminaffiliatedmetric'>
      <Adminaffiliatedmetric />
    </Route>
    <Route path='/viewaffiliatedmetric'>
      <Viewaffiliatedmetric />
    </Route>
    <Route path='/viewuniversitymetric'>
      <Viewuniversitymetric1 />
    </Route>
    <Route path='/viewstudpubadmin'>
      <Viewstudpubadmin />
    </Route>
    <Route path='/viewstudpub'>
      <Viewstudpub />
    </Route>
    <Route path='/addstudpub'>
      <Addstudpub />
    </Route>
    <Route path='/editstudpub'>
      <Editstudpub />
    </Route>
    <Route path='/editdeppub'>
      <Editdeppub />
    </Route>
    <Route path='/adddeppub'>
      <Adddeppub />
    </Route>
    <Route path='/viewdeppublicationsadmin'>
      <Viewdeppublicationsadmin />
    </Route>
    <Route path='/viewdeppublications'>
      <Viewdeppublications />
    </Route>
    <Route path='/addprogramsbulk'>
      <Addprogrambulk />
    </Route>
    <Route path='/editprograms'>
      <Editprograms />
    </Route>
    <Route path='/addprograms'>
      <Addprograms />
    </Route>
    <Route path='/viewprograms'>
      <Viewprograms />
    </Route>
    <Route path='/viewcurgapadmin'>
      <Viewcurgapadmin />
    </Route>
    <Route path='/viewcurgap'>
      <Viewcurgap />
    </Route>
    <Route path='/editcurgap'>
      <Editcurgap />
    </Route>
    <Route path='/addcurgap'>
      <Addcurgap />
    </Route>
    <Route path='/viewincubationadmin'>
      <Viewincubationadmin />
    </Route>
    <Route path='/viewsportsactadmin'>
      <Viewsportsactadmin />
    </Route>
    <Route path='/viewfdpadmin'>
      <Viewfdpadmin />
    </Route>
    <Route path='/viewfundsadmin'>
      <Viewfundsadmin />
    </Route>
    <Route path='/viewqualityinitadmin'>
      <Viewqualityinitadmin />
    </Route>
    <Route path='/viewfieldprojadmin'>
      <Viewfieldprojadmin />
    </Route>
<Route path='/viewexplearnprojadmin'>
      <Viewexplearnprojadmin />
    </Route>

    <Route path='/viewmoudoccomments'>
      <Viewmoudoccomments/>
    </Route>
    <Route path='/viewcollabdoccomments'>
      <Viewcollabdoccomments/>
    </Route>
    <Route path='/viewbosdoccomments'>
      <Viewbosdoccomments/>
    </Route>
    <Route path='/viewictdoccomments'>
      <Viewictdoccomments/>
    </Route>
    <Route path='/viewaddonccomments'>
      <Viewaddonccomments/>
    </Route>
    <Route path='/viewseedmdoccommentsd'>
      <Viewseedmdoccommentsd/>
    </Route>
    <Route path='/viewseedmdoccomments'>
      <Viewseedmdoscomments/>
    </Route>
    <Route path='/vieweventdoccomments'>
      <Vieweventdoccomments/>
    </Route>
    <Route path='/viewcareerdoccomments'>
      <Viewcareerdoccomments/>
    </Route>
    <Route path='/viewplacementdoccomments'>
      <Viewplacementdoccomments/>
    </Route>
    <Route path='/viewskilldevdoccomments'>
      <Viewskilldevdoccomments/>
    </Route>
    <Route path='/viewscholarshipdoccomments'>
      <Viewscholarshipdoccomments/>
    </Route>
    <Route path='/viewadmissiondoccomments'>
      <Viewadmissiondoccomments/>
    </Route>
    <Route path='/viewteacherawarddoccomments'>
      <Viewteacherawarddoccomments/>
    </Route>
    <Route path='/viewteacherfellowdoccomments'>
      <Viewteacherfellowdoccomments/>
    </Route>
    <Route path='/viewinnovationdoccoments'>
      <Viewinnovationdoccomments/>
    </Route>
    <Route path='/viewinnovationdoccomentsd'>
      <Viewinnovationdoccommentsd/>
    </Route>
    <Route path='/viewallbookdoccomments'>
      <Viewallbookdoccomments/>
    </Route>
    <Route path='/viewallpatentdocs'>
      <Viewallpatentdocs/>
    </Route>
    <Route path='/viewsyllabusrevdocs'>
      <Viewsyllabusrevdocs/>
    </Route>
    <Route path='/viewemployabilitydocs'>
      <Viewemployabilitydocs/>
    </Route>
    <Route path='/viewexplearningdocs'>
      <Viewexplearningdocs/>
    </Route>
    <Route path='/selectperiod'>
      <Selectperiod/>
    </Route>
    <Route path='/viewallpubdoccomcy'>
      <Viewallpubdoccomcy/>
    </Route>
    <Route path='/viewallpubdoccomments'>
      <Viewallpubdoccomments/>
    </Route>
    <Route path='/viewallpubdocs'>
      <Viewallpubdocs/>
    </Route>
    <Route path='/viewallsemreimbdocs'>
      <Viewallsemreimbdocs/>
    </Route>
    <Route path='/viewallseminardocs'>
      <Viewallseminardocs/>
    </Route>
    <Route path='/viewprojectyrdocs'>
      <Viewprojectyrdocs/>
    </Route>
    <Route path='/viewprojectsadmindoc'>
      <Viewprojectsadmindoc/>
    </Route>
    <Route path='/noaccess'>
      <Noaccess/>
    </Route>
    <Route path='/viewallhod'>
      <Viewallhod/>
    </Route>
    <Route path='/viewsupportingdocqueryuser'>
      <Viewsupportingdocqueryuser/>
    </Route>
    <Route path='/viewsupportingdocquery'>
      <Viewsupportingdocquery/>
    </Route>
    <Route path='/addkpibulk'>
      <Addkpibulk/>
    </Route>
    <Route path='/viewplacementall'>
      <Viewplacementall/>
    </Route>
    <Route path='/viewweeklyattendance'>
      <Viewweeklyattendance/>
    </Route>
    <Route path='/classattendanceweek'>
      <ClassAttendanceweek/>
    </Route>
    <Route path='/selectattendanceweek'>
      <Selectattendanceweek/>
    </Route>
    <Route path='/viewqsemployers'>
      <Viewqsemployers/>
    </Route>
    <Route path='/viewqspeer'>
      <Viewqspeer/>
    </Route>
    <Route path='/qspeer1/:id'>
      <Qspeer1/>
    </Route>
    <Route path='/qspeeradd/:id'>
      <Qspeeradd/>
    </Route>
    <Route path='/viewextawardsadmin'>
      <Viewextawardsadmin/>
    </Route>
    <Route path='/classstudentsmsec'>
      <Classstudentsmsec/>
    </Route>
    <Route path='/viewteacherfellowadmin'>
      <Viewteacherfellowadmin/>
    </Route>
    <Route path='/viewresearchfellowadmin'>
      <Viewresearchfellowadmin/>
    </Route>
    <Route path='/viewphdguideadmin'>
      <Viewphdguideadmin/>
    </Route>
    <Route path='/viewexplearningadmin'>
      <Viewexplearningadmin/>
    </Route>
    <Route path='/viewsyllabusrevadmin'>
      <Viewsyllabusrevadmin/>
    </Route>
    <Route path='/viewemployabilityadmin'>
      <Viewemployabilityadmin/>
    </Route>
    <Route path='/viewsyllabusrevaqao'>
      <Viewsyllabusrevaqao/>
    </Route>
    <Route path='/addsyllabusrevaqao'>
      <Addsyllabusrevaqao/>
    </Route>
    <Route path='/editsyllabusrevaqao'>
      <Editsyllabusrevaqao/>
    </Route>
    <Route path='/viewcbcsuao'>
      <Viewcbcsuao/>
    </Route>
    <Route path='/addcbcsuao'>
      <Addcbcsuao/>
    </Route>
    <Route path='/editcbcsuao'>
      <Editcbcsuao/>
    </Route>
    <Route path='/viewcombined'>
      <Viewcombined/>
    </Route>
    <Route path='/viewstartup'>
      <Viewstartup/>
    </Route>
    <Route path='/addstartup'>
      <Addstartup/>
    </Route>
    <Route path='/editstartup'>
      <Editstartup/>
    </Route>
    <Route path='/viewincubation'>
      <Viewincubation/>
    </Route>
    <Route path='/addincubation'>
      <Addincubation/>
    </Route>
    <Route path='/editincubation'>
      <Editincubation/>
    </Route>
    <Route path='/viewallassignments'>
      <Viewallassignments/>
    </Route>
    <Route path='/viewallcoursefiles'>
      <Viewallcoursefiles/>
    </Route>
    <Route path='/viewallclasses'>
      <Viewallclasses/>
    </Route>
    <Route path='/enrollstudentsext'>
      <Enrollstudentsext1/>
    </Route>
    <Route path='/viewlibbookstudents'>
      <Viewlibbookstudents/>
    </Route>
    <Route path='/viewallstudents'>
      <Viewallstudents/>
    </Route>
    <Route path='/selectmetricrulefiles'>
      <Selectmetricrulefiles/>
    </Route>
    <Route path='/selectmetricrule'>
      <Selectmetricrule/>
    </Route>
    <Route path='/viewmetricrules'>
      <Viewmetricrules/>
    </Route>
    <Route path='/editmetricrules'>
      <Editmetricrules/>
    </Route>
    <Route path='/addmetricrules'>
      <Addmetricrules/>
    </Route>

    <Route path='/updatequestions'>
      <Updatequestions/>
    </Route>
    <Route path='/getstudentbycatgender'>
      <Getstudentbycatgender/>
    </Route>
    <Route path='/getstudentbycategory'>
      <Getstudentbycategory/>
    </Route>
    <Route path='/selectattendance'>
      <Selectattendance/>
    </Route>
    <Route path='/getfacultycount'>
      <Getfacultycount/>
    </Route>
    <Route path='/getstudentcount'>
      <Getstudentcount/>
    </Route>
    <Route path='/viewdepbooksmy'>
      <Viewdepbooksmy/>
    </Route>
    <Route path='/viewdepprojectsmy'>
      <Viewdepprojectsmy/>
    </Route>
    <Route path='/viewdepseminarmy'>
      <Viewdepseminarmy/>
    </Route>
    <Route path='/viewdeppubmy'>
      <Viewdeppubmy/>
    </Route>
    <Route path='/viewsupportingdocadmin'>
      <Viewsupportingdocadmin/>
    </Route>
    <Route path='/editadminbooks'>
      <Editadminbooks/>
    </Route>
    <Route path='/addbulkaddonc'>
      <Addbulkaddonc/>
    </Route>
    <Route path='/addinstitution'>
      <Addinstitution/>
    </Route>
    <Route path='/viewinstitutions'>
      <Viewinstitutions/>
    </Route>
    <Route path='/editinstitutions'>
      <Editinstitutions/>
    </Route>
    <Route path='/selectinstitution'>
      <Selectinstitution/>
    </Route>

    <Route path='/selectdepartment'>
      <Selectdepartment/>
    </Route>
    <Route path='/viewdeppub'>
      <Viewdeppub/>
    </Route>
    <Route path='/viewdepprojects'>
      <Viewdepprojects/>
    </Route>
    <Route path='/viewdepseminar'>
      <Viewdepseminar/>
    </Route>
    <Route path='/viewdepbooks'>
      <Viewdepbooks/>
    </Route>


    <Route path='/addcircularfac'>
      <Addcircularfac/>
    </Route>
    <Route path='/viewcircularfac'>
      <Viewcircularfac/>
    </Route>
    <Route path='/editcircularfac'>
      <Editcircularfac/>
    </Route>

    <Route path='/addsupportingdoc'>
      <Addsupportingdoc/>
    </Route>
    <Route path='/addsupportingdoc2'>
      <Addsupportingdoc2/>
    </Route>
    <Route path='/selectawsconfig'>
      <Selectawsconfig/>
    </Route>
    <Route path='/viewsupportingdoc'>
      <Viewsupportingdoc/>
    </Route>
    <Route path='/viewsupportingdocall'>
      <Viewsupportingdocall/>
    </Route>


    <Route path='/viewawsconfig'>
      <Viewawsconfig/>
    </Route>
    <Route path='/editawsconfig'>
      <Editawsconfig/>
    </Route>
    <Route path='/addawsconfig'>
      <Addawsconfig/>
    </Route>

    <Route path='/viewtaskassignuser'>
      <Viewtaskassignuser/>
    </Route>
    <Route path='/edittaskassignuser'>
      <Edittaskassignuser/>
    </Route>
    <Route path='/viewtaskbyidticket'>
      <Viewtaskbyidticket/>
    </Route>

    <Route path='/addtaskticket'>
      <Addtaskticket/>
    </Route>
    <Route path='/viewtaskticket'>
      <Viewtaskticket/>
    </Route>
    <Route path='/viewtaskticketall'>
      <Viewtaskticketall/>
    </Route>
    <Route path='/edittaskticket'>
      <Edittaskticket/>
    </Route>

    <Route path='/thankyou'>
      <Thankyou/>
    </Route>
   

    <Route path='/addbosfac'>
      <Addbosfac/>
    </Route>
    <Route path='/viewbosfac'>
      <Viewbosfac/>
    </Route>
    <Route path='/editbosfac'>
      <Editbosfac/>
    </Route>

    <Route path='/adddepartment'>
      <Adddepartment/>
    </Route>
    <Route path='/viewdepartment'>
      <Viewdepartment/>
    </Route>
    <Route path='/editdepartment'>
      <Editdepartment/>
    </Route>

    <Route path='/addpubaqar'>
      <Addpubaqar/>
    </Route>
    <Route path='/viewpubaqar'>
      <Viewpubaqar/>
    </Route>
    <Route path='/editpubaqar'>
      <Editpubaqar/>
    </Route>

    <Route path='/addtaskassign'>
      <Addtaskassign/>
    </Route>
    <Route path='/edittaskassign'>
      <Edittaskassign />
    </Route>
    <Route path='/viewtaskassign'>
      <Viewtaskassign />
    </Route>
    
    <Route path='/viewkpigroup'>
      <Viewkpigroup />
    </Route>
    <Route path='/viewkpicriteria'>
      <Viewkpicriteria />
    </Route>
    <Route path='/viewweeklystatus'>
      <Viewweeklystatus />
    </Route>
    <Route path='/viewsuperweekly'>
      <Viewsuperweekly />
    </Route>
    <Route path='/addallkpi'>
      <Addallkpi/>
    </Route>
    <Route path='/alumnifeedbakanalysis'>
      <Alumnifeedbackanalysis />
    </Route>
    <Route path='/viewallfaculties'>
      <Viewallfaculties />
    </Route>
    <Route path='/viewfacultyreport'>
      <Viewfacultyreport />
    </Route>
    <Route path='/viewinnovation'>
      <Viewinnovation />
    </Route>
    <Route path='/viewinnovationadmin'>
      <Viewinnovationadmin />
    </Route>
    <Route path='/addinnovation'>
      <Addinnovation />
    </Route>
    <Route path='/editinnovation'>
      <Editinnovation />
    </Route>
    <Route path='/vieweventfdp'>
      <Vieweventfdp />
    </Route>
    <Route path='/open'>
      <Openclasses />
    </Route>
    <Route path='/workload'>
      <Workload13 />
    </Route>
    <Route path='/takeattendance'>
      <Takeattendance />
    </Route>
    <Route path='/viewclassattendance'>
      <Viewclassattendance />
    </Route>
    <Route path='/viewscopus'>
      <Viewscopus />
    </Route>
    <Route path='/assetlist'>
      <Assetlist />
    </Route>
    <Route path='/addasset'>
      <Addassets />
    </Route>
    <Route path='/assetassignment'>
      <Assetassignment />
    </Route>
    <Route path='/addassetassignment'>
      <Addassetassignment />
    </Route>
    <Route path='/addworkload'>
      <Addworkload />
    </Route>
    <Route path='/addworkloadf'>
      <Addworkloadf />
    </Route>
    <Route path='/submittedassignments'>
      <Submittedassignments />
    </Route>
    <Route path='/submissioncomments'>
      <Submissioncomments />
    </Route>
    <Route path='/addperiod'>
      <Addperiod />
    </Route>
    <Route path='/dashcourse'>
      <Dashcourse2 />
    </Route>
    <Route path='/dashcoursef'>
      <Dashcoursef />
    </Route>
    <Route path='/courseactions'>
      <CourseActions />
    </Route>
    <Route path='/enrollstudents'>
      <Enrollstudents1 />
    </Route>
    <Route path='/classstudents'>
      <Classstudents />
    </Route>
    <Route path='/classstudentsm'>
      <Classstudentsm />
    </Route>
    <Route path='/classattendance'>
      <Classattendance4 />
    </Route>
    <Route path='/getmyfeedback'>
      <Getmyfeedback />
    </Route>
    <Route path='/addassignments'>
      <Addassignments1 />
    </Route>
    <Route path='/classassignments'>
      <Classassignments />
    </Route>
    <Route path='/addcoursefiles'>
      <Addcoursefiles />
    </Route>
    <Route path='/classfiles'>
      <Classfiles />
    </Route>
    <Route path='/proctorlist'>
      <Proctorlist />
    </Route>
    <Route path='/examenrlist'>
      <Examenrlist1 />
    </Route>
    <Route path='/lessonplan'>
      <Lessonplan />
    </Route>
    <Route path='/addlessonplan'>
      <Addlessonplan />
    </Route>
    <Route path='/menteelist'>
      <Menteelist />
    </Route>
    <Route path='/mentorlogbook'>
      <Mentorlogbook />
    </Route>
    <Route path='/addmentorlogbook'>
      <Addmentorlogbook />
    </Route>
    <Route path='/addmentee'>
      <Addmentee />
    </Route>
    <Route path='/fees'>
      <Fees />
    </Route>
    <Route path='/workdiary'>
      <Workdiary />
    </Route>
    <Route path='/addlibbooks'>
      <Addlibbooks />
    </Route>
    <Route path='/libraryactions'>
      <LibraryActions />
    </Route>
    <Route path='/viewlibbooks'>
      <Viewlibbooks1 />
    </Route>
    <Route path='/viewlibbooksfaculty'>
      <Viewlibbooksfaculty />
    </Route>
    <Route path='/searchlibbooks'>
      <Searchlibbooks />
    </Route>
    <Route path='/searchlibbooks1'>
      <Searchlibbooks1 />
    </Route>
    <Route path='/libissuebooks'>
      <Libissuebooks />
    </Route>
    <Route path='/libreturnbooks'>
      <Libreturnbooks />
    </Route>
    <Route path='/libissuelist'>
      <Libissuelist />
    </Route>
    <Route path='/libissuelistall'>
      <Libissuelistall />
    </Route>
    <Route path='/viewprojects'>
      <Viewprojects />
    </Route>
    <Route path='/addprojects'>
      <Addprojects />
    </Route>
    <Route path='/editprojects'>
      <Editprojects />
    </Route>
    <Route path='/viewseminar'>
      <Viewseminar />
    </Route>
    <Route path='/addseminar'>
      <Addseminar />
    </Route>
    <Route path='/editseminar'>
      <Editseminar />
    </Route>
    <Route path='/viewpatent'>
      <Viewpatent />
    </Route>
    <Route path='/addpatent'>
      <Addpatent />
    </Route>
    <Route path='/editpatent'>
      <Editpatent />
    </Route>
    <Route path='/viewpub'>
      <Viewpub />
    </Route>
    <Route path='/addpub'>
      <Addpub />
    </Route>
    <Route path='/editpub'>
      <Editpub />
    </Route>
    <Route path='/viewbooks'>
      <Viewbooks />
    </Route>
    <Route path='/addbooks'>
      <Addbooks />
    </Route>
    <Route path='/editbooks'>
      <Editbooks />
    </Route>
    <Route path='/dashmydetails'>
      <Dashmydetails2 />
    </Route>
    <Route path='/updatepassword'>
      <Updatepassword1 />
    </Route>
    <Route path='/dashcriteria1'>
      <Dashcriteria1 />
    </Route>
    <Route path='/dashcriteria2'>
      <Dashcriteria2 />
    </Route>
    <Route path='/dashcriteria3'>
      <Dashcriteria3 />
    </Route>
    <Route path='/dashcriteria4'>
      <Dashcriteria4 />
    </Route>
    <Route path='/dashcriteria5'>
      <Dashcriteria5 />
    </Route>
    <Route path='/dashcriteria6'>
      <Dashcriteria6 />
    </Route>
    <Route path='/webinarregister/:id'>
      <Webinarregister />
    </Route>
    <Route path='/parentfeedbackanalysis'>
      <Parentfeedbackanalysis1 />
    </Route>
    <Route path='/curriculumfeedbackoption1'>
      <Curriculumfeedbackopt1 />
    </Route>
    <Route path='/employerfeedbackanalysis'>
      <Employerfeedbackanalysis />
    </Route>
    <Route path='/viewalerts'>
      <Viewalerts />
    </Route>
    <Route path='/viewkpi'>
      <Viewkpi />
    </Route>
    <Route path='/addphdguidebulk'>
      <Addphdguidebulk/>
    </Route>
    <Route path='/viewdynamicmodules'>
      <Viewdynamicmodules />
    </Route>
    <Route path='/selectcourse'>
      <Selectcourse />
    </Route>
    <Route path='/viewdynamicworkdiary'>
      <Viewdynamicworkdiary />
    </Route>
    <Route path='/viewvacstudlist'>
      <Viewvacstudlist />
    </Route>
    <Route path='/dashfacultydetails'>
      <Dashfacultydetails />
    </Route>
    <Route path='/viewallbooks'>
      <Viewallbooks />
    </Route>
    <Route path='/viewallprojects'>
      <Viewallprojects />
    </Route>
    <Route path='/viewallpublications'>
      <Viewallpubs />
    </Route>
    <Route path='/viewallpatents'>
      <Viewallpatents />
    </Route>
    <Route path='/viewallseminars'>
      <Viewallseminars />
    </Route>
    <Route path='/viewaddoncourseadmin'>
      <Viewaddoncourseadmin />
    </Route>
    <Route path='/viewadmissionadmin'>
      <Viewadmissionadmin />
    </Route>
    <Route path='/viewbosadmin'>
      <Viewbosadmin />
    </Route>
    <Route path='/viewcollaborationadmin'>
      <Viewcollaborationadmin />
    </Route>
    <Route path='/vieweventadmin'>
      <Vieweventadmin />
    </Route>
    <Route path='/viewexpenditureadmin'>
      <Viewexpenditureadmin />
    </Route>
    <Route path='/viewextensionactadmin'>
      <Viewextensionactadmin />
    </Route>
    <Route path='/viewictadmin'>
      <Viewictadmin />
    </Route>
    <Route path='/viewinstawardsadmin'>
      <Viewinstawardsadmin />
    </Route>
    <Route path='/viewlibraryexpadmin'>
      <Viewlibraryexpadmin />
    </Route>
    <Route path='/viewmouadmin'>
      <Viewmouadmin />
    </Route>
    <Route path='/viewpassexamadmin'>
      <Viewpassexamadmin />
    </Route>
    <Route path='/viewreservecatadmin'>
      <Viewreservecatadmin />
    </Route>
    <Route path='/deleteroleuser'>
      <Deleteroleuser />
    </Route>
    <Route path='/vieweventextension'>
      <Vieweventextension />
    </Route>
    <Route path='/vieweventipr'>
      <Vieweventipr />
    </Route>
    <Route path='/viewmlink'>
      <Viewmlink />
    </Route>
    <Route path='/editmlink'>
      <Editmlink />
    </Route>
    <Route path='/addmlink'>
      <Addmlink />
    </Route>


    <Route path='/addsdg3'>
      <Addsdg3/>
    </Route>
    <Route path='/editsdg3'>
      <Editsdg3 />
    </Route>
    <Route path='/viewsdg3'>
      <Viewsdg3 />
    </Route>
    <Route path='/addsdg4'>
      <Addsdg4/>
    </Route>
    <Route path='/editsdg4'>
      <Editsdg4 />
    </Route>
    <Route path='/viewsdg4'>
      <Viewsdg4 />
    </Route>
    <Route path='/addsdg5'>
      <Addsdg5/>
    </Route>
    <Route path='/editsdg5'>
      <Editsdg5 />
    </Route>
    <Route path='/viewsdg5'>
      <Viewsdg5 />
    </Route>
    <Route path='/addsdg8'>
      <Addsdg8/>
    </Route>
    <Route path='/editsdg8'>
      <Editsdg8 />
    </Route>
    <Route path='/viewsdg8'>
      <Viewsdg8 />
    </Route>
    <Route path='/addsdg9'>
      <Addsdg9/>
    </Route>
    <Route path='/editsdg9'>
      <Editsdg9 />
    </Route>
    <Route path='/viewsdg9'>
      <Viewsdg9 />
    </Route>
    <Route path='/addsdg10'>
      <Addsdg10/>
    </Route>
    <Route path='/editsdg10'>
      <Editsdg10 />
    </Route>
    <Route path='/viewsdg10'>
      <Viewsdg10 />
    </Route>
    <Route path='/addsdg11'>
      <Addsdg11/>
    </Route>
    <Route path='/editsdg11'>
      <Editsdg11 />
    </Route>
    <Route path='/viewsdg11'>
      <Viewsdg11 />
    </Route>
    <Route path='/addsdg12'>
      <Addsdg12/>
    </Route>
    <Route path='/editsdg12'>
      <Editsdg12 />
    </Route>
    <Route path='/viewsdg12'>
      <Viewsdg12 />
    </Route>
    <Route path='/addsdg13'>
      <Addsdg13/>
    </Route>
    <Route path='/editsdg13'>
      <Editsdg13 />
    </Route>
    <Route path='/viewsdg13'>
      <Viewsdg13 />
    </Route>
    <Route path='/addsdg16'>
      <Addsdg16/>
    </Route>
    <Route path='/editsdg16'>
      <Editsdg16 />
    </Route>
    <Route path='/viewsdg16'>
      <Viewsdg16 />
    </Route>
    <Route path='/addsdg17'>
      <Addsdg17/>
    </Route>
    <Route path='/editsdg17'>
      <Editsdg17 />
    </Route>
    <Route path='/viewsdg17'>
      <Viewsdg17 />
    </Route>
    <Route path='/viewsdg'>
      <Viewsdg />
    </Route>

    <Route path='/addsdg1'>
      <Addsdg1/>
    </Route>
    <Route path='/editsdg1'>
      <Editsdg1 />
    </Route>
    <Route path='/viewsdg1'>
      <Viewsdg1 />
    </Route>
    <Route path='/addsdg2'>
      <Addsdg2/>
    </Route>
    <Route path='/editsdg2'>
      <Editsdg2 />
    </Route>
    <Route path='/viewsdg2'>
      <Viewsdg2 />
    </Route>
    <Route path='/addsdg6'>
      <Addsdg6/>
    </Route>
    <Route path='/editsdg6'>
      <Editsdg6 />
    </Route>
    <Route path='/viewsdg6'>
      <Viewsdg6 />
    </Route>
    <Route path='/addsdg7'>
      <Addsdg7/>
    </Route>
    <Route path='/editsdg7'>
      <Editsdg7 />
    </Route>
    <Route path='/viewsdg7'>
      <Viewsdg7 />
    </Route>
    <Route path='/addsdg14'>
      <Addsdg14/>
    </Route>
    <Route path='/editsdg14'>
      <Editsdg14 />
    </Route>
    <Route path='/viewsdg14'>
      <Viewsdg14 />
    </Route>
    <Route path='/addsdg15'>
      <Addsdg15/>
    </Route>
    <Route path='/editsdg15'>
      <Editsdg15 />
    </Route>
    <Route path='/viewsdg15'>
      <Viewsdg15 />
    </Route>




    <Route path='/viewawardsadmin'>
      <Viewawardsadmin />
    </Route>
    <Route path='/viewcareercounseladmin'>
      <Viewcareercounseladmin />
    </Route>
    <Route path='/viewcbcsadmin'>
      <Viewcbcsadmin />
    </Route>
    <Route path='/viewegovernadmin'>
      <Viewegovernadmin />
    </Route> 
    <Route path='/viewhighereduadmin'>
      <Viewhighereduadmin />
    </Route>
    <Route path='/viewhigherexamadmin'>
      <Viewhigherexamadmin />
    </Route>
    <Route path='/viewplacementadmin'>
      <Viewplacementadmin />
    </Route>
    <Route path='/viewqualityadmin'>
      <Viewqualityadmin />
    </Route>
    <Route path='/viewskilldevadmin'>
      <Viewskilldevadmin />
    </Route>
    <Route path='/viewteacherdataadmin'>
      <Viewteacherdataadmin />
    </Route>
    <Route path='/viewteacherfsadmin'>
      <Viewteacherfsadmin />
    </Route>




    <Route path='/addaddoncourse'>
      <Addaddoncourse />
    </Route>
    <Route path='/addadmission'>
      <Addadmission />
    </Route>
    <Route path='/addbos'>
      <Addbos />
    </Route>
    <Route path='/addcbcs'>
      <Addcbcs />
    </Route>
    <Route path='/addcollaboration'>
      <Addcollaboration />
    </Route>
    <Route path='/addevent'>
      <Addevent />
    </Route>
    <Route path='/addexpenditure'>
      <Addexpenditure />
    </Route>
    <Route path='/addextensionact'>
      <Addextensionact />
    </Route>
    <Route path='/addict'>
      <Addict />
    </Route>
    <Route path='/addinstawards'>
      <Addinstawards />
    </Route>
    <Route path='/addlibraryexp'>
      <Addlibraryexp />
    </Route>
    <Route path='/addmou'>
      <Addmou />
    </Route>
    <Route path='/addpassexam'>
      <Addpassexam />
    </Route>
    <Route path='/addreservecategory'>
      <Addreservecat />
    </Route>
    <Route path='/addteacherdata'>
      <Addteacherdata />
    </Route>
  
    <Route path='/addegovern'>
      <Addegovern />
    </Route>
    <Route path='/addcareercounsel'>
      <Addcareercounsel />
    </Route>
    <Route path='/addawards'>
      <Addawards />
    </Route>
    <Route path='/addhigheredu'>
      <Addhigheredu />
    </Route>
    <Route path='/addhigherexam'>
      <Addhigherexam />
    </Route>
    <Route path='/addplacement'>
      <Addplacement />
    </Route>
    <Route path='/addquality'>
      <Addquality />
    </Route>
    <Route path='/addskilldev'>
      <Addskilldev />
    </Route>
    <Route path='/addteacherfs'>
      <Addteacherfs />
    </Route>


    <Route path='/viewaddoncourse'>
      <Viewaddoncourse />
    </Route>
    <Route path='/viewadmission'>
      <Viewadmission />
    </Route>
    <Route path='/viewbos'>
      <Viewbos />
    </Route>
    <Route path='/viewcbcs'>
      <Viewcbcs />
    </Route>
    <Route path='/viewcollaboration'>
      <Viewcollaboration />
    </Route>
    <Route path='/viewevent'>
      <Viewevent />
    </Route>
    <Route path='/viewexpenditure'>
      <Viewexpenditure />
    </Route>
    <Route path='/viewextact'>
      <Viewextensionact />
    </Route>
    <Route path='/viewict'>
      <Viewict />
    </Route>
    <Route path='/viewextawards'>
      <Viewinstawards />
    </Route>
    <Route path='/viewlibrary'>
      <Viewlibraryexp />
    </Route>
    <Route path='/viewmou'>
      <Viewmou />
    </Route>
    <Route path='/viewpassexam'>
      <Viewpassexam />
    </Route>
    <Route path='/viewreservecat'>
      <Viewreservecat />
    </Route>
    <Route path='/viewteacherdata'>
      <Viewteacherdata />
    </Route>

    <Route path='/viewegovern'>
      <Viewegovern />
    </Route>
    <Route path='/viewcareercounsel'>
      <Viewcareercounsel />
    </Route>
    <Route path='/viewawards'>
      <Viewawards />
    </Route>
    <Route path='/viewhigheredu'>
      <Viewhigheredu />
    </Route>
    <Route path='/viewhigherexam'>
      <Viewhigherexam />
    </Route>
    <Route path='/viewplacement'>
      <Viewplacement />
    </Route>
    <Route path='/viewquality'>
      <Viewquality />
    </Route>
    <Route path='/viewskilldev'>
      <Viewskilldev />
    </Route>
    <Route path='/viewteacherfs'>
      <Viewteacherfs />
    </Route>

    <Route path='/editaddoncourse'>
      <Editaddoncourse />
    </Route>
    <Route path='/editadmission'>
      <Editadmission />
    </Route>
    <Route path='/editbos'>
      <Editbos />
    </Route>
    <Route path='/editcbcs'>
      <Editcbcs />
    </Route>
    <Route path='/editcollaboration'>
      <Editcollaboration />
    </Route>
    <Route path='/editevent'>
      <Editevent />
    </Route>
    <Route path='/editexpenditure'>
      <Editexpenditure />
    </Route>
    <Route path='/editextensionact'>
      <Editextensionact />
    </Route>
    <Route path='/editict'>
      <Editict />
    </Route>
    <Route path='/editinstawards'>
      <Editinstawards />
    </Route>
    <Route path='/editlibraryexp'>
      <Editlibraryexp />
    </Route>
    <Route path='/editmou'>
      <Editmou />
    </Route>
    <Route path='/editpassexam'>
      <Editpassexam />
    </Route>
    <Route path='/editreservecategory'>
      <Editreservecat />
    </Route>
    <Route path='/editteacherdata'>
      <Editteacherdata />
    </Route>

    <Route path='/editegovern'>
      <Editegovern />
    </Route>
    <Route path='/editcareercounsel'>
      <Editcareercounsel />
    </Route>
    <Route path='/editawards'>
      <Editawards />
    </Route>
    <Route path='/edithigheredu'>
      <Edithigheredu />
    </Route>
    <Route path='/edithigherexam'>
      <Edithigherexam />
    </Route>
    <Route path='/editplacement'>
      <Editplacement />
    </Route>
    <Route path='/editquality'>
      <Editquality />
    </Route>
    <Route path='/editskilldev'>
      <Editskilldev />
    </Route>
    <Route path='/editteacherfs'>
      <Editteacherfs />
    </Route>

    <Route path='/addprojectstatus'>
      <Addprojectstatus1 />
    </Route>
    <Route path='/editprojectstatus'>
      <Editprojectstatus />
    </Route>
    <Route path='/viewprojectstatus'>
      <Viewprojectstatus />
    </Route>
    <Route path='/viewprojectstatusv'>
      <Viewprojectstatusv />
    </Route>
    <Route path='/viewprojectstatusbytaskdep'>
      <Viewprojectstatusbytaskdep />
    </Route>
    <Route path='/viewprojectstatusbytask'>
      <Viewprojectstatusbytask />
    </Route>

    <Route path='/addkpi'>
      <Addkpi/>
    </Route>
    <Route path='/editkpi'>
      <Editkpi />
    </Route>
    <Route path='/viewkpiuser'>
      <Viewkpiuser />
    </Route>

<Route path='/addtasks'>
      <Addtasks/>
    </Route>
    <Route path='/edittasks'>
      <Edittasks />
    </Route>
    <Route path='/viewtasks'>
      <Viewtasks />
    </Route>



    <Route path='/addresult'>
      <Addresult/>
    </Route>
    <Route path='/editresult'>
      <Editresult />
    </Route>
    <Route path='/viewresult'>
      <Viewresult />
    </Route>

    <Route path='/addecontent'>
      <Addecontent/>
    </Route>
    <Route path='/editecontent'>
      <Editecontent />
    </Route>
    <Route path='/viewecontent'>
      <Viewecontent />
    </Route>

    <Route path='/addscholarship'>
      <Addscholarship/>
    </Route>
    <Route path='/editscholarship'>
      <Editscholarship />
    </Route>
    <Route path='/viewscholarship'>
      <Viewscholarship />
    </Route>

    <Route path='/addfunds'>
      <Addfunds/>
    </Route>
    <Route path='/editfunds'>
      <Editfunds />
    </Route>
    <Route path='/viewfunds'>
      <Viewfunds />
    </Route>

    <Route path='/addseedm'>
      <Addseedm/>
    </Route>
    <Route path='/editseedm'>
      <Editseedm />
    </Route>
    <Route path='/viewseedm'>
      <Viewseedm />
    </Route>

    <Route path='/addexplearnprojbulk'>
      <Addexplearnprojbulk/>
    </Route>
    <Route path='/addfieldprojbulk'>
      <Addfieldprojbulk/>
    </Route>
    <Route path='/addteacherguidebulk'>
      <Addteacherguidebulk/>
    </Route>
    <Route path='/addsportsactbulk'>
      <Addsportsactbulk/>
    </Route>
    <Route path='/addegovbulk'>
      <Addegovbulk/>
    </Route>
    <Route path='/addeventbulk'>
      <Addeventbulk/>
    </Route>
    <Route path='/addfdpbulk'>
      <Addfdpbulk/>
    </Route>
    <Route path='/addqualityinitbulk'>
      <Addqualityinitbulk/>
    </Route>
    <Route path='/addmouactbulk'>
      <Addmouactbulk/>
    </Route>

    <Route path='/addconsultancy'>
      <Addconsultancy/>
    </Route>
    <Route path='/editconsultancy'>
      <Editconsultancy />
    </Route>
    <Route path='/viewconsultancy'>
      <Viewconsultancy />
    </Route>

    <Route path='/addsyllabusrev'>
      <Addsyllabusrev/>
    </Route>
    <Route path='/editsyllabusrev'>
      <Editsyllabusrev />
    </Route>
    <Route path='/viewsyllabusrev'>
      <Viewsyllabusrev />
    </Route>


    <Route path='/addexplearning'>
      <Addexplearning/>
    </Route>
    <Route path='/editexplearning'>
      <Editexplearning />
    </Route>
    <Route path='/viewexplearning'>
      <Viewexplearning />
    </Route>




    <Route path='/addexplearnproj'>
      <Addexplearnproj/>
    </Route>
    <Route path='/editexplearnproj'>
      <Editexplearnproj />
    </Route>
    <Route path='/viewexplearnproj'>
      <Viewexplearnproj />
    </Route>
    <Route path='/addfieldproj'>
      <Addfieldproj/>
    </Route>
    <Route path='/editfieldproj'>
      <Editfieldproj />
    </Route>
    <Route path='/viewfieldproj'>
      <Viewfieldproj />
    </Route>
    <Route path='/addteacherguide'>
      <Addteacherguide/>
    </Route>
    <Route path='/editteacherguide'>
      <Editteacherguide />
    </Route>
    <Route path='/viewteacherguide'>
      <Viewteacherguide />
    </Route>
    <Route path='/addextawards'>
      <Addextawards/>
    </Route>
    <Route path='/editextawards'>
      <Editextawards />
    </Route>
    <Route path='/viewextawards'>
      <Viewextawards />
    </Route>
    <Route path='/addmouact'>
      <Addmouact/>
    </Route>
    <Route path='/editmouact'>
      <Editmouact />
    </Route>
    <Route path='/viewmouact'>
      <Viewmouact />
    </Route>
    <Route path='/addsportsact'>
      <Addsportsact/>
    </Route>
    <Route path='/editsportsact'>
      <Editsportsact />
    </Route>
    <Route path='/viewsportsact'>
      <Viewsportsact />
    </Route>
    <Route path='/addegov'>
      <Addegov/>
    </Route>
    <Route path='/editegov'>
      <Editegov />
    </Route>
    <Route path='/viewegov'>
      <Viewegov />
    </Route>
    <Route path='/addfdp'>
      <Addfdp/>
    </Route>
    <Route path='/editfdp'>
      <Editfdp />
    </Route>
    <Route path='/viewfdp'>
      <Viewfdp />
    </Route>
    <Route path='/addqualityinit'>
      <Addqualityinit/>
    </Route>
    <Route path='/editqualityinit'>
      <Editqualityinit />
    </Route>
    <Route path='/viewqualityinit'>
      <Viewqualityinit />
    </Route>


    <Route path='/addbulkpublications'>
      <Addbulkpublications/>
    </Route>
    <Route path='/addbulkpatents'>
      <Addbulkpatents/>
    </Route>
    <Route path='/addbulkprojects'>
      <Addbulkprojects/>
    </Route>
    <Route path='/addbulkbooks'>
      <Addbulkbooks/>
    </Route>
    <Route path='/addbulkseminar'>
      <Addbulkseminar/>
    </Route>



    <Route path='/addemployability'>
      <Addemployability/>
    </Route>
    <Route path='/editemployability'>
      <Editemployability />
    </Route>
    <Route path='/viewemployability'>
      <Viewemployability />
    </Route>

    <Route path='/addphdguide'>
      <Addphdguide/>
    </Route>
    <Route path='/editphdguide'>
      <Editphdguide />
    </Route>
    <Route path='/viewphdguide'>
      <Viewphdguide />
    </Route>

    <Route path='/addteacheraward'>
      <Addteacheraward />
    </Route>
    <Route path='/editteacheraward'>
      <Editteacheraward />
    </Route>
    <Route path='/viewteacheraward'>
      <Viewteacheraward />
    </Route>

    <Route path='/addexamautomation'>
      <Addexamautomation/>
    </Route>
    <Route path='/editexamautomation'>
      <Editexamautomation />
    </Route>
    <Route path='/viewexamautomation'>
      <Viewexamautomation />
    </Route>

    <Route path='/addteacherfellow'>
      <Addteacherfellow/>
    </Route>
    <Route path='/editteacherfellow'>
      <Editteacherfellow />
    </Route>
    <Route path='/viewteacherfellow'>
      <Viewteacherfellow />
    </Route>

    <Route path='/addresearchfellow'>
      <Addresearchfellow/>
    </Route>
    <Route path='/editresearchfellow'>
      <Editresearchfellow />
    </Route>
    <Route path='/viewresearchfellow'>
      <Viewresearchfellow />
    </Route>

    <Route path='/addmentees'>
      <Addmentees/>
    </Route>
    <Route path='/editmentees'>
      <Editmentees />
    </Route>
    <Route path='/viewmentees'>
      <Viewmentees />
    </Route>

    <Route path='/addalumnicon'>
      <Addalumnicon/>
    </Route>
    <Route path='/editalumnicon'>
      <Editalumnicon />
    </Route>
    <Route path='/viewalumnicon'>
      <Viewalumnicon />
    </Route>




    <Route path='/addbosbulk'>
      <Addbosbulk/>
    </Route>
    <Route path='/addcbcsbulk'>
      <Addcbcsbulk/>
    </Route>
    <Route path='/addsyllabusrevbulk'>
      <Addsyllabusrevbulk/>
    </Route>
    <Route path='/addemployabilitybulk'>
      <Addemployabilitybulk/>
    </Route>
    <Route path='/addexplearningbulk'>
      <Addexplearningbulk/>
    </Route>

    <Route path='/addadmissionbulk'>
      <Addadmissionbulk/>
    </Route>
    <Route path='/addreservecatbulk'>
      <Addreservecatbulk/>
    </Route>
    <Route path='/addteacherdatabulk'>
      <Addteacherdatabulk/>
    </Route>
    <Route path='/addpassexambulk'>
      <Addpassexambulk/>
    </Route>
    <Route path='/addresultbulk'>
      <Addresultbulk/>
    </Route>
    <Route path='/addteacherawardbulk'>
      <Addteacherawardbulk/>
    </Route>
    <Route path='/addexamautomationbulk'>
      <Addexamautomationbulk/>
    </Route>
    <Route path='/addmenteesbulk'>
      <Addmenteesbulk/>
    </Route>

    <Route path='/addinstawardsbulk'>
      <Addinstawardsbulk/>
    </Route>
    <Route path='/addextactbulk'>
      <Addextactbulk/>
    </Route>
    <Route path='/addcollaborationbulk'>
      <Addcollaborationbulk/>
    </Route>
    <Route path='/addmoubulk'>
      <Addmoubulk/>
    </Route>
    <Route path='/addecontentbulk'>
      <Addecontentbulk/>
    </Route>
    <Route path='/addseedmbulk'>
      <Addseedmbulk/>
    </Route>
    <Route path='/addteacherfellowbulk'>
      <Addteacherfellowbulk/>
    </Route>
    <Route path='/addresearchfellowbulk'>
      <Addresearchfellowbulk/>

    </Route>
    <Route path='/addictbulk'>
      <Addictbulk/>
    </Route>
    <Route path='/addexpenditurebulk'>
      <Addexpenditurebulk/>
    </Route>
    <Route path='/addlibrarybulk'>
      <Addlibrarybulk/>
    </Route>

    <Route path='/addscholarshipbulk'>
      <Addscholarshipbulk/>
    </Route>
    <Route path='/addskilldevbulk'>
      <Addskilldevbulk/>
    </Route>
    <Route path='/addcareercounselbulk'>
      <Addcareercounselbulk/>
    </Route>
    <Route path='/addplacementbulk'>
      <Addplacementbulk/>
    </Route>
    <Route path='/addhigheredubulk'>
      <Addhigheredubulk/>
    </Route>
    <Route path='/addhigherexambulk'>
      <Addhigherexambulk/>
    </Route>
    <Route path='/addawardsbulk'>
      <Addawardsbulk/>
    </Route>
    <Route path='/addalumniconbulk'>
      <Addalumniconbulk/>
    </Route>

    <Route path='/addegovernbulk'>
      <Addegovernbulk/>
    </Route>
    <Route path='/addteacherfsbulk'>
      <Addteacherfsbulk/>
    </Route>
    <Route path='/addfundsbulk'>
      <Addfundsbulk/>
    </Route>
    <Route path='/addqualitybulk'>
      <Addqualitybulk/>
    </Route>

    <Route path='/viewecontentadmin'>
      <Viewecontentadmin />
    </Route>
    <Route path='/viewalumniconadmin'>
      <Viewalumniconadmin />
    </Route>
    <Route path='/viewresultadmin'>
      <Viewresultadmin />
    </Route>
    <Route path='/viewexamautomationadmin'>
      <Viewexamautomationadmin />
    </Route> 
    <Route path='/viewseedmadmin'>
      <Viewseedmadmin />
    </Route>
    <Route path='/viewmenteesadmin'>
      <Viewmenteesadmin />
    </Route>
    <Route path='/viewteacherawardadmin'>
      <Viewteacherawardadmin />
    </Route>
    <Route path='/viewconsultancyadmin'>
      <Viewconsultancyadmin />
    </Route>
    <Route path='/viewscholarshipadmin'>
      <Viewscholarshipadmin />
    </Route>
    <Route path='/viewdeptreport'>
      <Viewdeptreport/>
    </Route>





    
<Route path='/viewadmissiondoccommentsd'>
      <Viewadmissiondoccommentsd />
    </Route>
    <Route path='/viewallbookdoccommentsd'>
      <Viewallbookdoccommentsd />
    </Route>
    <Route path='/viewallpatentdocsd'>
      <Viewallpatentdocsd />
    </Route>
    <Route path='/viewallpubdoccomcyd'>
      <Viewallpubdoccomcyd />
    </Route> 
    <Route path='/viewallpubdoccommentsd'>
      <Viewallpubdoccommentsd />
    </Route>
    <Route path='/viewallpubdocsd'>
      <Viewallpubdocsd />
    </Route>
    <Route path='/viewallseminardocsd'>
      <Viewallseminardocsd />
    </Route>
    <Route path='/viewallsemreimbdocsd'>
      <Viewallsemreimbdocsd />
    </Route>
    <Route path='/viewbosdoccommentsd'>
      <Viewbosdoccommentsd />
    </Route>
    <Route path='/viewcareerdoccommentsd'>
      <Viewcareerdoccommentsd />
    </Route>
    <Route path='/viewcbcsdocd'>
      <Viewcbcsdocd />
    </Route>

    <Route path='/viewcollabdoccommentsd'>
      <Viewcollabdoccommentsd />
    </Route>
    <Route path='/viewemployabilitydocsd'>
      <Viewemployabilitydocsd />
    </Route>
    <Route path='/vieweventdoccommentsd'>
      <Vieweventdoccommentsd />
    </Route>
    <Route path='/viewexplearningdocsd'>
      <Viewexplearningdocsd />
    </Route>
    <Route path='/viewexplearningprojdocd'>
      <Viewexplearningprojdocd />
    </Route>
    <Route path='/viewictdoccommentsd'>
      <Viewictdoccommentsd />
    </Route>
    <Route path='/viewmoudoccommentsd'>
      <Viewmoudoccommentsd />
    </Route>

    <Route path='/viewpassexamdocsd'>
      <Viewpassexamdocsd />
    </Route>
    <Route path='/viewphdguidedoccommentsd'>
      <Viewphdguidedoccommentsd />
    </Route>
    <Route path='/viewplacementdoccommentsd'>
      <Viewplacementdoccommentsd />
    </Route>
    <Route path='/viewprojectyrdocsd'>
      <Viewprojectyrdocsd />
    </Route>
    <Route path='/viewreservecatdoccommentsd'>
      <Viewreservecatdoccommentsd />
    </Route>
    <Route path='/viewscholarshipdoccommentsd'>
      <Viewscholarshipdoccommentsd />
    </Route>
    <Route path='/viewsyllabusrevdocsd'>
      <Viewsyllabusrevdocsd />
    </Route>

    <Route path='/viewteacherawarddoccommentsd'>
      <Viewteacherawarddoccommentsd />
    </Route>
    <Route path='/viewteacherdatadocd'>
      <Viewteacherdatadocd />
    </Route>
    <Route path='/viewteacherfellowdoccommentsd'>
      <Viewteacherfellowdoccommentsd />
    </Route>
    <Route path='/viewsyllabusrevdocsd'>
      <Viewsyllabusrevdocsd />
    </Route>
    <Route path='/viewaddoncdoccommentsd'>
      <Viewaddoncdoccommentsd />
    </Route>







    </Switch>

    

  </div>
  );
}

export default App;
