import React, { useState, useContext, useEffect, useRef } from "react";
import classes from './form.module.css';
import { useHistory } from 'react-router-dom';
import global1 from './global1';
import { FileDrop } from 'react-file-drop';

import ep1 from '../api/ep1';
import {Link} from 'react-router-dom';
import FavoritesContext from '../store/favourites-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-date-picker';


import classes2 from './fileupload.module.css';
import classes1 from './newform2.module.css';
import './Demo.css';

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidefacNavigation from "../components/layout/SidefacNavigation";
import Sideicon from '../components/layout/Sideicon';
import SidedocNavigation from '../components/layout/An';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';



function Newclasses() {

    const favcontxt=useContext(FavoritesContext);



    const collegeref=useRef();
const departmentref=useRef();
const degrencrseref=useRef();
const facultyref=useRef();
const ifregularref=useRef();
const designationref=useRef();
const resadrs1ref=useRef();
const resadrs2ref=useRef();
const districtref=useRef();
const phoneref=useRef();
const mobileref=useRef();
const emailref=useRef();
const genderref=useRef();
const communityref=useRef();
const panref=useRef();
const passportref=useRef();
const adharref=useRef();
const fcodecoeref=useRef();
const fcodeaicteref=useRef();
const dobref=useRef();
const ageref=useRef();
const payscaleref=useRef();
const basicpayref=useRef();
const emolumentsref=useRef();

  

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [term2, setTerm2] = useState('');
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());

    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [isUploading, setIsuploading] = useState(false);
    const styles = { border: '1px solid black', width: '90%', height: 300,  color: 'black', padding: 20 };

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const name=global1.name;
    const user=global1.user;
    const courseid=global1.courseid;
    const coursecode=global1.coursecode;
    const coursename=global1.coursename;
    const token=global1.token;
    const accno=global1.accno;
    const book=global1.book;
    const department=global1.department;

    function logout() {
        favcontxt.addFavorite({
            studid: '',
            name: ''
        },'',0,'');
    }


    useEffect(() => {
       //logout();

    }, []);

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
    };

    const changeHandler1 = (files,event) => {
        setSelectedFile(files[0]);
        setIsFilePicked(true);
    };

    const searchapi = async () => {
       

        const college=collegeref.current.value;
const department=departmentref.current.value;
const degrencrse=degrencrseref.current.value;
const faculty=facultyref.current.value;
const ifregular=ifregularref.current.value;
const designation=designationref.current.value;
const resadrs1=resadrs1ref.current.value;
const resadrs2=resadrs2ref.current.value;
const district=districtref.current.value;
const phone=phoneref.current.value;
const mobile=mobileref.current.value;
const email=emailref.current.value;
const gender=genderref.current.value;
const community=communityref.current.value;
const pan=panref.current.value;
const passport=passportref.current.value;
const adhar=adharref.current.value;
const fcodecoe=fcodecoeref.current.value;
const fcodeaicte=fcodeaicteref.current.value;
const dob=dobref.current.value;
const age=ageref.current.value;
const payscale=payscaleref.current.value;
const basicpay=basicpayref.current.value;
const emoluments=emolumentsref.current.value;

        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response = await ep1.get('/api/v1/createpaunfacultydetailsbyfac', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: name,
                college:college,
department:department,
degrencrse:degrencrse,
faculty:faculty,
ifregular:ifregular,
designation:designation,
resadrs1:resadrs1,
resadrs2:resadrs2,
district:district,
phone:phone,
mobile:mobile,
email:email,
gender:gender,
community:community,
pan:pan,
passport:passport,
adhar:adhar,
fcodecoe:fcodecoe,
fcodeaicte:fcodeaicte,
dob:dob,
age:age,
payscale:payscale,
basicpay:basicpay,
emoluments:emoluments,
status1:'Submitted',
                comments:''

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        //alert(response.data.status);
        history.replace('/viewnpaunfacultydetails');
       
    };

   
           const width=window.innerWidth;
           const mdivwidth=width - 330;

           const openlink = async (link) => {
            
            var win = window.open(link, '_blank');
            win.focus();
          };


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width:60 }}>
                        <Sideicon />
                    </td>
                    <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <SidedocNavigation />
                    </td>
                    
                    <td> 
                        <TopNavigation />
                    <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
       >
                        
                        <p style={{ fontSize: 18 }}> Add data</p>
                        <hr />
                       
                            
                  Enter Name of the institution<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Name of the institution" id="college" ref={collegeref} />
<br /><br />

<label htmlFor="department">Name of the Department</label>

<select id="department" className="form-control"  ref={departmentref}>
<option value="AERONAUTICAL ENGINEERING">AERONAUTICAL ENGINEERING</option>
<option value="AEROSPACE ENGINEERING">AEROSPACE ENGINEERING</option>
<option value="APPLIED SCIENCE AND TECHNOLOGY">APPLIED SCIENCE AND TECHNOLOGY</option>
<option value="ARCHITECTURE">ARCHITECTURE</option>
<option value="AUTOMOBILE ENGINEERING">AUTOMOBILE ENGINEERING</option>
<option value="BIO">BIO</option>
</select>
<br /><br />

Enter Name of Degree & Course<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Name of Degree & Course" id="degrencrse" ref={degrencrseref} />
<br /><br />

Enter Name of faculty<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Name of faculty" id="faculty" ref={facultyref} />
<br /><br />

Enter If Faculty is Regular or Adjunct<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter If Faculty is Regular or Adjunct" id="ifregular" ref={ifregularref} />
<br /><br />

Enter Present designation<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Present designation" id="designation" ref={designationref} />
<br /><br />

Enter Residential Address Line 1<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Residential Address Line 1" id="resadrs1" ref={resadrs1ref} />
<br /><br />

Enter Residential Address Line 2<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Residential Address Line 2" id="resadrs2" ref={resadrs2ref} />
<br /><br />

Enter District<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter District" id="district" ref={districtref} />
<br /><br />

Enter Landline number<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter Landline number" id="phone" ref={phoneref} />
<br /><br />

Enter Mobile number<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter Mobile number" id="mobile" ref={mobileref} />
<br /><br />

Enter Email<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Email" id="email" ref={emailref} />
<br /><br />

<label htmlFor="gender">Gender</label>

<select id="gender" className="form-control"  ref={genderref}>
<option value="Male">Male</option>
<option value="Female">Female</option>
<option value="Transgender">Transgender</option>
</select>
<br /><br />

<label htmlFor="community">Community</label>

<select id="community" className="form-control"  ref={communityref}>
<option value="SC">SC</option>
<option value="SC">SC</option>
<option value="OC">OC</option>
<option value="BC">BC</option>
<option value="MBC">MBC</option>
<option value="Others">Others</option>
</select>
<br /><br />

Enter PAN number<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter PAN number" id="pan" ref={panref} />
<br /><br />

Enter Passport number<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Passport number" id="passport" ref={passportref} />
<br /><br />

Enter Aadhar number<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Aadhar number" id="adhar" ref={adharref} />
<br /><br />

Enter Faculty code given by COE<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Faculty code given by COE" id="fcodecoe" ref={fcodecoeref} />
<br /><br />

Enter Faculty code given by AICTE<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Faculty code given by AICTE" id="fcodeaicte" ref={fcodeaicteref} />
<br /><br />

Enter Date of Birth<br /><br />

<input type="date" required style={{width: "100%"}} placeholder="Enter Date of Birth" id="dob" ref={dobref} />
<br /><br />

Enter Age<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter Age" id="age" ref={ageref} />
<br /><br />

Enter Scale of Pay<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter Scale of Pay" id="payscale" ref={payscaleref} />
<br /><br />

Enter Basic Pay<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter Basic Pay" id="basicpay" ref={basicpayref} />
<br /><br />

Enter Total emoluments<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Total emoluments" id="emoluments" ref={emolumentsref} />
<br /><br />




                      
                        <button  onClick={searchapi}>Add data</button>

                      
      
            <div>
          
            <br />
            
                
            {isUploading ? (
				<div>
                    <p style={{fontSize: 18, fontWeight: 'bold'}}>Please wait</p>
					<img src="http://iqac.online/images/1480.gif" />
				</div>
			) : (
                <div></div>
			)}  
        
              
	
              
                <br />
                {term2}
            
          
           </div>
           </div>
           <FooterNav1 />
           </td></tr></table>
        </div>
    );

}

export default Newclasses;